/* tslint:disable */
/* eslint-disable */
/**
 * 默认接口
 * EasyLink 全新的易链系统
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import request, { BaseEntity, UniformResult, PagedResult, CustomUpdateModel } from '/@/utils/request';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, createUniformResult } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 干线费用 表单参数
 * @export
 * @interface AllocateCostFormData
 */
export interface AllocateCostFormData  extends BaseEntity {
    /**
     * 
     * @type {GanXianFeeTypeEnum}
     */
    'feeType': GanXianFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 干线费用 列表输出
 * @export
 * @interface AllocateCostListData
 */
export interface AllocateCostListData  extends BaseEntity {
    /**
     * 
     * @type {GanXianFeeTypeEnum}
     */
    'feeType': GanXianFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {FenBoFeeStatusEnum}
     */
    'status': FenBoFeeStatusEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 干线明细 表单参数
 * @export
 * @interface AllocateDetailsFormData
 */
export interface AllocateDetailsFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
}
/**
 * 干线明细 列表输出
 * @export
 * @interface AllocateDetailsListData
 */
export interface AllocateDetailsListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
    /**
     * 
     * @type {GanXianDetailsStatusEnum}
     */
    'status': GanXianDetailsStatusEnum;
}


/**
 * 干线分拨 表单参数
 * @export
 * @interface AllocateFormData
 */
export interface AllocateFormData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 日期
     * @type {string}
     */
    'date': string;
    /**
     * 预计到达
     * @type {string}
     */
    'arrivalDate'?: string | null;
    /**
     * 到站
     * @type {number}
     */
    'destination': number;
    /**
     * 距离
     * @type {number}
     */
    'distance': number | null;
    /**
     * GPS
     * @type {string}
     */
    'gps'?: string | null;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 干线分拨 列表输出
 * @export
 * @interface AllocateListData
 */
export interface AllocateListData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {GanXianStatusEnum}
     */
    'status': GanXianStatusEnum;
    /**
     * 日期
     * @type {string}
     */
    'date': string;
    /**
     * 预计到达
     * @type {string}
     */
    'arrivalDate'?: string | null;
    /**
     * 到站
     * @type {number}
     */
    'destination': number;
    /**
     * 距离
     * @type {number}
     */
    'distance': number | null;
    /**
     * GPS
     * @type {string}
     */
    'gps'?: string | null;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 基础机构 实体类
 * @export
 * @interface BaseOrg
 */
export interface BaseOrg  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 负责人
     * @type {string}
     */
    'manager': string;
    /**
     * 手机
     * @type {string}
     */
    'mobile': string;
    /**
     * 电话
     * @type {string}
     */
    'tel'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'area': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 坐标
     * @type {string}
     */
    'coordinate': string;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
    /**
     * 子机构
     * @type {Array<BaseOrg>}
     */
    'child'?: Array<BaseOrg> | null;
    /**
     * 
     * @type {Organization}
     */
    'orgInfo'?: Organization;
}
/**
 * 基础机构 表单参数
 * @export
 * @interface BaseOrgFormData
 */
export interface BaseOrgFormData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 负责人
     * @type {string}
     */
    'manager': string;
    /**
     * 手机
     * @type {string}
     */
    'mobile': string;
    /**
     * 电话
     * @type {string}
     */
    'tel'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'area': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 坐标
     * @type {string}
     */
    'coordinate': string;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
}
/**
 * 基础机构 列表输出
 * @export
 * @interface BaseOrgListData
 */
export interface BaseOrgListData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 负责人
     * @type {string}
     */
    'manager': string;
    /**
     * 手机
     * @type {string}
     */
    'mobile': string;
    /**
     * 电话
     * @type {string}
     */
    'tel'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'area': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
}
/**
 * 基础机构 打印
 * @export
 * @interface BaseOrgPrint
 */
export interface BaseOrgPrint  extends BaseEntity {
}
/**
 * 业务类型枚举<br />&nbsp;普通 PuTong = 0<br />&nbsp;汽运 QiYun = 1<br />&nbsp;空运 KongYun = 2<br />&nbsp;进港 JinGong = 3<br />&nbsp;出港 ChuGang = 4<br />&nbsp;城配 ChengPei = 5<br />
 * @export
 * @enum {string}
 */

export const BusinessTypeEnum = {
    PuTong: 'PuTong',
    QiYun: 'QiYun',
    KongYun: 'KongYun',
    JinGong: 'JinGong',
    ChuGang: 'ChuGang',
    ChengPei: 'ChengPei'
} as const;

export type BusinessTypeEnum = typeof BusinessTypeEnum[keyof typeof BusinessTypeEnum];


/**
 * 车辆类型<br />&nbsp;面包 XM = 0<br />&nbsp;金杯 ZM = 1<br />&nbsp;依维柯 YWK = 2<br />&nbsp;小货 XH = 3<br />&nbsp;4米2 H4M2 = 4<br />&nbsp;6米8 H6M8 = 5<br />&nbsp;7米6 H7M6 = 6<br />&nbsp;9米6 H9M6 = 7<br />&nbsp;13 H13M = 8<br />&nbsp;17米5 H17M5 = 9<br />
 * @export
 * @enum {string}
 */

export const CarTypeEnum = {
    Xm: 'XM',
    Zm: 'ZM',
    Ywk: 'YWK',
    Xh: 'XH',
    H4M2: 'H4M2',
    H6M8: 'H6M8',
    H7M6: 'H7M6',
    H9M6: 'H9M6',
    H13M: 'H13M',
    H17M5: 'H17M5'
} as const;

export type CarTypeEnum = typeof CarTypeEnum[keyof typeof CarTypeEnum];


/**
 * <br />&nbsp; Crashed = 0<br />&nbsp; Working = 1<br />&nbsp; Waiting = 2<br />
 * @export
 * @enum {string}
 */

export const ClusterStatus = {
    Crashed: 'Crashed',
    Working: 'Working',
    Waiting: 'Waiting'
} as const;

export type ClusterStatus = typeof ClusterStatus[keyof typeof ClusterStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ConditionalType = {
    Equal: 'Equal',
    Like: 'Like',
    GreaterThan: 'GreaterThan',
    GreaterThanOrEqual: 'GreaterThanOrEqual',
    LessThan: 'LessThan',
    LessThanOrEqual: 'LessThanOrEqual',
    In: 'In',
    NotIn: 'NotIn',
    LikeLeft: 'LikeLeft',
    LikeRight: 'LikeRight',
    NoEqual: 'NoEqual',
    IsNullOrEmpty: 'IsNullOrEmpty',
    IsNot: 'IsNot',
    NoLike: 'NoLike',
    EqualNull: 'EqualNull',
    InLike: 'InLike',
    Range: 'Range'
} as const;

export type ConditionalType = typeof ConditionalType[keyof typeof ConditionalType];


/**
 * 系统配置 表单参数
 * @export
 * @interface ConfigItemFormData
 */
export interface ConfigItemFormData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 
     * @type {ConfigTargetTypeEnum}
     */
    'targetType': ConfigTargetTypeEnum;
    /**
     * 键
     * @type {string}
     */
    'key': string;
    /**
     * 默认值
     * @type {string}
     */
    'value': string;
    /**
     * 值数据源
     * @type {number}
     */
    'dataSource': number | null;
}


/**
 * 系统配置 列表输出
 * @export
 * @interface ConfigItemListData
 */
export interface ConfigItemListData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 
     * @type {ConfigTargetTypeEnum}
     */
    'targetType': ConfigTargetTypeEnum;
    /**
     * 键
     * @type {string}
     */
    'key': string;
    /**
     * 默认值
     * @type {string}
     */
    'value': string;
    /**
     * 值数据源
     * @type {number}
     */
    'dataSource': number | null;
}


/**
 * 配置项目标值 表单参数
 * @export
 * @interface ConfigTargetFormData
 */
export interface ConfigTargetFormData  extends BaseEntity {
    /**
     * 配置编号
     * @type {number}
     */
    'configId': number;
    /**
     * 目标编号
     * @type {number}
     */
    'targetId': number;
    /**
     * 目标值
     * @type {string}
     */
    'value': string;
}
/**
 * 配置项目标值 列表输出
 * @export
 * @interface ConfigTargetListData
 */
export interface ConfigTargetListData  extends BaseEntity {
    /**
     * 配置编号
     * @type {number}
     */
    'configId': number;
    /**
     * 目标编号
     * @type {number}
     */
    'targetId': number;
    /**
     * 目标值
     * @type {string}
     */
    'value': string;
}
/**
 * 配置目标类型<br />&nbsp;系统 System = 0<br />&nbsp;机构 Org = 1<br />&nbsp;用户 User = 2<br />
 * @export
 * @enum {string}
 */

export const ConfigTargetTypeEnum = {
    System: 'System',
    Org: 'Org',
    User: 'User'
} as const;

export type ConfigTargetTypeEnum = typeof ConfigTargetTypeEnum[keyof typeof ConfigTargetTypeEnum];


/**
 * 合作模式<br />&nbsp;临时 Temporary = 0<br />&nbsp;合同 Contract = 1<br />&nbsp;自营 Own = 3<br />&nbsp;挂靠 Affiliated = 4<br />&nbsp;黑名单 Blacklist = 5<br />
 * @export
 * @enum {string}
 */

export const CooperationTypeEnum = {
    Temporary: 'Temporary',
    Contract: 'Contract',
    Own: 'Own',
    Affiliated: 'Affiliated',
    Blacklist: 'Blacklist'
} as const;

export type CooperationTypeEnum = typeof CooperationTypeEnum[keyof typeof CooperationTypeEnum];


/**
 * 收付关系<br />&nbsp;应收 Shou = 1<br />&nbsp;应付 Fu = -1<br />
 * @export
 * @enum {string}
 */

export const CostCtrlEnum = {
    Shou: 'Shou',
    Fu: 'Fu'
} as const;

export type CostCtrlEnum = typeof CostCtrlEnum[keyof typeof CostCtrlEnum];


/**
 * 内部报价 表单参数
 * @export
 * @interface CostQuoteFormData
 */
export interface CostQuoteFormData  extends BaseEntity {
    /**
     * 
     * @type {NeiBuFeeTypeEnum}
     */
    'feeType': NeiBuFeeTypeEnum;
    /**
     * 
     * @type {BusinessTypeEnum}
     */
    'businessType'?: BusinessTypeEnum;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'receivingType'?: ShouHuoTypeEnum;
    /**
     * 目的网点
     * @type {number}
     */
    'destinationStation': number;
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialArea'?: string | null;
    /**
     * 地址匹配
     * @type {string}
     */
    'specialAddress'?: string | null;
    /**
     * 异形费率
     * @type {number}
     */
    'irregularRates': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 内部报价公式 表单参数
 * @export
 * @interface CostQuoteFormulaFormData
 */
export interface CostQuoteFormulaFormData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 条件
     * @type {string}
     */
    'conditions': string;
    /**
     * 公式
     * @type {string}
     */
    'formula': string;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 内部报价公式 列表输出
 * @export
 * @interface CostQuoteFormulaListData
 */
export interface CostQuoteFormulaListData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 条件
     * @type {string}
     */
    'conditions': string;
    /**
     * 公式
     * @type {string}
     */
    'formula': string;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 内部报价 列表输出
 * @export
 * @interface CostQuoteListData
 */
export interface CostQuoteListData  extends BaseEntity {
    /**
     * 
     * @type {NeiBuFeeTypeEnum}
     */
    'feeType': NeiBuFeeTypeEnum;
    /**
     * 
     * @type {BusinessTypeEnum}
     */
    'businessType'?: BusinessTypeEnum;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'receivingType'?: ShouHuoTypeEnum;
    /**
     * 目的网点
     * @type {number}
     */
    'destinationStation': number;
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialArea'?: string | null;
    /**
     * 地址匹配
     * @type {string}
     */
    'specialAddress'?: string | null;
    /**
     * 异形费率
     * @type {number}
     */
    'irregularRates': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 内部报价模板 表单参数
 * @export
 * @interface CostQuoteTemplateFormData
 */
export interface CostQuoteTemplateFormData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 
     * @type {QuoteTypeEnum}
     */
    'quoteType': QuoteTypeEnum;
    /**
     * 最小值
     * @type {number}
     */
    'minValue': number;
    /**
     * 最大值
     * @type {number}
     */
    'maxValue': number;
    /**
     * 首值金额
     * @type {number}
     */
    'firstAmount': number;
    /**
     * 首值
     * @type {number}
     */
    'firstValue': number;
    /**
     * 单价
     * @type {number}
     */
    'unitPrice': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 内部报价模板 列表输出
 * @export
 * @interface CostQuoteTemplateListData
 */
export interface CostQuoteTemplateListData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 
     * @type {QuoteTypeEnum}
     */
    'quoteType': QuoteTypeEnum;
    /**
     * 最小值
     * @type {number}
     */
    'minValue': number;
    /**
     * 最大值
     * @type {number}
     */
    'maxValue': number;
    /**
     * 首值金额
     * @type {number}
     */
    'firstAmount': number;
    /**
     * 首值
     * @type {number}
     */
    'firstValue': number;
    /**
     * 单价
     * @type {number}
     */
    'unitPrice': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 内部报价关联 表单参数
 * @export
 * @interface CostQuoteWithFormData
 */
export interface CostQuoteWithFormData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 关联报价Id
     * @type {number}
     */
    'relatedQuoteId': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 内部报价关联 列表输出
 * @export
 * @interface CostQuoteWithListData
 */
export interface CostQuoteWithListData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 关联报价Id
     * @type {number}
     */
    'relatedQuoteId': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 客户类型<br />&nbsp;自动收录 AutomaticIngestion = 0<br />&nbsp;普通 Ordinary = 1<br />&nbsp;会员 Member = 2<br />&nbsp;同行 Fraternity = 3<br />&nbsp;黑名单 Blacklist = 4<br />
 * @export
 * @enum {string}
 */

export const CustomerTypeEnum = {
    AutomaticIngestion: 'AutomaticIngestion',
    Ordinary: 'Ordinary',
    Member: 'Member',
    Fraternity: 'Fraternity',
    Blacklist: 'Blacklist'
} as const;

export type CustomerTypeEnum = typeof CustomerTypeEnum[keyof typeof CustomerTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const DataRowVersion = {
    Original: 'Original',
    Current: 'Current',
    Proposed: 'Proposed',
    Default: 'Default'
} as const;

export type DataRowVersion = typeof DataRowVersion[keyof typeof DataRowVersion];


/**
 * 数据范围枚举<br />&nbsp;全部数据 All = 0<br />&nbsp;本部门及以下数据 OrgChild = 1<br />&nbsp;本部门数据 Org = 2<br />&nbsp;仅本人数据 Self = 3<br />&nbsp;自定义数据 Define = 4<br />
 * @export
 * @enum {string}
 */

export const DataScopeEnum = {
    All: 'All',
    OrgChild: 'OrgChild',
    Org: 'Org',
    Self: 'Self',
    Define: 'Define'
} as const;

export type DataScopeEnum = typeof DataScopeEnum[keyof typeof DataScopeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const DbType = {
    AnsiString: 'AnsiString',
    Binary: 'Binary',
    Byte: 'Byte',
    Boolean: 'Boolean',
    Currency: 'Currency',
    Date: 'Date',
    DateTime: 'DateTime',
    Decimal: 'Decimal',
    Double: 'Double',
    Guid: 'Guid',
    Int16: 'Int16',
    number: 'number',
    Object: 'Object',
    SByte: 'SByte',
    Single: 'Single',
    String: 'String',
    Time: 'Time',
    UInt16: 'UInt16',
    Unumber: 'Unumber',
    VarNumeric: 'VarNumeric',
    AnsiStringFixedLength: 'AnsiStringFixedLength',
    StringFixedLength: 'StringFixedLength',
    Xml: 'Xml',
    DateTime2: 'DateTime2',
    DateTimeOffset: 'DateTimeOffset'
} as const;

export type DbType = typeof DbType[keyof typeof DbType];


/**
 * 送货费用 表单参数
 * @export
 * @interface DeliveryCostFormData
 */
export interface DeliveryCostFormData  extends BaseEntity {
    /**
     * 
     * @type {SongHuoFeeTypeEnum}
     */
    'feeType': SongHuoFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 送货费用 列表输出
 * @export
 * @interface DeliveryCostListData
 */
export interface DeliveryCostListData  extends BaseEntity {
    /**
     * 
     * @type {SongHuoFeeTypeEnum}
     */
    'feeType': SongHuoFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {FenBoFeeStatusEnum}
     */
    'status': FenBoFeeStatusEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 送货明细 表单参数
 * @export
 * @interface DeliveryDetailsFormData
 */
export interface DeliveryDetailsFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
}
/**
 * 送货明细 列表输出
 * @export
 * @interface DeliveryDetailsListData
 */
export interface DeliveryDetailsListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
    /**
     * 
     * @type {SongHuoDetailsStatusEnum}
     */
    'status': SongHuoDetailsStatusEnum;
}


/**
 * 送货 表单参数
 * @export
 * @interface DeliveryFormData
 */
export interface DeliveryFormData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 卸车组
     * @type {number}
     */
    'unloader': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 送货 列表输出
 * @export
 * @interface DeliveryListData
 */
export interface DeliveryListData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {SongHuoStatusEnum}
     */
    'status': SongHuoStatusEnum;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 卸车组
     * @type {number}
     */
    'unloader': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 字典类别 表单参数
 * @export
 * @interface DicCategoryFormData
 */
export interface DicCategoryFormData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 编码
     * @type {string}
     */
    'code': string;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 字典类别 列表输出
 * @export
 * @interface DicCategoryListData
 */
export interface DicCategoryListData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 编码
     * @type {string}
     */
    'code': string;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 字典类别 打印
 * @export
 * @interface DicCategoryPrint
 */
export interface DicCategoryPrint  extends BaseEntity {
}
/**
 * 字典数据 表单参数
 * @export
 * @interface DicDataFormData
 */
export interface DicDataFormData  extends BaseEntity {
    /**
     * 类别编号
     * @type {number}
     */
    'dicTypeId': number;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 标签名
     * @type {string}
     */
    'label': string;
    /**
     * 值
     * @type {string}
     */
    'value': string;
    /**
     * 标签样式
     * @type {string}
     */
    'tagType'?: string | null;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 字典数据 列表输出
 * @export
 * @interface DicDataListData
 */
export interface DicDataListData  extends BaseEntity {
    /**
     * 类别编号
     * @type {number}
     */
    'dicTypeId': number;
    /**
     * 标签名
     * @type {string}
     */
    'label': string;
    /**
     * 值
     * @type {string}
     */
    'value': string;
    /**
     * 标签样式
     * @type {string}
     */
    'tagType'?: string | null;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 字典数据 打印
 * @export
 * @interface DicDataPrint
 */
export interface DicDataPrint  extends BaseEntity {
}
/**
 * 字典明细数据
 * @export
 * @interface DicDetailData
 */
export interface DicDetailData {
    /**
     * 主键
     * @type {number}
     */
    'id': number;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 标签名
     * @type {string}
     */
    'label': string;
    /**
     * 值
     * @type {string}
     */
    'value': string;
    /**
     * 标签样式
     * @type {string}
     */
    'tagType'?: string | null;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 字典信息数据
 * @export
 * @interface DicInfoData
 */
export interface DicInfoData {
    /**
     * 编号
     * @type {number}
     */
    'id': number;
    /**
     * 
     * @type {DicTypeEnum}
     */
    'type'?: DicTypeEnum;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 编码
     * @type {string}
     */
    'code': string;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 字典明细数据
     * @type {Array<DicDetailData>}
     */
    'dicDataList'?: Array<DicDetailData> | null;
}


/**
 * 字典类型<br />&nbsp;字典 Dictionary = 0<br />&nbsp;枚举 Enum = 1<br />&nbsp;常量 Const = 2<br />
 * @export
 * @enum {string}
 */

export const DicTypeEnum = {
    Dictionary: 'Dictionary',
    Enum: 'Enum',
    Const: 'Const'
} as const;

export type DicTypeEnum = typeof DicTypeEnum[keyof typeof DicTypeEnum];


/**
 * 
 * @export
 * @interface DiffLogColumnInfo
 */
export interface DiffLogColumnInfo {
    /**
     * 
     * @type {string}
     */
    'columnName'?: string | null;
    /**
     * 
     * @type {string}
     */
    'columnDescription'?: string | null;
    /**
     * 
     * @type {object}
     */
    'value'?: object | null;
    /**
     * 
     * @type {boolean}
     */
    'isPrimaryKey': boolean;
}
/**
 * 
 * @export
 * @interface DiffLogTableInfo
 */
export interface DiffLogTableInfo {
    /**
     * 
     * @type {string}
     */
    'tableName'?: string | null;
    /**
     * 
     * @type {string}
     */
    'tableDescription'?: string | null;
    /**
     * 
     * @type {Array<DiffLogColumnInfo>}
     */
    'columns'?: Array<DiffLogColumnInfo> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiffType = {
    Insert: 'insert',
    Update: 'update',
    Delete: 'delete'
} as const;

export type DiffType = typeof DiffType[keyof typeof DiffType];


/**
 * 司机资料 表单参数
 * @export
 * @interface DriverDataFormData
 */
export interface DriverDataFormData  extends BaseEntity {
    /**
     * 姓名
     * @type {string}
     */
    'name': string;
    /**
     * 手机
     * @type {string}
     */
    'mobile': string;
    /**
     * 身份证
     * @type {string}
     */
    'idCard'?: string | null;
    /**
     * 身份证结束日期
     * @type {string}
     */
    'endDate'?: string | null;
    /**
     * 驾驶证
     * @type {string}
     */
    'driverLicense'?: string | null;
    /**
     * 
     * @type {DrivingTypeEnum}
     */
    'drivingType'?: DrivingTypeEnum;
    /**
     * 驾驶证结束日期
     * @type {string}
     */
    'drivingLicenseEndDate'?: string | null;
    /**
     * 
     * @type {QualificationTypeEnum}
     */
    'qualificationType'?: QualificationTypeEnum;
    /**
     * 从业资格号
     * @type {string}
     */
    'qualificationNo'?: string | null;
    /**
     * 绑定车辆
     * @type {number}
     */
    'truckId': number | null;
    /**
     * 身份证正
     * @type {number}
     */
    'idCardFrontImageId': number | null;
    /**
     * 身份证反
     * @type {number}
     */
    'idCardReverseImageId': number | null;
    /**
     * 驾驶证主页
     * @type {number}
     */
    'driverLicenseFrontImageId': number | null;
    /**
     * 驾驶证副页
     * @type {number}
     */
    'driverLicenseReverseImageId': number | null;
    /**
     * 驾驶证其他
     * @type {number}
     */
    'driverLicenseOtherImageId': number | null;
    /**
     * 资格证主页
     * @type {number}
     */
    'qualificationImageId': number | null;
}


/**
 * 司机资料 列表输出
 * @export
 * @interface DriverDataListData
 */
export interface DriverDataListData  extends BaseEntity {
    /**
     * 姓名
     * @type {string}
     */
    'name': string;
    /**
     * 手机
     * @type {string}
     */
    'mobile': string;
    /**
     * 身份证
     * @type {string}
     */
    'idCard'?: string | null;
    /**
     * 身份证结束日期
     * @type {string}
     */
    'endDate'?: string | null;
    /**
     * 驾驶证
     * @type {string}
     */
    'driverLicense'?: string | null;
    /**
     * 
     * @type {DrivingTypeEnum}
     */
    'drivingType'?: DrivingTypeEnum;
    /**
     * 驾驶证结束日期
     * @type {string}
     */
    'drivingLicenseEndDate'?: string | null;
    /**
     * 
     * @type {QualificationTypeEnum}
     */
    'qualificationType'?: QualificationTypeEnum;
    /**
     * 从业资格号
     * @type {string}
     */
    'qualificationNo'?: string | null;
    /**
     * 绑定车辆
     * @type {number}
     */
    'truckId': number | null;
    /**
     * 身份证正
     * @type {number}
     */
    'idCardFrontImageId': number | null;
    /**
     * 身份证反
     * @type {number}
     */
    'idCardReverseImageId': number | null;
    /**
     * 驾驶证主页
     * @type {number}
     */
    'driverLicenseFrontImageId': number | null;
    /**
     * 驾驶证副页
     * @type {number}
     */
    'driverLicenseReverseImageId': number | null;
    /**
     * 驾驶证其他
     * @type {number}
     */
    'driverLicenseOtherImageId': number | null;
    /**
     * 资格证主页
     * @type {number}
     */
    'qualificationImageId': number | null;
}


/**
 * 驾驶证类型<br />&nbsp;A1 A1 = 0<br />&nbsp;A2 A2 = 1<br />&nbsp;A3 A3 = 2<br />&nbsp;B1 B1 = 3<br />&nbsp;B2 B2 = 4<br />&nbsp;C1 C1 = 5<br />&nbsp;C2 C2 = 6<br />&nbsp;C3 C3 = 7<br />&nbsp;C4 C4 = 8<br />&nbsp;C5 C5 = 9<br />&nbsp;D D = 10<br />&nbsp;E E = 11<br />&nbsp;F F = 12<br />&nbsp;M M = 13<br />&nbsp;N N = 14<br />&nbsp;P P = 15<br />
 * @export
 * @enum {string}
 */

export const DrivingTypeEnum = {
    A1: 'A1',
    A2: 'A2',
    A3: 'A3',
    B1: 'B1',
    B2: 'B2',
    C1: 'C1',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4',
    C5: 'C5',
    D: 'D',
    E: 'E',
    F: 'F',
    M: 'M',
    N: 'N',
    P: 'P'
} as const;

export type DrivingTypeEnum = typeof DrivingTypeEnum[keyof typeof DrivingTypeEnum];


/**
 * 短驳明细状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;配载 PeiZai = 1<br />&nbsp;装车 ZhuangChe = 2<br />&nbsp;卸车 XieChe = 3<br />&nbsp;完成 WanCheng = 4<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const DuanBoDetailsStatusEnum = {
    ChuShi: 'ChuShi',
    PeiZai: 'PeiZai',
    ZhuangChe: 'ZhuangChe',
    XieChe: 'XieChe',
    WanCheng: 'WanCheng',
    ZuoFei: 'ZuoFei'
} as const;

export type DuanBoDetailsStatusEnum = typeof DuanBoDetailsStatusEnum[keyof typeof DuanBoDetailsStatusEnum];


/**
 * 短驳费用类型<br />&nbsp;运费 YunFei = 0<br />&nbsp;装卸费 ZhuangXieFei = 1<br />&nbsp;其他费 QiTaFei = 2<br />&nbsp;应收款 YingShouKuan = 100<br />
 * @export
 * @enum {string}
 */

export const DuanBoFeeTypeEnum = {
    YunFei: 'YunFei',
    ZhuangXieFei: 'ZhuangXieFei',
    QiTaFei: 'QiTaFei',
    YingShouKuan: 'YingShouKuan'
} as const;

export type DuanBoFeeTypeEnum = typeof DuanBoFeeTypeEnum[keyof typeof DuanBoFeeTypeEnum];


/**
 * 短驳状态<br />&nbsp;开单 KaiDan = 1<br />&nbsp;装货 ZhuangHuo = 2<br />&nbsp;发车 FaChe = 3<br />&nbsp;到车 DaoChe = 4<br />&nbsp;卸货 XieHuo = 5<br />&nbsp;完成 WanCheng = 6<br />&nbsp;异常 YiChang = 7<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const DuanBoStatusEnum = {
    KaiDan: 'KaiDan',
    ZhuangHuo: 'ZhuangHuo',
    FaChe: 'FaChe',
    DaoChe: 'DaoChe',
    XieHuo: 'XieHuo',
    WanCheng: 'WanCheng',
    YiChang: 'YiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type DuanBoStatusEnum = typeof DuanBoStatusEnum[keyof typeof DuanBoStatusEnum];


/**
 * 对外费用类型<br />&nbsp;运费 YunFei = 0<br />&nbsp;送货费 SongHuoFei = 1<br />&nbsp;提货费 TiHuoFei = 2<br />&nbsp;装卸费 ZhuangXieFei = 3<br />&nbsp;上楼费 ShangLouFei = 4<br />&nbsp;回单费 HuiDanFei = 5<br />&nbsp;信息费 XinXiFei = 6<br />&nbsp;保险费 BaoXianFei = 7<br />&nbsp;叉车费 ChaCheFei = 8<br />&nbsp;仓储费 CangChuFei = 9<br />&nbsp;其他费 QiTaFei = 10<br />
 * @export
 * @enum {string}
 */

export const DuiWaiFeeTypeEnum = {
    YunFei: 'YunFei',
    SongHuoFei: 'SongHuoFei',
    TiHuoFei: 'TiHuoFei',
    ZhuangXieFei: 'ZhuangXieFei',
    ShangLouFei: 'ShangLouFei',
    HuiDanFei: 'HuiDanFei',
    XinXiFei: 'XinXiFei',
    BaoXianFei: 'BaoXianFei',
    ChaCheFei: 'ChaCheFei',
    CangChuFei: 'CangChuFei',
    QiTaFei: 'QiTaFei'
} as const;

export type DuiWaiFeeTypeEnum = typeof DuiWaiFeeTypeEnum[keyof typeof DuiWaiFeeTypeEnum];


/**
 * 动态增删改参数模型
 * @export
 * @interface DynamicCudModel
 */
export interface DynamicCudModel {
    /**
     * 待新增数据
     * @type {Array<object>}
     */
    'addData'?: Array<object> | null;
    /**
     * 待修改数据
     * @type {Array<{ [key: string]: object; }>}
     */
    'updateData'?: Array<{ [key: string]: object; }> | null;
    /**
     * 待删除主键
     * @type {Array<number>}
     */
    'deleteIds'?: Array<number> | null;
}
/**
 * 动态过滤参数模型
 * @export
 * @interface DynamicFilterModel
 */
export interface DynamicFilterModel {
    /**
     * 
     * @type {WhereType}
     */
    'joiner'?: WhereType;
    /**
     * 字段名
     * @type {string}
     */
    'fieldName'?: string | null;
    /**
     * 字段值
     * @type {string}
     */
    'fieldValue'?: string | null;
    /**
     * 
     * @type {ConditionalType}
     */
    'sqlOperator'?: ConditionalType;
    /**
     * 分组，优先级高于字段信息(同时存在分组和字段信息，则会无视字段信息，Joiner 会连接分组)
     * @type {Array<DynamicFilterModel>}
     */
    'group'?: Array<DynamicFilterModel> | null;
}


/**
 * 动态更新参数模型
 * @export
 * @interface DynamicUpdateModel
 */
export interface DynamicUpdateModel {
    /**
     * 主表待更新参数
     * @type {{ [key: string]: object | null; }}
     */
    'data'?: { [key: string]: object | null; } | null;
    /**
     * 从表增删改参数
     * @type {{ [key: string]: DynamicCudModel; }}
     */
    'cudData'?: { [key: string]: DynamicCudModel; } | null;
}
/**
 * 能源类型<br />&nbsp;汽油 Gasoline = 1<br />&nbsp;柴油 Diesel = 2<br />&nbsp;电动 Electric = 3<br />&nbsp;混动 Hybrid = 4<br />&nbsp;天然气 NaturalGas = 5<br />&nbsp;氢气 Hydrogen = 6<br />&nbsp;液化气 LiquefiedGas = 7<br />&nbsp;乙醇 Ethanol = 8<br />
 * @export
 * @enum {string}
 */

export const EnergyTypeEnum = {
    Gasoline: 'Gasoline',
    Diesel: 'Diesel',
    Electric: 'Electric',
    Hybrid: 'Hybrid',
    NaturalGas: 'NaturalGas',
    Hydrogen: 'Hydrogen',
    LiquefiedGas: 'LiquefiedGas',
    Ethanol: 'Ethanol'
} as const;

export type EnergyTypeEnum = typeof EnergyTypeEnum[keyof typeof EnergyTypeEnum];


/**
 * 外发费用 表单参数
 * @export
 * @interface ExternalCostFormData
 */
export interface ExternalCostFormData  extends BaseEntity {
    /**
     * 
     * @type {WaiFaFeeTypeEnum}
     */
    'feeType': WaiFaFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 外发费用 列表输出
 * @export
 * @interface ExternalCostListData
 */
export interface ExternalCostListData  extends BaseEntity {
    /**
     * 
     * @type {WaiFaFeeTypeEnum}
     */
    'feeType': WaiFaFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {FenBoFeeStatusEnum}
     */
    'status': FenBoFeeStatusEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 外发明细 表单参数
 * @export
 * @interface ExternalDetailsFormData
 */
export interface ExternalDetailsFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
}
/**
 * 外发明细 列表输出
 * @export
 * @interface ExternalDetailsListData
 */
export interface ExternalDetailsListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
    /**
     * 
     * @type {WaiFaDetailsStatusEnum}
     */
    'status': WaiFaDetailsStatusEnum;
}


/**
 * 外发 表单参数
 * @export
 * @interface ExternalFormData
 */
export interface ExternalFormData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 日期
     * @type {string}
     */
    'date': string;
    /**
     * 预计到达
     * @type {string}
     */
    'arrivalDate'?: string | null;
    /**
     * 承运商Id
     * @type {number}
     */
    'carrierId': number;
    /**
     * 承运商名称
     * @type {string}
     */
    'carrierName': string;
    /**
     * 承运商电话
     * @type {string}
     */
    'carrierPhone': string;
    /**
     * 承运商单号
     * @type {string}
     */
    'carrierNo': string;
    /**
     * 中转到站
     * @type {number}
     */
    'destination': number;
    /**
     * 收货地址
     * @type {string}
     */
    'address': string;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 外发 列表输出
 * @export
 * @interface ExternalListData
 */
export interface ExternalListData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {WaiFaStatusEnum}
     */
    'status': WaiFaStatusEnum;
    /**
     * 日期
     * @type {string}
     */
    'date': string;
    /**
     * 预计到达
     * @type {string}
     */
    'arrivalDate'?: string | null;
    /**
     * 承运商Id
     * @type {number}
     */
    'carrierId': number;
    /**
     * 承运商名称
     * @type {string}
     */
    'carrierName': string;
    /**
     * 承运商电话
     * @type {string}
     */
    'carrierPhone': string;
    /**
     * 承运商单号
     * @type {string}
     */
    'carrierNo': string;
    /**
     * 中转到站
     * @type {number}
     */
    'destination': number;
    /**
     * 收货地址
     * @type {string}
     */
    'address': string;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 分拨费用状态<br />&nbsp;未核销 WeiHeXiao = 0<br />&nbsp;已核销 YiHeXiao = 1<br />
 * @export
 * @enum {string}
 */

export const FenBoFeeStatusEnum = {
    WeiHeXiao: 'WeiHeXiao',
    YiHeXiao: 'YiHeXiao'
} as const;

export type FenBoFeeStatusEnum = typeof FenBoFeeStatusEnum[keyof typeof FenBoFeeStatusEnum];


/**
 * 分拨分摊方式<br />&nbsp;不分摊 BuFenTan = 0<br />&nbsp;票数 PiaoShu = 1<br />&nbsp;件数 JianShu = 2<br />&nbsp;重量 ZhongLiang = 3<br />&nbsp;体积 TiJi = 4<br />&nbsp;自定义 ZiDingYi = 9<br />
 * @export
 * @enum {string}
 */

export const FenBoFenTanTypeEnum = {
    BuFenTan: 'BuFenTan',
    PiaoShu: 'PiaoShu',
    JianShu: 'JianShu',
    ZhongLiang: 'ZhongLiang',
    TiJi: 'TiJi',
    ZiDingYi: 'ZiDingYi'
} as const;

export type FenBoFenTanTypeEnum = typeof FenBoFenTanTypeEnum[keyof typeof FenBoFenTanTypeEnum];


/**
 * 分拨付款方式<br />&nbsp;现付 XianFu = 0<br />&nbsp;到付 DaoFu = 1<br />&nbsp;月结 YueJie = 2<br />&nbsp;欠付 QianFu = 3<br />
 * @export
 * @enum {string}
 */

export const FenBoJieSuanTypeEnum = {
    XianFu: 'XianFu',
    DaoFu: 'DaoFu',
    YueJie: 'YueJie',
    QianFu: 'QianFu'
} as const;

export type FenBoJieSuanTypeEnum = typeof FenBoJieSuanTypeEnum[keyof typeof FenBoJieSuanTypeEnum];


/**
 * 模块类型<br />&nbsp;运单 YunDan = 0<br />&nbsp;回单 HuiDan = 1<br />&nbsp;分拨 FenBo = 2<br />&nbsp;外发 WeiFa = 3<br />&nbsp;提货 TiHuo = 4<br />&nbsp;短驳 DuanBo = 5<br />&nbsp;送货 SongHuo = 6<br />&nbsp;签收 QianShou = 7<br />&nbsp;记账 JiZhang = 8<br />&nbsp;凭证 PingZheng = 9<br />&nbsp;机构 JiGou = 10<br />
 * @export
 * @enum {string}
 */

export const FileModuleType = {
    YunDan: 'YunDan',
    HuiDan: 'HuiDan',
    FenBo: 'FenBo',
    WeiFa: 'WeiFa',
    TiHuo: 'TiHuo',
    DuanBo: 'DuanBo',
    SongHuo: 'SongHuo',
    QianShou: 'QianShou',
    JiZhang: 'JiZhang',
    PingZheng: 'PingZheng',
    JiGou: 'JiGou'
} as const;

export type FileModuleType = typeof FileModuleType[keyof typeof FileModuleType];


/**
 * 文件管理 表单参数
 * @export
 * @interface FilesFormData
 */
export interface FilesFormData  extends BaseEntity {
    /**
     * 
     * @type {StorageTypeEnum}
     */
    'storageType': StorageTypeEnum;
    /**
     * 仓储名称
     * @type {string}
     */
    'bucketName': string;
    /**
     * 文件名称
     * @type {string}
     */
    'fileName': string;
    /**
     * 文件后缀
     * @type {string}
     */
    'suffix'?: string | null;
    /**
     * 存储路径
     * @type {string}
     */
    'filePath': string;
    /**
     * 文件大小KB
     * @type {number}
     */
    'sizeKb': number | null;
    /**
     * 文件原名
     * @type {string}
     */
    'originalName': string;
    /**
     * 外链地址
     * @type {string}
     */
    'url'?: string | null;
    /**
     * 文件MD5
     * @type {number}
     */
    'fileMd5': number;
}


/**
 * 文件管理 列表输出
 * @export
 * @interface FilesListData
 */
export interface FilesListData  extends BaseEntity {
    /**
     * 
     * @type {StorageTypeEnum}
     */
    'storageType': StorageTypeEnum;
    /**
     * 仓储名称
     * @type {string}
     */
    'bucketName': string;
    /**
     * 文件名称
     * @type {string}
     */
    'fileName': string;
    /**
     * 文件后缀
     * @type {string}
     */
    'suffix'?: string | null;
    /**
     * 存储路径
     * @type {string}
     */
    'filePath': string;
    /**
     * 文件大小KB
     * @type {number}
     */
    'sizeKb': number | null;
    /**
     * 文件原名
     * @type {string}
     */
    'originalName': string;
    /**
     * 外链地址
     * @type {string}
     */
    'url'?: string | null;
    /**
     * 文件MD5
     * @type {number}
     */
    'fileMd5': number;
}


/**
 * 干线明细状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;配载 PeiZai = 1<br />&nbsp;装车 ZhuangChe = 2<br />&nbsp;卸车 XieChe = 3<br />&nbsp;完成 WanCheng = 4<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const GanXianDetailsStatusEnum = {
    ChuShi: 'ChuShi',
    PeiZai: 'PeiZai',
    ZhuangChe: 'ZhuangChe',
    XieChe: 'XieChe',
    WanCheng: 'WanCheng',
    ZuoFei: 'ZuoFei'
} as const;

export type GanXianDetailsStatusEnum = typeof GanXianDetailsStatusEnum[keyof typeof GanXianDetailsStatusEnum];


/**
 * 干线费用类型<br />&nbsp;运费 YunFei = 0<br />&nbsp;装卸费 ZhuangXieFei = 1<br />&nbsp;其他费 QiTaFei = 2<br />&nbsp;接货费 JieHuoFei = 60<br />&nbsp;保险费 BaoXianFei = 103<br />&nbsp;停车费 TingCheFei = 104<br />&nbsp;蓬布费 PengBuFei = 105<br />&nbsp;托盘押金 TuoPanYajin = 110<br />&nbsp;油卡押金 YouKaYajin = 111<br />&nbsp;其他押金 QiTaYajin = 112<br />
 * @export
 * @enum {string}
 */

export const GanXianFeeTypeEnum = {
    YunFei: 'YunFei',
    ZhuangXieFei: 'ZhuangXieFei',
    QiTaFei: 'QiTaFei',
    JieHuoFei: 'JieHuoFei',
    BaoXianFei: 'BaoXianFei',
    TingCheFei: 'TingCheFei',
    PengBuFei: 'PengBuFei',
    TuoPanYajin: 'TuoPanYajin',
    YouKaYajin: 'YouKaYajin',
    QiTaYajin: 'QiTaYajin'
} as const;

export type GanXianFeeTypeEnum = typeof GanXianFeeTypeEnum[keyof typeof GanXianFeeTypeEnum];


/**
 * 干线分拨状态<br />&nbsp;开单 KaiDan = 1<br />&nbsp;装货 ZhuangHuo = 2<br />&nbsp;发车 FaChe = 3<br />&nbsp;到车 DaoChe = 4<br />&nbsp;卸货 XieHuo = 5<br />&nbsp;完成 WanCheng = 6<br />&nbsp;异常 YiChang = 7<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const GanXianStatusEnum = {
    KaiDan: 'KaiDan',
    ZhuangHuo: 'ZhuangHuo',
    FaChe: 'FaChe',
    DaoChe: 'DaoChe',
    XieHuo: 'XieHuo',
    WanCheng: 'WanCheng',
    YiChang: 'YiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type GanXianStatusEnum = typeof GanXianStatusEnum[keyof typeof GanXianStatusEnum];


/**
 * 生成签名 输入参数
 * @export
 * @interface GenSignInput
 */
export interface GenSignInput {
    /**
     * 身份标识
     * @type {string}
     */
    'accessKey': string;
    /**
     * 密钥
     * @type {string}
     */
    'accessSecret': string;
    /**
     * 
     * @type {HttpMethodEnum}
     */
    'method'?: HttpMethodEnum;
    /**
     * 请求接口地址
     * @type {string}
     */
    'url': string;
    /**
     * 时间戳
     * @type {number}
     */
    'timestamp': number;
    /**
     * 随机数
     * @type {string}
     */
    'nonce': string;
}


/**
 * HTTP 请求谓词枚举<br />&nbsp;Get Get = 1<br />&nbsp;Post Post = 2<br />&nbsp;Put Put = 3<br />&nbsp;Delete Delete = 4<br />&nbsp;Patch Patch = 5<br />&nbsp;Head Head = 6<br />&nbsp;Options Options = 7<br />&nbsp;Trace Trace = 8<br />
 * @export
 * @enum {string}
 */

export const HttpMethodEnum = {
    Get: 'Get',
    Post: 'Post',
    Put: 'Put',
    Delete: 'Delete',
    Patch: 'Patch',
    Head: 'Head',
    Options: 'Options',
    Trace: 'Trace'
} as const;

export type HttpMethodEnum = typeof HttpMethodEnum[keyof typeof HttpMethodEnum];


/**
 * 回单状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;已发出 YiFaChu = 30<br />&nbsp;已接收 YiJieShou = 31<br />&nbsp;签收 QianShou = 80<br />&nbsp;异常 YiChang = 90<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const HuiDanStatusEnum = {
    ChuShi: 'ChuShi',
    YiFaChu: 'YiFaChu',
    YiJieShou: 'YiJieShou',
    QianShou: 'QianShou',
    YiChang: 'YiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type HuiDanStatusEnum = typeof HuiDanStatusEnum[keyof typeof HuiDanStatusEnum];


/**
 * 接口 实体类
 * @export
 * @interface Interface
 */
export interface Interface  extends BaseEntity {
    /**
     * 操作编号
     * @type {string}
     */
    'operationId': string;
    /**
     * 控制器
     * @type {string}
     */
    'controller': string;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 路径
     * @type {string}
     */
    'path': string;
    /**
     * 
     * @type {HttpMethodEnum}
     */
    'httpMethod': HttpMethodEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 接口的菜单列表
     * @type {Array<Menu>}
     */
    'menus'?: Array<Menu> | null;
}


/**
 * 接口 表单参数
 * @export
 * @interface InterfaceFormData
 */
export interface InterfaceFormData  extends BaseEntity {
    /**
     * 操作编号
     * @type {string}
     */
    'operationId': string;
    /**
     * 控制器
     * @type {string}
     */
    'controller': string;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 路径
     * @type {string}
     */
    'path': string;
    /**
     * 
     * @type {HttpMethodEnum}
     */
    'httpMethod': HttpMethodEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 接口 列表输出
 * @export
 * @interface InterfaceListData
 */
export interface InterfaceListData  extends BaseEntity {
    /**
     * 操作编号
     * @type {string}
     */
    'operationId': string;
    /**
     * 控制器
     * @type {string}
     */
    'controller': string;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 路径
     * @type {string}
     */
    'path': string;
    /**
     * 
     * @type {HttpMethodEnum}
     */
    'httpMethod': HttpMethodEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 单据有效性输入
 * @export
 * @interface IsValidInput
 */
export interface IsValidInput {
    /**
     * 主键ID
     * @type {number}
     */
    'id': number;
    /**
     * 乐观锁
     * @type {number}
     */
    'ver': number;
    /**
     * 是否启用
     * @type {boolean}
     */
    'isValid': boolean;
}
/**
 * 结算状态<br />&nbsp;未结清 WeiJieQing = 0<br />&nbsp;已结清 YiJieQing = 1<br />
 * @export
 * @enum {string}
 */

export const JieSuanStatusEnum = {
    WeiJieQing: 'WeiJieQing',
    YiJieQing: 'YiJieQing'
} as const;

export type JieSuanStatusEnum = typeof JieSuanStatusEnum[keyof typeof JieSuanStatusEnum];


/**
 * 结算方式<br />&nbsp;现付 XianFu = 0<br />&nbsp;到付 DaoFu = 1<br />&nbsp;月结 YueJie = 2<br />&nbsp;临欠 LinQian = 3<br />&nbsp;回单付 HuiDanFu = 4<br />&nbsp;货款付 HuoKuanFu = 5<br />&nbsp;免费 MianFei = 9<br />
 * @export
 * @enum {string}
 */

export const JieSuanTypeEnum = {
    XianFu: 'XianFu',
    DaoFu: 'DaoFu',
    YueJie: 'YueJie',
    LinQian: 'LinQian',
    HuiDanFu: 'HuiDanFu',
    HuoKuanFu: 'HuoKuanFu',
    MianFei: 'MianFei'
} as const;

export type JieSuanTypeEnum = typeof JieSuanTypeEnum[keyof typeof JieSuanTypeEnum];


/**
 * 任务集群 实体类
 * @export
 * @interface JobCluster
 */
export interface JobCluster  extends BaseEntity {
    /**
     * 集群编码
     * @type {string}
     */
    'clusterId': string;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 
     * @type {ClusterStatus}
     */
    'status': ClusterStatus;
}


/**
 * 任务详情 表单参数
 * @export
 * @interface JobDetailFormData
 */
export interface JobDetailFormData  extends BaseEntity {
    /**
     * 作业编码
     * @type {string}
     */
    'jobId': string;
    /**
     * 组名称
     * @type {string}
     */
    'groupName'?: string | null;
    /**
     * 处理程序 FullName
     * @type {string}
     */
    'jobType'?: string | null;
    /**
     * 处理程序集 Name
     * @type {string}
     */
    'assemblyName'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 并行
     * @type {boolean}
     */
    'concurrent': boolean;
    /**
     * 扫描触发器特性
     * @type {boolean}
     */
    'includeAnnotations': boolean;
    /**
     * 额外数据
     * @type {string}
     */
    'properties'?: string | null;
    /**
     * 
     * @type {JobDetailTypeEnum}
     */
    'type': JobDetailTypeEnum;
    /**
     * 脚本代码
     * @type {string}
     */
    'cSharpClassCode'?: string | null;
}


/**
 * 任务详情 列表输出
 * @export
 * @interface JobDetailListData
 */
export interface JobDetailListData  extends BaseEntity {
    /**
     * 作业编码
     * @type {string}
     */
    'jobId': string;
    /**
     * 组名称
     * @type {string}
     */
    'groupName'?: string | null;
    /**
     * 处理程序 FullName
     * @type {string}
     */
    'jobType'?: string | null;
    /**
     * 处理程序集 Name
     * @type {string}
     */
    'assemblyName'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 并行
     * @type {boolean}
     */
    'concurrent': boolean;
    /**
     * 扫描触发器特性
     * @type {boolean}
     */
    'includeAnnotations': boolean;
    /**
     * 额外数据
     * @type {string}
     */
    'properties'?: string | null;
    /**
     * 
     * @type {JobDetailTypeEnum}
     */
    'type': JobDetailTypeEnum;
    /**
     * 脚本代码
     * @type {string}
     */
    'cSharpClassCode'?: string | null;
    /**
     * 作业的触发器列表
     * @type {Array<JobTriggerListData>}
     */
    'jobTriggers'?: Array<JobTriggerListData> | null;
}


/**
 * 作业创建类型枚举<br />&nbsp;内置 BuiltIn = 0<br />&nbsp;HTTP请求 Http = 1<br />
 * @export
 * @enum {string}
 */

export const JobDetailTypeEnum = {
    BuiltIn: 'BuiltIn',
    Http: 'Http'
} as const;

export type JobDetailTypeEnum = typeof JobDetailTypeEnum[keyof typeof JobDetailTypeEnum];


/**
 * 任务触发器 表单参数
 * @export
 * @interface JobTriggerFormData
 */
export interface JobTriggerFormData  extends BaseEntity {
    /**
     * 触发器编码
     * @type {string}
     */
    'triggerId': string;
    /**
     * 作业编码
     * @type {string}
     */
    'jobId': string;
    /**
     * 触发程序类型 FullName
     * @type {string}
     */
    'triggerType'?: string | null;
    /**
     * 触发程序集 Name
     * @type {string}
     */
    'assemblyName'?: string | null;
    /**
     * 触发程序构造参数
     * @type {string}
     */
    'args'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 起始时间
     * @type {string}
     */
    'startTime'?: string | null;
    /**
     * 结束时间
     * @type {string}
     */
    'endTime'?: string | null;
    /**
     * 最大触发次数
     * @type {number}
     */
    'maxNumberOfRuns': number;
    /**
     * 最大出错次数
     * @type {number}
     */
    'maxNumberOfErrors': number;
    /**
     * 重试间隔(ms)
     * @type {number}
     */
    'retryTimeout': number;
    /**
     * 立即启动
     * @type {boolean}
     */
    'startNow': boolean;
    /**
     * 启动时执行一次
     * @type {boolean}
     */
    'runOnStart': boolean;
    /**
     * 启动时重置触发次数
     * @type {boolean}
     */
    'resetOnlyOnce': boolean;
}
/**
 * 任务触发器 列表输出
 * @export
 * @interface JobTriggerListData
 */
export interface JobTriggerListData  extends BaseEntity {
    /**
     * 触发器编码
     * @type {string}
     */
    'triggerId': string;
    /**
     * 作业编码
     * @type {string}
     */
    'jobId': string;
    /**
     * 触发程序类型 FullName
     * @type {string}
     */
    'triggerType'?: string | null;
    /**
     * 触发程序集 Name
     * @type {string}
     */
    'assemblyName'?: string | null;
    /**
     * 触发程序构造参数
     * @type {string}
     */
    'args'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 
     * @type {TriggerStatus}
     */
    'status': TriggerStatus;
    /**
     * 起始时间
     * @type {string}
     */
    'startTime'?: string | null;
    /**
     * 结束时间
     * @type {string}
     */
    'endTime'?: string | null;
    /**
     * 最近运行时间
     * @type {string}
     */
    'lastRunTime'?: string | null;
    /**
     * 下次运行时间
     * @type {string}
     */
    'nextRunTime'?: string | null;
    /**
     * 触发次数
     * @type {number}
     */
    'numberOfRuns': number;
    /**
     * 最大触发次数
     * @type {number}
     */
    'maxNumberOfRuns': number;
    /**
     * 出错次数
     * @type {number}
     */
    'numberOfErrors': number;
    /**
     * 最大出错次数
     * @type {number}
     */
    'maxNumberOfErrors': number;
    /**
     * 重试次数
     * @type {number}
     */
    'numRetries': number;
    /**
     * 重试间隔(ms)
     * @type {number}
     */
    'retryTimeout': number;
    /**
     * 立即启动
     * @type {boolean}
     */
    'startNow': boolean;
    /**
     * 启动时执行一次
     * @type {boolean}
     */
    'runOnStart': boolean;
    /**
     * 启动时重置触发次数
     * @type {boolean}
     */
    'resetOnlyOnce': boolean;
}


/**
 * 任务触发器运行记录
 * @export
 * @interface JobTriggerRecord
 */
export interface JobTriggerRecord  extends BaseEntity {
    /**
     * 作业Id
     * @type {string}
     */
    'jobId': string;
    /**
     * 触发器Id
     * @type {string}
     */
    'triggerId': string;
    /**
     * 当前运行次数
     * @type {number}
     */
    'numberOfRuns': number;
    /**
     * 最近运行时间
     * @type {string}
     */
    'lastRunTime'?: string | null;
    /**
     * 下一次运行时间
     * @type {string}
     */
    'nextRunTime'?: string | null;
    /**
     * 
     * @type {TriggerStatus}
     */
    'status'?: TriggerStatus;
    /**
     * 本次执行结果
     * @type {string}
     */
    'result'?: string | null;
    /**
     * 本次执行耗时
     * @type {number}
     */
    'elapsedTime': number;
}


/**
 * 键值对
 * @export
 * @interface KeyValueItem
 */
export interface KeyValueItem {
    /**
     * ID
     * @type {number}
     */
    'id': number;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
}
/**
 * 键值对树
 * @export
 * @interface KeyValueTree
 */
export interface KeyValueTree {
    /**
     * ID
     * @type {number}
     */
    'id': number;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 父ID
     * @type {number}
     */
    'parentId': number;
    /**
     * 子节点
     * @type {Array<KeyValueTree>}
     */
    'child': Array<KeyValueTree>;
}
/**
 * 差异日志源 实体类
 * @export
 * @interface LogDiffSource
 */
export interface LogDiffSource {
    /**
     * 主键
     * @type {number}
     */
    'id': number;
    /**
     * 操作前数据
     * @type {Array<DiffLogTableInfo>}
     */
    'afterData'?: Array<DiffLogTableInfo> | null;
    /**
     * 操作后数据
     * @type {Array<DiffLogTableInfo>}
     */
    'beforeData'?: Array<DiffLogTableInfo> | null;
    /**
     * 参数
     * @type {Array<SugarParameter>}
     */
    'parameters'?: Array<SugarParameter> | null;
    /**
     * SQL
     * @type {string}
     */
    'sql': string;
    /**
     * 耗时
     * @type {number}
     */
    'time': number;
    /**
     * 自定义对象
     * @type {string}
     */
    'businessData'?: string | null;
    /**
     * 
     * @type {DiffType}
     */
    'diffType': DiffType;
    /**
     * 创建人ID
     * @type {number}
     */
    'createdBy': number;
    /**
     * 创建人
     * @type {string}
     */
    'createdName'?: string | null;
    /**
     * 创建者姓名
     * @type {string}
     */
    'creatorName'?: string | null;
    /**
     * 创建时间
     * @type {string}
     */
    'createdTime'?: string;
    /**
     * 所属机构ID
     * @type {number}
     */
    'orgId': number;
}


/**
 * 异常日志输出
 * @export
 * @interface LogExOutput
 */
export interface LogExOutput {
    /**
     * 主键
     * @type {number}
     */
    'id': number;
    /**
     * 用户编号
     * @type {number}
     */
    'userId': number | null;
    /**
     * 账号
     * @type {string}
     */
    'account'?: string | null;
    /**
     * 姓名
     * @type {string}
     */
    'userName'?: string | null;
    /**
     * 类名
     * @type {string}
     */
    'className': string;
    /**
     * 方法名
     * @type {string}
     */
    'actionName': string;
    /**
     * 显示名称
     * @type {string}
     */
    'actionLabel'?: string | null;
    /**
     * 请求方式
     * @type {string}
     */
    'httpMethod'?: string | null;
    /**
     * 请求地址
     * @type {string}
     */
    'url'?: string | null;
    /**
     * 请求参数
     * @type {string}
     */
    'parameter'?: string | null;
    /**
     * 返回结果
     * @type {string}
     */
    'result'?: string | null;
    /**
     * 状态
     * @type {number}
     */
    'status': number | null;
    /**
     * IP地址
     * @type {string}
     */
    'ip'?: string | null;
    /**
     * 地区
     * @type {string}
     */
    'location'?: string | null;
    /**
     * 来源特征
     * @type {string}
     */
    'userAgent'?: string | null;
    /**
     * 耗时
     * @type {number}
     */
    'elapsed': number;
    /**
     * 操作时间
     * @type {string}
     */
    'operateTime'?: string | null;
    /**
     * 
     * @type {LogLevel}
     */
    'level'?: LogLevel;
    /**
     * 事件ID
     * @type {number}
     */
    'eventId': number | null;
    /**
     * 线程ID
     * @type {number}
     */
    'threadId': number | null;
    /**
     * 请求跟踪ID
     * @type {string}
     */
    'traceId'?: string | null;
    /**
     * 创建人ID
     * @type {number}
     */
    'createdBy': number;
    /**
     * 创建时间
     * @type {string}
     */
    'createdTime'?: string;
    /**
     * 所属机构ID
     * @type {number}
     */
    'orgId': number | null;
    /**
     * 异常名称
     * @type {string}
     */
    'name': string;
    /**
     * 异常源
     * @type {string}
     */
    'source'?: string | null;
    /**
     * 堆栈信息
     * @type {string}
     */
    'stackTrace'?: string | null;
    /**
     * 参数对象
     * @type {string}
     */
    'paramsObj'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LogLevel = {
    Trace: 'Trace',
    Debug: 'Debug',
    Information: 'Information',
    Warning: 'Warning',
    Error: 'Error',
    Critical: 'Critical',
    None: 'None'
} as const;

export type LogLevel = typeof LogLevel[keyof typeof LogLevel];


/**
 * 
 * @export
 * @interface LogOutput
 */
export interface LogOutput {
    /**
     * 主键
     * @type {number}
     */
    'id': number;
    /**
     * 用户编号
     * @type {number}
     */
    'userId': number | null;
    /**
     * 账号
     * @type {string}
     */
    'account'?: string | null;
    /**
     * 姓名
     * @type {string}
     */
    'userName'?: string | null;
    /**
     * 类名
     * @type {string}
     */
    'className': string;
    /**
     * 方法名
     * @type {string}
     */
    'actionName': string;
    /**
     * 显示名称
     * @type {string}
     */
    'actionLabel'?: string | null;
    /**
     * 请求方式
     * @type {string}
     */
    'httpMethod'?: string | null;
    /**
     * 请求地址
     * @type {string}
     */
    'url'?: string | null;
    /**
     * 请求参数
     * @type {string}
     */
    'parameter'?: string | null;
    /**
     * 返回结果
     * @type {string}
     */
    'result'?: string | null;
    /**
     * 状态
     * @type {number}
     */
    'status': number | null;
    /**
     * IP地址
     * @type {string}
     */
    'ip'?: string | null;
    /**
     * 地区
     * @type {string}
     */
    'location'?: string | null;
    /**
     * 来源特征
     * @type {string}
     */
    'userAgent'?: string | null;
    /**
     * 耗时
     * @type {number}
     */
    'elapsed': number;
    /**
     * 操作时间
     * @type {string}
     */
    'operateTime'?: string | null;
    /**
     * 
     * @type {LogLevel}
     */
    'level'?: LogLevel;
    /**
     * 事件ID
     * @type {number}
     */
    'eventId': number | null;
    /**
     * 线程ID
     * @type {number}
     */
    'threadId': number | null;
    /**
     * 请求跟踪ID
     * @type {string}
     */
    'traceId'?: string | null;
    /**
     * 创建人ID
     * @type {number}
     */
    'createdBy': number;
    /**
     * 创建时间
     * @type {string}
     */
    'createdTime'?: string;
    /**
     * 所属机构ID
     * @type {number}
     */
    'orgId': number | null;
}


/**
 * 登录配置项
 * @export
 * @interface LoginConfigOutput
 */
export interface LoginConfigOutput {
    /**
     * 是否启用二次验证
     * @type {boolean}
     */
    'enableTwoFactorAuth': boolean;
    /**
     * 是否启用验证码
     * @type {boolean}
     */
    'enableCaptcha': boolean;
}
/**
 * 登录输入参数
 * @export
 * @interface LoginInput
 */
export interface LoginInput {
    /**
     * 账号
     * @type {string}
     */
    'account': string;
    /**
     * 密码
     * @type {string}
     */
    'password': string;
}
/**
 * 登录输出参数
 * @export
 * @interface LoginOutput
 */
export interface LoginOutput {
    /**
     * Token
     * @type {string}
     */
    'accessToken': string;
    /**
     * 刷新Token
     * @type {string}
     */
    'refreshToken': string;
}
/**
 * 登陆用户的基础信息
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 用户ID
     * @type {number}
     */
    'id': number;
    /**
     * 当前机构ID
     * @type {number}
     */
    'orgId': number;
    /**
     * 当前机构名称
     * @type {string}
     */
    'orgName'?: string | null;
    /**
     * 上级机构ID
     * @type {number}
     */
    'parentId': number;
    /**
     * 财务中心ID
     * @type {number}
     */
    'financeCenter': number;
    /**
     * 用户登陆账号
     * @type {string}
     */
    'account'?: string | null;
    /**
     * 用户登陆名称
     * @type {string}
     */
    'name'?: string | null;
    /**
     * 手机号
     * @type {string}
     */
    'phoneNumber'?: string | null;
    /**
     * 是否为管理员身份
     * @type {boolean}
     */
    'isAdmin': boolean;
    /**
     * 当前机构下用户的菜单功能权限
     * @type {Array<string>}
     */
    'menuPermissions'?: Array<string> | null;
}
/**
 * 承运商 表单参数
 * @export
 * @interface LogisticsCompanyFormData
 */
export interface LogisticsCompanyFormData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 行政区域
     * @type {string}
     */
    'region': string;
    /**
     * 地址
     * @type {string}
     */
    'address'?: string | null;
    /**
     * 站点电话
     * @type {string}
     */
    'phone'?: string | null;
    /**
     * 负责人
     * @type {string}
     */
    'manager'?: string | null;
    /**
     * 手机
     * @type {string}
     */
    'mobile'?: string | null;
    /**
     * 联系人
     * @type {string}
     */
    'contacts'?: string | null;
    /**
     * 联系手机
     * @type {string}
     */
    'contactMobile'?: string | null;
    /**
     * 主营线路
     * @type {string}
     */
    'businessScope'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 承运商 列表输出
 * @export
 * @interface LogisticsCompanyListData
 */
export interface LogisticsCompanyListData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 行政区域
     * @type {string}
     */
    'region': string;
    /**
     * 地址
     * @type {string}
     */
    'address'?: string | null;
    /**
     * 站点电话
     * @type {string}
     */
    'phone'?: string | null;
    /**
     * 负责人
     * @type {string}
     */
    'manager'?: string | null;
    /**
     * 手机
     * @type {string}
     */
    'mobile'?: string | null;
    /**
     * 联系人
     * @type {string}
     */
    'contacts'?: string | null;
    /**
     * 联系手机
     * @type {string}
     */
    'contactMobile'?: string | null;
    /**
     * 主营线路
     * @type {string}
     */
    'businessScope'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 菜单和功能 实体类
 * @export
 * @interface Menu
 */
export interface Menu  extends BaseEntity {
    /**
     * 路由名称
     * @type {string}
     */
    'name'?: string | null;
    /**
     * 路由地址
     * @type {string}
     */
    'path'?: string | null;
    /**
     * 显示名称
     * @type {string}
     */
    'title': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 
     * @type {MenuTypeEnum}
     */
    'type': MenuTypeEnum;
    /**
     * 组件路径
     * @type {string}
     */
    'component'?: string | null;
    /**
     * 重定向
     * @type {string}
     */
    'redirect'?: string | null;
    /**
     * 权限标识
     * @type {string}
     */
    'permission'?: string | null;
    /**
     * 图标
     * @type {string}
     */
    'icon'?: string | null;
    /**
     * 外链链接
     * @type {string}
     */
    'outLink'?: string | null;
    /**
     * 是否内嵌
     * @type {boolean}
     */
    'isIframe': boolean;
    /**
     * 是否隐藏
     * @type {boolean}
     */
    'isHide': boolean;
    /**
     * 是否缓存
     * @type {boolean}
     */
    'isKeepAlive': boolean;
    /**
     * 是否固定
     * @type {boolean}
     */
    'isAffix': boolean;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 子菜单
     * @type {Array<Menu>}
     */
    'child'?: Array<Menu> | null;
    /**
     * 菜单的角色列表
     * @type {Array<Role>}
     */
    'roles'?: Array<Role> | null;
    /**
     * 菜单的接口列表
     * @type {Array<Interface>}
     */
    'interfaces'?: Array<Interface> | null;
}


/**
 * 菜单和功能 表单参数
 * @export
 * @interface MenuFormData
 */
export interface MenuFormData  extends BaseEntity {
    /**
     * 路由名称
     * @type {string}
     */
    'name'?: string | null;
    /**
     * 路由地址
     * @type {string}
     */
    'path'?: string | null;
    /**
     * 显示名称
     * @type {string}
     */
    'title': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 
     * @type {MenuTypeEnum}
     */
    'type': MenuTypeEnum;
    /**
     * 组件路径
     * @type {string}
     */
    'component'?: string | null;
    /**
     * 重定向
     * @type {string}
     */
    'redirect'?: string | null;
    /**
     * 权限标识
     * @type {string}
     */
    'permission'?: string | null;
    /**
     * 图标
     * @type {string}
     */
    'icon'?: string | null;
    /**
     * 外链链接
     * @type {string}
     */
    'outLink'?: string | null;
    /**
     * 是否内嵌
     * @type {boolean}
     */
    'isIframe': boolean;
    /**
     * 是否隐藏
     * @type {boolean}
     */
    'isHide': boolean;
    /**
     * 是否缓存
     * @type {boolean}
     */
    'isKeepAlive': boolean;
    /**
     * 是否固定
     * @type {boolean}
     */
    'isAffix': boolean;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 菜单和功能 列表输出
 * @export
 * @interface MenuListData
 */
export interface MenuListData  extends BaseEntity {
    /**
     * 路由名称
     * @type {string}
     */
    'name'?: string | null;
    /**
     * 路由地址
     * @type {string}
     */
    'path'?: string | null;
    /**
     * 显示名称
     * @type {string}
     */
    'title': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 
     * @type {MenuTypeEnum}
     */
    'type': MenuTypeEnum;
    /**
     * 组件路径
     * @type {string}
     */
    'component'?: string | null;
    /**
     * 重定向
     * @type {string}
     */
    'redirect'?: string | null;
    /**
     * 权限标识
     * @type {string}
     */
    'permission'?: string | null;
    /**
     * 图标
     * @type {string}
     */
    'icon'?: string | null;
    /**
     * 外链链接
     * @type {string}
     */
    'outLink'?: string | null;
    /**
     * 是否内嵌
     * @type {boolean}
     */
    'isIframe': boolean;
    /**
     * 是否隐藏
     * @type {boolean}
     */
    'isHide': boolean;
    /**
     * 是否缓存
     * @type {boolean}
     */
    'isKeepAlive': boolean;
    /**
     * 是否固定
     * @type {boolean}
     */
    'isAffix': boolean;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 
 * @export
 * @interface MenuMeta
 */
export interface MenuMeta {
    /**
     * 标题
     * @type {string}
     */
    'title': string;
    /**
     * 图标
     * @type {string}
     */
    'icon'?: string | null;
    /**
     * 是否内嵌
     * @type {boolean}
     */
    'isIframe': boolean;
    /**
     * 外链链接
     * @type {string}
     */
    'isLink'?: string | null;
    /**
     * 是否隐藏
     * @type {boolean}
     */
    'isHide': boolean;
    /**
     * 是否缓存
     * @type {boolean}
     */
    'isKeepAlive': boolean;
    /**
     * 是否固定
     * @type {boolean}
     */
    'isAffix': boolean;
}
/**
 * 菜单和功能类型枚举<br />&nbsp;菜单分组 Group = 1<br />&nbsp;菜单 Menu = 2<br />&nbsp;功能点 Func = 3<br />
 * @export
 * @enum {string}
 */

export const MenuTypeEnum = {
    Group: 'Group',
    Menu: 'Menu',
    Func: 'Func'
} as const;

export type MenuTypeEnum = typeof MenuTypeEnum[keyof typeof MenuTypeEnum];


/**
 * 模块字段生成 表单参数
 * @export
 * @interface ModuleFieldGenFormData
 */
export interface ModuleFieldGenFormData  extends BaseEntity {
    /**
     * 模块ID
     * @type {number}
     */
    'moduleId': number;
    /**
     * 表名
     * @type {string}
     */
    'tableName'?: string | null;
    /**
     * 表说明
     * @type {string}
     */
    'tableDes'?: string | null;
    /**
     * 数据库字段名称
     * @type {string}
     */
    'fieldName': string;
    /**
     * 数据库字段类型
     * @type {string}
     */
    'fieldType'?: string | null;
    /**
     * 数据库字段长度
     * @type {number}
     */
    'fieldLong': number;
    /**
     * 数据库字段说明
     * @type {string}
     */
    'fieldDes'?: string | null;
    /**
     * 是否可为空
     * @type {boolean}
     */
    'isNull': boolean;
    /**
     * 是否主键
     * @type {boolean}
     */
    'isKey': boolean;
    /**
     * 是否自增字段
     * @type {boolean}
     */
    'isIdentity': boolean;
    /**
     * C#字段类型
     * @type {string}
     */
    'cSharpFieldType': string;
    /**
     * C#字段长度
     * @type {number}
     */
    'cSharpFieldLong': number;
    /**
     * C#字段名称
     * @type {string}
     */
    'cSharpFieldName': string;
    /**
     * C#字段注释
     * @type {string}
     */
    'cSharpFieldDes': string;
    /**
     * 基类字段
     * @type {boolean}
     */
    'isBaseField': boolean;
    /**
     * 控件类型
     * @type {string}
     */
    'controlType'?: string | null;
    /**
     * 控件数据源
     * @type {string}
     */
    'controlDataSources'?: string | null;
    /**
     * 默认值
     * @type {string}
     */
    'defaults'?: string | null;
    /**
     * 验证规则
     * @type {string}
     */
    'validation'?: string | null;
    /**
     * 排序
     * @type {number}
     */
    'sort': number;
    /**
     * 跳过新增
     * @type {boolean}
     */
    'ignoreAdd': boolean;
    /**
     * 跳过编辑
     * @type {boolean}
     */
    'ignoreEdit': boolean;
    /**
     * 新增时可见
     * @type {boolean}
     */
    'visibleWhenAdd': boolean;
    /**
     * 编辑时可见
     * @type {boolean}
     */
    'visibleWhenEdit': boolean;
    /**
     * 新增时只读
     * @type {boolean}
     */
    'readonlyWhenAdd': boolean;
    /**
     * 编辑时只读
     * @type {boolean}
     */
    'readonlyWhenEdit': boolean;
    /**
     * 搜索
     * @type {boolean}
     */
    'isSearch': boolean;
    /**
     * 高级搜索
     * @type {boolean}
     */
    'isAdvSearch': boolean;
    /**
     * 网格列
     * @type {boolean}
     */
    'isColumn': boolean;
    /**
     * 打印
     * @type {boolean}
     */
    'isPrint': boolean;
    /**
     * 导入
     * @type {boolean}
     */
    'isImport': boolean;
    /**
     * 导出
     * @type {boolean}
     */
    'isExport': boolean;
    /**
     * 验证空
     * @type {boolean}
     */
    'isCheckNull': boolean;
    /**
     * 验证重复
     * @type {boolean}
     */
    'isCheckDuplicate': boolean;
    /**
     * 排序
     * @type {boolean}
     */
    'isOrderBy': boolean;
    /**
     * 汇总方式
     * @type {string}
     */
    'summaryItemType'?: string | null;
    /**
     * 汇总显示格式
     * @type {string}
     */
    'summaryItemDisplayFormat'?: string | null;
}
/**
 * 模块生成 表单参数
 * @export
 * @interface ModuleGenFormData
 */
export interface ModuleGenFormData  extends BaseEntity {
    /**
     * 所属库
     * @type {string}
     */
    'configId': string;
    /**
     * 命名空间
     * @type {string}
     */
    'nameSpace': string;
    /**
     * 模块名称
     * @type {string}
     */
    'name': string;
    /**
     * 显示名称
     * @type {string}
     */
    'display': string;
    /**
     * 表/视图名称
     * @type {string}
     */
    'tableName': string;
    /**
     * 实体基类
     * @type {string}
     */
    'entityBaseType'?: string | null;
    /**
     * 上级菜单
     * @type {number}
     */
    'parentMenuId': number | null;
    /**
     * 图标
     * @type {string}
     */
    'icon'?: string | null;
    /**
     * 子模块外键字段
     * @type {string}
     */
    'foreignKeyName'?: string | null;
    /**
     * 子模块ID
     * @type {number}
     */
    'childModuleId': number | null;
    /**
     * 启用查询
     * @type {boolean}
     */
    'isQuery': boolean;
    /**
     * 启用新增
     * @type {boolean}
     */
    'isAdd': boolean;
    /**
     * 启用编辑
     * @type {boolean}
     */
    'isEdit': boolean;
    /**
     * 启用删除
     * @type {boolean}
     */
    'isDelete': boolean;
    /**
     * 启用审核
     * @type {boolean}
     */
    'isCheck': boolean;
    /**
     * 启用导入
     * @type {boolean}
     */
    'isImport': boolean;
    /**
     * 启用导出
     * @type {boolean}
     */
    'isExport': boolean;
    /**
     * 启用打印
     * @type {boolean}
     */
    'isPrint': boolean;
    /**
     * 启用快查树
     * @type {boolean}
     */
    'isTree': boolean;
    /**
     * 子表可空
     * @type {boolean}
     */
    'isChildListNull': boolean;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 模块字段配置
     * @type {Array<ModuleFieldGenFormData>}
     */
    'fields'?: Array<ModuleFieldGenFormData> | null;
}
/**
 * 模块选择
 * @export
 * @interface ModuleSelectData
 */
export interface ModuleSelectData {
    /**
     * 主键，0 表示待新增
     * @type {number}
     */
    'id': number;
    /**
     * 所属库
     * @type {string}
     */
    'configId': string;
    /**
     * 显示名称
     * @type {string}
     */
    'display': string;
    /**
     * 表/视图名称
     * @type {string}
     */
    'tableName': string;
}
/**
 * 内部费用类型<br />&nbsp;干线费 GanXianFei = 0<br />&nbsp;派送费 PaiSongFei = 1<br />&nbsp;装卸费 ZhuangXieFei = 2<br />&nbsp;落地费 LuoDiFei = 3<br />&nbsp;上楼费 ShangLouFei = 4<br />&nbsp;操作费 CaoZuoFei = 5<br />&nbsp;信息费 XinXiFei = 6<br />&nbsp;标签费 BiaoQianFei = 7<br />&nbsp;服务费 FuWuFei = 8<br />&nbsp;保险费 BaoXianFei = 9<br />&nbsp;手续费 ShouXuFei = 10<br />&nbsp;调度费 DiaoDuFei = 11<br />&nbsp;管理费 GuanLiFei = 12<br />
 * @export
 * @enum {string}
 */

export const NeiBuFeeTypeEnum = {
    GanXianFei: 'GanXianFei',
    PaiSongFei: 'PaiSongFei',
    ZhuangXieFei: 'ZhuangXieFei',
    LuoDiFei: 'LuoDiFei',
    ShangLouFei: 'ShangLouFei',
    CaoZuoFei: 'CaoZuoFei',
    XinXiFei: 'XinXiFei',
    BiaoQianFei: 'BiaoQianFei',
    FuWuFei: 'FuWuFei',
    BaoXianFei: 'BaoXianFei',
    ShouXuFei: 'ShouXuFei',
    DiaoDuFei: 'DiaoDuFei',
    GuanLiFei: 'GuanLiFei'
} as const;

export type NeiBuFeeTypeEnum = typeof NeiBuFeeTypeEnum[keyof typeof NeiBuFeeTypeEnum];


/**
 * 通知公告 表单参数
 * @export
 * @interface NoticeFormData
 */
export interface NoticeFormData  extends BaseEntity {
    /**
     * 
     * @type {NoticeTypeEnum}
     */
    'type': NoticeTypeEnum;
    /**
     * 编码
     * @type {string}
     */
    'code': string;
    /**
     * 标题
     * @type {string}
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     */
    'content': string;
    /**
     * 
     * @type {NoticePushTypeEnum}
     */
    'pushType': NoticePushTypeEnum;
    /**
     * 投递范围
     * @type {Array<number>}
     */
    'pushScope'?: Array<number> | null;
    /**
     * 其他抄收人
     * @type {Array<number>}
     */
    'otherReceivers'?: Array<number> | null;
}


/**
 * 通知公告 列表输出
 * @export
 * @interface NoticeListData
 */
export interface NoticeListData  extends BaseEntity {
    /**
     * 
     * @type {NoticeTypeEnum}
     */
    'type': NoticeTypeEnum;
    /**
     * 编码
     * @type {string}
     */
    'code': string;
    /**
     * 标题
     * @type {string}
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     */
    'content': string;
    /**
     * 
     * @type {NoticePushTypeEnum}
     */
    'pushType': NoticePushTypeEnum;
    /**
     * 投递范围
     * @type {Array<number>}
     */
    'pushScope'?: Array<number> | null;
    /**
     * 其他抄收人
     * @type {Array<number>}
     */
    'otherReceivers'?: Array<number> | null;
    /**
     * 发布时间
     * @type {string}
     */
    'pushDate'?: string | null;
    /**
     * 撤回时间
     * @type {string}
     */
    'revokeDate'?: string | null;
    /**
     * 
     * @type {NoticeStatusEnum}
     */
    'status': NoticeStatusEnum;
}


/**
 * 通知公告 打印
 * @export
 * @interface NoticePrint
 */
export interface NoticePrint  extends BaseEntity {
}
/**
 * 通知投递范围<br />&nbsp;全局 All = 0<br />&nbsp;本机构 Org = 1<br />&nbsp;本机构和下级机构 OrgAndSub = 2<br />&nbsp;人员 Per = 3<br />
 * @export
 * @enum {string}
 */

export const NoticePushTypeEnum = {
    All: 'All',
    Org: 'Org',
    OrgAndSub: 'OrgAndSub',
    Per: 'Per'
} as const;

export type NoticePushTypeEnum = typeof NoticePushTypeEnum[keyof typeof NoticePushTypeEnum];


/**
 * 通知公告状态<br />&nbsp;草稿 Draft = 0<br />&nbsp;发布 Public = 1<br />&nbsp;撤回 Cancel = 2<br />&nbsp;作废 Invalid = 3<br />
 * @export
 * @enum {string}
 */

export const NoticeStatusEnum = {
    Draft: 'Draft',
    Public: 'Public',
    Cancel: 'Cancel',
    Invalid: 'Invalid'
} as const;

export type NoticeStatusEnum = typeof NoticeStatusEnum[keyof typeof NoticeStatusEnum];


/**
 * 通知公告类型<br />&nbsp;通知 Notice = 1<br />&nbsp;公告 Announcement = 2<br />
 * @export
 * @enum {string}
 */

export const NoticeTypeEnum = {
    Notice: 'Notice',
    Announcement: 'Announcement'
} as const;

export type NoticeTypeEnum = typeof NoticeTypeEnum[keyof typeof NoticeTypeEnum];


/**
 * 通知已读 列表输出
 * @export
 * @interface NotifyReadListData
 */
export interface NotifyReadListData  extends BaseEntity {
    /**
     * 通知编号
     * @type {number}
     */
    'noticeId': number;
    /**
     * 
     * @type {NotifyReadStatusEnum}
     */
    'status': NotifyReadStatusEnum;
    /**
     * 已读时间
     * @type {string}
     */
    'readDate': string;
    /**
     * 
     * @type {NoticeTypeEnum}
     */
    'type': NoticeTypeEnum;
    /**
     * 标题
     * @type {string}
     */
    'title': string;
    /**
     * 发布时间
     * @type {string}
     */
    'pushDate'?: string | null;
}


/**
 * 通知已读类型<br />&nbsp;未读 Unread = 0<br />&nbsp;已读 ReadAlready = 1<br />
 * @export
 * @enum {string}
 */

export const NotifyReadStatusEnum = {
    Unread: 'Unread',
    ReadAlready: 'ReadAlready'
} as const;

export type NotifyReadStatusEnum = typeof NotifyReadStatusEnum[keyof typeof NotifyReadStatusEnum];


/**
 * 开放认证 表单参数
 * @export
 * @interface OpenAccessFormData
 */
export interface OpenAccessFormData  extends BaseEntity {
    /**
     * 访问标识
     * @type {string}
     */
    'accessKey': string;
    /**
     * 访问密钥
     * @type {string}
     */
    'accessSecret': string;
    /**
     * 用户ID
     * @type {number}
     */
    'userId': number;
}
/**
 * 开放认证 列表输出
 * @export
 * @interface OpenAccessListData
 */
export interface OpenAccessListData  extends BaseEntity {
    /**
     * 访问标识
     * @type {string}
     */
    'accessKey': string;
    /**
     * 访问密钥
     * @type {string}
     */
    'accessSecret': string;
    /**
     * 用户ID
     * @type {number}
     */
    'userId': number;
}
/**
 * 机构配置项
 * @export
 * @interface OrgConfig
 */
export interface OrgConfig {
    /**
     * 重量体积比例（重量kg/体积M3/1000）
     * @type {number}
     */
    'wToVRatio': number;
    /**
     * 锁机阈值
     * @type {number}
     */
    'lockThreshold': number;
    /**
     * 告警阈值
     * @type {number}
     */
    'warningThreshold': number;
    /**
     * 提现阈值
     * @type {number}
     */
    'withdrawThreshold': number;
    /**
     * 代收款限额
     * @type {number}
     */
    'collectionLimit': number;
    /**
     * 到付款限额
     * @type {number}
     */
    'cashOnDeliveryLimit': number;
    /**
     * 
     * @type {PickupLimit}
     */
    'pickupLimit': PickupLimit;
    /**
     * 锁机监测
     * @type {boolean}
     */
    'lockLimit': boolean;
    /**
     * 允许发货
     * @type {boolean}
     */
    'shipment': boolean;
    /**
     * 短信服务
     * @type {boolean}
     */
    'sms': boolean;
    /**
     * 回单服务
     * @type {boolean}
     */
    'receipt': boolean;
    /**
     * 对外公开
     * @type {boolean}
     */
    'public': boolean;
    /**
     * 开通电子运单
     * @type {boolean}
     */
    'eleWaybill': boolean;
    /**
     * 开通电子回单
     * @type {boolean}
     */
    'eleReceipt': boolean;
    /**
     * 正常签收时效（小时）
     * @type {number}
     */
    'normalSigninTimeLimit': number;
    /**
     * 回单返回时效（小时）
     * @type {number}
     */
    'receiptReturnTimeLimit': number;
}
/**
 * 机构等级<br />&nbsp;总部 Headquarter = 0<br />&nbsp;分拨中心 DistributionCenter = 1<br />&nbsp;专线 Line = 2<br />&nbsp;网点 Branch = 3<br />
 * @export
 * @enum {string}
 */

export const OrgGradeEnum = {
    Headquarter: 'Headquarter',
    DistributionCenter: 'DistributionCenter',
    Line: 'Line',
    Branch: 'Branch'
} as const;

export type OrgGradeEnum = typeof OrgGradeEnum[keyof typeof OrgGradeEnum];


/**
 * 机构类型<br />&nbsp;自营 Own = 1<br />&nbsp;加盟 Franchisee = 2<br />
 * @export
 * @enum {string}
 */

export const OrgTypeEnum = {
    Own: 'Own',
    Franchisee: 'Franchisee'
} as const;

export type OrgTypeEnum = typeof OrgTypeEnum[keyof typeof OrgTypeEnum];


/**
 * 机构 实体类
 * @export
 * @interface Organization
 */
export interface Organization  extends BaseEntity {
    /**
     * 
     * @type {OrgTypeEnum}
     */
    'type': OrgTypeEnum;
    /**
     * 
     * @type {OrgGradeEnum}
     */
    'grade': OrgGradeEnum;
    /**
     * 服务范围
     * @type {Array<string>}
     */
    'deliveryScope': Array<string>;
    /**
     * 排除范围
     * @type {Array<string>}
     */
    'exclusionScope'?: Array<string> | null;
    /**
     * 特殊区域
     * @type {Array<string>}
     */
    'specialScope'?: Array<string> | null;
    /**
     * 服务类型
     * @type {Array<ShouHuoTypeEnum>}
     */
    'serviceType': Array<ShouHuoTypeEnum>;
    /**
     * 服务提醒
     * @type {string}
     */
    'serviceRemark'?: string | null;
    /**
     * 财务中心
     * @type {number}
     */
    'financeCenter': number;
    /**
     * 
     * @type {OrgConfig}
     */
    'config': OrgConfig;
    /**
     * 白名单
     * @type {Array<string>}
     */
    'whiteList'?: Array<string> | null;
    /**
     * 黑名单
     * @type {Array<string>}
     */
    'blackList'?: Array<string> | null;
    /**
     * 
     * @type {BaseOrg}
     */
    'baseOrg'?: BaseOrg;
}


/**
 * 机构 表单参数
 * @export
 * @interface OrganizationFormData
 */
export interface OrganizationFormData  extends BaseEntity {
    /**
     * 
     * @type {OrgTypeEnum}
     */
    'type': OrgTypeEnum;
    /**
     * 
     * @type {OrgGradeEnum}
     */
    'grade': OrgGradeEnum;
    /**
     * 服务范围
     * @type {string}
     */
    'deliveryScope': string;
    /**
     * 排除范围
     * @type {string}
     */
    'exclusionScope'?: string | null;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialScope'?: string | null;
    /**
     * 服务类型
     * @type {Array<ShouHuoTypeEnum>}
     */
    'serviceType': Array<ShouHuoTypeEnum>;
    /**
     * 服务提醒
     * @type {string}
     */
    'serviceRemark'?: string | null;
    /**
     * 财务中心
     * @type {number}
     */
    'financeCenter': number;
    /**
     * 白名单
     * @type {string}
     */
    'whiteList'?: string | null;
    /**
     * 黑名单
     * @type {string}
     */
    'blackList'?: string | null;
}


/**
 * 机构 列表输出
 * @export
 * @interface OrganizationListData
 */
export interface OrganizationListData  extends BaseEntity {
    /**
     * 
     * @type {OrgTypeEnum}
     */
    'type': OrgTypeEnum;
    /**
     * 
     * @type {OrgGradeEnum}
     */
    'grade': OrgGradeEnum;
    /**
     * 服务范围
     * @type {string}
     */
    'deliveryScope': string;
    /**
     * 排除范围
     * @type {string}
     */
    'exclusionScope'?: string | null;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialScope'?: string | null;
    /**
     * 服务类型
     * @type {Array<ShouHuoTypeEnum>}
     */
    'serviceType': Array<ShouHuoTypeEnum>;
    /**
     * 服务提醒
     * @type {string}
     */
    'serviceRemark'?: string | null;
    /**
     * 财务中心
     * @type {number}
     */
    'financeCenter': number;
    /**
     * 白名单
     * @type {string}
     */
    'whiteList'?: string | null;
    /**
     * 黑名单
     * @type {string}
     */
    'blackList'?: string | null;
}


/**
 * 通用分页入参
 * @export
 * @interface PagedInput
 */
export interface PagedInput {
    /**
     * 当前页码
     * @type {number}
     */
    'pageNo': number;
    /**
     * 页码容量
     * @type {number}
     */
    'pageSize': number;
    /**
     * 排序字段
     * @type {string}
     */
    'sortField'?: string | null;
    /**
     * 排序方法,默认升序,否则降序
     * @type {string}
     */
    'sortOrder'?: string | null;
}
/**
 * 分页动态搜索条件
 * @export
 * @interface PaginatedDynamicSearchInfos
 */
export interface PaginatedDynamicSearchInfos {
    /**
     * 搜索条件
     * @type {Array<DynamicFilterModel>}
     */
    'searchInfos'?: Array<DynamicFilterModel> | null;
    /**
     * 
     * @type {PagedInput}
     */
    'pagerInfo'?: PagedInput;
}
/**
 * 盘点状态<br />&nbsp;未盘点 WeiPanDian = 0<br />&nbsp;在库 ZaiKu = 1<br />&nbsp;不在库 BuZaiKu = 2<br />
 * @export
 * @enum {string}
 */

export const PanDianStatusEnum = {
    WeiPanDian: 'WeiPanDian',
    ZaiKu: 'ZaiKu',
    BuZaiKu: 'BuZaiKu'
} as const;

export type PanDianStatusEnum = typeof PanDianStatusEnum[keyof typeof PanDianStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const ParameterDirection = {
    Input: 'Input',
    Output: 'Output',
    InputOutput: 'InputOutput',
    ReturnValue: 'ReturnValue'
} as const;

export type ParameterDirection = typeof ParameterDirection[keyof typeof ParameterDirection];


/**
 * 提货费用 表单参数
 * @export
 * @interface PickUpCostFormData
 */
export interface PickUpCostFormData  extends BaseEntity {
    /**
     * 
     * @type {TiHuoFeeTypeEnum}
     */
    'feeType': TiHuoFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 提货费用 列表输出
 * @export
 * @interface PickUpCostListData
 */
export interface PickUpCostListData  extends BaseEntity {
    /**
     * 
     * @type {TiHuoFeeTypeEnum}
     */
    'feeType': TiHuoFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {FenBoFeeStatusEnum}
     */
    'status': FenBoFeeStatusEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 提货明细 表单参数
 * @export
 * @interface PickUpDetailsFormData
 */
export interface PickUpDetailsFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
}
/**
 * 提货明细 列表输出
 * @export
 * @interface PickUpDetailsListData
 */
export interface PickUpDetailsListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
    /**
     * 
     * @type {TiHuoDetailsStatusEnum}
     */
    'status': TiHuoDetailsStatusEnum;
}


/**
 * 提货 表单参数
 * @export
 * @interface PickUpFormData
 */
export interface PickUpFormData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {TiHuoTypeEnum}
     */
    'type': TiHuoTypeEnum;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 卸车组
     * @type {number}
     */
    'unloader': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 提货 列表输出
 * @export
 * @interface PickUpListData
 */
export interface PickUpListData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {TiHuoStatusEnum}
     */
    'status': TiHuoStatusEnum;
    /**
     * 
     * @type {TiHuoTypeEnum}
     */
    'type': TiHuoTypeEnum;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 卸车组
     * @type {number}
     */
    'unloader': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 自提限制
 * @export
 * @interface PickupLimit
 */
export interface PickupLimit {
    /**
     * 重量（kg）
     * @type {number}
     */
    'weight': number;
    /**
     * 体积（M3）
     * @type {number}
     */
    'volume': number;
}
/**
 * 车牌颜色<br />&nbsp;蓝色 Blue = 1<br />&nbsp;黄色 Yellow = 2<br />&nbsp;黑色 Black = 3<br />&nbsp;白色 White = 4<br />&nbsp;绿色 Green = 5<br />
 * @export
 * @enum {string}
 */

export const PlateColorEnum = {
    Blue: 'Blue',
    Yellow: 'Yellow',
    Black: 'Black',
    White: 'White',
    Green: 'Green'
} as const;

export type PlateColorEnum = typeof PlateColorEnum[keyof typeof PlateColorEnum];


/**
 * 搬运团队 表单参数
 * @export
 * @interface PortersTeamFormData
 */
export interface PortersTeamFormData  extends BaseEntity {
    /**
     * 组名
     * @type {string}
     */
    'name': string;
    /**
     * 负责人
     * @type {number}
     */
    'managerId': number;
    /**
     * 成员
     * @type {Array<number>}
     */
    'members'?: Array<number> | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 搬运团队 列表输出
 * @export
 * @interface PortersTeamListData
 */
export interface PortersTeamListData  extends BaseEntity {
    /**
     * 组名
     * @type {string}
     */
    'name': string;
    /**
     * 负责人
     * @type {number}
     */
    'managerId': number;
    /**
     * 成员
     * @type {Array<number>}
     */
    'members'?: Array<number> | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 预付金账单 表单参数
 * @export
 * @interface PrepaidBillFormData
 */
export interface PrepaidBillFormData  extends BaseEntity {
    /**
     * 财务日期
     * @type {string}
     */
    'feeDate': string;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {YuFuFeeTypeEnum}
     */
    'feeType': YuFuFeeTypeEnum;
    /**
     * 
     * @type {YuFuSourceTypeEnum}
     */
    'sourceType'?: YuFuSourceTypeEnum;
    /**
     * 来源单号
     * @type {string}
     */
    'sourceNo'?: string | null;
    /**
     * 财务中心
     * @type {number}
     */
    'financeCenter': number;
    /**
     * 关联网点
     * @type {number}
     */
    'assOrgId': number | null;
    /**
     * 关联财务中心
     * @type {number}
     */
    'assFinanceCenter': number | null;
    /**
     * 
     * @type {YuFuJinCaoZuoTypeEnum}
     */
    'settlementType'?: YuFuJinCaoZuoTypeEnum;
    /**
     * 结算备注
     * @type {string}
     */
    'settlementNotes'?: string | null;
    /**
     * 结余
     * @type {number}
     */
    'balance': number;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 预付金账单 列表输出
 * @export
 * @interface PrepaidBillListData
 */
export interface PrepaidBillListData  extends BaseEntity {
    /**
     * 财务日期
     * @type {string}
     */
    'feeDate': string;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {YuFuFeeTypeEnum}
     */
    'feeType': YuFuFeeTypeEnum;
    /**
     * 
     * @type {YuFuSourceTypeEnum}
     */
    'sourceType'?: YuFuSourceTypeEnum;
    /**
     * 来源单号
     * @type {string}
     */
    'sourceNo'?: string | null;
    /**
     * 财务中心
     * @type {number}
     */
    'financeCenter': number;
    /**
     * 关联网点
     * @type {number}
     */
    'assOrgId': number | null;
    /**
     * 关联财务中心
     * @type {number}
     */
    'assFinanceCenter': number | null;
    /**
     * 
     * @type {RuZhangStatusEnum}
     */
    'status': RuZhangStatusEnum;
    /**
     * 
     * @type {YuFuJinCaoZuoTypeEnum}
     */
    'settlementType'?: YuFuJinCaoZuoTypeEnum;
    /**
     * 
     * @type {JieSuanStatusEnum}
     */
    'settlementStatus'?: JieSuanStatusEnum;
    /**
     * 结算备注
     * @type {string}
     */
    'settlementNotes'?: string | null;
    /**
     * 结余
     * @type {number}
     */
    'balance': number;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 打印模板 表单参数
 * @export
 * @interface PrintTemplateFormData
 */
export interface PrintTemplateFormData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 模板
     * @type {string}
     */
    'template': string;
    /**
     * 
     * @type {PrintTypeEnum}
     */
    'type': PrintTypeEnum;
    /**
     * 客户端地址
     * @type {string}
     */
    'clientUrl'?: string | null;
    /**
     * 参数
     * @type {string}
     */
    'param'?: string | null;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 打印模板 列表输出
 * @export
 * @interface PrintTemplateListData
 */
export interface PrintTemplateListData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 模板
     * @type {string}
     */
    'template': string;
    /**
     * 
     * @type {PrintTypeEnum}
     */
    'type': PrintTypeEnum;
    /**
     * 客户端地址
     * @type {string}
     */
    'clientUrl'?: string | null;
    /**
     * 参数
     * @type {string}
     */
    'param'?: string | null;
    /**
     * 排序
     * @type {number}
     */
    'orderNo': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 打印类型枚举<br />&nbsp;浏览器打印 Browser = 0<br />&nbsp;客户端打印 Client = 1<br />
 * @export
 * @enum {string}
 */

export const PrintTypeEnum = {
    Browser: 'Browser',
    Client: 'Client'
} as const;

export type PrintTypeEnum = typeof PrintTypeEnum[keyof typeof PrintTypeEnum];


/**
 * 从业资格证类型<br />&nbsp;经营性道路货物运输驾驶员 Driver = 1<br />&nbsp;道路危险货物运输驾驶员 DangerousDriver = 2<br />&nbsp;其他 Other = 3<br />
 * @export
 * @enum {string}
 */

export const QualificationTypeEnum = {
    Driver: 'Driver',
    DangerousDriver: 'DangerousDriver',
    Other: 'Other'
} as const;

export type QualificationTypeEnum = typeof QualificationTypeEnum[keyof typeof QualificationTypeEnum];


/**
 * 报价类型枚举<br />&nbsp;重量 ZhongLiang = 0<br />&nbsp;体积 TiJi = 1<br />&nbsp;件数 JianShu = 2<br />&nbsp;派送距离 PaiSongJuLi = 3<br />&nbsp;提货距离 TiHuoJuLi = 4<br />&nbsp;计重 JiZhong = 5<br />&nbsp;代收款 DaiShou = 6<br />&nbsp;到付款 DaoFu = 7<br />
 * @export
 * @enum {string}
 */

export const QuoteTypeEnum = {
    ZhongLiang: 'ZhongLiang',
    TiJi: 'TiJi',
    JianShu: 'JianShu',
    PaiSongJuLi: 'PaiSongJuLi',
    TiHuoJuLi: 'TiHuoJuLi',
    JiZhong: 'JiZhong',
    DaiShou: 'DaiShou',
    DaoFu: 'DaoFu'
} as const;

export type QuoteTypeEnum = typeof QuoteTypeEnum[keyof typeof QuoteTypeEnum];


/**
 * 回单跟踪 表单参数
 * @export
 * @interface ReceiptTrackFormData
 */
export interface ReceiptTrackFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 回单号
     * @type {string}
     */
    'receiptNo': string;
    /**
     * 
     * @type {HuiDanStatusEnum}
     */
    'status': HuiDanStatusEnum;
    /**
     * 跟踪网点
     * @type {number}
     */
    'currentOrgId': number;
    /**
     * 关联网点
     * @type {number}
     */
    'assOrgId': number | null;
    /**
     * 跟踪说明
     * @type {string}
     */
    'trackingRemark': string;
    /**
     * 返回方式
     * @type {number}
     */
    'returnType': number;
    /**
     * 快递单号
     * @type {string}
     */
    'courierNumber'?: string | null;
    /**
     * 快递公司
     * @type {string}
     */
    'courierCompany'?: string | null;
    /**
     * 快递费
     * @type {number}
     */
    'courierFee': number;
    /**
     * 对外公开
     * @type {boolean}
     */
    'isPublic': boolean;
}


/**
 * 回单跟踪 列表输出
 * @export
 * @interface ReceiptTrackListData
 */
export interface ReceiptTrackListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 回单号
     * @type {string}
     */
    'receiptNo': string;
    /**
     * 
     * @type {HuiDanStatusEnum}
     */
    'status': HuiDanStatusEnum;
    /**
     * 跟踪网点
     * @type {number}
     */
    'currentOrgId': number;
    /**
     * 关联网点
     * @type {number}
     */
    'assOrgId': number | null;
    /**
     * 跟踪说明
     * @type {string}
     */
    'trackingRemark': string;
    /**
     * 返回方式
     * @type {number}
     */
    'returnType': number;
    /**
     * 快递单号
     * @type {string}
     */
    'courierNumber'?: string | null;
    /**
     * 快递公司
     * @type {string}
     */
    'courierCompany'?: string | null;
    /**
     * 快递费
     * @type {number}
     */
    'courierFee': number;
    /**
     * 对外公开
     * @type {boolean}
     */
    'isPublic': boolean;
}


/**
 * 收货客户 表单参数
 * @export
 * @interface ReceivingCustomersFormData
 */
export interface ReceivingCustomersFormData  extends BaseEntity {
    /**
     * 发货客户
     * @type {number}
     */
    'shippingCustomerId': number;
    /**
     * 客户名称
     * @type {string}
     */
    'companyName': string;
    /**
     * 联系人
     * @type {string}
     */
    'contacts'?: string | null;
    /**
     * 手机
     * @type {string}
     */
    'mobilePhone'?: string | null;
    /**
     * 电话
     * @type {string}
     */
    'telephone'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'region': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 到站
     * @type {string}
     */
    'arrivalStation'?: string | null;
    /**
     * 目的地
     * @type {string}
     */
    'destination'?: string | null;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'deliveryType'?: ShouHuoTypeEnum;
}


/**
 * 收货客户 列表输出
 * @export
 * @interface ReceivingCustomersListData
 */
export interface ReceivingCustomersListData  extends BaseEntity {
    /**
     * 发货客户
     * @type {number}
     */
    'shippingCustomerId': number;
    /**
     * 客户名称
     * @type {string}
     */
    'companyName': string;
    /**
     * 联系人
     * @type {string}
     */
    'contacts'?: string | null;
    /**
     * 手机
     * @type {string}
     */
    'mobilePhone'?: string | null;
    /**
     * 电话
     * @type {string}
     */
    'telephone'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'region': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 到站
     * @type {string}
     */
    'arrivalStation'?: string | null;
    /**
     * 目的地
     * @type {string}
     */
    'destination'?: string | null;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'deliveryType'?: ShouHuoTypeEnum;
}


/**
 * 角色 实体类
 * @export
 * @interface Role
 */
export interface Role  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 子角色
     * @type {Array<number>}
     */
    'childGroup'?: Array<number> | null;
    /**
     * 
     * @type {DataScopeEnum}
     */
    'dataScope': DataScopeEnum;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
    /**
     * 角色菜单功能列表
     * @type {Array<Menu>}
     */
    'menus'?: Array<Menu> | null;
}


/**
 * 角色 表单参数
 * @export
 * @interface RoleFormData
 */
export interface RoleFormData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 子角色
     * @type {Array<number>}
     */
    'childGroup'?: Array<number> | null;
    /**
     * 
     * @type {DataScopeEnum}
     */
    'dataScope': DataScopeEnum;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
}


/**
 * 角色 列表输出
 * @export
 * @interface RoleListData
 */
export interface RoleListData  extends BaseEntity {
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 子角色
     * @type {Array<number>}
     */
    'childGroup'?: Array<number> | null;
    /**
     * 
     * @type {DataScopeEnum}
     */
    'dataScope': DataScopeEnum;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
    /**
     * 描述
     * @type {string}
     */
    'description'?: string | null;
}


/**
 * 角色 打印
 * @export
 * @interface RolePrint
 */
export interface RolePrint  extends BaseEntity {
}
/**
 * 入账状态<br />&nbsp;未入账 WeiRuZhang = 0<br />&nbsp;已入账 YiRuZhang = 1<br />
 * @export
 * @enum {string}
 */

export const RuZhangStatusEnum = {
    WeiRuZhang: 'WeiRuZhang',
    YiRuZhang: 'YiRuZhang'
} as const;

export type RuZhangStatusEnum = typeof RuZhangStatusEnum[keyof typeof RuZhangStatusEnum];


/**
 * 对外报价 表单参数
 * @export
 * @interface SalesQuoteFormData
 */
export interface SalesQuoteFormData  extends BaseEntity {
    /**
     * 
     * @type {DuiWaiFeeTypeEnum}
     */
    'feeType': DuiWaiFeeTypeEnum;
    /**
     * 
     * @type {BusinessTypeEnum}
     */
    'businessType'?: BusinessTypeEnum;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'receivingType'?: ShouHuoTypeEnum;
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialArea'?: string | null;
    /**
     * 地址匹配
     * @type {string}
     */
    'specialAddress'?: string | null;
    /**
     * 发货客户
     * @type {number}
     */
    'shippingCustomer': number | null;
    /**
     * 异形费率
     * @type {number}
     */
    'irregularRates': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 对外报价公式 表单参数
 * @export
 * @interface SalesQuoteFormulaFormData
 */
export interface SalesQuoteFormulaFormData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 条件
     * @type {string}
     */
    'conditions': string;
    /**
     * 公式
     * @type {string}
     */
    'formula': string;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 对外报价公式 列表输出
 * @export
 * @interface SalesQuoteFormulaListData
 */
export interface SalesQuoteFormulaListData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 条件
     * @type {string}
     */
    'conditions': string;
    /**
     * 公式
     * @type {string}
     */
    'formula': string;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 对外报价 列表输出
 * @export
 * @interface SalesQuoteListData
 */
export interface SalesQuoteListData  extends BaseEntity {
    /**
     * 
     * @type {DuiWaiFeeTypeEnum}
     */
    'feeType': DuiWaiFeeTypeEnum;
    /**
     * 
     * @type {BusinessTypeEnum}
     */
    'businessType'?: BusinessTypeEnum;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'receivingType'?: ShouHuoTypeEnum;
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialArea'?: string | null;
    /**
     * 地址匹配
     * @type {string}
     */
    'specialAddress'?: string | null;
    /**
     * 发货客户
     * @type {number}
     */
    'shippingCustomer': number | null;
    /**
     * 异形费率
     * @type {number}
     */
    'irregularRates': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 对外报价模板 表单参数
 * @export
 * @interface SalesQuoteTemplateFormData
 */
export interface SalesQuoteTemplateFormData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 
     * @type {QuoteTypeEnum}
     */
    'quoteType': QuoteTypeEnum;
    /**
     * 最小值
     * @type {number}
     */
    'minValue': number;
    /**
     * 最大值
     * @type {number}
     */
    'maxValue': number;
    /**
     * 首值金额
     * @type {number}
     */
    'firstAmount': number;
    /**
     * 首值
     * @type {number}
     */
    'firstValue': number;
    /**
     * 单价
     * @type {number}
     */
    'unitPrice': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 对外报价模板 列表输出
 * @export
 * @interface SalesQuoteTemplateListData
 */
export interface SalesQuoteTemplateListData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 
     * @type {QuoteTypeEnum}
     */
    'quoteType': QuoteTypeEnum;
    /**
     * 最小值
     * @type {number}
     */
    'minValue': number;
    /**
     * 最大值
     * @type {number}
     */
    'maxValue': number;
    /**
     * 首值金额
     * @type {number}
     */
    'firstAmount': number;
    /**
     * 首值
     * @type {number}
     */
    'firstValue': number;
    /**
     * 单价
     * @type {number}
     */
    'unitPrice': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 对外报价关联 表单参数
 * @export
 * @interface SalesQuoteWithFormData
 */
export interface SalesQuoteWithFormData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 关联报价Id
     * @type {number}
     */
    'relatedQuoteId': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 对外报价关联 列表输出
 * @export
 * @interface SalesQuoteWithListData
 */
export interface SalesQuoteWithListData  extends BaseEntity {
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 最低价
     * @type {number}
     */
    'lowestPrice': number;
    /**
     * 最高价
     * @type {number}
     */
    'highestPrice': number;
    /**
     * 关联报价Id
     * @type {number}
     */
    'relatedQuoteId': number;
    /**
     * 折扣
     * @type {number}
     */
    'discount': number;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 发货客户 表单参数
 * @export
 * @interface ShippingCustomersFormData
 */
export interface ShippingCustomersFormData  extends BaseEntity {
    /**
     * 发货客户
     * @type {string}
     */
    'companyName': string;
    /**
     * 联系人
     * @type {string}
     */
    'contacts'?: string | null;
    /**
     * 手机
     * @type {string}
     */
    'mobilePhone'?: string | null;
    /**
     * 电话
     * @type {string}
     */
    'telephone'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'region': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 身份证
     * @type {string}
     */
    'idCard'?: string | null;
    /**
     * 
     * @type {CustomerTypeEnum}
     */
    'type': CustomerTypeEnum;
    /**
     * 
     * @type {JieSuanTypeEnum}
     */
    'payType'?: JieSuanTypeEnum;
    /**
     * 品名
     * @type {string}
     */
    'productName'?: string | null;
    /**
     * 包装方式
     * @type {string}
     */
    'packagingType'?: string | null;
    /**
     * 保险费率
     * @type {number}
     */
    'insuranceRate': number | null;
}


/**
 * 发货客户 列表输出
 * @export
 * @interface ShippingCustomersListData
 */
export interface ShippingCustomersListData  extends BaseEntity {
    /**
     * 发货客户
     * @type {string}
     */
    'companyName': string;
    /**
     * 联系人
     * @type {string}
     */
    'contacts'?: string | null;
    /**
     * 手机
     * @type {string}
     */
    'mobilePhone'?: string | null;
    /**
     * 电话
     * @type {string}
     */
    'telephone'?: string | null;
    /**
     * 行政区域
     * @type {string}
     */
    'region': string;
    /**
     * 地址
     * @type {string}
     */
    'address': string;
    /**
     * 身份证
     * @type {string}
     */
    'idCard'?: string | null;
    /**
     * 
     * @type {CustomerTypeEnum}
     */
    'type': CustomerTypeEnum;
    /**
     * 
     * @type {JieSuanTypeEnum}
     */
    'payType'?: JieSuanTypeEnum;
    /**
     * 品名
     * @type {string}
     */
    'productName'?: string | null;
    /**
     * 包装方式
     * @type {string}
     */
    'packagingType'?: string | null;
    /**
     * 保险费率
     * @type {number}
     */
    'insuranceRate': number | null;
}


/**
 * 短驳费用 表单参数
 * @export
 * @interface ShortTransportCostFormData
 */
export interface ShortTransportCostFormData  extends BaseEntity {
    /**
     * 
     * @type {DuanBoFeeTypeEnum}
     */
    'feeType': DuanBoFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
}


/**
 * 短驳费用 列表输出
 * @export
 * @interface ShortTransportCostListData
 */
export interface ShortTransportCostListData  extends BaseEntity {
    /**
     * 
     * @type {DuanBoFeeTypeEnum}
     */
    'feeType': DuanBoFeeTypeEnum;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 
     * @type {FenBoFeeStatusEnum}
     */
    'status': FenBoFeeStatusEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
}


/**
 * 短驳明细 表单参数
 * @export
 * @interface ShortTransportDetailsFormData
 */
export interface ShortTransportDetailsFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
}
/**
 * 短驳明细 列表输出
 * @export
 * @interface ShortTransportDetailsListData
 */
export interface ShortTransportDetailsListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 成本
     * @type {number}
     */
    'cost': number;
    /**
     * 
     * @type {DuanBoDetailsStatusEnum}
     */
    'status': DuanBoDetailsStatusEnum;
}


/**
 * 短驳 表单参数
 * @export
 * @interface ShortTransportFormData
 */
export interface ShortTransportFormData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 到站
     * @type {number}
     */
    'destination': number;
    /**
     * 距离
     * @type {number}
     */
    'distance': number | null;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 卸车组
     * @type {number}
     */
    'unloader': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 短驳 列表输出
 * @export
 * @interface ShortTransportListData
 */
export interface ShortTransportListData  extends BaseEntity {
    /**
     * 批次
     * @type {string}
     */
    'no': string;
    /**
     * 
     * @type {DuanBoStatusEnum}
     */
    'status': DuanBoStatusEnum;
    /**
     * 到站
     * @type {number}
     */
    'destination': number;
    /**
     * 距离
     * @type {number}
     */
    'distance': number | null;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 司机
     * @type {string}
     */
    'driver': string;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone': string;
    /**
     * 装运组
     * @type {number}
     */
    'shipper': number | null;
    /**
     * 卸车组
     * @type {number}
     */
    'unloader': number | null;
    /**
     * 
     * @type {FenBoJieSuanTypeEnum}
     */
    'payType': FenBoJieSuanTypeEnum;
    /**
     * 
     * @type {FenBoFenTanTypeEnum}
     */
    'splitType': FenBoFenTanTypeEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 收货方式<br />&nbsp;自提 ZiTi = 0<br />&nbsp;派送 PaiSong = 1<br />
 * @export
 * @enum {string}
 */

export const ShouHuoTypeEnum = {
    ZiTi: 'ZiTi',
    PaiSong: 'PaiSong'
} as const;

export type ShouHuoTypeEnum = typeof ShouHuoTypeEnum[keyof typeof ShouHuoTypeEnum];


/**
 * 送货明细状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;配载 PeiZai = 1<br />&nbsp;装车 ZhuangChe = 2<br />&nbsp;卸车 XieChe = 3<br />&nbsp;完成 WanCheng = 4<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const SongHuoDetailsStatusEnum = {
    ChuShi: 'ChuShi',
    PeiZai: 'PeiZai',
    ZhuangChe: 'ZhuangChe',
    XieChe: 'XieChe',
    WanCheng: 'WanCheng',
    ZuoFei: 'ZuoFei'
} as const;

export type SongHuoDetailsStatusEnum = typeof SongHuoDetailsStatusEnum[keyof typeof SongHuoDetailsStatusEnum];


/**
 * 送货费用类型<br />&nbsp;运费 YunFei = 0<br />&nbsp;装卸费 ZhuangXieFei = 1<br />&nbsp;其他费 QiTaFei = 2<br />&nbsp;应收款 YingShouKuan = 100<br />
 * @export
 * @enum {string}
 */

export const SongHuoFeeTypeEnum = {
    YunFei: 'YunFei',
    ZhuangXieFei: 'ZhuangXieFei',
    QiTaFei: 'QiTaFei',
    YingShouKuan: 'YingShouKuan'
} as const;

export type SongHuoFeeTypeEnum = typeof SongHuoFeeTypeEnum[keyof typeof SongHuoFeeTypeEnum];


/**
 * 送货状态<br />&nbsp;开单 KaiDan = 1<br />&nbsp;装货 ZhuangHuo = 2<br />&nbsp;发车 FaChe = 3<br />&nbsp;到车 DaoChe = 4<br />&nbsp;卸货 XieHuo = 5<br />&nbsp;完成 WanCheng = 6<br />&nbsp;异常 YiChang = 7<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const SongHuoStatusEnum = {
    KaiDan: 'KaiDan',
    ZhuangHuo: 'ZhuangHuo',
    FaChe: 'FaChe',
    DaoChe: 'DaoChe',
    XieHuo: 'XieHuo',
    WanCheng: 'WanCheng',
    YiChang: 'YiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type SongHuoStatusEnum = typeof SongHuoStatusEnum[keyof typeof SongHuoStatusEnum];


/**
 * 库存 表单参数
 * @export
 * @interface StockFormData
 */
export interface StockFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 入库时间
     * @type {string}
     */
    'incomingTime': string;
    /**
     * 入库数量
     * @type {number}
     */
    'incomingQuantity': number;
    /**
     * 出库时间
     * @type {string}
     */
    'outgoingTime'?: string | null;
    /**
     * 出库数量
     * @type {number}
     */
    'outgoingQuantity': number | null;
    /**
     * 盘点数量
     * @type {number}
     */
    'inventoryQuantity': number | null;
    /**
     * 
     * @type {PanDianStatusEnum}
     */
    'inventoryStatus'?: PanDianStatusEnum;
    /**
     * 盘点时间
     * @type {string}
     */
    'inventoryTime'?: string | null;
}


/**
 * 库存 列表输出
 * @export
 * @interface StockListData
 */
export interface StockListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 入库时间
     * @type {string}
     */
    'incomingTime': string;
    /**
     * 入库数量
     * @type {number}
     */
    'incomingQuantity': number;
    /**
     * 出库时间
     * @type {string}
     */
    'outgoingTime'?: string | null;
    /**
     * 出库数量
     * @type {number}
     */
    'outgoingQuantity': number | null;
    /**
     * 盘点数量
     * @type {number}
     */
    'inventoryQuantity': number | null;
    /**
     * 
     * @type {PanDianStatusEnum}
     */
    'inventoryStatus'?: PanDianStatusEnum;
    /**
     * 盘点时间
     * @type {string}
     */
    'inventoryTime'?: string | null;
}


/**
 * 库位 表单参数
 * @export
 * @interface StockLocationFormData
 */
export interface StockLocationFormData  extends BaseEntity {
    /**
     * 编号
     * @type {string}
     */
    'no': string;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 容量
     * @type {number}
     */
    'capacity': number;
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 库位 列表输出
 * @export
 * @interface StockLocationListData
 */
export interface StockLocationListData  extends BaseEntity {
    /**
     * 编号
     * @type {string}
     */
    'no': string;
    /**
     * 名称
     * @type {string}
     */
    'name': string;
    /**
     * 容量
     * @type {number}
     */
    'capacity': number;
    /**
     * 目的地
     * @type {string}
     */
    'destination': string;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 存储类型<br />&nbsp;本地 Local = 0<br />&nbsp;Minio Minio = 1<br />&nbsp;阿里云OSS Aliyun = 2<br />&nbsp;腾讯云COS QCloud = 3<br />&nbsp;七牛云OSS Qiniu = 4<br />&nbsp;华为云OBS HuaweiCloud = 5<br />&nbsp;百度云BOS BaiduCloud = 6<br />&nbsp;天翼云OOS Ctyun = 7<br />
 * @export
 * @enum {string}
 */

export const StorageTypeEnum = {
    Local: 'Local',
    Minio: 'Minio',
    Aliyun: 'Aliyun',
    QCloud: 'QCloud',
    Qiniu: 'Qiniu',
    HuaweiCloud: 'HuaweiCloud',
    BaiduCloud: 'BaiduCloud',
    Ctyun: 'Ctyun'
} as const;

export type StorageTypeEnum = typeof StorageTypeEnum[keyof typeof StorageTypeEnum];


/**
 * 
 * @export
 * @interface SugarParameter
 */
export interface SugarParameter {
    /**
     * 
     * @type {number}
     */
    'precision': number;
    /**
     * 
     * @type {boolean}
     */
    'isRefCursor': boolean;
    /**
     * 
     * @type {boolean}
     */
    'isClob': boolean;
    /**
     * 
     * @type {boolean}
     */
    'isNClob': boolean;
    /**
     * 
     * @type {boolean}
     */
    'isNvarchar2': boolean;
    /**
     * 
     * @type {DbType}
     */
    'dbType'?: DbType;
    /**
     * 
     * @type {ParameterDirection}
     */
    'direction'?: ParameterDirection;
    /**
     * 
     * @type {boolean}
     */
    'isNullable': boolean;
    /**
     * 
     * @type {string}
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {number}
     */
    'scale': number;
    /**
     * 
     * @type {number}
     */
    'size': number;
    /**
     * 
     * @type {string}
     */
    'sourceColumn'?: string | null;
    /**
     * 
     * @type {boolean}
     */
    'sourceColumnNullMapping': boolean;
    /**
     * 
     * @type {string}
     */
    'udtTypeName'?: string | null;
    /**
     * 
     * @type {object}
     */
    'value'?: object | null;
    /**
     * 
     * @type {{ [key: string]: object | null; }}
     */
    'tempDate'?: { [key: string]: object | null; } | null;
    /**
     * 
     * @type {DataRowVersion}
     */
    'sourceVersion'?: DataRowVersion;
    /**
     * 
     * @type {string}
     */
    'typeName'?: string | null;
    /**
     * 
     * @type {boolean}
     */
    'isJson': boolean;
    /**
     * 
     * @type {boolean}
     */
    'isArray': boolean;
    /**
     * 
     * @type {object}
     */
    'customDbType'?: object | null;
}


/**
 * 三方平台类型枚举<br />&nbsp;微信 WeChat = 0<br />&nbsp;微信小程序 WeChatMiniProgram = 1<br />&nbsp;QQ QQ = 2<br />&nbsp;支付宝 Alipay = 3<br />&nbsp;钉钉 DingTalk = 4<br />
 * @export
 * @enum {string}
 */

export const ThirdPartyTypeEnum = {
    WeChat: 'WeChat',
    WeChatMiniProgram: 'WeChatMiniProgram',
    Qq: 'QQ',
    Alipay: 'Alipay',
    DingTalk: 'DingTalk'
} as const;

export type ThirdPartyTypeEnum = typeof ThirdPartyTypeEnum[keyof typeof ThirdPartyTypeEnum];


/**
 * 三方用户 表单参数
 * @export
 * @interface ThirdPartyUserFormData
 */
export interface ThirdPartyUserFormData  extends BaseEntity {
    /**
     * 用户ID
     * @type {number}
     */
    'userId': number;
    /**
     * 
     * @type {ThirdPartyTypeEnum}
     */
    'platformType': ThirdPartyTypeEnum;
    /**
     * OpenId
     * @type {string}
     */
    'openId': string;
    /**
     * UnionId
     * @type {string}
     */
    'unionId'?: string | null;
    /**
     * 密钥
     * @type {string}
     */
    'sessionKey'?: string | null;
    /**
     * 昵称
     * @type {string}
     */
    'nickName'?: string | null;
    /**
     * 头像
     * @type {string}
     */
    'avatar'?: string | null;
    /**
     * 手机号
     * @type {string}
     */
    'mobile'?: string | null;
    /**
     * 性别
     * @type {number}
     */
    'sex': number | null;
    /**
     * 语言
     * @type {string}
     */
    'language'?: string | null;
    /**
     * 城市
     * @type {string}
     */
    'city'?: string | null;
    /**
     * 省份
     * @type {string}
     */
    'province'?: string | null;
    /**
     * 国家
     * @type {string}
     */
    'country'?: string | null;
    /**
     * AccessToken
     * @type {string}
     */
    'accessToken'?: string | null;
    /**
     * RefreshToken
     * @type {string}
     */
    'refreshToken'?: string | null;
    /**
     * 过期时间
     * @type {number}
     */
    'expiresIn': number | null;
    /**
     * 授权范围
     * @type {string}
     */
    'scope'?: string | null;
}


/**
 * 三方用户 列表输出
 * @export
 * @interface ThirdPartyUserListData
 */
export interface ThirdPartyUserListData  extends BaseEntity {
    /**
     * 用户ID
     * @type {number}
     */
    'userId': number;
    /**
     * 
     * @type {ThirdPartyTypeEnum}
     */
    'platformType': ThirdPartyTypeEnum;
    /**
     * OpenId
     * @type {string}
     */
    'openId': string;
    /**
     * UnionId
     * @type {string}
     */
    'unionId'?: string | null;
    /**
     * 密钥
     * @type {string}
     */
    'sessionKey'?: string | null;
    /**
     * 昵称
     * @type {string}
     */
    'nickName'?: string | null;
    /**
     * 头像
     * @type {string}
     */
    'avatar'?: string | null;
    /**
     * 手机号
     * @type {string}
     */
    'mobile'?: string | null;
    /**
     * 性别
     * @type {number}
     */
    'sex': number | null;
    /**
     * 语言
     * @type {string}
     */
    'language'?: string | null;
    /**
     * 城市
     * @type {string}
     */
    'city'?: string | null;
    /**
     * 省份
     * @type {string}
     */
    'province'?: string | null;
    /**
     * 国家
     * @type {string}
     */
    'country'?: string | null;
    /**
     * AccessToken
     * @type {string}
     */
    'accessToken'?: string | null;
    /**
     * RefreshToken
     * @type {string}
     */
    'refreshToken'?: string | null;
    /**
     * 过期时间
     * @type {number}
     */
    'expiresIn': number | null;
    /**
     * 授权范围
     * @type {string}
     */
    'scope'?: string | null;
}


/**
 * 提货明细状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;配载 PeiZai = 1<br />&nbsp;提货 TiHuo = 2<br />&nbsp;入库 RuKu = 3<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const TiHuoDetailsStatusEnum = {
    ChuShi: 'ChuShi',
    PeiZai: 'PeiZai',
    TiHuo: 'TiHuo',
    RuKu: 'RuKu',
    ZuoFei: 'ZuoFei'
} as const;

export type TiHuoDetailsStatusEnum = typeof TiHuoDetailsStatusEnum[keyof typeof TiHuoDetailsStatusEnum];


/**
 * 提货费用类型<br />&nbsp;运费 YunFei = 0<br />&nbsp;装卸费 ZhuangXieFei = 1<br />&nbsp;其他费 QiTaFei = 2<br />
 * @export
 * @enum {string}
 */

export const TiHuoFeeTypeEnum = {
    YunFei: 'YunFei',
    ZhuangXieFei: 'ZhuangXieFei',
    QiTaFei: 'QiTaFei'
} as const;

export type TiHuoFeeTypeEnum = typeof TiHuoFeeTypeEnum[keyof typeof TiHuoFeeTypeEnum];


/**
 * 提货状态<br />&nbsp;开单 KaiDan = 1<br />&nbsp;发车 FaChe = 2<br />&nbsp;到车 DaoChe = 3<br />&nbsp;提货 TiHuo = 4<br />&nbsp;入库 RuKu = 5<br />&nbsp;完成 WanCheng = 6<br />&nbsp;异常 YiChang = 7<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const TiHuoStatusEnum = {
    KaiDan: 'KaiDan',
    FaChe: 'FaChe',
    DaoChe: 'DaoChe',
    TiHuo: 'TiHuo',
    RuKu: 'RuKu',
    WanCheng: 'WanCheng',
    YiChang: 'YiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type TiHuoStatusEnum = typeof TiHuoStatusEnum[keyof typeof TiHuoStatusEnum];


/**
 * 提货类型<br />&nbsp;客户提货 KeHu = 0<br />&nbsp;中心提货 ZhongXin = 1<br />
 * @export
 * @enum {string}
 */

export const TiHuoTypeEnum = {
    KeHu: 'KeHu',
    ZhongXin: 'ZhongXin'
} as const;

export type TiHuoTypeEnum = typeof TiHuoTypeEnum[keyof typeof TiHuoTypeEnum];


/**
 * <br />&nbsp; Backlog = 0<br />&nbsp; Ready = 1<br />&nbsp; Running = 2<br />&nbsp; Pause = 3<br />&nbsp; Blocked = 4<br />&nbsp; ErrorToReady = 5<br />&nbsp; Archived = 6<br />&nbsp; Panic = 7<br />&nbsp; Overrun = 8<br />&nbsp; Unoccupied = 9<br />&nbsp; NotStart = 10<br />&nbsp; Unknown = 11<br />&nbsp; Unhandled = 12<br />
 * @export
 * @enum {string}
 */

export const TriggerStatus = {
    Backlog: 'Backlog',
    Ready: 'Ready',
    Running: 'Running',
    Pause: 'Pause',
    Blocked: 'Blocked',
    ErrorToReady: 'ErrorToReady',
    Archived: 'Archived',
    Panic: 'Panic',
    Overrun: 'Overrun',
    Unoccupied: 'Unoccupied',
    NotStart: 'NotStart',
    Unknown: 'Unknown',
    Unhandled: 'Unhandled'
} as const;

export type TriggerStatus = typeof TriggerStatus[keyof typeof TriggerStatus];


/**
 * 车辆资料 表单参数
 * @export
 * @interface TruckDataFormData
 */
export interface TruckDataFormData  extends BaseEntity {
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 
     * @type {VanTypeEnum}
     */
    'vanType': VanTypeEnum;
    /**
     * 车长
     * @type {number}
     */
    'length': number | null;
    /**
     * 车宽
     * @type {number}
     */
    'width': number | null;
    /**
     * 车高
     * @type {number}
     */
    'height': number | null;
    /**
     * 载重
     * @type {number}
     */
    'carryingWidth': number | null;
    /**
     * 载方
     * @type {number}
     */
    'carryingVolume': number | null;
    /**
     * 
     * @type {PlateColorEnum}
     */
    'licensePlateColor'?: PlateColorEnum;
    /**
     * 
     * @type {EnergyTypeEnum}
     */
    'energyType'?: EnergyTypeEnum;
    /**
     * 
     * @type {CooperationTypeEnum}
     */
    'cooperationType': CooperationTypeEnum;
    /**
     * 车架号
     * @type {string}
     */
    'frameId'?: string | null;
    /**
     * 发动机号
     * @type {string}
     */
    'engineId'?: string | null;
    /**
     * 年检有效期
     * @type {string}
     */
    'expireDate'?: string | null;
    /**
     * 强制报废期
     * @type {string}
     */
    'scrapDate'?: string | null;
    /**
     * 车主
     * @type {string}
     */
    'carOwner'?: string | null;
    /**
     * 车主电话
     * @type {string}
     */
    'phone'?: string | null;
    /**
     * 车主证件
     * @type {string}
     */
    'idCard'?: string | null;
    /**
     * 车主地址
     * @type {string}
     */
    'address'?: string | null;
    /**
     * 挂靠方
     * @type {string}
     */
    'affiliatedParty'?: string | null;
    /**
     * 道路运输证
     * @type {string}
     */
    'transportLicense'?: string | null;
    /**
     * 经营许可证
     * @type {string}
     */
    'businessLicense'?: string | null;
    /**
     * 挂车牌号
     * @type {string}
     */
    'trailerPlateNo'?: string | null;
    /**
     * 行驶证主页
     * @type {number}
     */
    'licenseFrontImageId': number | null;
    /**
     * 行驶证副页
     * @type {number}
     */
    'licenseReverseImageId': number | null;
    /**
     * 行驶证其他
     * @type {number}
     */
    'licenseOtherImageId': number | null;
    /**
     * 运输证主页
     * @type {number}
     */
    'transportLicenseImageId': number | null;
}


/**
 * 车辆资料 列表输出
 * @export
 * @interface TruckDataListData
 */
export interface TruckDataListData  extends BaseEntity {
    /**
     * 车牌号
     * @type {string}
     */
    'carNo': string;
    /**
     * 
     * @type {CarTypeEnum}
     */
    'carType': CarTypeEnum;
    /**
     * 
     * @type {VanTypeEnum}
     */
    'vanType': VanTypeEnum;
    /**
     * 车长
     * @type {number}
     */
    'length': number | null;
    /**
     * 车宽
     * @type {number}
     */
    'width': number | null;
    /**
     * 车高
     * @type {number}
     */
    'height': number | null;
    /**
     * 载重
     * @type {number}
     */
    'carryingWidth': number | null;
    /**
     * 载方
     * @type {number}
     */
    'carryingVolume': number | null;
    /**
     * 
     * @type {PlateColorEnum}
     */
    'licensePlateColor'?: PlateColorEnum;
    /**
     * 
     * @type {EnergyTypeEnum}
     */
    'energyType'?: EnergyTypeEnum;
    /**
     * 
     * @type {CooperationTypeEnum}
     */
    'cooperationType': CooperationTypeEnum;
    /**
     * 车架号
     * @type {string}
     */
    'frameId'?: string | null;
    /**
     * 发动机号
     * @type {string}
     */
    'engineId'?: string | null;
    /**
     * 年检有效期
     * @type {string}
     */
    'expireDate'?: string | null;
    /**
     * 强制报废期
     * @type {string}
     */
    'scrapDate'?: string | null;
    /**
     * 车主
     * @type {string}
     */
    'carOwner'?: string | null;
    /**
     * 车主电话
     * @type {string}
     */
    'phone'?: string | null;
    /**
     * 车主证件
     * @type {string}
     */
    'idCard'?: string | null;
    /**
     * 车主地址
     * @type {string}
     */
    'address'?: string | null;
    /**
     * 挂靠方
     * @type {string}
     */
    'affiliatedParty'?: string | null;
    /**
     * 道路运输证
     * @type {string}
     */
    'transportLicense'?: string | null;
    /**
     * 经营许可证
     * @type {string}
     */
    'businessLicense'?: string | null;
    /**
     * 挂车牌号
     * @type {string}
     */
    'trailerPlateNo'?: string | null;
    /**
     * 行驶证主页
     * @type {number}
     */
    'licenseFrontImageId': number | null;
    /**
     * 行驶证副页
     * @type {number}
     */
    'licenseReverseImageId': number | null;
    /**
     * 行驶证其他
     * @type {number}
     */
    'licenseOtherImageId': number | null;
    /**
     * 运输证主页
     * @type {number}
     */
    'transportLicenseImageId': number | null;
}


/**
 * element-plus 的文件上传类
 * @export
 * @interface UploadUserFile
 */
export interface UploadUserFile {
    /**
     * 文件名
     * @type {string}
     */
    'name': string;
    /**
     * 文件 Id
     * @type {number}
     */
    'uid': number | null;
    /**
     * 文件地址
     * @type {string}
     */
    'url'?: string | null;
}
/**
 * 用户修改锁屏密码 输入类
 * @export
 * @interface UserChangePinInput
 */
export interface UserChangePinInput {
    /**
     * 当前密码
     * @type {string}
     */
    'pin': string;
    /**
     * 新密码
     * @type {string}
     */
    'newPin': string;
    /**
     * 重复新密码
     * @type {string}
     */
    'confirmNewPin': string;
}
/**
 * 
 * @export
 * @interface UserChangePwdInput
 */
export interface UserChangePwdInput {
    /**
     * 当前密码
     * @type {string}
     */
    'password': string;
    /**
     * 新密码
     * @type {string}
     */
    'newPassword': string;
    /**
     * 重复新密码
     * @type {string}
     */
    'confirmNewPassword': string;
}
/**
 * 用户配置项
 * @export
 * @interface UserConfigOutput
 */
export interface UserConfigOutput {
    /**
     * 是否开启水印
     * @type {boolean}
     */
    'enabledWatermark': boolean;
}
/**
 * 用户连接状态实体
 * @export
 * @interface UserConnection
 */
export interface UserConnection  extends BaseEntity {
    /**
     * 连接Id
     * @type {string}
     */
    'connectionId': string;
    /**
     * 用户Id
     * @type {number}
     */
    'userId': number;
    /**
     * 账号
     * @type {string}
     */
    'account': string;
    /**
     * 姓名
     * @type {string}
     */
    'name': string;
    /**
     * 连接时间
     * @type {string}
     */
    'time'?: string | null;
    /**
     * 连接IP
     * @type {string}
     */
    'ip'?: string | null;
    /**
     * 浏览器
     * @type {string}
     */
    'userAgent'?: string | null;
}
/**
 * 用户表单参数
 * @export
 * @interface UserFormData
 */
export interface UserFormData  extends BaseEntity {
    /**
     * 账号
     * @type {string}
     */
    'account': string;
    /**
     * 姓名
     * @type {string}
     */
    'name': string;
    /**
     * 手机号
     * @type {string}
     */
    'phoneNumber': string;
    /**
     * 密保问题
     * @type {string}
     */
    'question'?: string | null;
    /**
     * 密保答案
     * @type {string}
     */
    'answer'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 
     * @type {UserOrgRoleAddInput}
     */
    'mainUserOrgRole': UserOrgRoleAddInput;
    /**
     * 用户机构列表
     * @type {Array<UserOrgRoleAddInput>}
     */
    'orgList'?: Array<UserOrgRoleAddInput> | null;
}
/**
 * 用户资料列表输出
 * @export
 * @interface UserListData
 */
export interface UserListData  extends BaseEntity {
    /**
     * 账号
     * @type {string}
     */
    'account': string;
    /**
     * 姓名
     * @type {string}
     */
    'name': string;
    /**
     * 手机号
     * @type {string}
     */
    'phoneNumber': string;
    /**
     * 密保问题
     * @type {string}
     */
    'question'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 用户菜单树
 * @export
 * @interface UserMenuTree
 */
export interface UserMenuTree {
    /**
     * Id
     * @type {number}
     */
    'id': number;
    /**
     * 路由名称
     * @type {string}
     */
    'name': string;
    /**
     * 路由地址
     * @type {string}
     */
    'path'?: string | null;
    /**
     * 显示名称
     * @type {string}
     */
    'title': string;
    /**
     * 上级编号
     * @type {number}
     */
    'parentId': number;
    /**
     * 
     * @type {MenuTypeEnum}
     */
    'type': MenuTypeEnum;
    /**
     * 组件路径
     * @type {string}
     */
    'component'?: string | null;
    /**
     * 重定向
     * @type {string}
     */
    'redirect'?: string | null;
    /**
     * 
     * @type {MenuMeta}
     */
    'meta': MenuMeta;
    /**
     * 子菜单
     * @type {Array<UserMenuTree>}
     */
    'children'?: Array<UserMenuTree> | null;
}


/**
 * 用户机构角色新增参数
 * @export
 * @interface UserOrgRoleAddInput
 */
export interface UserOrgRoleAddInput {
    /**
     * 主键
     * @type {number}
     */
    'id': number;
    /**
     * 乐观锁
     * @type {number}
     */
    'ver': number;
    /**
     * 用户编号
     * @type {number}
     */
    'userId': number;
    /**
     * 机构编号
     * @type {number}
     */
    'orgId': number;
    /**
     * 角色编号集合
     * @type {Array<number>}
     */
    'roleIds': Array<number>;
    /**
     * 是否包含下级
     * @type {boolean}
     */
    'isIncludeSub': boolean;
    /**
     * 生效日期
     * @type {string}
     */
    'effectiveDate'?: string | null;
    /**
     * 失效日期
     * @type {string}
     */
    'expiryDate'?: string | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 是否删除
     * @type {boolean}
     */
    'isDeleted': boolean;
}
/**
 * 车厢<br />&nbsp;厢车 Box = 0<br />&nbsp;高栏 Column = 1<br />&nbsp;平板 Flat = 2<br />
 * @export
 * @enum {string}
 */

export const VanTypeEnum = {
    Box: 'Box',
    Column: 'Column',
    Flat: 'Flat'
} as const;

export type VanTypeEnum = typeof VanTypeEnum[keyof typeof VanTypeEnum];


/**
 * 外发明细状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;配载 PeiZai = 1<br />&nbsp;发运 FaYun = 2<br />&nbsp;完成 WanCheng = 3<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const WaiFaDetailsStatusEnum = {
    ChuShi: 'ChuShi',
    PeiZai: 'PeiZai',
    FaYun: 'FaYun',
    WanCheng: 'WanCheng',
    ZuoFei: 'ZuoFei'
} as const;

export type WaiFaDetailsStatusEnum = typeof WaiFaDetailsStatusEnum[keyof typeof WaiFaDetailsStatusEnum];


/**
 * 外发费用类型<br />&nbsp;运费 YunFei = 0<br />&nbsp;装卸费 ZhuangXieFei = 1<br />&nbsp;其他费 QiTaFei = 2<br />&nbsp;送货费 SongHuoFei = 30<br />&nbsp;应收款 YingShouKuan = 100<br />&nbsp;到付款 DaoFuKuan = 101<br />&nbsp;代收款 DaiShouKuan = 102<br />
 * @export
 * @enum {string}
 */

export const WaiFaFeeTypeEnum = {
    YunFei: 'YunFei',
    ZhuangXieFei: 'ZhuangXieFei',
    QiTaFei: 'QiTaFei',
    SongHuoFei: 'SongHuoFei',
    YingShouKuan: 'YingShouKuan',
    DaoFuKuan: 'DaoFuKuan',
    DaiShouKuan: 'DaiShouKuan'
} as const;

export type WaiFaFeeTypeEnum = typeof WaiFaFeeTypeEnum[keyof typeof WaiFaFeeTypeEnum];


/**
 * 外发状态<br />&nbsp;开单 KaiDan = 1<br />&nbsp;发运 FaYun = 2<br />&nbsp;到达 DaoDa = 3<br />&nbsp;送货 SongHuo = 4<br />&nbsp;完成 WanCheng = 6<br />&nbsp;异常 YiChang = 7<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const WaiFaStatusEnum = {
    KaiDan: 'KaiDan',
    FaYun: 'FaYun',
    DaoDa: 'DaoDa',
    SongHuo: 'SongHuo',
    WanCheng: 'WanCheng',
    YiChang: 'YiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type WaiFaStatusEnum = typeof WaiFaStatusEnum[keyof typeof WaiFaStatusEnum];


/**
 * 运单费用 表单参数
 * @export
 * @interface WaybillCostFormData
 */
export interface WaybillCostFormData {
    /**
     * 主键，0 表示待新增
     * @type {number}
     */
    'id': number;
    /**
     * 乐观锁
     * @type {number}
     */
    'ver': number;
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 
     * @type {DuiWaiFeeTypeEnum}
     */
    'feeType': DuiWaiFeeTypeEnum;
    /**
     * 费用类型名称
     * @type {string}
     */
    'feeTypeName'?: string | null;
    /**
     * 金额
     * @type {number}
     */
    'amount': number;
    /**
     * 
     * @type {CostCtrlEnum}
     */
    'ctrl': CostCtrlEnum;
    /**
     * 报价快照
     * @type {string}
     */
    'quoteSnapshot'?: string | null;
    /**
     * 
     * @type {FileModuleType}
     */
    'sourceType'?: FileModuleType;
    /**
     * 来源单号
     * @type {string}
     */
    'sourceNo'?: string | null;
}


/**
 * 运单 表单参数
 * @export
 * @interface WaybillFormData
 */
export interface WaybillFormData  extends BaseEntity {
    /**
     * 运单号
     * @type {string}
     */
    'no': string;
    /**
     * 订单号
     * @type {string}
     */
    'orderNo'?: string | null;
    /**
     * 客户单号
     * @type {string}
     */
    'customerNo'?: string | null;
    /**
     * 来源单号
     * @type {string}
     */
    'sourceNo'?: string | null;
    /**
     * 
     * @type {BusinessTypeEnum}
     */
    'businessType': BusinessTypeEnum;
    /**
     * 始发站
     * @type {number}
     */
    'originatingStation': number;
    /**
     * 中转站
     * @type {number}
     */
    'transferStation': number | null;
    /**
     * 目的站
     * @type {number}
     */
    'destinationStation': number;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialArea'?: string | null;
    /**
     * 发货客户Id
     * @type {number}
     */
    'shippingId': number | null;
    /**
     * 发货客户
     * @type {string}
     */
    'shippingCustomer'?: string | null;
    /**
     * 发货人
     * @type {string}
     */
    'shipper': string;
    /**
     * 发货手机
     * @type {string}
     */
    'shippingMobile'?: string | null;
    /**
     * 发货电话
     * @type {string}
     */
    'shippingPhone'?: string | null;
    /**
     * 发货证件
     * @type {string}
     */
    'shippingCertificate'?: string | null;
    /**
     * 发货区域
     * @type {string}
     */
    'shippingArea': string;
    /**
     * 发货地址
     * @type {string}
     */
    'shippingAddress': string;
    /**
     * 接货距离
     * @type {number}
     */
    'pickupDistance': number | null;
    /**
     * 收货客户Id
     * @type {number}
     */
    'receivingId': number | null;
    /**
     * 收货客户
     * @type {string}
     */
    'receivingCustomer'?: string | null;
    /**
     * 收货人
     * @type {string}
     */
    'consignee': string;
    /**
     * 收货手机
     * @type {string}
     */
    'receivingMobile': string;
    /**
     * 收货电话
     * @type {string}
     */
    'receivingPhone'?: string | null;
    /**
     * 收货区域
     * @type {string}
     */
    'receivingArea': string;
    /**
     * 收货地址
     * @type {string}
     */
    'receivingAddress': string;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'receivingType': ShouHuoTypeEnum;
    /**
     * 派送距离
     * @type {number}
     */
    'deliveryDistance': number | null;
    /**
     * 
     * @type {JieSuanTypeEnum}
     */
    'settlementType': JieSuanTypeEnum;
    /**
     * 
     * @type {ZhiFuTypeEnum}
     */
    'paymentType': ZhiFuTypeEnum;
    /**
     * 回单要求
     * @type {string}
     */
    'receiptRemark'?: string | null;
    /**
     * 回单号
     * @type {string}
     */
    'receiptNo'?: string | null;
    /**
     * 等通知
     * @type {boolean}
     */
    'isWait': boolean;
    /**
     * 上楼
     * @type {boolean}
     */
    'isUpstairs': boolean;
    /**
     * 楼层
     * @type {number}
     */
    'floor': number;
    /**
     * 卸货
     * @type {boolean}
     */
    'isUnload': boolean;
    /**
     * 异形
     * @type {boolean}
     */
    'isIrregular': boolean;
    /**
     * 短信
     * @type {boolean}
     */
    'isSMS': boolean;
    /**
     * 接货
     * @type {boolean}
     */
    'isPickup': boolean;
    /**
     * 现付
     * @type {number}
     */
    'payNow': number;
    /**
     * 到付
     * @type {number}
     */
    'payArrival': number;
    /**
     * 回付
     * @type {number}
     */
    'payReceipt': number;
    /**
     * 月结
     * @type {number}
     */
    'payMonthly': number;
    /**
     * 临欠
     * @type {number}
     */
    'payOwed': number;
    /**
     * 货款付
     * @type {number}
     */
    'payGoods': number;
    /**
     * 回扣
     * @type {number}
     */
    'brokerage': number;
    /**
     * 代收货款
     * @type {number}
     */
    'goodsPrice': number;
    /**
     * 自动运单号
     * @type {boolean}
     */
    'autoWaybillNo': boolean;
    /**
     * 自动回单号
     * @type {boolean}
     */
    'autoReceiptNo': boolean;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
    /**
     * 运单费用列表
     * @type {Array<WaybillCostFormData>}
     */
    'costList'?: Array<WaybillCostFormData> | null;
    /**
     * 运单货物明细
     * @type {Array<WaybillGoodsFormData>}
     */
    'goodsList'?: Array<WaybillGoodsFormData> | null;
}


/**
 * 运单货物 表单参数
 * @export
 * @interface WaybillGoodsFormData
 */
export interface WaybillGoodsFormData {
    /**
     * 主键，0 表示待新增
     * @type {number}
     */
    'id': number;
    /**
     * 乐观锁
     * @type {number}
     */
    'ver': number;
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 货名
     * @type {string}
     */
    'goodsName': string;
    /**
     * 包装
     * @type {string}
     */
    'package': string;
    /**
     * 件数
     * @type {number}
     */
    'quantity': number;
    /**
     * 重量
     * @type {number}
     */
    'weight': number;
    /**
     * 体积
     * @type {number}
     */
    'volume': number;
    /**
     * 长
     * @type {number}
     */
    'length': number | null;
    /**
     * 宽
     * @type {number}
     */
    'width': number | null;
    /**
     * 高
     * @type {number}
     */
    'height': number | null;
    /**
     * 计重
     * @type {number}
     */
    'calculateWeight': number;
    /**
     * 价值
     * @type {number}
     */
    'insurancePrice': number | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 运单 列表输出
 * @export
 * @interface WaybillListData
 */
export interface WaybillListData  extends BaseEntity {
    /**
     * 运单号
     * @type {string}
     */
    'no': string;
    /**
     * 订单号
     * @type {string}
     */
    'orderNo'?: string | null;
    /**
     * 客户单号
     * @type {string}
     */
    'customerNo'?: string | null;
    /**
     * 来源单号
     * @type {string}
     */
    'sourceNo'?: string | null;
    /**
     * 
     * @type {BusinessTypeEnum}
     */
    'businessType': BusinessTypeEnum;
    /**
     * 始发站
     * @type {number}
     */
    'originatingStation': number;
    /**
     * 中转站
     * @type {number}
     */
    'transferStation': number | null;
    /**
     * 目的站
     * @type {number}
     */
    'destinationStation': number;
    /**
     * 特殊区域
     * @type {string}
     */
    'specialArea'?: string | null;
    /**
     * 发货客户Id
     * @type {number}
     */
    'shippingId': number | null;
    /**
     * 发货客户
     * @type {string}
     */
    'shippingCustomer'?: string | null;
    /**
     * 发货人
     * @type {string}
     */
    'shipper': string;
    /**
     * 发货手机
     * @type {string}
     */
    'shippingMobile'?: string | null;
    /**
     * 发货电话
     * @type {string}
     */
    'shippingPhone'?: string | null;
    /**
     * 发货证件
     * @type {string}
     */
    'shippingCertificate'?: string | null;
    /**
     * 发货区域
     * @type {string}
     */
    'shippingArea': string;
    /**
     * 发货地址
     * @type {string}
     */
    'shippingAddress': string;
    /**
     * 接货距离
     * @type {number}
     */
    'pickupDistance': number | null;
    /**
     * 收货客户Id
     * @type {number}
     */
    'receivingId': number | null;
    /**
     * 收货客户
     * @type {string}
     */
    'receivingCustomer'?: string | null;
    /**
     * 收货人
     * @type {string}
     */
    'consignee': string;
    /**
     * 收货手机
     * @type {string}
     */
    'receivingMobile': string;
    /**
     * 收货电话
     * @type {string}
     */
    'receivingPhone'?: string | null;
    /**
     * 收货区域
     * @type {string}
     */
    'receivingArea': string;
    /**
     * 收货地址
     * @type {string}
     */
    'receivingAddress': string;
    /**
     * 
     * @type {ShouHuoTypeEnum}
     */
    'receivingType': ShouHuoTypeEnum;
    /**
     * 派送距离
     * @type {number}
     */
    'deliveryDistance': number | null;
    /**
     * 
     * @type {JieSuanTypeEnum}
     */
    'settlementType': JieSuanTypeEnum;
    /**
     * 
     * @type {ZhiFuTypeEnum}
     */
    'paymentType': ZhiFuTypeEnum;
    /**
     * 回单要求
     * @type {string}
     */
    'receiptRemark'?: string | null;
    /**
     * 回单号
     * @type {string}
     */
    'receiptNo'?: string | null;
    /**
     * 等通知
     * @type {boolean}
     */
    'isWait': boolean;
    /**
     * 上楼
     * @type {boolean}
     */
    'isUpstairs': boolean;
    /**
     * 楼层
     * @type {number}
     */
    'floor': number;
    /**
     * 卸货
     * @type {boolean}
     */
    'isUnload': boolean;
    /**
     * 异形
     * @type {boolean}
     */
    'isIrregular': boolean;
    /**
     * 短信
     * @type {boolean}
     */
    'isSMS': boolean;
    /**
     * 接货
     * @type {boolean}
     */
    'isPickup': boolean;
    /**
     * 现付
     * @type {number}
     */
    'payNow': number;
    /**
     * 到付
     * @type {number}
     */
    'payArrival': number;
    /**
     * 回付
     * @type {number}
     */
    'payReceipt': number;
    /**
     * 月结
     * @type {number}
     */
    'payMonthly': number;
    /**
     * 临欠
     * @type {number}
     */
    'payOwed': number;
    /**
     * 货款付
     * @type {number}
     */
    'payGoods': number;
    /**
     * 回扣
     * @type {number}
     */
    'brokerage': number;
    /**
     * 代收货款
     * @type {number}
     */
    'goodsPrice': number;
    /**
     * 自动运单号
     * @type {boolean}
     */
    'autoWaybillNo': boolean;
    /**
     * 自动回单号
     * @type {boolean}
     */
    'autoReceiptNo': boolean;
    /**
     * 状态网点
     * @type {number}
     */
    'statusOrg': number;
    /**
     * 
     * @type {WaybillStatusEnum}
     */
    'status': WaybillStatusEnum;
    /**
     * 回单状态网点
     * @type {number}
     */
    'receiptStatusOrg': number | null;
    /**
     * 
     * @type {HuiDanStatusEnum}
     */
    'receiptStatus'?: HuiDanStatusEnum;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}


/**
 * 运单 打印
 * @export
 * @interface WaybillPrint
 */
export interface WaybillPrint  extends BaseEntity {
}
/**
 * 运单签收 表单参数
 * @export
 * @interface WaybillSignFormData
 */
export interface WaybillSignFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 签收人
     * @type {string}
     */
    'consignee': string;
    /**
     * 手机号
     * @type {string}
     */
    'phone'?: string | null;
    /**
     * 证件号
     * @type {string}
     */
    'idNo'?: string | null;
    /**
     * 不是本人
     * @type {boolean}
     */
    'notPerson': boolean;
    /**
     * 异常签收
     * @type {boolean}
     */
    'abnormalReceipt': boolean;
    /**
     * 异常原因
     * @type {string}
     */
    'abnormalReason'?: string | null;
    /**
     * 运费调整
     * @type {number}
     */
    'freightAdjustment': number;
    /**
     * 货款调整
     * @type {number}
     */
    'goodsPriceAdjustment': number;
    /**
     * 仓储费
     * @type {number}
     */
    'storageFee': number;
    /**
     * 其他费
     * @type {number}
     */
    'otherFee': number;
    /**
     * 费用说明
     * @type {string}
     */
    'feeRemark'?: string | null;
    /**
     * 签收图片
     * @type {number}
     */
    'picture': number | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 运单签收 列表输出
 * @export
 * @interface WaybillSignListData
 */
export interface WaybillSignListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 签收人
     * @type {string}
     */
    'consignee': string;
    /**
     * 手机号
     * @type {string}
     */
    'phone'?: string | null;
    /**
     * 证件号
     * @type {string}
     */
    'idNo'?: string | null;
    /**
     * 不是本人
     * @type {boolean}
     */
    'notPerson': boolean;
    /**
     * 异常签收
     * @type {boolean}
     */
    'abnormalReceipt': boolean;
    /**
     * 异常原因
     * @type {string}
     */
    'abnormalReason'?: string | null;
    /**
     * 运费调整
     * @type {number}
     */
    'freightAdjustment': number;
    /**
     * 货款调整
     * @type {number}
     */
    'goodsPriceAdjustment': number;
    /**
     * 仓储费
     * @type {number}
     */
    'storageFee': number;
    /**
     * 其他费
     * @type {number}
     */
    'otherFee': number;
    /**
     * 费用说明
     * @type {string}
     */
    'feeRemark'?: string | null;
    /**
     * 签收图片
     * @type {number}
     */
    'picture': number | null;
    /**
     * 备注
     * @type {string}
     */
    'remark'?: string | null;
}
/**
 * 运单状态<br />&nbsp;初始 ChuShi = 0<br />&nbsp;开单 KaiDan = 10<br />&nbsp;提货 TiHuo = 20<br />&nbsp;入库 RuKu = 21<br />&nbsp;发车 FaChe = 30<br />&nbsp;到车 DaoChe = 31<br />&nbsp;留仓 LiuCang = 32<br />&nbsp;派送 PaiSong = 40<br />&nbsp;自提 ZiTi = 41<br />&nbsp;延迟 YanChi = 42<br />&nbsp;签收 QianShou = 80<br />&nbsp;拒收 JuShou = 81<br />&nbsp;异常 YiChang = 90<br />&nbsp;签收异常 QianShouYiChang = 91<br />&nbsp;作废 ZuoFei = 99<br />
 * @export
 * @enum {string}
 */

export const WaybillStatusEnum = {
    ChuShi: 'ChuShi',
    KaiDan: 'KaiDan',
    TiHuo: 'TiHuo',
    RuKu: 'RuKu',
    FaChe: 'FaChe',
    DaoChe: 'DaoChe',
    LiuCang: 'LiuCang',
    PaiSong: 'PaiSong',
    ZiTi: 'ZiTi',
    YanChi: 'YanChi',
    QianShou: 'QianShou',
    JuShou: 'JuShou',
    YiChang: 'YiChang',
    QianShouYiChang: 'QianShouYiChang',
    ZuoFei: 'ZuoFei'
} as const;

export type WaybillStatusEnum = typeof WaybillStatusEnum[keyof typeof WaybillStatusEnum];


/**
 * 运单跟踪缓存 表单参数
 * @export
 * @interface WaybillTrackCacheFormData
 */
export interface WaybillTrackCacheFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 运单号
     * @type {string}
     */
    'waybillNo': string;
    /**
     * 跟踪网点
     * @type {number}
     */
    'currentOrgId': number;
    /**
     * 跟踪网点名称
     * @type {string}
     */
    'currentOrgName': string;
    /**
     * 跟踪电话
     * @type {string}
     */
    'trackingPhone': string;
    /**
     * 跟踪记录
     * @type {string}
     */
    'trackingRecords': string;
    /**
     * 关联单号
     * @type {string}
     */
    'assNo'?: string | null;
    /**
     * 装卸组
     * @type {string}
     */
    'loadingTeam'?: string | null;
    /**
     * 装卸人
     * @type {string}
     */
    'loaders'?: string | null;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo'?: string | null;
    /**
     * 司机
     * @type {string}
     */
    'driver'?: string | null;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone'?: string | null;
    /**
     * 对外公开
     * @type {boolean}
     */
    'isPublic': boolean;
}
/**
 * 运单跟踪缓存 列表输出
 * @export
 * @interface WaybillTrackCacheListData
 */
export interface WaybillTrackCacheListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 运单号
     * @type {string}
     */
    'waybillNo': string;
    /**
     * 跟踪网点
     * @type {number}
     */
    'currentOrgId': number;
    /**
     * 跟踪网点名称
     * @type {string}
     */
    'currentOrgName': string;
    /**
     * 跟踪电话
     * @type {string}
     */
    'trackingPhone': string;
    /**
     * 跟踪记录
     * @type {string}
     */
    'trackingRecords': string;
    /**
     * 关联单号
     * @type {string}
     */
    'assNo'?: string | null;
    /**
     * 装卸组
     * @type {string}
     */
    'loadingTeam'?: string | null;
    /**
     * 装卸人
     * @type {string}
     */
    'loaders'?: string | null;
    /**
     * 车牌号
     * @type {string}
     */
    'carNo'?: string | null;
    /**
     * 司机
     * @type {string}
     */
    'driver'?: string | null;
    /**
     * 司机电话
     * @type {string}
     */
    'driverPhone'?: string | null;
    /**
     * 对外公开
     * @type {boolean}
     */
    'isPublic': boolean;
}
/**
 * 运单跟踪 表单参数
 * @export
 * @interface WaybillTrackFormData
 */
export interface WaybillTrackFormData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 
     * @type {WaybillStatusEnum}
     */
    'status': WaybillStatusEnum;
    /**
     * 跟踪网点
     * @type {number}
     */
    'currentOrgId': number;
    /**
     * 关联网点
     * @type {number}
     */
    'assOrgId': number | null;
    /**
     * 关联单号
     * @type {number}
     */
    'assId': number | null;
    /**
     * 对外公开
     * @type {boolean}
     */
    'isPublic': boolean;
}


/**
 * 运单跟踪 列表输出
 * @export
 * @interface WaybillTrackListData
 */
export interface WaybillTrackListData  extends BaseEntity {
    /**
     * 运单Id
     * @type {number}
     */
    'waybillId': number;
    /**
     * 
     * @type {WaybillStatusEnum}
     */
    'status': WaybillStatusEnum;
    /**
     * 跟踪网点
     * @type {number}
     */
    'currentOrgId': number;
    /**
     * 关联网点
     * @type {number}
     */
    'assOrgId': number | null;
    /**
     * 关联单号
     * @type {number}
     */
    'assId': number | null;
    /**
     * 对外公开
     * @type {boolean}
     */
    'isPublic': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WhereType = {
    And: 'And',
    Or: 'Or',
    Null: 'Null'
} as const;

export type WhereType = typeof WhereType[keyof typeof WhereType];


/**
 * 预付费用类型<br />&nbsp;干线费 GanXianFei = 0<br />&nbsp;派送费 PaiSongFei = 1<br />&nbsp;装卸费 ZhuangXieFei = 2<br />&nbsp;落地费 LuoDiFei = 3<br />&nbsp;上楼费 ShangLouFei = 4<br />&nbsp;操作费 CaoZuoFei = 5<br />&nbsp;信息费 XinXiFei = 6<br />&nbsp;标签费 BiaoQianFei = 7<br />&nbsp;服务费 FuWuFei = 8<br />&nbsp;保险费 BaoXianFei = 9<br />&nbsp;手续费 ShouXuFei = 10<br />&nbsp;调度费 DiaoDuFei = 11<br />&nbsp;管理费 GuanLiFei = 12<br />&nbsp;应收款 YingShouKuan = 100<br />&nbsp;到付款 DaoFuKuan = 101<br />&nbsp;代收款 DaiShouKuan = 102<br />&nbsp;罚款 FaKuan = 103<br />&nbsp;赔付 PeiFu = 104<br />&nbsp;充值 ChongZhi = 105<br />&nbsp;提现 TiXian = 106<br />&nbsp;转账 ZhuanZhang = 107<br />&nbsp;加收费 JiaShouFei = 108<br />&nbsp;物料费 WuLiaoFei = 109<br />
 * @export
 * @enum {string}
 */

export const YuFuFeeTypeEnum = {
    GanXianFei: 'GanXianFei',
    PaiSongFei: 'PaiSongFei',
    ZhuangXieFei: 'ZhuangXieFei',
    LuoDiFei: 'LuoDiFei',
    ShangLouFei: 'ShangLouFei',
    CaoZuoFei: 'CaoZuoFei',
    XinXiFei: 'XinXiFei',
    BiaoQianFei: 'BiaoQianFei',
    FuWuFei: 'FuWuFei',
    BaoXianFei: 'BaoXianFei',
    ShouXuFei: 'ShouXuFei',
    DiaoDuFei: 'DiaoDuFei',
    GuanLiFei: 'GuanLiFei',
    YingShouKuan: 'YingShouKuan',
    DaoFuKuan: 'DaoFuKuan',
    DaiShouKuan: 'DaiShouKuan',
    FaKuan: 'FaKuan',
    PeiFu: 'PeiFu',
    ChongZhi: 'ChongZhi',
    TiXian: 'TiXian',
    ZhuanZhang: 'ZhuanZhang',
    JiaShouFei: 'JiaShouFei',
    WuLiaoFei: 'WuLiaoFei'
} as const;

export type YuFuFeeTypeEnum = typeof YuFuFeeTypeEnum[keyof typeof YuFuFeeTypeEnum];


/**
 * 预付金操作类型<br />&nbsp;转账 ZhuanZhang = 0<br />&nbsp;充值 ChongZhi = 1<br />&nbsp;提现 TiXian = 2<br />
 * @export
 * @enum {string}
 */

export const YuFuJinCaoZuoTypeEnum = {
    ZhuanZhang: 'ZhuanZhang',
    ChongZhi: 'ChongZhi',
    TiXian: 'TiXian'
} as const;

export type YuFuJinCaoZuoTypeEnum = typeof YuFuJinCaoZuoTypeEnum[keyof typeof YuFuJinCaoZuoTypeEnum];


/**
 * 预付金账单来源类型<br />&nbsp;报价生成 BaoJia = 0<br />&nbsp;手工录入 ShouGong = 1<br />&nbsp;费用调整 TiaoZheng = 2<br />&nbsp;充值提现 ChongZhiTiXian = 3<br />&nbsp;质控奖惩 ZhiKong = 4<br />&nbsp;投诉仲裁 ZhongCai = 5<br />&nbsp;固定费用 GuDing = 6<br />&nbsp;自动扣款 ZiDong = 7<br />&nbsp;物料费用 WuLiao = 8<br />
 * @export
 * @enum {string}
 */

export const YuFuSourceTypeEnum = {
    BaoJia: 'BaoJia',
    ShouGong: 'ShouGong',
    TiaoZheng: 'TiaoZheng',
    ChongZhiTiXian: 'ChongZhiTiXian',
    ZhiKong: 'ZhiKong',
    ZhongCai: 'ZhongCai',
    GuDing: 'GuDing',
    ZiDong: 'ZiDong',
    WuLiao: 'WuLiao'
} as const;

export type YuFuSourceTypeEnum = typeof YuFuSourceTypeEnum[keyof typeof YuFuSourceTypeEnum];


/**
 * 支付类型<br />&nbsp;现金 XianJin = 0<br />&nbsp;微信 WeiXin = 1<br />&nbsp;支付宝 ZhiFuBao = 2<br />&nbsp;转账 ZhuanZhang = 3<br />&nbsp;其他 QiTa = 9<br />
 * @export
 * @enum {string}
 */

export const ZhiFuTypeEnum = {
    XianJin: 'XianJin',
    WeiXin: 'WeiXin',
    ZhiFuBao: 'ZhiFuBao',
    ZhuanZhang: 'ZhuanZhang',
    QiTa: 'QiTa'
} as const;

export type ZhiFuTypeEnum = typeof ZhiFuTypeEnum[keyof typeof ZhiFuTypeEnum];



/**
 * AllocateApi - axios parameter creator
 * 干线分拨 接口
 * @export
 */
export const AllocateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocate: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteAllocate', 'ids', ids)
            const localVarPath = `/api/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllocate', 'id', id)
            const localVarPath = `/api/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocate/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {AllocateFormData} [allocateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocate: async (allocateFormData?: AllocateFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocatePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocate/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocate/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateFormData>} [customUpdateModelAllocateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAllocate: async (customUpdateModelAllocateFormData?: CustomUpdateModel<AllocateFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelAllocateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocateApi - functional programming interface
 * 干线分拨 接口
 * @export
 */
export const AllocateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocateApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllocate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllocate(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.deleteAllocate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<AllocateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.getAllocate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocateDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<AllocateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocateDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.getAllocateDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {AllocateFormData} [allocateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocate(allocateFormData?: AllocateFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocate(allocateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.postAllocate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<AllocateListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocatePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.postAllocatePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocateSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.postAllocateSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateFormData>} [customUpdateModelAllocateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAllocate(customUpdateModelAllocateFormData?: CustomUpdateModel<AllocateFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAllocate(customUpdateModelAllocateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateApi.putAllocate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * AllocateApi - factory interface
 * 干线分拨 接口
 * @export
 */
export const AllocateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocateApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteAllocate(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateFormData>> {
            return localVarFp.getAllocate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateFormData>> {
            return localVarFp.getAllocateDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {AllocateFormData} [allocateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocate(allocateFormData?: AllocateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postAllocate(allocateFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<AllocateListData>>> {
            return localVarFp.postAllocatePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postAllocateSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateFormData>} [customUpdateModelAllocateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAllocate(customUpdateModelAllocateFormData?: CustomUpdateModel<AllocateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putAllocate(customUpdateModelAllocateFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocateApi - interface
 * 干线分拨 接口
 * @export
 * @interface AllocateApi
 */
export interface AllocateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    deleteAllocate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    getAllocate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    getAllocateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateFormData>>;

    /**
     * 新增
     * @param {AllocateFormData} [allocateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    postAllocate(allocateFormData?: AllocateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    postAllocatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<AllocateListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    postAllocateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<AllocateFormData>} [customUpdateModelAllocateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApiInterface
     */
    putAllocate(customUpdateModelAllocateFormData?: CustomUpdateModel<AllocateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * AllocateApi - object-oriented interface
 * 干线分拨 接口
 * @export
 * @class AllocateApi
 * @extends {BaseAPI}
 */
export class AllocateApi extends BaseAPI implements AllocateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public deleteAllocate(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).deleteAllocate(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public getAllocate(id: number, options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).getAllocate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public getAllocateDefault(options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).getAllocateDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {AllocateFormData} [allocateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public postAllocate(allocateFormData?: AllocateFormData, options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).postAllocate(allocateFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public postAllocatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).postAllocatePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public postAllocateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).postAllocateSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<AllocateFormData>} [customUpdateModelAllocateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateApi
     */
    public putAllocate(customUpdateModelAllocateFormData?: CustomUpdateModel<AllocateFormData>, options?: RawAxiosRequestConfig) {
        return AllocateApiFp(this.configuration).putAllocate(customUpdateModelAllocateFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AllocateCostApi - axios parameter creator
 * 干线费用 接口
 * @export
 */
export const AllocateCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocateCost: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteAllocateCost', 'ids', ids)
            const localVarPath = `/api/allocateCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateCost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllocateCost', 'id', id)
            const localVarPath = `/api/allocateCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateCostDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateCost/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {AllocateCostFormData} [allocateCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateCost: async (allocateCostFormData?: AllocateCostFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocateCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateCostPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateCost/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateCostFormData>} [customUpdateModelAllocateCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAllocateCost: async (customUpdateModelAllocateCostFormData?: CustomUpdateModel<AllocateCostFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelAllocateCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocateCostApi - functional programming interface
 * 干线费用 接口
 * @export
 */
export const AllocateCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocateCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllocateCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllocateCost(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateCostApi.deleteAllocateCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocateCost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<AllocateCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocateCost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateCostApi.getAllocateCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocateCostDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<AllocateCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocateCostDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateCostApi.getAllocateCostDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {AllocateCostFormData} [allocateCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocateCost(allocateCostFormData?: AllocateCostFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocateCost(allocateCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateCostApi.postAllocateCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocateCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<AllocateCostListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocateCostPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateCostApi.postAllocateCostPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateCostFormData>} [customUpdateModelAllocateCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAllocateCost(customUpdateModelAllocateCostFormData?: CustomUpdateModel<AllocateCostFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAllocateCost(customUpdateModelAllocateCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateCostApi.putAllocateCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * AllocateCostApi - factory interface
 * 干线费用 接口
 * @export
 */
export const AllocateCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocateCostApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocateCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteAllocateCost(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateCostFormData>> {
            return localVarFp.getAllocateCost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateCostFormData>> {
            return localVarFp.getAllocateCostDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {AllocateCostFormData} [allocateCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateCost(allocateCostFormData?: AllocateCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postAllocateCost(allocateCostFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<AllocateCostListData>>> {
            return localVarFp.postAllocateCostPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateCostFormData>} [customUpdateModelAllocateCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAllocateCost(customUpdateModelAllocateCostFormData?: CustomUpdateModel<AllocateCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putAllocateCost(customUpdateModelAllocateCostFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocateCostApi - interface
 * 干线费用 接口
 * @export
 * @interface AllocateCostApi
 */
export interface AllocateCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApiInterface
     */
    deleteAllocateCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApiInterface
     */
    getAllocateCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateCostFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApiInterface
     */
    getAllocateCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateCostFormData>>;

    /**
     * 新增
     * @param {AllocateCostFormData} [allocateCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApiInterface
     */
    postAllocateCost(allocateCostFormData?: AllocateCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApiInterface
     */
    postAllocateCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<AllocateCostListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<AllocateCostFormData>} [customUpdateModelAllocateCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApiInterface
     */
    putAllocateCost(customUpdateModelAllocateCostFormData?: CustomUpdateModel<AllocateCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * AllocateCostApi - object-oriented interface
 * 干线费用 接口
 * @export
 * @class AllocateCostApi
 * @extends {BaseAPI}
 */
export class AllocateCostApi extends BaseAPI implements AllocateCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApi
     */
    public deleteAllocateCost(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return AllocateCostApiFp(this.configuration).deleteAllocateCost(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApi
     */
    public getAllocateCost(id: number, options?: RawAxiosRequestConfig) {
        return AllocateCostApiFp(this.configuration).getAllocateCost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApi
     */
    public getAllocateCostDefault(options?: RawAxiosRequestConfig) {
        return AllocateCostApiFp(this.configuration).getAllocateCostDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {AllocateCostFormData} [allocateCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApi
     */
    public postAllocateCost(allocateCostFormData?: AllocateCostFormData, options?: RawAxiosRequestConfig) {
        return AllocateCostApiFp(this.configuration).postAllocateCost(allocateCostFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApi
     */
    public postAllocateCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return AllocateCostApiFp(this.configuration).postAllocateCostPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<AllocateCostFormData>} [customUpdateModelAllocateCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateCostApi
     */
    public putAllocateCost(customUpdateModelAllocateCostFormData?: CustomUpdateModel<AllocateCostFormData>, options?: RawAxiosRequestConfig) {
        return AllocateCostApiFp(this.configuration).putAllocateCost(customUpdateModelAllocateCostFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AllocateDetailsApi - axios parameter creator
 * 干线明细 接口
 * @export
 */
export const AllocateDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocateDetails: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteAllocateDetails', 'ids', ids)
            const localVarPath = `/api/allocateDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateDetails: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllocateDetails', 'id', id)
            const localVarPath = `/api/allocateDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateDetailsDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateDetails/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {AllocateDetailsFormData} [allocateDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateDetails: async (allocateDetailsFormData?: AllocateDetailsFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocateDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateDetailsPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateDetails/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateDetailsFormData>} [customUpdateModelAllocateDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAllocateDetails: async (customUpdateModelAllocateDetailsFormData?: CustomUpdateModel<AllocateDetailsFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocateDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelAllocateDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocateDetailsApi - functional programming interface
 * 干线明细 接口
 * @export
 */
export const AllocateDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocateDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllocateDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllocateDetails(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateDetailsApi.deleteAllocateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocateDetails(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<AllocateDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocateDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateDetailsApi.getAllocateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocateDetailsDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<AllocateDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocateDetailsDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateDetailsApi.getAllocateDetailsDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {AllocateDetailsFormData} [allocateDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocateDetails(allocateDetailsFormData?: AllocateDetailsFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocateDetails(allocateDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateDetailsApi.postAllocateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocateDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<AllocateDetailsListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocateDetailsPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateDetailsApi.postAllocateDetailsPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateDetailsFormData>} [customUpdateModelAllocateDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAllocateDetails(customUpdateModelAllocateDetailsFormData?: CustomUpdateModel<AllocateDetailsFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAllocateDetails(customUpdateModelAllocateDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocateDetailsApi.putAllocateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * AllocateDetailsApi - factory interface
 * 干线明细 接口
 * @export
 */
export const AllocateDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocateDetailsApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocateDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteAllocateDetails(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateDetailsFormData>> {
            return localVarFp.getAllocateDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocateDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateDetailsFormData>> {
            return localVarFp.getAllocateDetailsDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {AllocateDetailsFormData} [allocateDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateDetails(allocateDetailsFormData?: AllocateDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postAllocateDetails(allocateDetailsFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<AllocateDetailsListData>>> {
            return localVarFp.postAllocateDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<AllocateDetailsFormData>} [customUpdateModelAllocateDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAllocateDetails(customUpdateModelAllocateDetailsFormData?: CustomUpdateModel<AllocateDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putAllocateDetails(customUpdateModelAllocateDetailsFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocateDetailsApi - interface
 * 干线明细 接口
 * @export
 * @interface AllocateDetailsApi
 */
export interface AllocateDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApiInterface
     */
    deleteAllocateDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApiInterface
     */
    getAllocateDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateDetailsFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApiInterface
     */
    getAllocateDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<AllocateDetailsFormData>>;

    /**
     * 新增
     * @param {AllocateDetailsFormData} [allocateDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApiInterface
     */
    postAllocateDetails(allocateDetailsFormData?: AllocateDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApiInterface
     */
    postAllocateDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<AllocateDetailsListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<AllocateDetailsFormData>} [customUpdateModelAllocateDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApiInterface
     */
    putAllocateDetails(customUpdateModelAllocateDetailsFormData?: CustomUpdateModel<AllocateDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * AllocateDetailsApi - object-oriented interface
 * 干线明细 接口
 * @export
 * @class AllocateDetailsApi
 * @extends {BaseAPI}
 */
export class AllocateDetailsApi extends BaseAPI implements AllocateDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApi
     */
    public deleteAllocateDetails(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return AllocateDetailsApiFp(this.configuration).deleteAllocateDetails(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApi
     */
    public getAllocateDetails(id: number, options?: RawAxiosRequestConfig) {
        return AllocateDetailsApiFp(this.configuration).getAllocateDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApi
     */
    public getAllocateDetailsDefault(options?: RawAxiosRequestConfig) {
        return AllocateDetailsApiFp(this.configuration).getAllocateDetailsDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {AllocateDetailsFormData} [allocateDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApi
     */
    public postAllocateDetails(allocateDetailsFormData?: AllocateDetailsFormData, options?: RawAxiosRequestConfig) {
        return AllocateDetailsApiFp(this.configuration).postAllocateDetails(allocateDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApi
     */
    public postAllocateDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return AllocateDetailsApiFp(this.configuration).postAllocateDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<AllocateDetailsFormData>} [customUpdateModelAllocateDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocateDetailsApi
     */
    public putAllocateDetails(customUpdateModelAllocateDetailsFormData?: CustomUpdateModel<AllocateDetailsFormData>, options?: RawAxiosRequestConfig) {
        return AllocateDetailsApiFp(this.configuration).putAllocateDetails(customUpdateModelAllocateDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * 登录鉴权服务接口
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取登录配置
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthLoginConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/loginConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 验证锁屏密码
         * @param {string} [pin] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUnLockScreen: async (pin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/unLockScreen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pin !== undefined) {
                localVarQueryParameter['pin'] = pin;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取登录用户的资料
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/userInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取水印配置
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthWatermarkConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/watermarkConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过连接Id踢掉在线用户
         * @param {string} [connectionId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthForceOffline: async (connectionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/forceOffline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (connectionId !== undefined) {
                localVarQueryParameter['connectionId'] = connectionId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登录系统
         * @param {LoginInput} loginInput 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogin: async (loginInput: LoginInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginInput' is not null or undefined
            assertParamExists('postAuthLogin', 'loginInput', loginInput)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 退出系统
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取在线用户分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthUserConnectionPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/userConnectionPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * 登录鉴权服务接口
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取登录配置
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthLoginConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<LoginConfigOutput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthLoginConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAuthLoginConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 验证锁屏密码
         * @param {string} [pin] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUnLockScreen(pin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Boolean>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUnLockScreen(pin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAuthUnLockScreen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取登录用户的资料
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUserInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<LoginUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUserInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAuthUserInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取水印配置
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthWatermarkConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UserConfigOutput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthWatermarkConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAuthWatermarkConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过连接Id踢掉在线用户
         * @param {string} [connectionId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthForceOffline(connectionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthForceOffline(connectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.postAuthForceOffline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 登录系统
         * @param {LoginInput} loginInput 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthLogin(loginInput: LoginInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<LoginOutput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthLogin(loginInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.postAuthLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 退出系统
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthLogout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthLogout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.postAuthLogout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取在线用户分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthUserConnectionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<UserConnection>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthUserConnectionPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.postAuthUserConnectionPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * 登录鉴权服务接口
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 获取登录配置
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthLoginConfig(options?: RawAxiosRequestConfig): Promise<UniformResult<LoginConfigOutput>> {
            return localVarFp.getAuthLoginConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 验证锁屏密码
         * @param {string} [pin] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUnLockScreen(pin?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Boolean>> {
            return localVarFp.getAuthUnLockScreen(pin, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取登录用户的资料
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserInfo(options?: RawAxiosRequestConfig): Promise<UniformResult<LoginUser>> {
            return localVarFp.getAuthUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取水印配置
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthWatermarkConfig(options?: RawAxiosRequestConfig): Promise<UniformResult<UserConfigOutput>> {
            return localVarFp.getAuthWatermarkConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 通过连接Id踢掉在线用户
         * @param {string} [connectionId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthForceOffline(connectionId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postAuthForceOffline(connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 登录系统
         * @param {LoginInput} loginInput 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogin(loginInput: LoginInput, options?: RawAxiosRequestConfig): Promise<UniformResult<LoginOutput>> {
            return localVarFp.postAuthLogin(loginInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 退出系统
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogout(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postAuthLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取在线用户分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthUserConnectionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<UserConnection>>> {
            return localVarFp.postAuthUserConnectionPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * 登录鉴权服务接口
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 获取登录配置
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getAuthLoginConfig(options?: RawAxiosRequestConfig): Promise<UniformResult<LoginConfigOutput>>;

    /**
     * 验证锁屏密码
     * @param {string} [pin] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getAuthUnLockScreen(pin?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Boolean>>;

    /**
     * 获取登录用户的资料
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getAuthUserInfo(options?: RawAxiosRequestConfig): Promise<UniformResult<LoginUser>>;

    /**
     * 获取水印配置
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getAuthWatermarkConfig(options?: RawAxiosRequestConfig): Promise<UniformResult<UserConfigOutput>>;

    /**
     * 通过连接Id踢掉在线用户
     * @param {string} [connectionId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    postAuthForceOffline(connectionId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 登录系统
     * @param {LoginInput} loginInput 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    postAuthLogin(loginInput: LoginInput, options?: RawAxiosRequestConfig): Promise<UniformResult<LoginOutput>>;

    /**
     * 退出系统
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    postAuthLogout(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取在线用户分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    postAuthUserConnectionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<UserConnection>>>;

}

/**
 * AuthApi - object-oriented interface
 * 登录鉴权服务接口
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 获取登录配置
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthLoginConfig(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthLoginConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 验证锁屏密码
     * @param {string} [pin] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUnLockScreen(pin?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthUnLockScreen(pin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取登录用户的资料
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUserInfo(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取水印配置
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthWatermarkConfig(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthWatermarkConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过连接Id踢掉在线用户
     * @param {string} [connectionId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthForceOffline(connectionId?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthForceOffline(connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登录系统
     * @param {LoginInput} loginInput 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthLogin(loginInput: LoginInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthLogin(loginInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 退出系统
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthLogout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取在线用户分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthUserConnectionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthUserConnectionPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CacheApi - axios parameter creator
 * 缓存管理服务接口
 * @export
 */
export const CacheApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 根据键批量删除缓存
         * @param {Array<string>} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCache: async (key: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteCache', 'key', key)
            const localVarPath = `/api/cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key) {
                localVarQueryParameter['key'] = key;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 清空缓存
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCacheAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cache/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 根据键获取缓存值
         * @param {string} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCache: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getCache', 'key', key)
            const localVarPath = `/api/cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取所有缓存键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCacheAllKeys: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cache/allKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 搜索缓存键
         * @param {string} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCacheSearch: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('postCacheSearch', 'key', key)
            const localVarPath = `/api/cache/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CacheApi - functional programming interface
 * 缓存管理服务接口
 * @export
 */
export const CacheApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CacheApiAxiosParamCreator(configuration)
    return {
        /**
         * 根据键批量删除缓存
         * @param {Array<string>} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCache(key: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCache(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CacheApi.deleteCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 清空缓存
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCacheAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCacheAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CacheApi.deleteCacheAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 根据键获取缓存值
         * @param {string} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCache(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCache(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CacheApi.getCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取所有缓存键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCacheAllKeys(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCacheAllKeys(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CacheApi.getCacheAllKeys']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 搜索缓存键
         * @param {string} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCacheSearch(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCacheSearch(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CacheApi.postCacheSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * CacheApi - factory interface
 * 缓存管理服务接口
 * @export
 */
export const CacheApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CacheApiFp(configuration)
    return {
        /**
         * 根据键批量删除缓存
         * @param {Array<string>} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCache(key: Array<string>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteCache(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 清空缓存
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCacheAll(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.deleteCacheAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 根据键获取缓存值
         * @param {string} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCache(key: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.getCache(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取所有缓存键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCacheAllKeys(options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>> {
            return localVarFp.getCacheAllKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 搜索缓存键
         * @param {string} key 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCacheSearch(key: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>> {
            return localVarFp.postCacheSearch(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CacheApi - interface
 * 缓存管理服务接口
 * @export
 * @interface CacheApi
 */
export interface CacheApiInterface {
    /**
     * 根据键批量删除缓存
     * @param {Array<string>} key 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApiInterface
     */
    deleteCache(key: Array<string>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 清空缓存
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApiInterface
     */
    deleteCacheAll(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 根据键获取缓存值
     * @param {string} key 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApiInterface
     */
    getCache(key: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取所有缓存键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApiInterface
     */
    getCacheAllKeys(options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>>;

    /**
     * 搜索缓存键
     * @param {string} key 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApiInterface
     */
    postCacheSearch(key: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>>;

}

/**
 * CacheApi - object-oriented interface
 * 缓存管理服务接口
 * @export
 * @class CacheApi
 * @extends {BaseAPI}
 */
export class CacheApi extends BaseAPI implements CacheApiInterface {
    /**
     * 根据键批量删除缓存
     * @param {Array<string>} key 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApi
     */
    public deleteCache(key: Array<string>, options?: RawAxiosRequestConfig) {
        return CacheApiFp(this.configuration).deleteCache(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 清空缓存
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApi
     */
    public deleteCacheAll(options?: RawAxiosRequestConfig) {
        return CacheApiFp(this.configuration).deleteCacheAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 根据键获取缓存值
     * @param {string} key 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApi
     */
    public getCache(key: string, options?: RawAxiosRequestConfig) {
        return CacheApiFp(this.configuration).getCache(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取所有缓存键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApi
     */
    public getCacheAllKeys(options?: RawAxiosRequestConfig) {
        return CacheApiFp(this.configuration).getCacheAllKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 搜索缓存键
     * @param {string} key 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheApi
     */
    public postCacheSearch(key: string, options?: RawAxiosRequestConfig) {
        return CacheApiFp(this.configuration).postCacheSearch(key, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigItemApi - axios parameter creator
 * 系统配置 接口
 * @export
 */
export const ConfigItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigItem: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteConfigItem', 'ids', ids)
            const localVarPath = `/api/configItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigItem: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConfigItem', 'id', id)
            const localVarPath = `/api/configItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigItemDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取配置项键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigItemKeyValueList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem/keyValueList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ConfigItemFormData} [configItemFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItem: async (configItemFormData?: ConfigItemFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configItemFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItemImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItemPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItemSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ConfigItemFormData>} [customUpdateModelConfigItemFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConfigItem: async (customUpdateModelConfigItemFormData?: CustomUpdateModel<ConfigItemFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelConfigItemFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigItemApi - functional programming interface
 * 系统配置 接口
 * @export
 */
export const ConfigItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfigItem(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfigItem(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.deleteConfigItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigItem(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ConfigItemFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.getConfigItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigItemDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ConfigItemFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigItemDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.getConfigItemDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取配置项键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigItemKeyValueList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigItemKeyValueList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.getConfigItemKeyValueList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ConfigItemFormData} [configItemFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigItem(configItemFormData?: ConfigItemFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigItem(configItemFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.postConfigItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigItemImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigItemImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.postConfigItemImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigItemPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ConfigItemListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigItemPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.postConfigItemPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigItemSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigItemSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.postConfigItemSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ConfigItemFormData>} [customUpdateModelConfigItemFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putConfigItem(customUpdateModelConfigItemFormData?: CustomUpdateModel<ConfigItemFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putConfigItem(customUpdateModelConfigItemFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigItemApi.putConfigItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ConfigItemApi - factory interface
 * 系统配置 接口
 * @export
 */
export const ConfigItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigItemApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigItem(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteConfigItem(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigItem(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigItemFormData>> {
            return localVarFp.getConfigItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigItemDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigItemFormData>> {
            return localVarFp.getConfigItemDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取配置项键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigItemKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getConfigItemKeyValueList(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ConfigItemFormData} [configItemFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItem(configItemFormData?: ConfigItemFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postConfigItem(configItemFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItemImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postConfigItemImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItemPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ConfigItemListData>>> {
            return localVarFp.postConfigItemPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigItemSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postConfigItemSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ConfigItemFormData>} [customUpdateModelConfigItemFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConfigItem(customUpdateModelConfigItemFormData?: CustomUpdateModel<ConfigItemFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putConfigItem(customUpdateModelConfigItemFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigItemApi - interface
 * 系统配置 接口
 * @export
 * @interface ConfigItemApi
 */
export interface ConfigItemApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    deleteConfigItem(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    getConfigItem(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigItemFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    getConfigItemDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigItemFormData>>;

    /**
     * 获取配置项键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    getConfigItemKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 新增
     * @param {ConfigItemFormData} [configItemFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    postConfigItem(configItemFormData?: ConfigItemFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    postConfigItemImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    postConfigItemPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ConfigItemListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    postConfigItemSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ConfigItemFormData>} [customUpdateModelConfigItemFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApiInterface
     */
    putConfigItem(customUpdateModelConfigItemFormData?: CustomUpdateModel<ConfigItemFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ConfigItemApi - object-oriented interface
 * 系统配置 接口
 * @export
 * @class ConfigItemApi
 * @extends {BaseAPI}
 */
export class ConfigItemApi extends BaseAPI implements ConfigItemApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public deleteConfigItem(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).deleteConfigItem(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public getConfigItem(id: number, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).getConfigItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public getConfigItemDefault(options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).getConfigItemDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取配置项键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public getConfigItemKeyValueList(options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).getConfigItemKeyValueList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ConfigItemFormData} [configItemFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public postConfigItem(configItemFormData?: ConfigItemFormData, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).postConfigItem(configItemFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public postConfigItemImport(file?: File, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).postConfigItemImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public postConfigItemPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).postConfigItemPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public postConfigItemSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).postConfigItemSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ConfigItemFormData>} [customUpdateModelConfigItemFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigItemApi
     */
    public putConfigItem(customUpdateModelConfigItemFormData?: CustomUpdateModel<ConfigItemFormData>, options?: RawAxiosRequestConfig) {
        return ConfigItemApiFp(this.configuration).putConfigItem(customUpdateModelConfigItemFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigTargetApi - axios parameter creator
 * 配置项目标值 接口
 * @export
 */
export const ConfigTargetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigTarget: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteConfigTarget', 'ids', ids)
            const localVarPath = `/api/configTarget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigTarget: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConfigTarget', 'id', id)
            const localVarPath = `/api/configTarget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigTargetDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configTarget/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过配置 ID 获取目标值明细
         * @param {number} [id] 配置主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigTargetListByConfigId: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configTarget/listByConfigId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ConfigTargetFormData} [configTargetFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigTarget: async (configTargetFormData?: ConfigTargetFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configTarget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configTargetFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigTargetPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configTarget/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigTargetSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configTarget/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ConfigTargetFormData>} [customUpdateModelConfigTargetFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConfigTarget: async (customUpdateModelConfigTargetFormData?: CustomUpdateModel<ConfigTargetFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configTarget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelConfigTargetFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigTargetApi - functional programming interface
 * 配置项目标值 接口
 * @export
 */
export const ConfigTargetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigTargetApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfigTarget(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfigTarget(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.deleteConfigTarget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigTarget(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ConfigTargetFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigTarget(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.getConfigTarget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigTargetDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ConfigTargetFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigTargetDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.getConfigTargetDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过配置 ID 获取目标值明细
         * @param {number} [id] 配置主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigTargetListByConfigId(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ConfigTargetListData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigTargetListByConfigId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.getConfigTargetListByConfigId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ConfigTargetFormData} [configTargetFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigTarget(configTargetFormData?: ConfigTargetFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigTarget(configTargetFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.postConfigTarget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigTargetPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ConfigTargetListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigTargetPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.postConfigTargetPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfigTargetSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfigTargetSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.postConfigTargetSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ConfigTargetFormData>} [customUpdateModelConfigTargetFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putConfigTarget(customUpdateModelConfigTargetFormData?: CustomUpdateModel<ConfigTargetFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putConfigTarget(customUpdateModelConfigTargetFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigTargetApi.putConfigTarget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ConfigTargetApi - factory interface
 * 配置项目标值 接口
 * @export
 */
export const ConfigTargetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigTargetApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigTarget(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteConfigTarget(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigTarget(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigTargetFormData>> {
            return localVarFp.getConfigTarget(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigTargetDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigTargetFormData>> {
            return localVarFp.getConfigTargetDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 通过配置 ID 获取目标值明细
         * @param {number} [id] 配置主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigTargetListByConfigId(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigTargetListData[]>> {
            return localVarFp.getConfigTargetListByConfigId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ConfigTargetFormData} [configTargetFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigTarget(configTargetFormData?: ConfigTargetFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postConfigTarget(configTargetFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigTargetPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ConfigTargetListData>>> {
            return localVarFp.postConfigTargetPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfigTargetSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postConfigTargetSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ConfigTargetFormData>} [customUpdateModelConfigTargetFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConfigTarget(customUpdateModelConfigTargetFormData?: CustomUpdateModel<ConfigTargetFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putConfigTarget(customUpdateModelConfigTargetFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigTargetApi - interface
 * 配置项目标值 接口
 * @export
 * @interface ConfigTargetApi
 */
export interface ConfigTargetApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    deleteConfigTarget(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    getConfigTarget(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigTargetFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    getConfigTargetDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigTargetFormData>>;

    /**
     * 通过配置 ID 获取目标值明细
     * @param {number} [id] 配置主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    getConfigTargetListByConfigId(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ConfigTargetListData[]>>;

    /**
     * 新增
     * @param {ConfigTargetFormData} [configTargetFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    postConfigTarget(configTargetFormData?: ConfigTargetFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    postConfigTargetPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ConfigTargetListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    postConfigTargetSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ConfigTargetFormData>} [customUpdateModelConfigTargetFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApiInterface
     */
    putConfigTarget(customUpdateModelConfigTargetFormData?: CustomUpdateModel<ConfigTargetFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ConfigTargetApi - object-oriented interface
 * 配置项目标值 接口
 * @export
 * @class ConfigTargetApi
 * @extends {BaseAPI}
 */
export class ConfigTargetApi extends BaseAPI implements ConfigTargetApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public deleteConfigTarget(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).deleteConfigTarget(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public getConfigTarget(id: number, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).getConfigTarget(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public getConfigTargetDefault(options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).getConfigTargetDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过配置 ID 获取目标值明细
     * @param {number} [id] 配置主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public getConfigTargetListByConfigId(id?: number, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).getConfigTargetListByConfigId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ConfigTargetFormData} [configTargetFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public postConfigTarget(configTargetFormData?: ConfigTargetFormData, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).postConfigTarget(configTargetFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public postConfigTargetPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).postConfigTargetPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public postConfigTargetSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).postConfigTargetSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ConfigTargetFormData>} [customUpdateModelConfigTargetFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigTargetApi
     */
    public putConfigTarget(customUpdateModelConfigTargetFormData?: CustomUpdateModel<ConfigTargetFormData>, options?: RawAxiosRequestConfig) {
        return ConfigTargetApiFp(this.configuration).putConfigTarget(customUpdateModelConfigTargetFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CostQuoteApi - axios parameter creator
 * 内部报价 接口
 * @export
 */
export const CostQuoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuote: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteCostQuote', 'ids', ids)
            const localVarPath = `/api/costQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuote: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCostQuote', 'id', id)
            const localVarPath = `/api/costQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuote/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {CostQuoteFormData} [costQuoteFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuote: async (costQuoteFormData?: CostQuoteFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costQuoteFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuotePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuote/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuote/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteFormData>} [customUpdateModelCostQuoteFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuote: async (customUpdateModelCostQuoteFormData?: CustomUpdateModel<CostQuoteFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelCostQuoteFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CostQuoteApi - functional programming interface
 * 内部报价 接口
 * @export
 */
export const CostQuoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CostQuoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCostQuote(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCostQuote(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.deleteCostQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuote(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuote(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.getCostQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.getCostQuoteDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {CostQuoteFormData} [costQuoteFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuote(costQuoteFormData?: CostQuoteFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuote(costQuoteFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.postCostQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<CostQuoteListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuotePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.postCostQuotePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.postCostQuoteSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteFormData>} [customUpdateModelCostQuoteFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCostQuote(customUpdateModelCostQuoteFormData?: CustomUpdateModel<CostQuoteFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCostQuote(customUpdateModelCostQuoteFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteApi.putCostQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * CostQuoteApi - factory interface
 * 内部报价 接口
 * @export
 */
export const CostQuoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CostQuoteApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuote(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteCostQuote(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuote(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormData>> {
            return localVarFp.getCostQuote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormData>> {
            return localVarFp.getCostQuoteDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {CostQuoteFormData} [costQuoteFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuote(costQuoteFormData?: CostQuoteFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuote(costQuoteFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteListData>>> {
            return localVarFp.postCostQuotePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteFormData>} [customUpdateModelCostQuoteFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuote(customUpdateModelCostQuoteFormData?: CustomUpdateModel<CostQuoteFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putCostQuote(customUpdateModelCostQuoteFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CostQuoteApi - interface
 * 内部报价 接口
 * @export
 * @interface CostQuoteApi
 */
export interface CostQuoteApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    deleteCostQuote(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    getCostQuote(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    getCostQuoteDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormData>>;

    /**
     * 新增
     * @param {CostQuoteFormData} [costQuoteFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    postCostQuote(costQuoteFormData?: CostQuoteFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    postCostQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    postCostQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteFormData>} [customUpdateModelCostQuoteFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApiInterface
     */
    putCostQuote(customUpdateModelCostQuoteFormData?: CustomUpdateModel<CostQuoteFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * CostQuoteApi - object-oriented interface
 * 内部报价 接口
 * @export
 * @class CostQuoteApi
 * @extends {BaseAPI}
 */
export class CostQuoteApi extends BaseAPI implements CostQuoteApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public deleteCostQuote(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).deleteCostQuote(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public getCostQuote(id: number, options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).getCostQuote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public getCostQuoteDefault(options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).getCostQuoteDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {CostQuoteFormData} [costQuoteFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public postCostQuote(costQuoteFormData?: CostQuoteFormData, options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).postCostQuote(costQuoteFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public postCostQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).postCostQuotePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public postCostQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).postCostQuoteSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteFormData>} [customUpdateModelCostQuoteFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteApi
     */
    public putCostQuote(customUpdateModelCostQuoteFormData?: CustomUpdateModel<CostQuoteFormData>, options?: RawAxiosRequestConfig) {
        return CostQuoteApiFp(this.configuration).putCostQuote(customUpdateModelCostQuoteFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CostQuoteFormulaApi - axios parameter creator
 * 内部报价公式 接口
 * @export
 */
export const CostQuoteFormulaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuoteFormula: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteCostQuoteFormula', 'ids', ids)
            const localVarPath = `/api/costQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteFormula: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCostQuoteFormula', 'id', id)
            const localVarPath = `/api/costQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteFormulaDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteFormula/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {CostQuoteFormulaFormData} [costQuoteFormulaFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteFormula: async (costQuoteFormulaFormData?: CostQuoteFormulaFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costQuoteFormulaFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteFormulaPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteFormula/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteFormulaSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteFormula/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteFormulaFormData>} [customUpdateModelCostQuoteFormulaFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuoteFormula: async (customUpdateModelCostQuoteFormulaFormData?: CustomUpdateModel<CostQuoteFormulaFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelCostQuoteFormulaFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CostQuoteFormulaApi - functional programming interface
 * 内部报价公式 接口
 * @export
 */
export const CostQuoteFormulaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CostQuoteFormulaApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCostQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCostQuoteFormula(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.deleteCostQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteFormula(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteFormulaFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteFormula(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.getCostQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteFormulaDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteFormulaFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteFormulaDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.getCostQuoteFormulaDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {CostQuoteFormulaFormData} [costQuoteFormulaFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteFormula(costQuoteFormulaFormData?: CostQuoteFormulaFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteFormula(costQuoteFormulaFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.postCostQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<CostQuoteFormulaListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteFormulaPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.postCostQuoteFormulaPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteFormulaSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.postCostQuoteFormulaSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteFormulaFormData>} [customUpdateModelCostQuoteFormulaFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData?: CustomUpdateModel<CostQuoteFormulaFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteFormulaApi.putCostQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * CostQuoteFormulaApi - factory interface
 * 内部报价公式 接口
 * @export
 */
export const CostQuoteFormulaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CostQuoteFormulaApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteCostQuoteFormula(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteFormula(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormulaFormData>> {
            return localVarFp.getCostQuoteFormula(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteFormulaDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormulaFormData>> {
            return localVarFp.getCostQuoteFormulaDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {CostQuoteFormulaFormData} [costQuoteFormulaFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteFormula(costQuoteFormulaFormData?: CostQuoteFormulaFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteFormula(costQuoteFormulaFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteFormulaListData>>> {
            return localVarFp.postCostQuoteFormulaPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteFormulaSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteFormulaFormData>} [customUpdateModelCostQuoteFormulaFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData?: CustomUpdateModel<CostQuoteFormulaFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CostQuoteFormulaApi - interface
 * 内部报价公式 接口
 * @export
 * @interface CostQuoteFormulaApi
 */
export interface CostQuoteFormulaApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    deleteCostQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    getCostQuoteFormula(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormulaFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    getCostQuoteFormulaDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteFormulaFormData>>;

    /**
     * 新增
     * @param {CostQuoteFormulaFormData} [costQuoteFormulaFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    postCostQuoteFormula(costQuoteFormulaFormData?: CostQuoteFormulaFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    postCostQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteFormulaListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    postCostQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteFormulaFormData>} [customUpdateModelCostQuoteFormulaFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApiInterface
     */
    putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData?: CustomUpdateModel<CostQuoteFormulaFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * CostQuoteFormulaApi - object-oriented interface
 * 内部报价公式 接口
 * @export
 * @class CostQuoteFormulaApi
 * @extends {BaseAPI}
 */
export class CostQuoteFormulaApi extends BaseAPI implements CostQuoteFormulaApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public deleteCostQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).deleteCostQuoteFormula(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public getCostQuoteFormula(id: number, options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).getCostQuoteFormula(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public getCostQuoteFormulaDefault(options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).getCostQuoteFormulaDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {CostQuoteFormulaFormData} [costQuoteFormulaFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public postCostQuoteFormula(costQuoteFormulaFormData?: CostQuoteFormulaFormData, options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).postCostQuoteFormula(costQuoteFormulaFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public postCostQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).postCostQuoteFormulaPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public postCostQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).postCostQuoteFormulaSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteFormulaFormData>} [customUpdateModelCostQuoteFormulaFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteFormulaApi
     */
    public putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData?: CustomUpdateModel<CostQuoteFormulaFormData>, options?: RawAxiosRequestConfig) {
        return CostQuoteFormulaApiFp(this.configuration).putCostQuoteFormula(customUpdateModelCostQuoteFormulaFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CostQuoteTemplateApi - axios parameter creator
 * 内部报价模板 接口
 * @export
 */
export const CostQuoteTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuoteTemplate: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteCostQuoteTemplate', 'ids', ids)
            const localVarPath = `/api/costQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteTemplate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCostQuoteTemplate', 'id', id)
            const localVarPath = `/api/costQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteTemplateDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteTemplate/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {CostQuoteTemplateFormData} [costQuoteTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteTemplate: async (costQuoteTemplateFormData?: CostQuoteTemplateFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costQuoteTemplateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteTemplatePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteTemplate/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteTemplateSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteTemplate/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteTemplateFormData>} [customUpdateModelCostQuoteTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuoteTemplate: async (customUpdateModelCostQuoteTemplateFormData?: CustomUpdateModel<CostQuoteTemplateFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelCostQuoteTemplateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CostQuoteTemplateApi - functional programming interface
 * 内部报价模板 接口
 * @export
 */
export const CostQuoteTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CostQuoteTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCostQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCostQuoteTemplate(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.deleteCostQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteTemplate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteTemplateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteTemplate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.getCostQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteTemplateDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteTemplateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteTemplateDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.getCostQuoteTemplateDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {CostQuoteTemplateFormData} [costQuoteTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteTemplate(costQuoteTemplateFormData?: CostQuoteTemplateFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteTemplate(costQuoteTemplateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.postCostQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<CostQuoteTemplateListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteTemplatePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.postCostQuoteTemplatePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteTemplateSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.postCostQuoteTemplateSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteTemplateFormData>} [customUpdateModelCostQuoteTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData?: CustomUpdateModel<CostQuoteTemplateFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteTemplateApi.putCostQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * CostQuoteTemplateApi - factory interface
 * 内部报价模板 接口
 * @export
 */
export const CostQuoteTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CostQuoteTemplateApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteCostQuoteTemplate(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteTemplate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteTemplateFormData>> {
            return localVarFp.getCostQuoteTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteTemplateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteTemplateFormData>> {
            return localVarFp.getCostQuoteTemplateDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {CostQuoteTemplateFormData} [costQuoteTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteTemplate(costQuoteTemplateFormData?: CostQuoteTemplateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteTemplate(costQuoteTemplateFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteTemplateListData>>> {
            return localVarFp.postCostQuoteTemplatePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteTemplateSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteTemplateFormData>} [customUpdateModelCostQuoteTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData?: CustomUpdateModel<CostQuoteTemplateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CostQuoteTemplateApi - interface
 * 内部报价模板 接口
 * @export
 * @interface CostQuoteTemplateApi
 */
export interface CostQuoteTemplateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    deleteCostQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    getCostQuoteTemplate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteTemplateFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    getCostQuoteTemplateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteTemplateFormData>>;

    /**
     * 新增
     * @param {CostQuoteTemplateFormData} [costQuoteTemplateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    postCostQuoteTemplate(costQuoteTemplateFormData?: CostQuoteTemplateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    postCostQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteTemplateListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    postCostQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteTemplateFormData>} [customUpdateModelCostQuoteTemplateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApiInterface
     */
    putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData?: CustomUpdateModel<CostQuoteTemplateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * CostQuoteTemplateApi - object-oriented interface
 * 内部报价模板 接口
 * @export
 * @class CostQuoteTemplateApi
 * @extends {BaseAPI}
 */
export class CostQuoteTemplateApi extends BaseAPI implements CostQuoteTemplateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public deleteCostQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).deleteCostQuoteTemplate(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public getCostQuoteTemplate(id: number, options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).getCostQuoteTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public getCostQuoteTemplateDefault(options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).getCostQuoteTemplateDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {CostQuoteTemplateFormData} [costQuoteTemplateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public postCostQuoteTemplate(costQuoteTemplateFormData?: CostQuoteTemplateFormData, options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).postCostQuoteTemplate(costQuoteTemplateFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public postCostQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).postCostQuoteTemplatePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public postCostQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).postCostQuoteTemplateSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteTemplateFormData>} [customUpdateModelCostQuoteTemplateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteTemplateApi
     */
    public putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData?: CustomUpdateModel<CostQuoteTemplateFormData>, options?: RawAxiosRequestConfig) {
        return CostQuoteTemplateApiFp(this.configuration).putCostQuoteTemplate(customUpdateModelCostQuoteTemplateFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CostQuoteWithApi - axios parameter creator
 * 内部报价关联 接口
 * @export
 */
export const CostQuoteWithApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuoteWith: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteCostQuoteWith', 'ids', ids)
            const localVarPath = `/api/costQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteWith: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCostQuoteWith', 'id', id)
            const localVarPath = `/api/costQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteWithDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteWith/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {CostQuoteWithFormData} [costQuoteWithFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteWith: async (costQuoteWithFormData?: CostQuoteWithFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costQuoteWithFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteWithPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteWith/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteWithSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteWith/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteWithFormData>} [customUpdateModelCostQuoteWithFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuoteWith: async (customUpdateModelCostQuoteWithFormData?: CustomUpdateModel<CostQuoteWithFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/costQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelCostQuoteWithFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CostQuoteWithApi - functional programming interface
 * 内部报价关联 接口
 * @export
 */
export const CostQuoteWithApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CostQuoteWithApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCostQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCostQuoteWith(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.deleteCostQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteWith(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteWithFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteWith(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.getCostQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQuoteWithDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<CostQuoteWithFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQuoteWithDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.getCostQuoteWithDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {CostQuoteWithFormData} [costQuoteWithFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteWith(costQuoteWithFormData?: CostQuoteWithFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteWith(costQuoteWithFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.postCostQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<CostQuoteWithListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteWithPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.postCostQuoteWithPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCostQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCostQuoteWithSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.postCostQuoteWithSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteWithFormData>} [customUpdateModelCostQuoteWithFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCostQuoteWith(customUpdateModelCostQuoteWithFormData?: CustomUpdateModel<CostQuoteWithFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCostQuoteWith(customUpdateModelCostQuoteWithFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostQuoteWithApi.putCostQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * CostQuoteWithApi - factory interface
 * 内部报价关联 接口
 * @export
 */
export const CostQuoteWithApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CostQuoteWithApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCostQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteCostQuoteWith(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteWith(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteWithFormData>> {
            return localVarFp.getCostQuoteWith(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQuoteWithDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteWithFormData>> {
            return localVarFp.getCostQuoteWithDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {CostQuoteWithFormData} [costQuoteWithFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteWith(costQuoteWithFormData?: CostQuoteWithFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteWith(costQuoteWithFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteWithListData>>> {
            return localVarFp.postCostQuoteWithPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCostQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postCostQuoteWithSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<CostQuoteWithFormData>} [customUpdateModelCostQuoteWithFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCostQuoteWith(customUpdateModelCostQuoteWithFormData?: CustomUpdateModel<CostQuoteWithFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putCostQuoteWith(customUpdateModelCostQuoteWithFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CostQuoteWithApi - interface
 * 内部报价关联 接口
 * @export
 * @interface CostQuoteWithApi
 */
export interface CostQuoteWithApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    deleteCostQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    getCostQuoteWith(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteWithFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    getCostQuoteWithDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<CostQuoteWithFormData>>;

    /**
     * 新增
     * @param {CostQuoteWithFormData} [costQuoteWithFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    postCostQuoteWith(costQuoteWithFormData?: CostQuoteWithFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    postCostQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<CostQuoteWithListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    postCostQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteWithFormData>} [customUpdateModelCostQuoteWithFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApiInterface
     */
    putCostQuoteWith(customUpdateModelCostQuoteWithFormData?: CustomUpdateModel<CostQuoteWithFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * CostQuoteWithApi - object-oriented interface
 * 内部报价关联 接口
 * @export
 * @class CostQuoteWithApi
 * @extends {BaseAPI}
 */
export class CostQuoteWithApi extends BaseAPI implements CostQuoteWithApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public deleteCostQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).deleteCostQuoteWith(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public getCostQuoteWith(id: number, options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).getCostQuoteWith(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public getCostQuoteWithDefault(options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).getCostQuoteWithDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {CostQuoteWithFormData} [costQuoteWithFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public postCostQuoteWith(costQuoteWithFormData?: CostQuoteWithFormData, options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).postCostQuoteWith(costQuoteWithFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public postCostQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).postCostQuoteWithPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public postCostQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).postCostQuoteWithSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<CostQuoteWithFormData>} [customUpdateModelCostQuoteWithFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostQuoteWithApi
     */
    public putCostQuoteWith(customUpdateModelCostQuoteWithFormData?: CustomUpdateModel<CostQuoteWithFormData>, options?: RawAxiosRequestConfig) {
        return CostQuoteWithApiFp(this.configuration).putCostQuoteWith(customUpdateModelCostQuoteWithFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliveryApi - axios parameter creator
 * 送货 接口
 * @export
 */
export const DeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDelivery: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteDelivery', 'ids', ids)
            const localVarPath = `/api/delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelivery: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDelivery', 'id', id)
            const localVarPath = `/api/delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/delivery/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {DeliveryFormData} [deliveryFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDelivery: async (deliveryFormData?: DeliveryFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/delivery/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliverySetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/delivery/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryFormData>} [customUpdateModelDeliveryFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDelivery: async (customUpdateModelDeliveryFormData?: CustomUpdateModel<DeliveryFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelDeliveryFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryApi - functional programming interface
 * 送货 接口
 * @export
 */
export const DeliveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDelivery(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDelivery(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.deleteDelivery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDelivery(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DeliveryFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDelivery(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.getDelivery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DeliveryFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.getDeliveryDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {DeliveryFormData} [deliveryFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDelivery(deliveryFormData?: DeliveryFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDelivery(deliveryFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.postDelivery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliveryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<DeliveryListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliveryPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.postDeliveryPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliverySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliverySetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.postDeliverySetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryFormData>} [customUpdateModelDeliveryFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDelivery(customUpdateModelDeliveryFormData?: CustomUpdateModel<DeliveryFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDelivery(customUpdateModelDeliveryFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryApi.putDelivery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * DeliveryApi - factory interface
 * 送货 接口
 * @export
 */
export const DeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDelivery(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteDelivery(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelivery(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryFormData>> {
            return localVarFp.getDelivery(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryFormData>> {
            return localVarFp.getDeliveryDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {DeliveryFormData} [deliveryFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDelivery(deliveryFormData?: DeliveryFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDelivery(deliveryFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DeliveryListData>>> {
            return localVarFp.postDeliveryPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliverySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDeliverySetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryFormData>} [customUpdateModelDeliveryFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDelivery(customUpdateModelDeliveryFormData?: CustomUpdateModel<DeliveryFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putDelivery(customUpdateModelDeliveryFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryApi - interface
 * 送货 接口
 * @export
 * @interface DeliveryApi
 */
export interface DeliveryApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    deleteDelivery(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    getDelivery(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    getDeliveryDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryFormData>>;

    /**
     * 新增
     * @param {DeliveryFormData} [deliveryFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    postDelivery(deliveryFormData?: DeliveryFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    postDeliveryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DeliveryListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    postDeliverySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<DeliveryFormData>} [customUpdateModelDeliveryFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApiInterface
     */
    putDelivery(customUpdateModelDeliveryFormData?: CustomUpdateModel<DeliveryFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * DeliveryApi - object-oriented interface
 * 送货 接口
 * @export
 * @class DeliveryApi
 * @extends {BaseAPI}
 */
export class DeliveryApi extends BaseAPI implements DeliveryApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public deleteDelivery(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).deleteDelivery(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public getDelivery(id: number, options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).getDelivery(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public getDeliveryDefault(options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).getDeliveryDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {DeliveryFormData} [deliveryFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public postDelivery(deliveryFormData?: DeliveryFormData, options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).postDelivery(deliveryFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public postDeliveryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).postDeliveryPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public postDeliverySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).postDeliverySetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<DeliveryFormData>} [customUpdateModelDeliveryFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public putDelivery(customUpdateModelDeliveryFormData?: CustomUpdateModel<DeliveryFormData>, options?: RawAxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).putDelivery(customUpdateModelDeliveryFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliveryCostApi - axios parameter creator
 * 送货费用 接口
 * @export
 */
export const DeliveryCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryCost: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteDeliveryCost', 'ids', ids)
            const localVarPath = `/api/deliveryCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryCost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeliveryCost', 'id', id)
            const localVarPath = `/api/deliveryCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryCostDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryCost/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {DeliveryCostFormData} [deliveryCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryCost: async (deliveryCostFormData?: DeliveryCostFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryCostPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryCost/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryCostFormData>} [customUpdateModelDeliveryCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeliveryCost: async (customUpdateModelDeliveryCostFormData?: CustomUpdateModel<DeliveryCostFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelDeliveryCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryCostApi - functional programming interface
 * 送货费用 接口
 * @export
 */
export const DeliveryCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryCost(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryCostApi.deleteDeliveryCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryCost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DeliveryCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryCost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryCostApi.getDeliveryCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryCostDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DeliveryCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryCostDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryCostApi.getDeliveryCostDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {DeliveryCostFormData} [deliveryCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliveryCost(deliveryCostFormData?: DeliveryCostFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliveryCost(deliveryCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryCostApi.postDeliveryCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliveryCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<DeliveryCostListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliveryCostPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryCostApi.postDeliveryCostPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryCostFormData>} [customUpdateModelDeliveryCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeliveryCost(customUpdateModelDeliveryCostFormData?: CustomUpdateModel<DeliveryCostFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeliveryCost(customUpdateModelDeliveryCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryCostApi.putDeliveryCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * DeliveryCostApi - factory interface
 * 送货费用 接口
 * @export
 */
export const DeliveryCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryCostApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteDeliveryCost(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryCostFormData>> {
            return localVarFp.getDeliveryCost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryCostFormData>> {
            return localVarFp.getDeliveryCostDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {DeliveryCostFormData} [deliveryCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryCost(deliveryCostFormData?: DeliveryCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDeliveryCost(deliveryCostFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DeliveryCostListData>>> {
            return localVarFp.postDeliveryCostPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryCostFormData>} [customUpdateModelDeliveryCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeliveryCost(customUpdateModelDeliveryCostFormData?: CustomUpdateModel<DeliveryCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putDeliveryCost(customUpdateModelDeliveryCostFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryCostApi - interface
 * 送货费用 接口
 * @export
 * @interface DeliveryCostApi
 */
export interface DeliveryCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApiInterface
     */
    deleteDeliveryCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApiInterface
     */
    getDeliveryCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryCostFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApiInterface
     */
    getDeliveryCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryCostFormData>>;

    /**
     * 新增
     * @param {DeliveryCostFormData} [deliveryCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApiInterface
     */
    postDeliveryCost(deliveryCostFormData?: DeliveryCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApiInterface
     */
    postDeliveryCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DeliveryCostListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<DeliveryCostFormData>} [customUpdateModelDeliveryCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApiInterface
     */
    putDeliveryCost(customUpdateModelDeliveryCostFormData?: CustomUpdateModel<DeliveryCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * DeliveryCostApi - object-oriented interface
 * 送货费用 接口
 * @export
 * @class DeliveryCostApi
 * @extends {BaseAPI}
 */
export class DeliveryCostApi extends BaseAPI implements DeliveryCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApi
     */
    public deleteDeliveryCost(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return DeliveryCostApiFp(this.configuration).deleteDeliveryCost(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApi
     */
    public getDeliveryCost(id: number, options?: RawAxiosRequestConfig) {
        return DeliveryCostApiFp(this.configuration).getDeliveryCost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApi
     */
    public getDeliveryCostDefault(options?: RawAxiosRequestConfig) {
        return DeliveryCostApiFp(this.configuration).getDeliveryCostDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {DeliveryCostFormData} [deliveryCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApi
     */
    public postDeliveryCost(deliveryCostFormData?: DeliveryCostFormData, options?: RawAxiosRequestConfig) {
        return DeliveryCostApiFp(this.configuration).postDeliveryCost(deliveryCostFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApi
     */
    public postDeliveryCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return DeliveryCostApiFp(this.configuration).postDeliveryCostPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<DeliveryCostFormData>} [customUpdateModelDeliveryCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryCostApi
     */
    public putDeliveryCost(customUpdateModelDeliveryCostFormData?: CustomUpdateModel<DeliveryCostFormData>, options?: RawAxiosRequestConfig) {
        return DeliveryCostApiFp(this.configuration).putDeliveryCost(customUpdateModelDeliveryCostFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliveryDetailsApi - axios parameter creator
 * 送货明细 接口
 * @export
 */
export const DeliveryDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDetails: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteDeliveryDetails', 'ids', ids)
            const localVarPath = `/api/deliveryDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryDetails: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeliveryDetails', 'id', id)
            const localVarPath = `/api/deliveryDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryDetailsDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryDetails/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {DeliveryDetailsFormData} [deliveryDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryDetails: async (deliveryDetailsFormData?: DeliveryDetailsFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryDetailsPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryDetails/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryDetailsFormData>} [customUpdateModelDeliveryDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeliveryDetails: async (customUpdateModelDeliveryDetailsFormData?: CustomUpdateModel<DeliveryDetailsFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deliveryDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelDeliveryDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryDetailsApi - functional programming interface
 * 送货明细 接口
 * @export
 */
export const DeliveryDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryDetails(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryDetailsApi.deleteDeliveryDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryDetails(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DeliveryDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryDetailsApi.getDeliveryDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryDetailsDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DeliveryDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryDetailsDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryDetailsApi.getDeliveryDetailsDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {DeliveryDetailsFormData} [deliveryDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliveryDetails(deliveryDetailsFormData?: DeliveryDetailsFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliveryDetails(deliveryDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryDetailsApi.postDeliveryDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliveryDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<DeliveryDetailsListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliveryDetailsPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryDetailsApi.postDeliveryDetailsPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryDetailsFormData>} [customUpdateModelDeliveryDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeliveryDetails(customUpdateModelDeliveryDetailsFormData?: CustomUpdateModel<DeliveryDetailsFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeliveryDetails(customUpdateModelDeliveryDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryDetailsApi.putDeliveryDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * DeliveryDetailsApi - factory interface
 * 送货明细 接口
 * @export
 */
export const DeliveryDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryDetailsApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteDeliveryDetails(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryDetailsFormData>> {
            return localVarFp.getDeliveryDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryDetailsFormData>> {
            return localVarFp.getDeliveryDetailsDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {DeliveryDetailsFormData} [deliveryDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryDetails(deliveryDetailsFormData?: DeliveryDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDeliveryDetails(deliveryDetailsFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliveryDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DeliveryDetailsListData>>> {
            return localVarFp.postDeliveryDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DeliveryDetailsFormData>} [customUpdateModelDeliveryDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeliveryDetails(customUpdateModelDeliveryDetailsFormData?: CustomUpdateModel<DeliveryDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putDeliveryDetails(customUpdateModelDeliveryDetailsFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryDetailsApi - interface
 * 送货明细 接口
 * @export
 * @interface DeliveryDetailsApi
 */
export interface DeliveryDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApiInterface
     */
    deleteDeliveryDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApiInterface
     */
    getDeliveryDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryDetailsFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApiInterface
     */
    getDeliveryDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DeliveryDetailsFormData>>;

    /**
     * 新增
     * @param {DeliveryDetailsFormData} [deliveryDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApiInterface
     */
    postDeliveryDetails(deliveryDetailsFormData?: DeliveryDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApiInterface
     */
    postDeliveryDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DeliveryDetailsListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<DeliveryDetailsFormData>} [customUpdateModelDeliveryDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApiInterface
     */
    putDeliveryDetails(customUpdateModelDeliveryDetailsFormData?: CustomUpdateModel<DeliveryDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * DeliveryDetailsApi - object-oriented interface
 * 送货明细 接口
 * @export
 * @class DeliveryDetailsApi
 * @extends {BaseAPI}
 */
export class DeliveryDetailsApi extends BaseAPI implements DeliveryDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApi
     */
    public deleteDeliveryDetails(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return DeliveryDetailsApiFp(this.configuration).deleteDeliveryDetails(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApi
     */
    public getDeliveryDetails(id: number, options?: RawAxiosRequestConfig) {
        return DeliveryDetailsApiFp(this.configuration).getDeliveryDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApi
     */
    public getDeliveryDetailsDefault(options?: RawAxiosRequestConfig) {
        return DeliveryDetailsApiFp(this.configuration).getDeliveryDetailsDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {DeliveryDetailsFormData} [deliveryDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApi
     */
    public postDeliveryDetails(deliveryDetailsFormData?: DeliveryDetailsFormData, options?: RawAxiosRequestConfig) {
        return DeliveryDetailsApiFp(this.configuration).postDeliveryDetails(deliveryDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApi
     */
    public postDeliveryDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return DeliveryDetailsApiFp(this.configuration).postDeliveryDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<DeliveryDetailsFormData>} [customUpdateModelDeliveryDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDetailsApi
     */
    public putDeliveryDetails(customUpdateModelDeliveryDetailsFormData?: CustomUpdateModel<DeliveryDetailsFormData>, options?: RawAxiosRequestConfig) {
        return DeliveryDetailsApiFp(this.configuration).putDeliveryDetails(customUpdateModelDeliveryDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DicCategoryApi - axios parameter creator
 * 字典类别接口
 * @export
 */
export const DicCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDicCategory: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteDicCategory', 'ids', ids)
            const localVarPath = `/api/dicCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDicCategory', 'id', id)
            const localVarPath = `/api/dicCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 根据字典编码获取字典明细
         * @param {string} [code] 字典编码
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryDicDetailList: async (code?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/dicDetailList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取所有字典数据（类别和明细）
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryPrint: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {DicCategoryFormData} [dicCategoryFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategory: async (dicCategoryFormData?: DicCategoryFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dicCategoryFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategoryImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategoryPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategorySetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DicCategoryFormData>} [customUpdateModelDicCategoryFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDicCategory: async (customUpdateModelDicCategoryFormData?: CustomUpdateModel<DicCategoryFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelDicCategoryFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DicCategoryApi - functional programming interface
 * 字典类别接口
 * @export
 */
export const DicCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DicCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDicCategory(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDicCategory(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.deleteDicCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicCategoryFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.getDicCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicCategoryDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicCategoryFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicCategoryDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.getDicCategoryDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 根据字典编码获取字典明细
         * @param {string} [code] 字典编码
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicCategoryDicDetailList(code?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicDetailData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicCategoryDicDetailList(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.getDicCategoryDicDetailList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicCategoryExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicCategoryExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.getDicCategoryExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取所有字典数据（类别和明细）
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicCategoryList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicInfoData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicCategoryList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.getDicCategoryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicCategoryPrint(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicCategoryPrint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicCategoryPrint(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.getDicCategoryPrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {DicCategoryFormData} [dicCategoryFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicCategory(dicCategoryFormData?: DicCategoryFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicCategory(dicCategoryFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.postDicCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicCategoryImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicCategoryImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.postDicCategoryImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicCategoryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<DicCategoryListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicCategoryPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.postDicCategoryPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicCategorySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicCategorySetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.postDicCategorySetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DicCategoryFormData>} [customUpdateModelDicCategoryFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDicCategory(customUpdateModelDicCategoryFormData?: CustomUpdateModel<DicCategoryFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDicCategory(customUpdateModelDicCategoryFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicCategoryApi.putDicCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * DicCategoryApi - factory interface
 * 字典类别接口
 * @export
 */
export const DicCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DicCategoryApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDicCategory(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteDicCategory(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategory(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicCategoryFormData>> {
            return localVarFp.getDicCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DicCategoryFormData>> {
            return localVarFp.getDicCategoryDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 根据字典编码获取字典明细
         * @param {string} [code] 字典编码
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryDicDetailList(code?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDetailData[]>> {
            return localVarFp.getDicCategoryDicDetailList(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getDicCategoryExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取所有字典数据（类别和明细）
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryList(options?: RawAxiosRequestConfig): Promise<UniformResult<DicInfoData[]>> {
            return localVarFp.getDicCategoryList(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicCategoryPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicCategoryPrint>> {
            return localVarFp.getDicCategoryPrint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {DicCategoryFormData} [dicCategoryFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategory(dicCategoryFormData?: DicCategoryFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDicCategory(dicCategoryFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategoryImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postDicCategoryImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategoryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DicCategoryListData>>> {
            return localVarFp.postDicCategoryPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicCategorySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDicCategorySetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DicCategoryFormData>} [customUpdateModelDicCategoryFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDicCategory(customUpdateModelDicCategoryFormData?: CustomUpdateModel<DicCategoryFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putDicCategory(customUpdateModelDicCategoryFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DicCategoryApi - interface
 * 字典类别接口
 * @export
 * @interface DicCategoryApi
 */
export interface DicCategoryApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    deleteDicCategory(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    getDicCategory(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicCategoryFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    getDicCategoryDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DicCategoryFormData>>;

    /**
     * 根据字典编码获取字典明细
     * @param {string} [code] 字典编码
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    getDicCategoryDicDetailList(code?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDetailData[]>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    getDicCategoryExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 获取所有字典数据（类别和明细）
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    getDicCategoryList(options?: RawAxiosRequestConfig): Promise<UniformResult<DicInfoData[]>>;

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    getDicCategoryPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicCategoryPrint>>;

    /**
     * 新增
     * @param {DicCategoryFormData} [dicCategoryFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    postDicCategory(dicCategoryFormData?: DicCategoryFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    postDicCategoryImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    postDicCategoryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DicCategoryListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    postDicCategorySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<DicCategoryFormData>} [customUpdateModelDicCategoryFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApiInterface
     */
    putDicCategory(customUpdateModelDicCategoryFormData?: CustomUpdateModel<DicCategoryFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * DicCategoryApi - object-oriented interface
 * 字典类别接口
 * @export
 * @class DicCategoryApi
 * @extends {BaseAPI}
 */
export class DicCategoryApi extends BaseAPI implements DicCategoryApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public deleteDicCategory(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).deleteDicCategory(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public getDicCategory(id: number, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).getDicCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public getDicCategoryDefault(options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).getDicCategoryDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 根据字典编码获取字典明细
     * @param {string} [code] 字典编码
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public getDicCategoryDicDetailList(code?: string, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).getDicCategoryDicDetailList(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public getDicCategoryExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).getDicCategoryExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取所有字典数据（类别和明细）
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public getDicCategoryList(options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).getDicCategoryList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public getDicCategoryPrint(id?: number, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).getDicCategoryPrint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {DicCategoryFormData} [dicCategoryFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public postDicCategory(dicCategoryFormData?: DicCategoryFormData, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).postDicCategory(dicCategoryFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public postDicCategoryImport(file?: File, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).postDicCategoryImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public postDicCategoryPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).postDicCategoryPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public postDicCategorySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).postDicCategorySetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<DicCategoryFormData>} [customUpdateModelDicCategoryFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicCategoryApi
     */
    public putDicCategory(customUpdateModelDicCategoryFormData?: CustomUpdateModel<DicCategoryFormData>, options?: RawAxiosRequestConfig) {
        return DicCategoryApiFp(this.configuration).putDicCategory(customUpdateModelDicCategoryFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DicDataApi - axios parameter creator
 * 字典数据 接口
 * @export
 */
export const DicDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDicData: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteDicData', 'ids', ids)
            const localVarPath = `/api/dicData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicData: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDicData', 'id', id)
            const localVarPath = `/api/dicData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过字典类别获取字典明细
         * @param {number} [id] 字典类别主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataListByDicTypeId: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/listByDicTypeId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataPrint: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {DicDataFormData} [dicDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicData: async (dicDataFormData?: DicDataFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dicDataFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicDataImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicDataPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicDataSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DicDataFormData>} [customUpdateModelDicDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDicData: async (customUpdateModelDicDataFormData?: CustomUpdateModel<DicDataFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dicData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelDicDataFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DicDataApi - functional programming interface
 * 字典数据 接口
 * @export
 */
export const DicDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DicDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDicData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDicData(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.deleteDicData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicData(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicDataFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.getDicData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicDataDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicDataFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicDataDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.getDicDataDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicDataExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicDataExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.getDicDataExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过字典类别获取字典明细
         * @param {number} [id] 字典类别主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicDataListByDicTypeId(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicDataListData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicDataListByDicTypeId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.getDicDataListByDicTypeId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDicDataPrint(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DicDataPrint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDicDataPrint(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.getDicDataPrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {DicDataFormData} [dicDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicData(dicDataFormData?: DicDataFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicData(dicDataFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.postDicData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicDataImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicDataImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.postDicDataImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<DicDataListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicDataPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.postDicDataPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDicDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDicDataSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.postDicDataSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DicDataFormData>} [customUpdateModelDicDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDicData(customUpdateModelDicDataFormData?: CustomUpdateModel<DicDataFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDicData(customUpdateModelDicDataFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DicDataApi.putDicData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * DicDataApi - factory interface
 * 字典数据 接口
 * @export
 */
export const DicDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DicDataApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDicData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteDicData(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicData(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataFormData>> {
            return localVarFp.getDicData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataFormData>> {
            return localVarFp.getDicDataDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getDicDataExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过字典类别获取字典明细
         * @param {number} [id] 字典类别主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataListByDicTypeId(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataListData[]>> {
            return localVarFp.getDicDataListByDicTypeId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDicDataPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataPrint>> {
            return localVarFp.getDicDataPrint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {DicDataFormData} [dicDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicData(dicDataFormData?: DicDataFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDicData(dicDataFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicDataImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postDicDataImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DicDataListData>>> {
            return localVarFp.postDicDataPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDicDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDicDataSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DicDataFormData>} [customUpdateModelDicDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDicData(customUpdateModelDicDataFormData?: CustomUpdateModel<DicDataFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putDicData(customUpdateModelDicDataFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DicDataApi - interface
 * 字典数据 接口
 * @export
 * @interface DicDataApi
 */
export interface DicDataApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    deleteDicData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    getDicData(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    getDicDataDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataFormData>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    getDicDataExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 通过字典类别获取字典明细
     * @param {number} [id] 字典类别主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    getDicDataListByDicTypeId(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataListData[]>>;

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    getDicDataPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DicDataPrint>>;

    /**
     * 新增
     * @param {DicDataFormData} [dicDataFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    postDicData(dicDataFormData?: DicDataFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    postDicDataImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    postDicDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DicDataListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    postDicDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<DicDataFormData>} [customUpdateModelDicDataFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApiInterface
     */
    putDicData(customUpdateModelDicDataFormData?: CustomUpdateModel<DicDataFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * DicDataApi - object-oriented interface
 * 字典数据 接口
 * @export
 * @class DicDataApi
 * @extends {BaseAPI}
 */
export class DicDataApi extends BaseAPI implements DicDataApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public deleteDicData(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).deleteDicData(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public getDicData(id: number, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).getDicData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public getDicDataDefault(options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).getDicDataDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public getDicDataExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).getDicDataExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过字典类别获取字典明细
     * @param {number} [id] 字典类别主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public getDicDataListByDicTypeId(id?: number, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).getDicDataListByDicTypeId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public getDicDataPrint(id?: number, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).getDicDataPrint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {DicDataFormData} [dicDataFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public postDicData(dicDataFormData?: DicDataFormData, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).postDicData(dicDataFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public postDicDataImport(file?: File, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).postDicDataImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public postDicDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).postDicDataPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public postDicDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).postDicDataSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<DicDataFormData>} [customUpdateModelDicDataFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DicDataApi
     */
    public putDicData(customUpdateModelDicDataFormData?: CustomUpdateModel<DicDataFormData>, options?: RawAxiosRequestConfig) {
        return DicDataApiFp(this.configuration).putDicData(customUpdateModelDicDataFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DriverDataApi - axios parameter creator
 * 司机资料 接口
 * @export
 */
export const DriverDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDriverData: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteDriverData', 'ids', ids)
            const localVarPath = `/api/driverData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverData: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDriverData', 'id', id)
            const localVarPath = `/api/driverData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverDataDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/driverData/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {DriverDataFormData} [driverDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDriverData: async (driverDataFormData?: DriverDataFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/driverData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(driverDataFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDriverDataPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/driverData/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDriverDataSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/driverData/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DriverDataFormData>} [customUpdateModelDriverDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDriverData: async (customUpdateModelDriverDataFormData?: CustomUpdateModel<DriverDataFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/driverData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelDriverDataFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverDataApi - functional programming interface
 * 司机资料 接口
 * @export
 */
export const DriverDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriverDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDriverData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDriverData(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.deleteDriverData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDriverData(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DriverDataFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDriverData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.getDriverData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDriverDataDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<DriverDataFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDriverDataDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.getDriverDataDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {DriverDataFormData} [driverDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDriverData(driverDataFormData?: DriverDataFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDriverData(driverDataFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.postDriverData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDriverDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<DriverDataListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDriverDataPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.postDriverDataPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDriverDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDriverDataSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.postDriverDataSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DriverDataFormData>} [customUpdateModelDriverDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDriverData(customUpdateModelDriverDataFormData?: CustomUpdateModel<DriverDataFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDriverData(customUpdateModelDriverDataFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverDataApi.putDriverData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * DriverDataApi - factory interface
 * 司机资料 接口
 * @export
 */
export const DriverDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriverDataApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDriverData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteDriverData(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverData(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DriverDataFormData>> {
            return localVarFp.getDriverData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverDataDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DriverDataFormData>> {
            return localVarFp.getDriverDataDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {DriverDataFormData} [driverDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDriverData(driverDataFormData?: DriverDataFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDriverData(driverDataFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDriverDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DriverDataListData>>> {
            return localVarFp.postDriverDataPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDriverDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postDriverDataSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<DriverDataFormData>} [customUpdateModelDriverDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDriverData(customUpdateModelDriverDataFormData?: CustomUpdateModel<DriverDataFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putDriverData(customUpdateModelDriverDataFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverDataApi - interface
 * 司机资料 接口
 * @export
 * @interface DriverDataApi
 */
export interface DriverDataApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    deleteDriverData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    getDriverData(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<DriverDataFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    getDriverDataDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<DriverDataFormData>>;

    /**
     * 新增
     * @param {DriverDataFormData} [driverDataFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    postDriverData(driverDataFormData?: DriverDataFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    postDriverDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<DriverDataListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    postDriverDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<DriverDataFormData>} [customUpdateModelDriverDataFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApiInterface
     */
    putDriverData(customUpdateModelDriverDataFormData?: CustomUpdateModel<DriverDataFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * DriverDataApi - object-oriented interface
 * 司机资料 接口
 * @export
 * @class DriverDataApi
 * @extends {BaseAPI}
 */
export class DriverDataApi extends BaseAPI implements DriverDataApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public deleteDriverData(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).deleteDriverData(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public getDriverData(id: number, options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).getDriverData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public getDriverDataDefault(options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).getDriverDataDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {DriverDataFormData} [driverDataFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public postDriverData(driverDataFormData?: DriverDataFormData, options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).postDriverData(driverDataFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public postDriverDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).postDriverDataPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public postDriverDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).postDriverDataSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<DriverDataFormData>} [customUpdateModelDriverDataFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDataApi
     */
    public putDriverData(customUpdateModelDriverDataFormData?: CustomUpdateModel<DriverDataFormData>, options?: RawAxiosRequestConfig) {
        return DriverDataApiFp(this.configuration).putDriverData(customUpdateModelDriverDataFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExternalApi - axios parameter creator
 * 外发 接口
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternal: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteExternal', 'ids', ids)
            const localVarPath = `/api/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternal: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExternal', 'id', id)
            const localVarPath = `/api/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ExternalFormData} [externalFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternal: async (externalFormData?: ExternalFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalFormData>} [customUpdateModelExternalFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternal: async (customUpdateModelExternalFormData?: CustomUpdateModel<ExternalFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelExternalFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * 外发 接口
 * @export
 */
export const ExternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExternal(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExternal(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.deleteExternal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternal(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ExternalFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternal(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.getExternal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ExternalFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.getExternalDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ExternalFormData} [externalFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternal(externalFormData?: ExternalFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternal(externalFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.postExternal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ExternalListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.postExternalPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.postExternalSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalFormData>} [customUpdateModelExternalFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExternal(customUpdateModelExternalFormData?: CustomUpdateModel<ExternalFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExternal(customUpdateModelExternalFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.putExternal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ExternalApi - factory interface
 * 外发 接口
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternal(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteExternal(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternal(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalFormData>> {
            return localVarFp.getExternal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalFormData>> {
            return localVarFp.getExternalDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ExternalFormData} [externalFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternal(externalFormData?: ExternalFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postExternal(externalFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ExternalListData>>> {
            return localVarFp.postExternalPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postExternalSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalFormData>} [customUpdateModelExternalFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternal(customUpdateModelExternalFormData?: CustomUpdateModel<ExternalFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putExternal(customUpdateModelExternalFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalApi - interface
 * 外发 接口
 * @export
 * @interface ExternalApi
 */
export interface ExternalApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    deleteExternal(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    getExternal(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    getExternalDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalFormData>>;

    /**
     * 新增
     * @param {ExternalFormData} [externalFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    postExternal(externalFormData?: ExternalFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    postExternalPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ExternalListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    postExternalSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ExternalFormData>} [customUpdateModelExternalFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApiInterface
     */
    putExternal(customUpdateModelExternalFormData?: CustomUpdateModel<ExternalFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ExternalApi - object-oriented interface
 * 外发 接口
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI implements ExternalApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public deleteExternal(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).deleteExternal(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getExternal(id: number, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).getExternal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getExternalDefault(options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).getExternalDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ExternalFormData} [externalFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public postExternal(externalFormData?: ExternalFormData, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).postExternal(externalFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public postExternalPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).postExternalPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public postExternalSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).postExternalSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ExternalFormData>} [customUpdateModelExternalFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public putExternal(customUpdateModelExternalFormData?: CustomUpdateModel<ExternalFormData>, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).putExternal(customUpdateModelExternalFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExternalCostApi - axios parameter creator
 * 外发费用 接口
 * @export
 */
export const ExternalCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalCost: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteExternalCost', 'ids', ids)
            const localVarPath = `/api/externalCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalCost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExternalCost', 'id', id)
            const localVarPath = `/api/externalCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalCostDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalCost/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ExternalCostFormData} [externalCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalCost: async (externalCostFormData?: ExternalCostFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalCostPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalCost/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalCostFormData>} [customUpdateModelExternalCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternalCost: async (customUpdateModelExternalCostFormData?: CustomUpdateModel<ExternalCostFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelExternalCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalCostApi - functional programming interface
 * 外发费用 接口
 * @export
 */
export const ExternalCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExternalCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExternalCost(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalCostApi.deleteExternalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalCost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ExternalCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalCost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalCostApi.getExternalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalCostDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ExternalCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalCostDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalCostApi.getExternalCostDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ExternalCostFormData} [externalCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalCost(externalCostFormData?: ExternalCostFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalCost(externalCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalCostApi.postExternalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ExternalCostListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalCostPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalCostApi.postExternalCostPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalCostFormData>} [customUpdateModelExternalCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExternalCost(customUpdateModelExternalCostFormData?: CustomUpdateModel<ExternalCostFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExternalCost(customUpdateModelExternalCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalCostApi.putExternalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ExternalCostApi - factory interface
 * 外发费用 接口
 * @export
 */
export const ExternalCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalCostApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteExternalCost(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalCostFormData>> {
            return localVarFp.getExternalCost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalCostFormData>> {
            return localVarFp.getExternalCostDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ExternalCostFormData} [externalCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalCost(externalCostFormData?: ExternalCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postExternalCost(externalCostFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ExternalCostListData>>> {
            return localVarFp.postExternalCostPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalCostFormData>} [customUpdateModelExternalCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternalCost(customUpdateModelExternalCostFormData?: CustomUpdateModel<ExternalCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putExternalCost(customUpdateModelExternalCostFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalCostApi - interface
 * 外发费用 接口
 * @export
 * @interface ExternalCostApi
 */
export interface ExternalCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApiInterface
     */
    deleteExternalCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApiInterface
     */
    getExternalCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalCostFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApiInterface
     */
    getExternalCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalCostFormData>>;

    /**
     * 新增
     * @param {ExternalCostFormData} [externalCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApiInterface
     */
    postExternalCost(externalCostFormData?: ExternalCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApiInterface
     */
    postExternalCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ExternalCostListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ExternalCostFormData>} [customUpdateModelExternalCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApiInterface
     */
    putExternalCost(customUpdateModelExternalCostFormData?: CustomUpdateModel<ExternalCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ExternalCostApi - object-oriented interface
 * 外发费用 接口
 * @export
 * @class ExternalCostApi
 * @extends {BaseAPI}
 */
export class ExternalCostApi extends BaseAPI implements ExternalCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApi
     */
    public deleteExternalCost(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ExternalCostApiFp(this.configuration).deleteExternalCost(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApi
     */
    public getExternalCost(id: number, options?: RawAxiosRequestConfig) {
        return ExternalCostApiFp(this.configuration).getExternalCost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApi
     */
    public getExternalCostDefault(options?: RawAxiosRequestConfig) {
        return ExternalCostApiFp(this.configuration).getExternalCostDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ExternalCostFormData} [externalCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApi
     */
    public postExternalCost(externalCostFormData?: ExternalCostFormData, options?: RawAxiosRequestConfig) {
        return ExternalCostApiFp(this.configuration).postExternalCost(externalCostFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApi
     */
    public postExternalCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ExternalCostApiFp(this.configuration).postExternalCostPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ExternalCostFormData>} [customUpdateModelExternalCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalCostApi
     */
    public putExternalCost(customUpdateModelExternalCostFormData?: CustomUpdateModel<ExternalCostFormData>, options?: RawAxiosRequestConfig) {
        return ExternalCostApiFp(this.configuration).putExternalCost(customUpdateModelExternalCostFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExternalDetailsApi - axios parameter creator
 * 外发明细 接口
 * @export
 */
export const ExternalDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalDetails: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteExternalDetails', 'ids', ids)
            const localVarPath = `/api/externalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalDetails: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExternalDetails', 'id', id)
            const localVarPath = `/api/externalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalDetailsDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalDetails/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ExternalDetailsFormData} [externalDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalDetails: async (externalDetailsFormData?: ExternalDetailsFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalDetailsPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalDetails/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalDetailsFormData>} [customUpdateModelExternalDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternalDetails: async (customUpdateModelExternalDetailsFormData?: CustomUpdateModel<ExternalDetailsFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/externalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelExternalDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalDetailsApi - functional programming interface
 * 外发明细 接口
 * @export
 */
export const ExternalDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExternalDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExternalDetails(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalDetailsApi.deleteExternalDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalDetails(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ExternalDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalDetailsApi.getExternalDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalDetailsDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ExternalDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalDetailsDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalDetailsApi.getExternalDetailsDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ExternalDetailsFormData} [externalDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalDetails(externalDetailsFormData?: ExternalDetailsFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalDetails(externalDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalDetailsApi.postExternalDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ExternalDetailsListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalDetailsPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalDetailsApi.postExternalDetailsPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalDetailsFormData>} [customUpdateModelExternalDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExternalDetails(customUpdateModelExternalDetailsFormData?: CustomUpdateModel<ExternalDetailsFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExternalDetails(customUpdateModelExternalDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalDetailsApi.putExternalDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ExternalDetailsApi - factory interface
 * 外发明细 接口
 * @export
 */
export const ExternalDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalDetailsApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteExternalDetails(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalDetailsFormData>> {
            return localVarFp.getExternalDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalDetailsFormData>> {
            return localVarFp.getExternalDetailsDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ExternalDetailsFormData} [externalDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalDetails(externalDetailsFormData?: ExternalDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postExternalDetails(externalDetailsFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ExternalDetailsListData>>> {
            return localVarFp.postExternalDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ExternalDetailsFormData>} [customUpdateModelExternalDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternalDetails(customUpdateModelExternalDetailsFormData?: CustomUpdateModel<ExternalDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putExternalDetails(customUpdateModelExternalDetailsFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalDetailsApi - interface
 * 外发明细 接口
 * @export
 * @interface ExternalDetailsApi
 */
export interface ExternalDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApiInterface
     */
    deleteExternalDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApiInterface
     */
    getExternalDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalDetailsFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApiInterface
     */
    getExternalDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ExternalDetailsFormData>>;

    /**
     * 新增
     * @param {ExternalDetailsFormData} [externalDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApiInterface
     */
    postExternalDetails(externalDetailsFormData?: ExternalDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApiInterface
     */
    postExternalDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ExternalDetailsListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ExternalDetailsFormData>} [customUpdateModelExternalDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApiInterface
     */
    putExternalDetails(customUpdateModelExternalDetailsFormData?: CustomUpdateModel<ExternalDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ExternalDetailsApi - object-oriented interface
 * 外发明细 接口
 * @export
 * @class ExternalDetailsApi
 * @extends {BaseAPI}
 */
export class ExternalDetailsApi extends BaseAPI implements ExternalDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApi
     */
    public deleteExternalDetails(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ExternalDetailsApiFp(this.configuration).deleteExternalDetails(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApi
     */
    public getExternalDetails(id: number, options?: RawAxiosRequestConfig) {
        return ExternalDetailsApiFp(this.configuration).getExternalDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApi
     */
    public getExternalDetailsDefault(options?: RawAxiosRequestConfig) {
        return ExternalDetailsApiFp(this.configuration).getExternalDetailsDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ExternalDetailsFormData} [externalDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApi
     */
    public postExternalDetails(externalDetailsFormData?: ExternalDetailsFormData, options?: RawAxiosRequestConfig) {
        return ExternalDetailsApiFp(this.configuration).postExternalDetails(externalDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApi
     */
    public postExternalDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ExternalDetailsApiFp(this.configuration).postExternalDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ExternalDetailsFormData>} [customUpdateModelExternalDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalDetailsApi
     */
    public putExternalDetails(customUpdateModelExternalDetailsFormData?: CustomUpdateModel<ExternalDetailsFormData>, options?: RawAxiosRequestConfig) {
        return ExternalDetailsApiFp(this.configuration).putExternalDetails(customUpdateModelExternalDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FilesApi - axios parameter creator
 * 文件管理 接口
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteFiles', 'ids', ids)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 彻底删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilesCompletelyDelete: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteFilesCompletelyDelete', 'ids', ids)
            const localVarPath = `/api/files/completelyDelete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFiles', 'id', id)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 文件下载
         * @param {number} id 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFile: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFilesFile', 'id', id)
            const localVarPath = `/api/files/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取文件下载链接
         * @param {number} id 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFileURL: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFilesFileURL', 'id', id)
            const localVarPath = `/api/files/fileURL`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取文件的 UploadUserFile 对象
         * @param {number} [id] 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesUploadUserFile: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/uploadUserFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取 UploadUserFile 对象列表
         * @param {Array<number>} [ids] 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesUploadUserFileList: async (ids?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/uploadUserFileList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {FilesFormData} [filesFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFiles: async (filesFormData?: FilesFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 文件上传
         * @param {File} file 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesUpload: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postFilesUpload', 'file', file)
            const localVarPath = `/api/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 文件上传并返回 UploadUserFile 对象
         * @param {File} file 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesUploadReturnUploadUserFile: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postFilesUploadReturnUploadUserFile', 'file', file)
            const localVarPath = `/api/files/uploadReturnUploadUserFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<FilesFormData>} [customUpdateModelFilesFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFiles: async (customUpdateModelFilesFormData?: CustomUpdateModel<FilesFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelFilesFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * 文件管理 接口
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFiles(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFiles(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.deleteFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 彻底删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFilesCompletelyDelete(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFilesCompletelyDelete(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.deleteFilesCompletelyDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiles(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<FilesFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.getFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<FilesFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.getFilesDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 文件下载
         * @param {number} id 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesFile(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.getFilesFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取文件下载链接
         * @param {number} id 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesFileURL(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesFileURL(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.getFilesFileURL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取文件的 UploadUserFile 对象
         * @param {number} [id] 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesUploadUserFile(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UploadUserFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesUploadUserFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.getFilesUploadUserFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取 UploadUserFile 对象列表
         * @param {Array<number>} [ids] 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesUploadUserFileList(ids?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UploadUserFile[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesUploadUserFileList(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.getFilesUploadUserFileList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {FilesFormData} [filesFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFiles(filesFormData?: FilesFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFiles(filesFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.postFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFilesPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<FilesListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFilesPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.postFilesPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFilesSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFilesSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.postFilesSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 文件上传
         * @param {File} file 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFilesUpload(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFilesUpload(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.postFilesUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 文件上传并返回 UploadUserFile 对象
         * @param {File} file 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFilesUploadReturnUploadUserFile(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UploadUserFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFilesUploadReturnUploadUserFile(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.postFilesUploadReturnUploadUserFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<FilesFormData>} [customUpdateModelFilesFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFiles(customUpdateModelFilesFormData?: CustomUpdateModel<FilesFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFiles(customUpdateModelFilesFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FilesApi.putFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * FilesApi - factory interface
 * 文件管理 接口
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteFiles(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 彻底删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilesCompletelyDelete(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteFilesCompletelyDelete(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<FilesFormData>> {
            return localVarFp.getFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<FilesFormData>> {
            return localVarFp.getFilesDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 文件下载
         * @param {number} id 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFile(id: number, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getFilesFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取文件下载链接
         * @param {number} id 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFileURL(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.getFilesFileURL(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取文件的 UploadUserFile 对象
         * @param {number} [id] 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesUploadUserFile(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<UploadUserFile>> {
            return localVarFp.getFilesUploadUserFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取 UploadUserFile 对象列表
         * @param {Array<number>} [ids] 文件ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesUploadUserFileList(ids?: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<UploadUserFile[]>> {
            return localVarFp.getFilesUploadUserFileList(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {FilesFormData} [filesFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFiles(filesFormData?: FilesFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postFiles(filesFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<FilesListData>>> {
            return localVarFp.postFilesPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postFilesSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 文件上传
         * @param {File} file 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesUpload(file: File, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postFilesUpload(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 文件上传并返回 UploadUserFile 对象
         * @param {File} file 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFilesUploadReturnUploadUserFile(file: File, options?: RawAxiosRequestConfig): Promise<UniformResult<UploadUserFile>> {
            return localVarFp.postFilesUploadReturnUploadUserFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<FilesFormData>} [customUpdateModelFilesFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFiles(customUpdateModelFilesFormData?: CustomUpdateModel<FilesFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putFiles(customUpdateModelFilesFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - interface
 * 文件管理 接口
 * @export
 * @interface FilesApi
 */
export interface FilesApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    deleteFiles(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 彻底删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    deleteFilesCompletelyDelete(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    getFiles(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<FilesFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    getFilesDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<FilesFormData>>;

    /**
     * 文件下载
     * @param {number} id 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    getFilesFile(id: number, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 获取文件下载链接
     * @param {number} id 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    getFilesFileURL(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 获取文件的 UploadUserFile 对象
     * @param {number} [id] 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    getFilesUploadUserFile(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<UploadUserFile>>;

    /**
     * 获取 UploadUserFile 对象列表
     * @param {Array<number>} [ids] 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    getFilesUploadUserFileList(ids?: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<UploadUserFile[]>>;

    /**
     * 新增
     * @param {FilesFormData} [filesFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    postFiles(filesFormData?: FilesFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    postFilesPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<FilesListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    postFilesSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 文件上传
     * @param {File} file 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    postFilesUpload(file: File, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 文件上传并返回 UploadUserFile 对象
     * @param {File} file 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    postFilesUploadReturnUploadUserFile(file: File, options?: RawAxiosRequestConfig): Promise<UniformResult<UploadUserFile>>;

    /**
     * 修改
     * @param {CustomUpdateModel<FilesFormData>} [customUpdateModelFilesFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    putFiles(customUpdateModelFilesFormData?: CustomUpdateModel<FilesFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * FilesApi - object-oriented interface
 * 文件管理 接口
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI implements FilesApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public deleteFiles(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).deleteFiles(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 彻底删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public deleteFilesCompletelyDelete(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).deleteFilesCompletelyDelete(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFiles(id: number, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFilesDefault(options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFilesDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 文件下载
     * @param {number} id 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFilesFile(id: number, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFilesFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取文件下载链接
     * @param {number} id 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFilesFileURL(id: number, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFilesFileURL(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取文件的 UploadUserFile 对象
     * @param {number} [id] 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFilesUploadUserFile(id?: number, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFilesUploadUserFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取 UploadUserFile 对象列表
     * @param {Array<number>} [ids] 文件ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFilesUploadUserFileList(ids?: Array<number>, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFilesUploadUserFileList(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {FilesFormData} [filesFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public postFiles(filesFormData?: FilesFormData, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).postFiles(filesFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public postFilesPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).postFilesPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public postFilesSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).postFilesSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 文件上传
     * @param {File} file 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public postFilesUpload(file: File, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).postFilesUpload(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 文件上传并返回 UploadUserFile 对象
     * @param {File} file 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public postFilesUploadReturnUploadUserFile(file: File, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).postFilesUploadReturnUploadUserFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<FilesFormData>} [customUpdateModelFilesFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public putFiles(customUpdateModelFilesFormData?: CustomUpdateModel<FilesFormData>, options?: RawAxiosRequestConfig) {
        return FilesApiFp(this.configuration).putFiles(customUpdateModelFilesFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InterfaceApi - axios parameter creator
 * 接口 接口
 * @export
 */
export const InterfaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterface: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteInterface', 'ids', ids)
            const localVarPath = `/api/interface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterface: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInterface', 'id', id)
            const localVarPath = `/api/interface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaceDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaceExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {InterfaceFormData} [interfaceFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterface: async (interfaceFormData?: InterfaceFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfaceFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfaceImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfacePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfaceSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 同步接口
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfaceSync: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<InterfaceFormData>} [customUpdateModelInterfaceFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInterface: async (customUpdateModelInterfaceFormData?: CustomUpdateModel<InterfaceFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelInterfaceFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterfaceApi - functional programming interface
 * 接口 接口
 * @export
 */
export const InterfaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterfaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterface(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterface(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.deleteInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterface(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<InterfaceFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterface(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.getInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterfaceDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<InterfaceFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterfaceDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.getInterfaceDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterfaceExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterfaceExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.getInterfaceExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {InterfaceFormData} [interfaceFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInterface(interfaceFormData?: InterfaceFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInterface(interfaceFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.postInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInterfaceImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInterfaceImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.postInterfaceImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<InterfaceListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInterfacePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.postInterfacePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInterfaceSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInterfaceSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.postInterfaceSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 同步接口
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInterfaceSync(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInterfaceSync(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.postInterfaceSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<InterfaceFormData>} [customUpdateModelInterfaceFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInterface(customUpdateModelInterfaceFormData?: CustomUpdateModel<InterfaceFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInterface(customUpdateModelInterfaceFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceApi.putInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * InterfaceApi - factory interface
 * 接口 接口
 * @export
 */
export const InterfaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterfaceApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterface(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteInterface(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterface(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<InterfaceFormData>> {
            return localVarFp.getInterface(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaceDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<InterfaceFormData>> {
            return localVarFp.getInterfaceDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaceExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getInterfaceExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {InterfaceFormData} [interfaceFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterface(interfaceFormData?: InterfaceFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postInterface(interfaceFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfaceImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postInterfaceImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<InterfaceListData>>> {
            return localVarFp.postInterfacePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfaceSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postInterfaceSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 同步接口
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInterfaceSync(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postInterfaceSync(options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<InterfaceFormData>} [customUpdateModelInterfaceFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInterface(customUpdateModelInterfaceFormData?: CustomUpdateModel<InterfaceFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putInterface(customUpdateModelInterfaceFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterfaceApi - interface
 * 接口 接口
 * @export
 * @interface InterfaceApi
 */
export interface InterfaceApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    deleteInterface(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    getInterface(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<InterfaceFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    getInterfaceDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<InterfaceFormData>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    getInterfaceExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 新增
     * @param {InterfaceFormData} [interfaceFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    postInterface(interfaceFormData?: InterfaceFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    postInterfaceImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    postInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<InterfaceListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    postInterfaceSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 同步接口
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    postInterfaceSync(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 修改
     * @param {CustomUpdateModel<InterfaceFormData>} [customUpdateModelInterfaceFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApiInterface
     */
    putInterface(customUpdateModelInterfaceFormData?: CustomUpdateModel<InterfaceFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * InterfaceApi - object-oriented interface
 * 接口 接口
 * @export
 * @class InterfaceApi
 * @extends {BaseAPI}
 */
export class InterfaceApi extends BaseAPI implements InterfaceApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public deleteInterface(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).deleteInterface(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public getInterface(id: number, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).getInterface(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public getInterfaceDefault(options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).getInterfaceDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public getInterfaceExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).getInterfaceExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {InterfaceFormData} [interfaceFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public postInterface(interfaceFormData?: InterfaceFormData, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).postInterface(interfaceFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public postInterfaceImport(file?: File, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).postInterfaceImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public postInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).postInterfacePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public postInterfaceSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).postInterfaceSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 同步接口
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public postInterfaceSync(options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).postInterfaceSync(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<InterfaceFormData>} [customUpdateModelInterfaceFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceApi
     */
    public putInterface(customUpdateModelInterfaceFormData?: CustomUpdateModel<InterfaceFormData>, options?: RawAxiosRequestConfig) {
        return InterfaceApiFp(this.configuration).putInterface(customUpdateModelInterfaceFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JobDetailApi - axios parameter creator
 * 任务详情 接口
 * @export
 */
export const JobDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobDetail: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteJobDetail', 'ids', ids)
            const localVarPath = `/api/jobDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobDetail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJobDetail', 'id', id)
            const localVarPath = `/api/jobDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobDetailDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取任务集群列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobDetailJobClusterList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/jobClusterList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {JobDetailFormData} [jobDetailFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetail: async (jobDetailFormData?: JobDetailFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobDetailFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 取消作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailCancelJob: async (jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/cancelJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 强制唤醒作业调度器
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailCancelSleep: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/cancelSleep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 暂停所有作业
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPauseAllJob: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/pauseAllJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 暂停作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPauseJob: async (jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/pauseJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 强制触发所有作业持久化
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPersistAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/persistAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 执行作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailRunJob: async (jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/runJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (jobId !== undefined) { 
                localVarFormParams.append('jobId', jobId as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启动所有作业
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailStartAllJob: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/startAllJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启动作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailStartJob: async (jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail/startJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<JobDetailFormData>} [customUpdateModelJobDetailFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJobDetail: async (customUpdateModelJobDetailFormData?: CustomUpdateModel<JobDetailFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelJobDetailFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobDetailApi - functional programming interface
 * 任务详情 接口
 * @export
 */
export const JobDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobDetail(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobDetail(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.deleteJobDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobDetail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<JobDetailFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobDetail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.getJobDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobDetailDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<JobDetailFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobDetailDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.getJobDetailDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取任务集群列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobDetailJobClusterList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<JobCluster[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobDetailJobClusterList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.getJobDetailJobClusterList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {JobDetailFormData} [jobDetailFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetail(jobDetailFormData?: JobDetailFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetail(jobDetailFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 取消作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailCancelJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailCancelJob(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailCancelJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 强制唤醒作业调度器
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailCancelSleep(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailCancelSleep(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailCancelSleep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<JobDetailListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 暂停所有作业
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailPauseAllJob(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailPauseAllJob(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailPauseAllJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 暂停作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailPauseJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailPauseJob(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailPauseJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 强制触发所有作业持久化
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailPersistAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailPersistAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailPersistAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 执行作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailRunJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailRunJob(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailRunJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启动所有作业
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailStartAllJob(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailStartAllJob(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailStartAllJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启动作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobDetailStartJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobDetailStartJob(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.postJobDetailStartJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<JobDetailFormData>} [customUpdateModelJobDetailFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putJobDetail(customUpdateModelJobDetailFormData?: CustomUpdateModel<JobDetailFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putJobDetail(customUpdateModelJobDetailFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobDetailApi.putJobDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * JobDetailApi - factory interface
 * 任务详情 接口
 * @export
 */
export const JobDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobDetailApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobDetail(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteJobDetail(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobDetail(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<JobDetailFormData>> {
            return localVarFp.getJobDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobDetailDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<JobDetailFormData>> {
            return localVarFp.getJobDetailDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取任务集群列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobDetailJobClusterList(options?: RawAxiosRequestConfig): Promise<UniformResult<JobCluster[]>> {
            return localVarFp.getJobDetailJobClusterList(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {JobDetailFormData} [jobDetailFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetail(jobDetailFormData?: JobDetailFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postJobDetail(jobDetailFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 取消作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailCancelJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailCancelJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 强制唤醒作业调度器
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailCancelSleep(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailCancelSleep(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<JobDetailListData>>> {
            return localVarFp.postJobDetailPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 暂停所有作业
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPauseAllJob(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailPauseAllJob(options).then((request) => request(axios, basePath));
        },
        /**
         * 暂停作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPauseJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailPauseJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 强制触发所有作业持久化
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailPersistAll(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailPersistAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 执行作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailRunJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailRunJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 启动所有作业
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailStartAllJob(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailStartAllJob(options).then((request) => request(axios, basePath));
        },
        /**
         * 启动作业
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobDetailStartJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobDetailStartJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<JobDetailFormData>} [customUpdateModelJobDetailFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJobDetail(customUpdateModelJobDetailFormData?: CustomUpdateModel<JobDetailFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putJobDetail(customUpdateModelJobDetailFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobDetailApi - interface
 * 任务详情 接口
 * @export
 * @interface JobDetailApi
 */
export interface JobDetailApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    deleteJobDetail(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    getJobDetail(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<JobDetailFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    getJobDetailDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<JobDetailFormData>>;

    /**
     * 获取任务集群列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    getJobDetailJobClusterList(options?: RawAxiosRequestConfig): Promise<UniformResult<JobCluster[]>>;

    /**
     * 新增
     * @param {JobDetailFormData} [jobDetailFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetail(jobDetailFormData?: JobDetailFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 取消作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailCancelJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 强制唤醒作业调度器
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailCancelSleep(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<JobDetailListData>>>;

    /**
     * 暂停所有作业
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailPauseAllJob(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 暂停作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailPauseJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 强制触发所有作业持久化
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailPersistAll(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 执行作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailRunJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 启动所有作业
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailStartAllJob(options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 启动作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    postJobDetailStartJob(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 修改
     * @param {CustomUpdateModel<JobDetailFormData>} [customUpdateModelJobDetailFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApiInterface
     */
    putJobDetail(customUpdateModelJobDetailFormData?: CustomUpdateModel<JobDetailFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * JobDetailApi - object-oriented interface
 * 任务详情 接口
 * @export
 * @class JobDetailApi
 * @extends {BaseAPI}
 */
export class JobDetailApi extends BaseAPI implements JobDetailApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public deleteJobDetail(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).deleteJobDetail(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public getJobDetail(id: number, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).getJobDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public getJobDetailDefault(options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).getJobDetailDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取任务集群列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public getJobDetailJobClusterList(options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).getJobDetailJobClusterList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {JobDetailFormData} [jobDetailFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetail(jobDetailFormData?: JobDetailFormData, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetail(jobDetailFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 取消作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailCancelJob(jobId?: string, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailCancelJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 强制唤醒作业调度器
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailCancelSleep(options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailCancelSleep(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 暂停所有作业
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailPauseAllJob(options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailPauseAllJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 暂停作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailPauseJob(jobId?: string, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailPauseJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 强制触发所有作业持久化
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailPersistAll(options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailPersistAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 执行作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailRunJob(jobId?: string, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailRunJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启动所有作业
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailStartAllJob(options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailStartAllJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启动作业
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public postJobDetailStartJob(jobId?: string, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).postJobDetailStartJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<JobDetailFormData>} [customUpdateModelJobDetailFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobDetailApi
     */
    public putJobDetail(customUpdateModelJobDetailFormData?: CustomUpdateModel<JobDetailFormData>, options?: RawAxiosRequestConfig) {
        return JobDetailApiFp(this.configuration).putJobDetail(customUpdateModelJobDetailFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JobTriggerApi - axios parameter creator
 * 任务触发器 接口
 * @export
 */
export const JobTriggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobTrigger: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteJobTrigger', 'ids', ids)
            const localVarPath = `/api/jobTrigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobTrigger: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJobTrigger', 'id', id)
            const localVarPath = `/api/jobTrigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobTriggerDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {JobTriggerFormData} [jobTriggerFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTrigger: async (jobTriggerFormData?: JobTriggerFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobTriggerFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取触发器列表
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerJobTriggerList: async (jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger/jobTriggerList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 暂停触发器
         * @param {string} [triggerId] 
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerPauseTrigger: async (triggerId?: string, jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger/pauseTrigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (triggerId !== undefined) {
                localVarQueryParameter['triggerId'] = triggerId;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启动触发器
         * @param {string} [triggerId] 
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerStartTrigger: async (triggerId?: string, jobId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger/startTrigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (triggerId !== undefined) {
                localVarQueryParameter['triggerId'] = triggerId;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<JobTriggerFormData>} [customUpdateModelJobTriggerFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJobTrigger: async (customUpdateModelJobTriggerFormData?: CustomUpdateModel<JobTriggerFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTrigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelJobTriggerFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobTriggerApi - functional programming interface
 * 任务触发器 接口
 * @export
 */
export const JobTriggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobTriggerApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobTrigger(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobTrigger(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.deleteJobTrigger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobTrigger(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<JobTriggerFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobTrigger(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.getJobTrigger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobTriggerDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<JobTriggerFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobTriggerDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.getJobTriggerDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {JobTriggerFormData} [jobTriggerFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobTrigger(jobTriggerFormData?: JobTriggerFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobTrigger(jobTriggerFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.postJobTrigger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取触发器列表
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobTriggerJobTriggerList(jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<JobTriggerListData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobTriggerJobTriggerList(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.postJobTriggerJobTriggerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobTriggerPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<JobTriggerListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobTriggerPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.postJobTriggerPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 暂停触发器
         * @param {string} [triggerId] 
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobTriggerPauseTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobTriggerPauseTrigger(triggerId, jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.postJobTriggerPauseTrigger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启动触发器
         * @param {string} [triggerId] 
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobTriggerStartTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobTriggerStartTrigger(triggerId, jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.postJobTriggerStartTrigger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<JobTriggerFormData>} [customUpdateModelJobTriggerFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putJobTrigger(customUpdateModelJobTriggerFormData?: CustomUpdateModel<JobTriggerFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putJobTrigger(customUpdateModelJobTriggerFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerApi.putJobTrigger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * JobTriggerApi - factory interface
 * 任务触发器 接口
 * @export
 */
export const JobTriggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobTriggerApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobTrigger(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteJobTrigger(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobTrigger(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<JobTriggerFormData>> {
            return localVarFp.getJobTrigger(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobTriggerDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<JobTriggerFormData>> {
            return localVarFp.getJobTriggerDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {JobTriggerFormData} [jobTriggerFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTrigger(jobTriggerFormData?: JobTriggerFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postJobTrigger(jobTriggerFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取触发器列表
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerJobTriggerList(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<JobTriggerListData[]>> {
            return localVarFp.postJobTriggerJobTriggerList(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<JobTriggerListData>>> {
            return localVarFp.postJobTriggerPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 暂停触发器
         * @param {string} [triggerId] 
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerPauseTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobTriggerPauseTrigger(triggerId, jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 启动触发器
         * @param {string} [triggerId] 
         * @param {string} [jobId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerStartTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postJobTriggerStartTrigger(triggerId, jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<JobTriggerFormData>} [customUpdateModelJobTriggerFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJobTrigger(customUpdateModelJobTriggerFormData?: CustomUpdateModel<JobTriggerFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putJobTrigger(customUpdateModelJobTriggerFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobTriggerApi - interface
 * 任务触发器 接口
 * @export
 * @interface JobTriggerApi
 */
export interface JobTriggerApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    deleteJobTrigger(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    getJobTrigger(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<JobTriggerFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    getJobTriggerDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<JobTriggerFormData>>;

    /**
     * 新增
     * @param {JobTriggerFormData} [jobTriggerFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    postJobTrigger(jobTriggerFormData?: JobTriggerFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取触发器列表
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    postJobTriggerJobTriggerList(jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<JobTriggerListData[]>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    postJobTriggerPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<JobTriggerListData>>>;

    /**
     * 暂停触发器
     * @param {string} [triggerId] 
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    postJobTriggerPauseTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 启动触发器
     * @param {string} [triggerId] 
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    postJobTriggerStartTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 修改
     * @param {CustomUpdateModel<JobTriggerFormData>} [customUpdateModelJobTriggerFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApiInterface
     */
    putJobTrigger(customUpdateModelJobTriggerFormData?: CustomUpdateModel<JobTriggerFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * JobTriggerApi - object-oriented interface
 * 任务触发器 接口
 * @export
 * @class JobTriggerApi
 * @extends {BaseAPI}
 */
export class JobTriggerApi extends BaseAPI implements JobTriggerApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public deleteJobTrigger(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).deleteJobTrigger(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public getJobTrigger(id: number, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).getJobTrigger(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public getJobTriggerDefault(options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).getJobTriggerDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {JobTriggerFormData} [jobTriggerFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public postJobTrigger(jobTriggerFormData?: JobTriggerFormData, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).postJobTrigger(jobTriggerFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取触发器列表
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public postJobTriggerJobTriggerList(jobId?: string, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).postJobTriggerJobTriggerList(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public postJobTriggerPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).postJobTriggerPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 暂停触发器
     * @param {string} [triggerId] 
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public postJobTriggerPauseTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).postJobTriggerPauseTrigger(triggerId, jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启动触发器
     * @param {string} [triggerId] 
     * @param {string} [jobId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public postJobTriggerStartTrigger(triggerId?: string, jobId?: string, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).postJobTriggerStartTrigger(triggerId, jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<JobTriggerFormData>} [customUpdateModelJobTriggerFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerApi
     */
    public putJobTrigger(customUpdateModelJobTriggerFormData?: CustomUpdateModel<JobTriggerFormData>, options?: RawAxiosRequestConfig) {
        return JobTriggerApiFp(this.configuration).putJobTrigger(customUpdateModelJobTriggerFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JobTriggerRecordApi - axios parameter creator
 * 任务触发器运行记录 接口
 * @export
 */
export const JobTriggerRecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerRecordPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobTriggerRecord/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobTriggerRecordApi - functional programming interface
 * 任务触发器运行记录 接口
 * @export
 */
export const JobTriggerRecordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobTriggerRecordApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJobTriggerRecordPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<JobTriggerRecord>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJobTriggerRecordPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobTriggerRecordApi.postJobTriggerRecordPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * JobTriggerRecordApi - factory interface
 * 任务触发器运行记录 接口
 * @export
 */
export const JobTriggerRecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobTriggerRecordApiFp(configuration)
    return {
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobTriggerRecordPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<JobTriggerRecord>>> {
            return localVarFp.postJobTriggerRecordPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobTriggerRecordApi - interface
 * 任务触发器运行记录 接口
 * @export
 * @interface JobTriggerRecordApi
 */
export interface JobTriggerRecordApiInterface {
    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerRecordApiInterface
     */
    postJobTriggerRecordPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<JobTriggerRecord>>>;

}

/**
 * JobTriggerRecordApi - object-oriented interface
 * 任务触发器运行记录 接口
 * @export
 * @class JobTriggerRecordApi
 * @extends {BaseAPI}
 */
export class JobTriggerRecordApi extends BaseAPI implements JobTriggerRecordApiInterface {
    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTriggerRecordApi
     */
    public postJobTriggerRecordPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return JobTriggerRecordApiFp(this.configuration).postJobTriggerRecordPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogDiffSourceApi - axios parameter creator
 * 差异日志源
 * @export
 */
export const LogDiffSourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogDiffSourcePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logDiffSource/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogDiffSourceApi - functional programming interface
 * 差异日志源
 * @export
 */
export const LogDiffSourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogDiffSourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogDiffSourcePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<LogDiffSource>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogDiffSourcePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogDiffSourceApi.postLogDiffSourcePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * LogDiffSourceApi - factory interface
 * 差异日志源
 * @export
 */
export const LogDiffSourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogDiffSourceApiFp(configuration)
    return {
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogDiffSourcePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogDiffSource>>> {
            return localVarFp.postLogDiffSourcePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogDiffSourceApi - interface
 * 差异日志源
 * @export
 * @interface LogDiffSourceApi
 */
export interface LogDiffSourceApiInterface {
    /**
     * 获取访问日志分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogDiffSourceApiInterface
     */
    postLogDiffSourcePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogDiffSource>>>;

}

/**
 * LogDiffSourceApi - object-oriented interface
 * 差异日志源
 * @export
 * @class LogDiffSourceApi
 * @extends {BaseAPI}
 */
export class LogDiffSourceApi extends BaseAPI implements LogDiffSourceApiInterface {
    /**
     * 获取访问日志分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogDiffSourceApi
     */
    public postLogDiffSourcePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return LogDiffSourceApiFp(this.configuration).postLogDiffSourcePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogExceptionApi - axios parameter creator
 * 异常日志
 * @export
 */
export const LogExceptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取日志原始消息
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogExceptionFullMessage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogExceptionFullMessage', 'id', id)
            const localVarPath = `/api/logException/fullMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogExceptionPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logException/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogExceptionApi - functional programming interface
 * 异常日志
 * @export
 */
export const LogExceptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogExceptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取日志原始消息
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogExceptionFullMessage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogExceptionFullMessage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogExceptionApi.getLogExceptionFullMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogExceptionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<LogExOutput>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogExceptionPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogExceptionApi.postLogExceptionPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * LogExceptionApi - factory interface
 * 异常日志
 * @export
 */
export const LogExceptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogExceptionApiFp(configuration)
    return {
        /**
         * 获取日志原始消息
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogExceptionFullMessage(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.getLogExceptionFullMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogExceptionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogExOutput>>> {
            return localVarFp.postLogExceptionPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogExceptionApi - interface
 * 异常日志
 * @export
 * @interface LogExceptionApi
 */
export interface LogExceptionApiInterface {
    /**
     * 获取日志原始消息
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExceptionApiInterface
     */
    getLogExceptionFullMessage(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 获取访问日志分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExceptionApiInterface
     */
    postLogExceptionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogExOutput>>>;

}

/**
 * LogExceptionApi - object-oriented interface
 * 异常日志
 * @export
 * @class LogExceptionApi
 * @extends {BaseAPI}
 */
export class LogExceptionApi extends BaseAPI implements LogExceptionApiInterface {
    /**
     * 获取日志原始消息
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExceptionApi
     */
    public getLogExceptionFullMessage(id: number, options?: RawAxiosRequestConfig) {
        return LogExceptionApiFp(this.configuration).getLogExceptionFullMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取访问日志分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogExceptionApi
     */
    public postLogExceptionPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return LogExceptionApiFp(this.configuration).postLogExceptionPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogInterfaceApi - axios parameter creator
 * 接口日志
 * @export
 */
export const LogInterfaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取日志原始消息
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogInterfaceFullMessage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogInterfaceFullMessage', 'id', id)
            const localVarPath = `/api/logInterface/fullMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogInterfacePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logInterface/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogInterfaceApi - functional programming interface
 * 接口日志
 * @export
 */
export const LogInterfaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogInterfaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取日志原始消息
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogInterfaceFullMessage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogInterfaceFullMessage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogInterfaceApi.getLogInterfaceFullMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<LogOutput>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogInterfacePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogInterfaceApi.postLogInterfacePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * LogInterfaceApi - factory interface
 * 接口日志
 * @export
 */
export const LogInterfaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogInterfaceApiFp(configuration)
    return {
        /**
         * 获取日志原始消息
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogInterfaceFullMessage(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.getLogInterfaceFullMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取访问日志分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogOutput>>> {
            return localVarFp.postLogInterfacePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogInterfaceApi - interface
 * 接口日志
 * @export
 * @interface LogInterfaceApi
 */
export interface LogInterfaceApiInterface {
    /**
     * 获取日志原始消息
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogInterfaceApiInterface
     */
    getLogInterfaceFullMessage(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 获取访问日志分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogInterfaceApiInterface
     */
    postLogInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogOutput>>>;

}

/**
 * LogInterfaceApi - object-oriented interface
 * 接口日志
 * @export
 * @class LogInterfaceApi
 * @extends {BaseAPI}
 */
export class LogInterfaceApi extends BaseAPI implements LogInterfaceApiInterface {
    /**
     * 获取日志原始消息
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogInterfaceApi
     */
    public getLogInterfaceFullMessage(id: number, options?: RawAxiosRequestConfig) {
        return LogInterfaceApiFp(this.configuration).getLogInterfaceFullMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取访问日志分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogInterfaceApi
     */
    public postLogInterfacePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return LogInterfaceApiFp(this.configuration).postLogInterfacePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogisticsCompanyApi - axios parameter creator
 * 承运商 接口
 * @export
 */
export const LogisticsCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogisticsCompany: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteLogisticsCompany', 'ids', ids)
            const localVarPath = `/api/logisticsCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogisticsCompany: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogisticsCompany', 'id', id)
            const localVarPath = `/api/logisticsCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogisticsCompanyDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logisticsCompany/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {LogisticsCompanyFormData} [logisticsCompanyFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogisticsCompany: async (logisticsCompanyFormData?: LogisticsCompanyFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logisticsCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logisticsCompanyFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogisticsCompanyPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logisticsCompany/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogisticsCompanySetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logisticsCompany/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<LogisticsCompanyFormData>} [customUpdateModelLogisticsCompanyFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLogisticsCompany: async (customUpdateModelLogisticsCompanyFormData?: CustomUpdateModel<LogisticsCompanyFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logisticsCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelLogisticsCompanyFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogisticsCompanyApi - functional programming interface
 * 承运商 接口
 * @export
 */
export const LogisticsCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogisticsCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLogisticsCompany(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLogisticsCompany(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.deleteLogisticsCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogisticsCompany(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<LogisticsCompanyFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogisticsCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.getLogisticsCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogisticsCompanyDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<LogisticsCompanyFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogisticsCompanyDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.getLogisticsCompanyDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {LogisticsCompanyFormData} [logisticsCompanyFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogisticsCompany(logisticsCompanyFormData?: LogisticsCompanyFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogisticsCompany(logisticsCompanyFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.postLogisticsCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogisticsCompanyPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<LogisticsCompanyListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogisticsCompanyPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.postLogisticsCompanyPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogisticsCompanySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogisticsCompanySetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.postLogisticsCompanySetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<LogisticsCompanyFormData>} [customUpdateModelLogisticsCompanyFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLogisticsCompany(customUpdateModelLogisticsCompanyFormData?: CustomUpdateModel<LogisticsCompanyFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLogisticsCompany(customUpdateModelLogisticsCompanyFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsCompanyApi.putLogisticsCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * LogisticsCompanyApi - factory interface
 * 承运商 接口
 * @export
 */
export const LogisticsCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogisticsCompanyApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogisticsCompany(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteLogisticsCompany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogisticsCompany(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<LogisticsCompanyFormData>> {
            return localVarFp.getLogisticsCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogisticsCompanyDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<LogisticsCompanyFormData>> {
            return localVarFp.getLogisticsCompanyDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {LogisticsCompanyFormData} [logisticsCompanyFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogisticsCompany(logisticsCompanyFormData?: LogisticsCompanyFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postLogisticsCompany(logisticsCompanyFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogisticsCompanyPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogisticsCompanyListData>>> {
            return localVarFp.postLogisticsCompanyPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogisticsCompanySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postLogisticsCompanySetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<LogisticsCompanyFormData>} [customUpdateModelLogisticsCompanyFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLogisticsCompany(customUpdateModelLogisticsCompanyFormData?: CustomUpdateModel<LogisticsCompanyFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putLogisticsCompany(customUpdateModelLogisticsCompanyFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogisticsCompanyApi - interface
 * 承运商 接口
 * @export
 * @interface LogisticsCompanyApi
 */
export interface LogisticsCompanyApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    deleteLogisticsCompany(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    getLogisticsCompany(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<LogisticsCompanyFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    getLogisticsCompanyDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<LogisticsCompanyFormData>>;

    /**
     * 新增
     * @param {LogisticsCompanyFormData} [logisticsCompanyFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    postLogisticsCompany(logisticsCompanyFormData?: LogisticsCompanyFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    postLogisticsCompanyPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<LogisticsCompanyListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    postLogisticsCompanySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<LogisticsCompanyFormData>} [customUpdateModelLogisticsCompanyFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApiInterface
     */
    putLogisticsCompany(customUpdateModelLogisticsCompanyFormData?: CustomUpdateModel<LogisticsCompanyFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * LogisticsCompanyApi - object-oriented interface
 * 承运商 接口
 * @export
 * @class LogisticsCompanyApi
 * @extends {BaseAPI}
 */
export class LogisticsCompanyApi extends BaseAPI implements LogisticsCompanyApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public deleteLogisticsCompany(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).deleteLogisticsCompany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public getLogisticsCompany(id: number, options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).getLogisticsCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public getLogisticsCompanyDefault(options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).getLogisticsCompanyDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {LogisticsCompanyFormData} [logisticsCompanyFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public postLogisticsCompany(logisticsCompanyFormData?: LogisticsCompanyFormData, options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).postLogisticsCompany(logisticsCompanyFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public postLogisticsCompanyPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).postLogisticsCompanyPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public postLogisticsCompanySetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).postLogisticsCompanySetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<LogisticsCompanyFormData>} [customUpdateModelLogisticsCompanyFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsCompanyApi
     */
    public putLogisticsCompany(customUpdateModelLogisticsCompanyFormData?: CustomUpdateModel<LogisticsCompanyFormData>, options?: RawAxiosRequestConfig) {
        return LogisticsCompanyApiFp(this.configuration).putLogisticsCompany(customUpdateModelLogisticsCompanyFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MenuApi - axios parameter creator
 * 菜单服务接口
 * @export
 */
export const MenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenu: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteMenu', 'ids', ids)
            const localVarPath = `/api/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMenu', 'id', id)
            const localVarPath = `/api/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取所有菜单按钮树(完整，包含未启用的)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuTreeFunc: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/treeFunc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取所有菜单按钮树(精简)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuTreeFuncLite: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/treeFuncLite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取所有菜单树(精简)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuTreeLite: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/treeLite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取用户菜单按钮权限树列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuUserAllMenuFuncTree: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/userAllMenuFuncTree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取用户菜单树
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuUserMenuTree: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/userMenuTree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {MenuFormData} [menuFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenu: async (menuFormData?: MenuFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 配置菜单功能按钮的接口权限
         * @param {number} menuId 
         * @param {Array<number>} [requestBody] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenuInterfaceIdList: async (menuId: number, requestBody?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuId' is not null or undefined
            assertParamExists('postMenuInterfaceIdList', 'menuId', menuId)
            const localVarPath = `/api/menu/interfaceIdList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenuPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenuSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<MenuFormData>} [customUpdateModelMenuFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMenu: async (customUpdateModelMenuFormData?: CustomUpdateModel<MenuFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelMenuFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuApi - functional programming interface
 * 菜单服务接口
 * @export
 */
export const MenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMenu(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMenu(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.deleteMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenu(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<MenuFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenu(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<MenuFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenuDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取所有菜单按钮树(完整，包含未启用的)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuTreeFunc(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Menu[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuTreeFunc(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenuTreeFunc']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取所有菜单按钮树(精简)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuTreeFuncLite(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueTree[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuTreeFuncLite(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenuTreeFuncLite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取所有菜单树(精简)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuTreeLite(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueTree[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuTreeLite(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenuTreeLite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取用户菜单按钮权限树列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuUserAllMenuFuncTree(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueTree[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuUserAllMenuFuncTree(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenuUserAllMenuFuncTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取用户菜单树
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuUserMenuTree(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UserMenuTree[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuUserMenuTree(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.getMenuUserMenuTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {MenuFormData} [menuFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMenu(menuFormData?: MenuFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMenu(menuFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.postMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 配置菜单功能按钮的接口权限
         * @param {number} menuId 
         * @param {Array<number>} [requestBody] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMenuInterfaceIdList(menuId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMenuInterfaceIdList(menuId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.postMenuInterfaceIdList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMenuPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<MenuListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMenuPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.postMenuPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMenuSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMenuSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.postMenuSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<MenuFormData>} [customUpdateModelMenuFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMenu(customUpdateModelMenuFormData?: CustomUpdateModel<MenuFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMenu(customUpdateModelMenuFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MenuApi.putMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * MenuApi - factory interface
 * 菜单服务接口
 * @export
 */
export const MenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenu(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteMenu(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<MenuFormData>> {
            return localVarFp.getMenu(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<MenuFormData>> {
            return localVarFp.getMenuDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取所有菜单按钮树(完整，包含未启用的)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuTreeFunc(options?: RawAxiosRequestConfig): Promise<UniformResult<Menu[]>> {
            return localVarFp.getMenuTreeFunc(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取所有菜单按钮树(精简)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuTreeFuncLite(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>> {
            return localVarFp.getMenuTreeFuncLite(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取所有菜单树(精简)
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuTreeLite(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>> {
            return localVarFp.getMenuTreeLite(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取用户菜单按钮权限树列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuUserAllMenuFuncTree(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>> {
            return localVarFp.getMenuUserAllMenuFuncTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取用户菜单树
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuUserMenuTree(options?: RawAxiosRequestConfig): Promise<UniformResult<UserMenuTree[]>> {
            return localVarFp.getMenuUserMenuTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {MenuFormData} [menuFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenu(menuFormData?: MenuFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postMenu(menuFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 配置菜单功能按钮的接口权限
         * @param {number} menuId 
         * @param {Array<number>} [requestBody] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenuInterfaceIdList(menuId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postMenuInterfaceIdList(menuId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenuPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<MenuListData>>> {
            return localVarFp.postMenuPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMenuSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postMenuSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<MenuFormData>} [customUpdateModelMenuFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMenu(customUpdateModelMenuFormData?: CustomUpdateModel<MenuFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putMenu(customUpdateModelMenuFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuApi - interface
 * 菜单服务接口
 * @export
 * @interface MenuApi
 */
export interface MenuApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    deleteMenu(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenu(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<MenuFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenuDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<MenuFormData>>;

    /**
     * 获取所有菜单按钮树(完整，包含未启用的)
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenuTreeFunc(options?: RawAxiosRequestConfig): Promise<UniformResult<Menu[]>>;

    /**
     * 获取所有菜单按钮树(精简)
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenuTreeFuncLite(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>>;

    /**
     * 获取所有菜单树(精简)
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenuTreeLite(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>>;

    /**
     * 获取用户菜单按钮权限树列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenuUserAllMenuFuncTree(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>>;

    /**
     * 获取用户菜单树
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    getMenuUserMenuTree(options?: RawAxiosRequestConfig): Promise<UniformResult<UserMenuTree[]>>;

    /**
     * 新增
     * @param {MenuFormData} [menuFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    postMenu(menuFormData?: MenuFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 配置菜单功能按钮的接口权限
     * @param {number} menuId 
     * @param {Array<number>} [requestBody] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    postMenuInterfaceIdList(menuId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    postMenuPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<MenuListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    postMenuSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<MenuFormData>} [customUpdateModelMenuFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApiInterface
     */
    putMenu(customUpdateModelMenuFormData?: CustomUpdateModel<MenuFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * MenuApi - object-oriented interface
 * 菜单服务接口
 * @export
 * @class MenuApi
 * @extends {BaseAPI}
 */
export class MenuApi extends BaseAPI implements MenuApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public deleteMenu(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).deleteMenu(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenu(id: number, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenu(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenuDefault(options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenuDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取所有菜单按钮树(完整，包含未启用的)
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenuTreeFunc(options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenuTreeFunc(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取所有菜单按钮树(精简)
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenuTreeFuncLite(options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenuTreeFuncLite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取所有菜单树(精简)
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenuTreeLite(options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenuTreeLite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取用户菜单按钮权限树列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenuUserAllMenuFuncTree(options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenuUserAllMenuFuncTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取用户菜单树
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public getMenuUserMenuTree(options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).getMenuUserMenuTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {MenuFormData} [menuFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public postMenu(menuFormData?: MenuFormData, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).postMenu(menuFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 配置菜单功能按钮的接口权限
     * @param {number} menuId 
     * @param {Array<number>} [requestBody] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public postMenuInterfaceIdList(menuId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).postMenuInterfaceIdList(menuId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public postMenuPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).postMenuPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public postMenuSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).postMenuSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<MenuFormData>} [customUpdateModelMenuFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public putMenu(customUpdateModelMenuFormData?: CustomUpdateModel<MenuFormData>, options?: RawAxiosRequestConfig) {
        return MenuApiFp(this.configuration).putMenu(customUpdateModelMenuFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModuleFieldGenApi - axios parameter creator
 * 模块字段配置接口
 * @export
 */
export const ModuleFieldGenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取表的字段名称和注释列表
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenFieldDisplayList: async (name: string, configId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModuleFieldGenFieldDisplayList', 'name', name)
            const localVarPath = `/api/moduleFieldGen/fieldDisplayList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取表的注释
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableComment: async (name: string, configId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModuleFieldGenTableComment', 'name', name)
            const localVarPath = `/api/moduleFieldGen/tableComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取表的自增字段
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableIdentityList: async (name: string, configId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModuleFieldGenTableIdentityList', 'name', name)
            const localVarPath = `/api/moduleFieldGen/tableIdentityList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取表的主键
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableKeyList: async (name: string, configId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModuleFieldGenTableKeyList', 'name', name)
            const localVarPath = `/api/moduleFieldGen/tableKeyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取数据库的所有表名称
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableNames: async (configId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleFieldGen/tableNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取数据库的全部表名称和注释
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableNamesAndComments: async (configId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleFieldGen/tableNamesAndComments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增或更新表的控件配置模板
         * @param {Array<ModuleFieldGenFormData>} [moduleFieldGenFormData] 配置列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleFieldGenAddOrUpdateFieldGenConfigs: async (moduleFieldGenFormData?: Array<ModuleFieldGenFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleFieldGen/AddOrUpdateFieldGenConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleFieldGenFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleFieldGenApi - functional programming interface
 * 模块字段配置接口
 * @export
 */
export const ModuleFieldGenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleFieldGenApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取表的字段名称和注释列表
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleFieldGenFieldDisplayList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Record<string, string>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleFieldGenFieldDisplayList(name, configId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.getModuleFieldGenFieldDisplayList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取表的注释
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleFieldGenTableComment(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleFieldGenTableComment(name, configId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.getModuleFieldGenTableComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取表的自增字段
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleFieldGenTableIdentityList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleFieldGenTableIdentityList(name, configId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.getModuleFieldGenTableIdentityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取表的主键
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleFieldGenTableKeyList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleFieldGenTableKeyList(name, configId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.getModuleFieldGenTableKeyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取数据库的所有表名称
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleFieldGenTableNames(configId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleFieldGenTableNames(configId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.getModuleFieldGenTableNames']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取数据库的全部表名称和注释
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleFieldGenTableNamesAndComments(configId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Record<string, string>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleFieldGenTableNamesAndComments(configId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.getModuleFieldGenTableNamesAndComments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增或更新表的控件配置模板
         * @param {Array<ModuleFieldGenFormData>} [moduleFieldGenFormData] 配置列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData?: Array<ModuleFieldGenFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleFieldGenApi.postModuleFieldGenAddOrUpdateFieldGenConfigs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ModuleFieldGenApi - factory interface
 * 模块字段配置接口
 * @export
 */
export const ModuleFieldGenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleFieldGenApiFp(configuration)
    return {
        /**
         * 获取表的字段名称和注释列表
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenFieldDisplayList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Record<string, string>>> {
            return localVarFp.getModuleFieldGenFieldDisplayList(name, configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取表的注释
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableComment(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.getModuleFieldGenTableComment(name, configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取表的自增字段
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableIdentityList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>> {
            return localVarFp.getModuleFieldGenTableIdentityList(name, configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取表的主键
         * @param {string} name 表名
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableKeyList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>> {
            return localVarFp.getModuleFieldGenTableKeyList(name, configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取数据库的所有表名称
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableNames(configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>> {
            return localVarFp.getModuleFieldGenTableNames(configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取数据库的全部表名称和注释
         * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleFieldGenTableNamesAndComments(configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Record<string, string>>> {
            return localVarFp.getModuleFieldGenTableNamesAndComments(configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增或更新表的控件配置模板
         * @param {Array<ModuleFieldGenFormData>} [moduleFieldGenFormData] 配置列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData?: Array<ModuleFieldGenFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleFieldGenApi - interface
 * 模块字段配置接口
 * @export
 * @interface ModuleFieldGenApi
 */
export interface ModuleFieldGenApiInterface {
    /**
     * 获取表的字段名称和注释列表
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    getModuleFieldGenFieldDisplayList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Record<string, string>>>;

    /**
     * 获取表的注释
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    getModuleFieldGenTableComment(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 获取表的自增字段
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    getModuleFieldGenTableIdentityList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>>;

    /**
     * 获取表的主键
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    getModuleFieldGenTableKeyList(name: string, configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>>;

    /**
     * 获取数据库的所有表名称
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    getModuleFieldGenTableNames(configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<string[]>>;

    /**
     * 获取数据库的全部表名称和注释
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    getModuleFieldGenTableNamesAndComments(configId?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<Record<string, string>>>;

    /**
     * 新增或更新表的控件配置模板
     * @param {Array<ModuleFieldGenFormData>} [moduleFieldGenFormData] 配置列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApiInterface
     */
    postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData?: Array<ModuleFieldGenFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

}

/**
 * ModuleFieldGenApi - object-oriented interface
 * 模块字段配置接口
 * @export
 * @class ModuleFieldGenApi
 * @extends {BaseAPI}
 */
export class ModuleFieldGenApi extends BaseAPI implements ModuleFieldGenApiInterface {
    /**
     * 获取表的字段名称和注释列表
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public getModuleFieldGenFieldDisplayList(name: string, configId?: string, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).getModuleFieldGenFieldDisplayList(name, configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取表的注释
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public getModuleFieldGenTableComment(name: string, configId?: string, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).getModuleFieldGenTableComment(name, configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取表的自增字段
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public getModuleFieldGenTableIdentityList(name: string, configId?: string, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).getModuleFieldGenTableIdentityList(name, configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取表的主键
     * @param {string} name 表名
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public getModuleFieldGenTableKeyList(name: string, configId?: string, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).getModuleFieldGenTableKeyList(name, configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取数据库的所有表名称
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public getModuleFieldGenTableNames(configId?: string, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).getModuleFieldGenTableNames(configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取数据库的全部表名称和注释
     * @param {string} [configId] 库定位Id，默认为空，取当前表的所属库
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public getModuleFieldGenTableNamesAndComments(configId?: string, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).getModuleFieldGenTableNamesAndComments(configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增或更新表的控件配置模板
     * @param {Array<ModuleFieldGenFormData>} [moduleFieldGenFormData] 配置列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleFieldGenApi
     */
    public postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData?: Array<ModuleFieldGenFormData>, options?: RawAxiosRequestConfig) {
        return ModuleFieldGenApiFp(this.configuration).postModuleFieldGenAddOrUpdateFieldGenConfigs(moduleFieldGenFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModuleGenApi - axios parameter creator
 * 模块生成接口
 * @export
 */
export const ModuleGenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModuleGen: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteModuleGen', 'ids', ids)
            const localVarPath = `/api/moduleGen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGen: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getModuleGen', 'id', id)
            const localVarPath = `/api/moduleGen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGenDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleGen/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取表的控件配置模版
         * @param {number} moduleId 模块ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGenFieldGenConfigs: async (moduleId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('getModuleGenFieldGenConfigs', 'moduleId', moduleId)
            const localVarPath = `/api/moduleGen/fieldGenConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (moduleId !== undefined) {
                localVarQueryParameter['moduleId'] = moduleId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取模块选择列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGenModuleSelectList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleGen/moduleSelectList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ModuleGenFormData} [moduleGenFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGen: async (moduleGenFormData?: ModuleGenFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleGen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleGenFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生成
         * @param {number} id 模块配置主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenGenerate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postModuleGenGenerate', 'id', id)
            const localVarPath = `/api/moduleGen/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生成菜单
         * @param {number} moduleId 模块ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenGenerateMenu: async (moduleId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('postModuleGenGenerateMenu', 'moduleId', moduleId)
            const localVarPath = `/api/moduleGen/generateMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (moduleId !== undefined) {
                localVarQueryParameter['moduleId'] = moduleId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleGen/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleGen/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ModuleGenFormData>} [customUpdateModelModuleGenFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putModuleGen: async (customUpdateModelModuleGenFormData?: CustomUpdateModel<ModuleGenFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduleGen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelModuleGenFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleGenApi - functional programming interface
 * 模块生成接口
 * @export
 */
export const ModuleGenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleGenApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModuleGen(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModuleGen(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.deleteModuleGen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleGen(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ModuleGenFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleGen(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.getModuleGen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleGenDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ModuleGenFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleGenDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.getModuleGenDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取表的控件配置模版
         * @param {number} moduleId 模块ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleGenFieldGenConfigs(moduleId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ModuleFieldGenFormData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleGenFieldGenConfigs(moduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.getModuleGenFieldGenConfigs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取模块选择列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleGenModuleSelectList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ModuleSelectData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleGenModuleSelectList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.getModuleGenModuleSelectList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ModuleGenFormData} [moduleGenFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModuleGen(moduleGenFormData?: ModuleGenFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModuleGen(moduleGenFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.postModuleGen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 生成
         * @param {number} id 模块配置主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModuleGenGenerate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModuleGenGenerate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.postModuleGenGenerate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 生成菜单
         * @param {number} moduleId 模块ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModuleGenGenerateMenu(moduleId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModuleGenGenerateMenu(moduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.postModuleGenGenerateMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModuleGenPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ModuleGenFormData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModuleGenPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.postModuleGenPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModuleGenSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModuleGenSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.postModuleGenSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ModuleGenFormData>} [customUpdateModelModuleGenFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putModuleGen(customUpdateModelModuleGenFormData?: CustomUpdateModel<ModuleGenFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putModuleGen(customUpdateModelModuleGenFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleGenApi.putModuleGen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ModuleGenApi - factory interface
 * 模块生成接口
 * @export
 */
export const ModuleGenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleGenApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModuleGen(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteModuleGen(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGen(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleGenFormData>> {
            return localVarFp.getModuleGen(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGenDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleGenFormData>> {
            return localVarFp.getModuleGenDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取表的控件配置模版
         * @param {number} moduleId 模块ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGenFieldGenConfigs(moduleId: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleFieldGenFormData[]>> {
            return localVarFp.getModuleGenFieldGenConfigs(moduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取模块选择列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleGenModuleSelectList(options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleSelectData[]>> {
            return localVarFp.getModuleGenModuleSelectList(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ModuleGenFormData} [moduleGenFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGen(moduleGenFormData?: ModuleGenFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postModuleGen(moduleGenFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 生成
         * @param {number} id 模块配置主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenGenerate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postModuleGenGenerate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 生成菜单
         * @param {number} moduleId 模块ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenGenerateMenu(moduleId: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postModuleGenGenerateMenu(moduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ModuleGenFormData>>> {
            return localVarFp.postModuleGenPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModuleGenSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postModuleGenSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ModuleGenFormData>} [customUpdateModelModuleGenFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putModuleGen(customUpdateModelModuleGenFormData?: CustomUpdateModel<ModuleGenFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putModuleGen(customUpdateModelModuleGenFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleGenApi - interface
 * 模块生成接口
 * @export
 * @interface ModuleGenApi
 */
export interface ModuleGenApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    deleteModuleGen(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    getModuleGen(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleGenFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    getModuleGenDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleGenFormData>>;

    /**
     * 获取表的控件配置模版
     * @param {number} moduleId 模块ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    getModuleGenFieldGenConfigs(moduleId: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleFieldGenFormData[]>>;

    /**
     * 获取模块选择列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    getModuleGenModuleSelectList(options?: RawAxiosRequestConfig): Promise<UniformResult<ModuleSelectData[]>>;

    /**
     * 新增
     * @param {ModuleGenFormData} [moduleGenFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    postModuleGen(moduleGenFormData?: ModuleGenFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 生成
     * @param {number} id 模块配置主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    postModuleGenGenerate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 生成菜单
     * @param {number} moduleId 模块ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    postModuleGenGenerateMenu(moduleId: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    postModuleGenPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ModuleGenFormData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    postModuleGenSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ModuleGenFormData>} [customUpdateModelModuleGenFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApiInterface
     */
    putModuleGen(customUpdateModelModuleGenFormData?: CustomUpdateModel<ModuleGenFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ModuleGenApi - object-oriented interface
 * 模块生成接口
 * @export
 * @class ModuleGenApi
 * @extends {BaseAPI}
 */
export class ModuleGenApi extends BaseAPI implements ModuleGenApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public deleteModuleGen(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).deleteModuleGen(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public getModuleGen(id: number, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).getModuleGen(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public getModuleGenDefault(options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).getModuleGenDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取表的控件配置模版
     * @param {number} moduleId 模块ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public getModuleGenFieldGenConfigs(moduleId: number, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).getModuleGenFieldGenConfigs(moduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取模块选择列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public getModuleGenModuleSelectList(options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).getModuleGenModuleSelectList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ModuleGenFormData} [moduleGenFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public postModuleGen(moduleGenFormData?: ModuleGenFormData, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).postModuleGen(moduleGenFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生成
     * @param {number} id 模块配置主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public postModuleGenGenerate(id: number, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).postModuleGenGenerate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生成菜单
     * @param {number} moduleId 模块ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public postModuleGenGenerateMenu(moduleId: number, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).postModuleGenGenerateMenu(moduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public postModuleGenPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).postModuleGenPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public postModuleGenSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).postModuleGenSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ModuleGenFormData>} [customUpdateModelModuleGenFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleGenApi
     */
    public putModuleGen(customUpdateModelModuleGenFormData?: CustomUpdateModel<ModuleGenFormData>, options?: RawAxiosRequestConfig) {
        return ModuleGenApiFp(this.configuration).putModuleGen(customUpdateModelModuleGenFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoticeApi - axios parameter creator
 * 通知公告 接口
 * @export
 */
export const NoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotice: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteNotice', 'ids', ids)
            const localVarPath = `/api/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotice: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotice', 'id', id)
            const localVarPath = `/api/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticeDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticeExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticePrint: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取未读的通知公告
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticeUnreadNotifyList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/unreadNotifyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {NoticeFormData} [noticeFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotice: async (noticeFormData?: NoticeFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticeImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取接收的通知公告（站内信）
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticePageReceived: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/pageReceived`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 发布通知公告
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticePublic: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postNoticePublic', 'id', id)
            const localVarPath = `/api/notice/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 设置通知公告已读状态
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticeSetRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postNoticeSetRead', 'id', id)
            const localVarPath = `/api/notice/setRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticeSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<NoticeFormData>} [customUpdateModelNoticeFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotice: async (customUpdateModelNoticeFormData?: CustomUpdateModel<NoticeFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelNoticeFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticeApi - functional programming interface
 * 通知公告 接口
 * @export
 */
export const NoticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotice(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotice(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.deleteNotice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotice(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<NoticeFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotice(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.getNotice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoticeDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<NoticeFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoticeDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.getNoticeDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoticeExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoticeExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.getNoticeExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoticePrint(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<NoticePrint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoticePrint(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.getNoticePrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取未读的通知公告
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoticeUnreadNotifyList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<NotifyReadListData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoticeUnreadNotifyList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.getNoticeUnreadNotifyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {NoticeFormData} [noticeFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotice(noticeFormData?: NoticeFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNotice(noticeFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNotice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNoticeImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNoticeImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNoticeImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNoticePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<NoticeListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNoticePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNoticePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取接收的通知公告（站内信）
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNoticePageReceived(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<NotifyReadListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNoticePageReceived(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNoticePageReceived']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 发布通知公告
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNoticePublic(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNoticePublic(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNoticePublic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 设置通知公告已读状态
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNoticeSetRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNoticeSetRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNoticeSetRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNoticeSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNoticeSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.postNoticeSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<NoticeFormData>} [customUpdateModelNoticeFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotice(customUpdateModelNoticeFormData?: CustomUpdateModel<NoticeFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotice(customUpdateModelNoticeFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoticeApi.putNotice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * NoticeApi - factory interface
 * 通知公告 接口
 * @export
 */
export const NoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticeApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotice(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteNotice(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotice(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<NoticeFormData>> {
            return localVarFp.getNotice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticeDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<NoticeFormData>> {
            return localVarFp.getNoticeDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticeExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getNoticeExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticePrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<NoticePrint>> {
            return localVarFp.getNoticePrint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取未读的通知公告
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoticeUnreadNotifyList(options?: RawAxiosRequestConfig): Promise<UniformResult<NotifyReadListData[]>> {
            return localVarFp.getNoticeUnreadNotifyList(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {NoticeFormData} [noticeFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotice(noticeFormData?: NoticeFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postNotice(noticeFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticeImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postNoticeImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<NoticeListData>>> {
            return localVarFp.postNoticePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取接收的通知公告（站内信）
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticePageReceived(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<NotifyReadListData>>> {
            return localVarFp.postNoticePageReceived(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 发布通知公告
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticePublic(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postNoticePublic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 设置通知公告已读状态
         * @param {number} id 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticeSetRead(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postNoticeSetRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNoticeSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postNoticeSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<NoticeFormData>} [customUpdateModelNoticeFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotice(customUpdateModelNoticeFormData?: CustomUpdateModel<NoticeFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putNotice(customUpdateModelNoticeFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticeApi - interface
 * 通知公告 接口
 * @export
 * @interface NoticeApi
 */
export interface NoticeApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    deleteNotice(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    getNotice(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<NoticeFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    getNoticeDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<NoticeFormData>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    getNoticeExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    getNoticePrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<NoticePrint>>;

    /**
     * 获取未读的通知公告
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    getNoticeUnreadNotifyList(options?: RawAxiosRequestConfig): Promise<UniformResult<NotifyReadListData[]>>;

    /**
     * 新增
     * @param {NoticeFormData} [noticeFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNotice(noticeFormData?: NoticeFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNoticeImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNoticePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<NoticeListData>>>;

    /**
     * 获取接收的通知公告（站内信）
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNoticePageReceived(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<NotifyReadListData>>>;

    /**
     * 发布通知公告
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNoticePublic(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 设置通知公告已读状态
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNoticeSetRead(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    postNoticeSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<NoticeFormData>} [customUpdateModelNoticeFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApiInterface
     */
    putNotice(customUpdateModelNoticeFormData?: CustomUpdateModel<NoticeFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * NoticeApi - object-oriented interface
 * 通知公告 接口
 * @export
 * @class NoticeApi
 * @extends {BaseAPI}
 */
export class NoticeApi extends BaseAPI implements NoticeApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public deleteNotice(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).deleteNotice(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNotice(id: number, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).getNotice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNoticeDefault(options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).getNoticeDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNoticeExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).getNoticeExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNoticePrint(id?: number, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).getNoticePrint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取未读的通知公告
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNoticeUnreadNotifyList(options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).getNoticeUnreadNotifyList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {NoticeFormData} [noticeFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNotice(noticeFormData?: NoticeFormData, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNotice(noticeFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNoticeImport(file?: File, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNoticeImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNoticePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNoticePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取接收的通知公告（站内信）
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNoticePageReceived(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNoticePageReceived(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 发布通知公告
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNoticePublic(id: number, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNoticePublic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 设置通知公告已读状态
     * @param {number} id 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNoticeSetRead(id: number, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNoticeSetRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postNoticeSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).postNoticeSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<NoticeFormData>} [customUpdateModelNoticeFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public putNotice(customUpdateModelNoticeFormData?: CustomUpdateModel<NoticeFormData>, options?: RawAxiosRequestConfig) {
        return NoticeApiFp(this.configuration).putNotice(customUpdateModelNoticeFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OpenAccessApi - axios parameter creator
 * 开放认证 接口
 * @export
 */
export const OpenAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpenAccess: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteOpenAccess', 'ids', ids)
            const localVarPath = `/api/openAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAccess: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOpenAccess', 'id', id)
            const localVarPath = `/api/openAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAccessDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {OpenAccessFormData} [openAccessFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccess: async (openAccessFormData?: OpenAccessFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openAccessFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生成签名
         * @param {GenSignInput} [genSignInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessGenSign: async (genSignInput?: GenSignInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess/genSign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genSignInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 创建密钥
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessSecret: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess/secret`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<OpenAccessFormData>} [customUpdateModelOpenAccessFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOpenAccess: async (customUpdateModelOpenAccessFormData?: CustomUpdateModel<OpenAccessFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelOpenAccessFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenAccessApi - functional programming interface
 * 开放认证 接口
 * @export
 */
export const OpenAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOpenAccess(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOpenAccess(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.deleteOpenAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenAccess(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<OpenAccessFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenAccess(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.getOpenAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenAccessDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<OpenAccessFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenAccessDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.getOpenAccessDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {OpenAccessFormData} [openAccessFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenAccess(openAccessFormData?: OpenAccessFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenAccess(openAccessFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.postOpenAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 生成签名
         * @param {GenSignInput} [genSignInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenAccessGenSign(genSignInput?: GenSignInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenAccessGenSign(genSignInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.postOpenAccessGenSign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenAccessPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<OpenAccessListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenAccessPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.postOpenAccessPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 创建密钥
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenAccessSecret(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenAccessSecret(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.postOpenAccessSecret']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenAccessSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenAccessSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.postOpenAccessSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<OpenAccessFormData>} [customUpdateModelOpenAccessFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOpenAccess(customUpdateModelOpenAccessFormData?: CustomUpdateModel<OpenAccessFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOpenAccess(customUpdateModelOpenAccessFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenAccessApi.putOpenAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * OpenAccessApi - factory interface
 * 开放认证 接口
 * @export
 */
export const OpenAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenAccessApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpenAccess(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteOpenAccess(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAccess(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<OpenAccessFormData>> {
            return localVarFp.getOpenAccess(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAccessDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<OpenAccessFormData>> {
            return localVarFp.getOpenAccessDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {OpenAccessFormData} [openAccessFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccess(openAccessFormData?: OpenAccessFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postOpenAccess(openAccessFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 生成签名
         * @param {GenSignInput} [genSignInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessGenSign(genSignInput?: GenSignInput, options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.postOpenAccessGenSign(genSignInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<OpenAccessListData>>> {
            return localVarFp.postOpenAccessPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 创建密钥
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessSecret(options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.postOpenAccessSecret(options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenAccessSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postOpenAccessSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<OpenAccessFormData>} [customUpdateModelOpenAccessFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOpenAccess(customUpdateModelOpenAccessFormData?: CustomUpdateModel<OpenAccessFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putOpenAccess(customUpdateModelOpenAccessFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenAccessApi - interface
 * 开放认证 接口
 * @export
 * @interface OpenAccessApi
 */
export interface OpenAccessApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    deleteOpenAccess(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    getOpenAccess(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<OpenAccessFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    getOpenAccessDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<OpenAccessFormData>>;

    /**
     * 新增
     * @param {OpenAccessFormData} [openAccessFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    postOpenAccess(openAccessFormData?: OpenAccessFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 生成签名
     * @param {GenSignInput} [genSignInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    postOpenAccessGenSign(genSignInput?: GenSignInput, options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    postOpenAccessPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<OpenAccessListData>>>;

    /**
     * 创建密钥
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    postOpenAccessSecret(options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    postOpenAccessSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<OpenAccessFormData>} [customUpdateModelOpenAccessFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApiInterface
     */
    putOpenAccess(customUpdateModelOpenAccessFormData?: CustomUpdateModel<OpenAccessFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * OpenAccessApi - object-oriented interface
 * 开放认证 接口
 * @export
 * @class OpenAccessApi
 * @extends {BaseAPI}
 */
export class OpenAccessApi extends BaseAPI implements OpenAccessApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public deleteOpenAccess(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).deleteOpenAccess(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public getOpenAccess(id: number, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).getOpenAccess(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public getOpenAccessDefault(options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).getOpenAccessDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {OpenAccessFormData} [openAccessFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public postOpenAccess(openAccessFormData?: OpenAccessFormData, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).postOpenAccess(openAccessFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生成签名
     * @param {GenSignInput} [genSignInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public postOpenAccessGenSign(genSignInput?: GenSignInput, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).postOpenAccessGenSign(genSignInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public postOpenAccessPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).postOpenAccessPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 创建密钥
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public postOpenAccessSecret(options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).postOpenAccessSecret(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public postOpenAccessSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).postOpenAccessSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<OpenAccessFormData>} [customUpdateModelOpenAccessFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAccessApi
     */
    public putOpenAccess(customUpdateModelOpenAccessFormData?: CustomUpdateModel<OpenAccessFormData>, options?: RawAxiosRequestConfig) {
        return OpenAccessApiFp(this.configuration).putOpenAccess(customUpdateModelOpenAccessFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrgApi - axios parameter creator
 * 机构接口
 * @export
 */
export const OrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrg: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteOrg', 'ids', ids)
            const localVarPath = `/api/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrg: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrg', 'id', id)
            const localVarPath = `/api/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取全部机构键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/keyValueList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取当前平台下的机构键值对列表
         * @param {string} [area] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueListByCurrent: async (area?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/keyValueListByCurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取指定机构的下级机构键值对列表
         * @param {number} id 平台id
         * @param {string} [town] 街镇区域编号
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueListById: async (id: number, town?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrgKeyValueListById', 'id', id)
            const localVarPath = `/api/org/keyValueListById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (town !== undefined) {
                localVarQueryParameter['town'] = town;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取当前机构和下级机构键值列表树
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueTree: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/keyValueTree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过服务区域获取机构列表
         * @param {string} town 服务区域Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgListByTown: async (town: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'town' is not null or undefined
            assertParamExists('getOrgListByTown', 'town', town)
            const localVarPath = `/api/org/listByTown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (town !== undefined) {
                localVarQueryParameter['town'] = town;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgPrint: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {BaseOrgFormData} [baseOrgFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrg: async (baseOrgFormData?: BaseOrgFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrgFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrgImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrgPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrgSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<BaseOrgFormData>} [customUpdateModelBaseOrgFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrg: async (customUpdateModelBaseOrgFormData?: CustomUpdateModel<BaseOrgFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelBaseOrgFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgApi - functional programming interface
 * 机构接口
 * @export
 */
export const OrgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrg(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrg(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.deleteOrg']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrg(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<BaseOrgFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrg(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrg']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<BaseOrgFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取全部机构键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgKeyValueList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgKeyValueList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgKeyValueList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取当前平台下的机构键值对列表
         * @param {string} [area] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgKeyValueListByCurrent(area?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgKeyValueListByCurrent(area, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgKeyValueListByCurrent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取指定机构的下级机构键值对列表
         * @param {number} id 平台id
         * @param {string} [town] 街镇区域编号
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgKeyValueListById(id: number, town?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgKeyValueListById(id, town, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgKeyValueListById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取当前机构和下级机构键值列表树
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgKeyValueTree(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueTree[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgKeyValueTree(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgKeyValueTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过服务区域获取机构列表
         * @param {string} town 服务区域Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgListByTown(town: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<BaseOrg[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgListByTown(town, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgListByTown']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgPrint(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<BaseOrgPrint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgPrint(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.getOrgPrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {BaseOrgFormData} [baseOrgFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrg(baseOrgFormData?: BaseOrgFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrg(baseOrgFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.postOrg']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrgImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrgImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.postOrgImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrgPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<BaseOrgListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrgPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.postOrgPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrgSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrgSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.postOrgSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<BaseOrgFormData>} [customUpdateModelBaseOrgFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOrg(customUpdateModelBaseOrgFormData?: CustomUpdateModel<BaseOrgFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOrg(customUpdateModelBaseOrgFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.putOrg']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * OrgApi - factory interface
 * 机构接口
 * @export
 */
export const OrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrg(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteOrg(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrg(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrgFormData>> {
            return localVarFp.getOrg(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrgFormData>> {
            return localVarFp.getOrgDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getOrgExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取全部机构键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getOrgKeyValueList(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取当前平台下的机构键值对列表
         * @param {string} [area] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueListByCurrent(area?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getOrgKeyValueListByCurrent(area, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取指定机构的下级机构键值对列表
         * @param {number} id 平台id
         * @param {string} [town] 街镇区域编号
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueListById(id: number, town?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getOrgKeyValueListById(id, town, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取当前机构和下级机构键值列表树
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgKeyValueTree(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>> {
            return localVarFp.getOrgKeyValueTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 通过服务区域获取机构列表
         * @param {string} town 服务区域Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgListByTown(town: string, options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrg[]>> {
            return localVarFp.getOrgListByTown(town, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrgPrint>> {
            return localVarFp.getOrgPrint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {BaseOrgFormData} [baseOrgFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrg(baseOrgFormData?: BaseOrgFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postOrg(baseOrgFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrgImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postOrgImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrgPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<BaseOrgListData>>> {
            return localVarFp.postOrgPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrgSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postOrgSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<BaseOrgFormData>} [customUpdateModelBaseOrgFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrg(customUpdateModelBaseOrgFormData?: CustomUpdateModel<BaseOrgFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putOrg(customUpdateModelBaseOrgFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgApi - interface
 * 机构接口
 * @export
 * @interface OrgApi
 */
export interface OrgApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    deleteOrg(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrg(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrgFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrgFormData>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 获取全部机构键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 获取当前平台下的机构键值对列表
     * @param {string} [area] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgKeyValueListByCurrent(area?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 获取指定机构的下级机构键值对列表
     * @param {number} id 平台id
     * @param {string} [town] 街镇区域编号
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgKeyValueListById(id: number, town?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 获取当前机构和下级机构键值列表树
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgKeyValueTree(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>>;

    /**
     * 通过服务区域获取机构列表
     * @param {string} town 服务区域Id
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgListByTown(town: string, options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrg[]>>;

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<BaseOrgPrint>>;

    /**
     * 新增
     * @param {BaseOrgFormData} [baseOrgFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    postOrg(baseOrgFormData?: BaseOrgFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    postOrgImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    postOrgPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<BaseOrgListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    postOrgSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<BaseOrgFormData>} [customUpdateModelBaseOrgFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    putOrg(customUpdateModelBaseOrgFormData?: CustomUpdateModel<BaseOrgFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * OrgApi - object-oriented interface
 * 机构接口
 * @export
 * @class OrgApi
 * @extends {BaseAPI}
 */
export class OrgApi extends BaseAPI implements OrgApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public deleteOrg(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).deleteOrg(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrg(id: number, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrg(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgDefault(options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取全部机构键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgKeyValueList(options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgKeyValueList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取当前平台下的机构键值对列表
     * @param {string} [area] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgKeyValueListByCurrent(area?: string, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgKeyValueListByCurrent(area, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取指定机构的下级机构键值对列表
     * @param {number} id 平台id
     * @param {string} [town] 街镇区域编号
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgKeyValueListById(id: number, town?: string, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgKeyValueListById(id, town, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取当前机构和下级机构键值列表树
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgKeyValueTree(options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgKeyValueTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过服务区域获取机构列表
     * @param {string} town 服务区域Id
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgListByTown(town: string, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgListByTown(town, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public getOrgPrint(id?: number, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).getOrgPrint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {BaseOrgFormData} [baseOrgFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public postOrg(baseOrgFormData?: BaseOrgFormData, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).postOrg(baseOrgFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public postOrgImport(file?: File, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).postOrgImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public postOrgPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).postOrgPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public postOrgSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).postOrgSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<BaseOrgFormData>} [customUpdateModelBaseOrgFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public putOrg(customUpdateModelBaseOrgFormData?: CustomUpdateModel<BaseOrgFormData>, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).putOrg(customUpdateModelBaseOrgFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationApi - axios parameter creator
 * 机构 接口
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteOrganization', 'ids', ids)
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganization', 'id', id)
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {OrganizationFormData} [organizationFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganization: async (organizationFormData?: OrganizationFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<OrganizationFormData>} [customUpdateModelOrganizationFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrganization: async (customUpdateModelOrganizationFormData?: CustomUpdateModel<OrganizationFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelOrganizationFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * 机构 接口
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.deleteOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<OrganizationFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.getOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<OrganizationFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.getOrganizationDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {OrganizationFormData} [organizationFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganization(organizationFormData?: OrganizationFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganization(organizationFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.postOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<OrganizationListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.postOrganizationPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.postOrganizationSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<OrganizationFormData>} [customUpdateModelOrganizationFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOrganization(customUpdateModelOrganizationFormData?: CustomUpdateModel<OrganizationFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOrganization(customUpdateModelOrganizationFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationApi.putOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * 机构 接口
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteOrganization(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<OrganizationFormData>> {
            return localVarFp.getOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<OrganizationFormData>> {
            return localVarFp.getOrganizationDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {OrganizationFormData} [organizationFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganization(organizationFormData?: OrganizationFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postOrganization(organizationFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<OrganizationListData>>> {
            return localVarFp.postOrganizationPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postOrganizationSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<OrganizationFormData>} [customUpdateModelOrganizationFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrganization(customUpdateModelOrganizationFormData?: CustomUpdateModel<OrganizationFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putOrganization(customUpdateModelOrganizationFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - interface
 * 机构 接口
 * @export
 * @interface OrganizationApi
 */
export interface OrganizationApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    deleteOrganization(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    getOrganization(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<OrganizationFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    getOrganizationDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<OrganizationFormData>>;

    /**
     * 新增
     * @param {OrganizationFormData} [organizationFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    postOrganization(organizationFormData?: OrganizationFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    postOrganizationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<OrganizationListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    postOrganizationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<OrganizationFormData>} [customUpdateModelOrganizationFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    putOrganization(customUpdateModelOrganizationFormData?: CustomUpdateModel<OrganizationFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * OrganizationApi - object-oriented interface
 * 机构 接口
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI implements OrganizationApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public deleteOrganization(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).deleteOrganization(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganization(id: number, options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationDefault(options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {OrganizationFormData} [organizationFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public postOrganization(organizationFormData?: OrganizationFormData, options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).postOrganization(organizationFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public postOrganizationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).postOrganizationPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public postOrganizationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).postOrganizationSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<OrganizationFormData>} [customUpdateModelOrganizationFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public putOrganization(customUpdateModelOrganizationFormData?: CustomUpdateModel<OrganizationFormData>, options?: RawAxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).putOrganization(customUpdateModelOrganizationFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PickUpApi - axios parameter creator
 * 提货 接口
 * @export
 */
export const PickUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePickUp: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deletePickUp', 'ids', ids)
            const localVarPath = `/api/pickUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUp: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPickUp', 'id', id)
            const localVarPath = `/api/pickUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUp/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {PickUpFormData} [pickUpFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUp: async (pickUpFormData?: PickUpFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickUpFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUp/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUp/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpFormData>} [customUpdateModelPickUpFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPickUp: async (customUpdateModelPickUpFormData?: CustomUpdateModel<PickUpFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelPickUpFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PickUpApi - functional programming interface
 * 提货 接口
 * @export
 */
export const PickUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PickUpApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePickUp(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePickUp(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.deletePickUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickUp(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PickUpFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickUp(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.getPickUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickUpDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PickUpFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickUpDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.getPickUpDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {PickUpFormData} [pickUpFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUp(pickUpFormData?: PickUpFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUp(pickUpFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.postPickUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUpPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<PickUpListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUpPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.postPickUpPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUpSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUpSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.postPickUpSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpFormData>} [customUpdateModelPickUpFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPickUp(customUpdateModelPickUpFormData?: CustomUpdateModel<PickUpFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPickUp(customUpdateModelPickUpFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpApi.putPickUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * PickUpApi - factory interface
 * 提货 接口
 * @export
 */
export const PickUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PickUpApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePickUp(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deletePickUp(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUp(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpFormData>> {
            return localVarFp.getPickUp(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpFormData>> {
            return localVarFp.getPickUpDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {PickUpFormData} [pickUpFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUp(pickUpFormData?: PickUpFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPickUp(pickUpFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PickUpListData>>> {
            return localVarFp.postPickUpPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPickUpSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpFormData>} [customUpdateModelPickUpFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPickUp(customUpdateModelPickUpFormData?: CustomUpdateModel<PickUpFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putPickUp(customUpdateModelPickUpFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PickUpApi - interface
 * 提货 接口
 * @export
 * @interface PickUpApi
 */
export interface PickUpApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    deletePickUp(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    getPickUp(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    getPickUpDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpFormData>>;

    /**
     * 新增
     * @param {PickUpFormData} [pickUpFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    postPickUp(pickUpFormData?: PickUpFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    postPickUpPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PickUpListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    postPickUpSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<PickUpFormData>} [customUpdateModelPickUpFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApiInterface
     */
    putPickUp(customUpdateModelPickUpFormData?: CustomUpdateModel<PickUpFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * PickUpApi - object-oriented interface
 * 提货 接口
 * @export
 * @class PickUpApi
 * @extends {BaseAPI}
 */
export class PickUpApi extends BaseAPI implements PickUpApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public deletePickUp(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).deletePickUp(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public getPickUp(id: number, options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).getPickUp(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public getPickUpDefault(options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).getPickUpDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {PickUpFormData} [pickUpFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public postPickUp(pickUpFormData?: PickUpFormData, options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).postPickUp(pickUpFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public postPickUpPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).postPickUpPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public postPickUpSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).postPickUpSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<PickUpFormData>} [customUpdateModelPickUpFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpApi
     */
    public putPickUp(customUpdateModelPickUpFormData?: CustomUpdateModel<PickUpFormData>, options?: RawAxiosRequestConfig) {
        return PickUpApiFp(this.configuration).putPickUp(customUpdateModelPickUpFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PickUpCostApi - axios parameter creator
 * 提货费用 接口
 * @export
 */
export const PickUpCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePickUpCost: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deletePickUpCost', 'ids', ids)
            const localVarPath = `/api/pickUpCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpCost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPickUpCost', 'id', id)
            const localVarPath = `/api/pickUpCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpCostDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpCost/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {PickUpCostFormData} [pickUpCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpCost: async (pickUpCostFormData?: PickUpCostFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickUpCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpCostPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpCost/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpCostFormData>} [customUpdateModelPickUpCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPickUpCost: async (customUpdateModelPickUpCostFormData?: CustomUpdateModel<PickUpCostFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelPickUpCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PickUpCostApi - functional programming interface
 * 提货费用 接口
 * @export
 */
export const PickUpCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PickUpCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePickUpCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePickUpCost(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpCostApi.deletePickUpCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickUpCost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PickUpCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickUpCost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpCostApi.getPickUpCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickUpCostDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PickUpCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickUpCostDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpCostApi.getPickUpCostDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {PickUpCostFormData} [pickUpCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUpCost(pickUpCostFormData?: PickUpCostFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUpCost(pickUpCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpCostApi.postPickUpCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUpCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<PickUpCostListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUpCostPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpCostApi.postPickUpCostPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpCostFormData>} [customUpdateModelPickUpCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPickUpCost(customUpdateModelPickUpCostFormData?: CustomUpdateModel<PickUpCostFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPickUpCost(customUpdateModelPickUpCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpCostApi.putPickUpCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * PickUpCostApi - factory interface
 * 提货费用 接口
 * @export
 */
export const PickUpCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PickUpCostApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePickUpCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deletePickUpCost(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpCostFormData>> {
            return localVarFp.getPickUpCost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpCostFormData>> {
            return localVarFp.getPickUpCostDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {PickUpCostFormData} [pickUpCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpCost(pickUpCostFormData?: PickUpCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPickUpCost(pickUpCostFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PickUpCostListData>>> {
            return localVarFp.postPickUpCostPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpCostFormData>} [customUpdateModelPickUpCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPickUpCost(customUpdateModelPickUpCostFormData?: CustomUpdateModel<PickUpCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putPickUpCost(customUpdateModelPickUpCostFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PickUpCostApi - interface
 * 提货费用 接口
 * @export
 * @interface PickUpCostApi
 */
export interface PickUpCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApiInterface
     */
    deletePickUpCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApiInterface
     */
    getPickUpCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpCostFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApiInterface
     */
    getPickUpCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpCostFormData>>;

    /**
     * 新增
     * @param {PickUpCostFormData} [pickUpCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApiInterface
     */
    postPickUpCost(pickUpCostFormData?: PickUpCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApiInterface
     */
    postPickUpCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PickUpCostListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<PickUpCostFormData>} [customUpdateModelPickUpCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApiInterface
     */
    putPickUpCost(customUpdateModelPickUpCostFormData?: CustomUpdateModel<PickUpCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * PickUpCostApi - object-oriented interface
 * 提货费用 接口
 * @export
 * @class PickUpCostApi
 * @extends {BaseAPI}
 */
export class PickUpCostApi extends BaseAPI implements PickUpCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApi
     */
    public deletePickUpCost(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return PickUpCostApiFp(this.configuration).deletePickUpCost(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApi
     */
    public getPickUpCost(id: number, options?: RawAxiosRequestConfig) {
        return PickUpCostApiFp(this.configuration).getPickUpCost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApi
     */
    public getPickUpCostDefault(options?: RawAxiosRequestConfig) {
        return PickUpCostApiFp(this.configuration).getPickUpCostDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {PickUpCostFormData} [pickUpCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApi
     */
    public postPickUpCost(pickUpCostFormData?: PickUpCostFormData, options?: RawAxiosRequestConfig) {
        return PickUpCostApiFp(this.configuration).postPickUpCost(pickUpCostFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApi
     */
    public postPickUpCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return PickUpCostApiFp(this.configuration).postPickUpCostPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<PickUpCostFormData>} [customUpdateModelPickUpCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpCostApi
     */
    public putPickUpCost(customUpdateModelPickUpCostFormData?: CustomUpdateModel<PickUpCostFormData>, options?: RawAxiosRequestConfig) {
        return PickUpCostApiFp(this.configuration).putPickUpCost(customUpdateModelPickUpCostFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PickUpDetailsApi - axios parameter creator
 * 提货明细 接口
 * @export
 */
export const PickUpDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePickUpDetails: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deletePickUpDetails', 'ids', ids)
            const localVarPath = `/api/pickUpDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpDetails: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPickUpDetails', 'id', id)
            const localVarPath = `/api/pickUpDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpDetailsDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpDetails/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {PickUpDetailsFormData} [pickUpDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpDetails: async (pickUpDetailsFormData?: PickUpDetailsFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pickUpDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpDetailsPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpDetails/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpDetailsFormData>} [customUpdateModelPickUpDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPickUpDetails: async (customUpdateModelPickUpDetailsFormData?: CustomUpdateModel<PickUpDetailsFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pickUpDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelPickUpDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PickUpDetailsApi - functional programming interface
 * 提货明细 接口
 * @export
 */
export const PickUpDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PickUpDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePickUpDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePickUpDetails(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpDetailsApi.deletePickUpDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickUpDetails(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PickUpDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickUpDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpDetailsApi.getPickUpDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickUpDetailsDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PickUpDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickUpDetailsDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpDetailsApi.getPickUpDetailsDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {PickUpDetailsFormData} [pickUpDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUpDetails(pickUpDetailsFormData?: PickUpDetailsFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUpDetails(pickUpDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpDetailsApi.postPickUpDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickUpDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<PickUpDetailsListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickUpDetailsPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpDetailsApi.postPickUpDetailsPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpDetailsFormData>} [customUpdateModelPickUpDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPickUpDetails(customUpdateModelPickUpDetailsFormData?: CustomUpdateModel<PickUpDetailsFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPickUpDetails(customUpdateModelPickUpDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PickUpDetailsApi.putPickUpDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * PickUpDetailsApi - factory interface
 * 提货明细 接口
 * @export
 */
export const PickUpDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PickUpDetailsApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePickUpDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deletePickUpDetails(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpDetailsFormData>> {
            return localVarFp.getPickUpDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickUpDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpDetailsFormData>> {
            return localVarFp.getPickUpDetailsDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {PickUpDetailsFormData} [pickUpDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpDetails(pickUpDetailsFormData?: PickUpDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPickUpDetails(pickUpDetailsFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickUpDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PickUpDetailsListData>>> {
            return localVarFp.postPickUpDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PickUpDetailsFormData>} [customUpdateModelPickUpDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPickUpDetails(customUpdateModelPickUpDetailsFormData?: CustomUpdateModel<PickUpDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putPickUpDetails(customUpdateModelPickUpDetailsFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PickUpDetailsApi - interface
 * 提货明细 接口
 * @export
 * @interface PickUpDetailsApi
 */
export interface PickUpDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApiInterface
     */
    deletePickUpDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApiInterface
     */
    getPickUpDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpDetailsFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApiInterface
     */
    getPickUpDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PickUpDetailsFormData>>;

    /**
     * 新增
     * @param {PickUpDetailsFormData} [pickUpDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApiInterface
     */
    postPickUpDetails(pickUpDetailsFormData?: PickUpDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApiInterface
     */
    postPickUpDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PickUpDetailsListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<PickUpDetailsFormData>} [customUpdateModelPickUpDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApiInterface
     */
    putPickUpDetails(customUpdateModelPickUpDetailsFormData?: CustomUpdateModel<PickUpDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * PickUpDetailsApi - object-oriented interface
 * 提货明细 接口
 * @export
 * @class PickUpDetailsApi
 * @extends {BaseAPI}
 */
export class PickUpDetailsApi extends BaseAPI implements PickUpDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApi
     */
    public deletePickUpDetails(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return PickUpDetailsApiFp(this.configuration).deletePickUpDetails(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApi
     */
    public getPickUpDetails(id: number, options?: RawAxiosRequestConfig) {
        return PickUpDetailsApiFp(this.configuration).getPickUpDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApi
     */
    public getPickUpDetailsDefault(options?: RawAxiosRequestConfig) {
        return PickUpDetailsApiFp(this.configuration).getPickUpDetailsDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {PickUpDetailsFormData} [pickUpDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApi
     */
    public postPickUpDetails(pickUpDetailsFormData?: PickUpDetailsFormData, options?: RawAxiosRequestConfig) {
        return PickUpDetailsApiFp(this.configuration).postPickUpDetails(pickUpDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApi
     */
    public postPickUpDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return PickUpDetailsApiFp(this.configuration).postPickUpDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<PickUpDetailsFormData>} [customUpdateModelPickUpDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickUpDetailsApi
     */
    public putPickUpDetails(customUpdateModelPickUpDetailsFormData?: CustomUpdateModel<PickUpDetailsFormData>, options?: RawAxiosRequestConfig) {
        return PickUpDetailsApiFp(this.configuration).putPickUpDetails(customUpdateModelPickUpDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PortersTeamApi - axios parameter creator
 * 搬运团队 接口
 * @export
 */
export const PortersTeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortersTeam: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deletePortersTeam', 'ids', ids)
            const localVarPath = `/api/portersTeam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortersTeam: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPortersTeam', 'id', id)
            const localVarPath = `/api/portersTeam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortersTeamDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portersTeam/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {PortersTeamFormData} [portersTeamFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortersTeam: async (portersTeamFormData?: PortersTeamFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portersTeam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portersTeamFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortersTeamPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portersTeam/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortersTeamSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portersTeam/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PortersTeamFormData>} [customUpdateModelPortersTeamFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortersTeam: async (customUpdateModelPortersTeamFormData?: CustomUpdateModel<PortersTeamFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portersTeam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelPortersTeamFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortersTeamApi - functional programming interface
 * 搬运团队 接口
 * @export
 */
export const PortersTeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortersTeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortersTeam(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortersTeam(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.deletePortersTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortersTeam(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PortersTeamFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortersTeam(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.getPortersTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortersTeamDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PortersTeamFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortersTeamDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.getPortersTeamDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {PortersTeamFormData} [portersTeamFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPortersTeam(portersTeamFormData?: PortersTeamFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPortersTeam(portersTeamFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.postPortersTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPortersTeamPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<PortersTeamListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPortersTeamPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.postPortersTeamPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPortersTeamSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPortersTeamSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.postPortersTeamSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PortersTeamFormData>} [customUpdateModelPortersTeamFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPortersTeam(customUpdateModelPortersTeamFormData?: CustomUpdateModel<PortersTeamFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPortersTeam(customUpdateModelPortersTeamFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortersTeamApi.putPortersTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * PortersTeamApi - factory interface
 * 搬运团队 接口
 * @export
 */
export const PortersTeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortersTeamApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortersTeam(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deletePortersTeam(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortersTeam(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PortersTeamFormData>> {
            return localVarFp.getPortersTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortersTeamDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PortersTeamFormData>> {
            return localVarFp.getPortersTeamDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {PortersTeamFormData} [portersTeamFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortersTeam(portersTeamFormData?: PortersTeamFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPortersTeam(portersTeamFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortersTeamPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PortersTeamListData>>> {
            return localVarFp.postPortersTeamPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortersTeamSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPortersTeamSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PortersTeamFormData>} [customUpdateModelPortersTeamFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortersTeam(customUpdateModelPortersTeamFormData?: CustomUpdateModel<PortersTeamFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putPortersTeam(customUpdateModelPortersTeamFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortersTeamApi - interface
 * 搬运团队 接口
 * @export
 * @interface PortersTeamApi
 */
export interface PortersTeamApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    deletePortersTeam(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    getPortersTeam(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PortersTeamFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    getPortersTeamDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PortersTeamFormData>>;

    /**
     * 新增
     * @param {PortersTeamFormData} [portersTeamFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    postPortersTeam(portersTeamFormData?: PortersTeamFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    postPortersTeamPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PortersTeamListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    postPortersTeamSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<PortersTeamFormData>} [customUpdateModelPortersTeamFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApiInterface
     */
    putPortersTeam(customUpdateModelPortersTeamFormData?: CustomUpdateModel<PortersTeamFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * PortersTeamApi - object-oriented interface
 * 搬运团队 接口
 * @export
 * @class PortersTeamApi
 * @extends {BaseAPI}
 */
export class PortersTeamApi extends BaseAPI implements PortersTeamApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public deletePortersTeam(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).deletePortersTeam(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public getPortersTeam(id: number, options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).getPortersTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public getPortersTeamDefault(options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).getPortersTeamDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {PortersTeamFormData} [portersTeamFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public postPortersTeam(portersTeamFormData?: PortersTeamFormData, options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).postPortersTeam(portersTeamFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public postPortersTeamPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).postPortersTeamPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public postPortersTeamSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).postPortersTeamSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<PortersTeamFormData>} [customUpdateModelPortersTeamFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortersTeamApi
     */
    public putPortersTeam(customUpdateModelPortersTeamFormData?: CustomUpdateModel<PortersTeamFormData>, options?: RawAxiosRequestConfig) {
        return PortersTeamApiFp(this.configuration).putPortersTeam(customUpdateModelPortersTeamFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrepaidBillApi - axios parameter creator
 * 预付金账单 接口
 * @export
 */
export const PrepaidBillApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrepaidBill: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deletePrepaidBill', 'ids', ids)
            const localVarPath = `/api/prepaidBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrepaidBill: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrepaidBill', 'id', id)
            const localVarPath = `/api/prepaidBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrepaidBillDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prepaidBill/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {PrepaidBillFormData} [prepaidBillFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrepaidBill: async (prepaidBillFormData?: PrepaidBillFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prepaidBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prepaidBillFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrepaidBillPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prepaidBill/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PrepaidBillFormData>} [customUpdateModelPrepaidBillFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPrepaidBill: async (customUpdateModelPrepaidBillFormData?: CustomUpdateModel<PrepaidBillFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prepaidBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelPrepaidBillFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrepaidBillApi - functional programming interface
 * 预付金账单 接口
 * @export
 */
export const PrepaidBillApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrepaidBillApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePrepaidBill(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePrepaidBill(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrepaidBillApi.deletePrepaidBill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrepaidBill(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PrepaidBillFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrepaidBill(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrepaidBillApi.getPrepaidBill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrepaidBillDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PrepaidBillFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrepaidBillDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrepaidBillApi.getPrepaidBillDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {PrepaidBillFormData} [prepaidBillFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPrepaidBill(prepaidBillFormData?: PrepaidBillFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPrepaidBill(prepaidBillFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrepaidBillApi.postPrepaidBill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPrepaidBillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<PrepaidBillListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPrepaidBillPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrepaidBillApi.postPrepaidBillPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PrepaidBillFormData>} [customUpdateModelPrepaidBillFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPrepaidBill(customUpdateModelPrepaidBillFormData?: CustomUpdateModel<PrepaidBillFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPrepaidBill(customUpdateModelPrepaidBillFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrepaidBillApi.putPrepaidBill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * PrepaidBillApi - factory interface
 * 预付金账单 接口
 * @export
 */
export const PrepaidBillApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrepaidBillApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrepaidBill(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deletePrepaidBill(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrepaidBill(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PrepaidBillFormData>> {
            return localVarFp.getPrepaidBill(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrepaidBillDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PrepaidBillFormData>> {
            return localVarFp.getPrepaidBillDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {PrepaidBillFormData} [prepaidBillFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrepaidBill(prepaidBillFormData?: PrepaidBillFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPrepaidBill(prepaidBillFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrepaidBillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PrepaidBillListData>>> {
            return localVarFp.postPrepaidBillPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PrepaidBillFormData>} [customUpdateModelPrepaidBillFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPrepaidBill(customUpdateModelPrepaidBillFormData?: CustomUpdateModel<PrepaidBillFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putPrepaidBill(customUpdateModelPrepaidBillFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrepaidBillApi - interface
 * 预付金账单 接口
 * @export
 * @interface PrepaidBillApi
 */
export interface PrepaidBillApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApiInterface
     */
    deletePrepaidBill(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApiInterface
     */
    getPrepaidBill(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PrepaidBillFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApiInterface
     */
    getPrepaidBillDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PrepaidBillFormData>>;

    /**
     * 新增
     * @param {PrepaidBillFormData} [prepaidBillFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApiInterface
     */
    postPrepaidBill(prepaidBillFormData?: PrepaidBillFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApiInterface
     */
    postPrepaidBillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PrepaidBillListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<PrepaidBillFormData>} [customUpdateModelPrepaidBillFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApiInterface
     */
    putPrepaidBill(customUpdateModelPrepaidBillFormData?: CustomUpdateModel<PrepaidBillFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * PrepaidBillApi - object-oriented interface
 * 预付金账单 接口
 * @export
 * @class PrepaidBillApi
 * @extends {BaseAPI}
 */
export class PrepaidBillApi extends BaseAPI implements PrepaidBillApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApi
     */
    public deletePrepaidBill(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return PrepaidBillApiFp(this.configuration).deletePrepaidBill(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApi
     */
    public getPrepaidBill(id: number, options?: RawAxiosRequestConfig) {
        return PrepaidBillApiFp(this.configuration).getPrepaidBill(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApi
     */
    public getPrepaidBillDefault(options?: RawAxiosRequestConfig) {
        return PrepaidBillApiFp(this.configuration).getPrepaidBillDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {PrepaidBillFormData} [prepaidBillFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApi
     */
    public postPrepaidBill(prepaidBillFormData?: PrepaidBillFormData, options?: RawAxiosRequestConfig) {
        return PrepaidBillApiFp(this.configuration).postPrepaidBill(prepaidBillFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApi
     */
    public postPrepaidBillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return PrepaidBillApiFp(this.configuration).postPrepaidBillPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<PrepaidBillFormData>} [customUpdateModelPrepaidBillFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaidBillApi
     */
    public putPrepaidBill(customUpdateModelPrepaidBillFormData?: CustomUpdateModel<PrepaidBillFormData>, options?: RawAxiosRequestConfig) {
        return PrepaidBillApiFp(this.configuration).putPrepaidBill(customUpdateModelPrepaidBillFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrintTemplateApi - axios parameter creator
 * 打印模板 接口
 * @export
 */
export const PrintTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrintTemplate: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deletePrintTemplate', 'ids', ids)
            const localVarPath = `/api/printTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintTemplate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrintTemplate', 'id', id)
            const localVarPath = `/api/printTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintTemplateDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printTemplate/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {PrintTemplateFormData} [printTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrintTemplate: async (printTemplateFormData?: PrintTemplateFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printTemplateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrintTemplatePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printTemplate/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrintTemplateSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printTemplate/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PrintTemplateFormData>} [customUpdateModelPrintTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPrintTemplate: async (customUpdateModelPrintTemplateFormData?: CustomUpdateModel<PrintTemplateFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelPrintTemplateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintTemplateApi - functional programming interface
 * 打印模板 接口
 * @export
 */
export const PrintTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePrintTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePrintTemplate(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.deletePrintTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintTemplate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PrintTemplateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintTemplate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.getPrintTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintTemplateDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PrintTemplateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintTemplateDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.getPrintTemplateDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {PrintTemplateFormData} [printTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPrintTemplate(printTemplateFormData?: PrintTemplateFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPrintTemplate(printTemplateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.postPrintTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPrintTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<PrintTemplateListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPrintTemplatePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.postPrintTemplatePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPrintTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPrintTemplateSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.postPrintTemplateSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PrintTemplateFormData>} [customUpdateModelPrintTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPrintTemplate(customUpdateModelPrintTemplateFormData?: CustomUpdateModel<PrintTemplateFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPrintTemplate(customUpdateModelPrintTemplateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintTemplateApi.putPrintTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * PrintTemplateApi - factory interface
 * 打印模板 接口
 * @export
 */
export const PrintTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintTemplateApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrintTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deletePrintTemplate(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintTemplate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PrintTemplateFormData>> {
            return localVarFp.getPrintTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintTemplateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PrintTemplateFormData>> {
            return localVarFp.getPrintTemplateDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {PrintTemplateFormData} [printTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrintTemplate(printTemplateFormData?: PrintTemplateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPrintTemplate(printTemplateFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrintTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PrintTemplateListData>>> {
            return localVarFp.postPrintTemplatePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrintTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postPrintTemplateSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<PrintTemplateFormData>} [customUpdateModelPrintTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPrintTemplate(customUpdateModelPrintTemplateFormData?: CustomUpdateModel<PrintTemplateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putPrintTemplate(customUpdateModelPrintTemplateFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintTemplateApi - interface
 * 打印模板 接口
 * @export
 * @interface PrintTemplateApi
 */
export interface PrintTemplateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    deletePrintTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    getPrintTemplate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<PrintTemplateFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    getPrintTemplateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<PrintTemplateFormData>>;

    /**
     * 新增
     * @param {PrintTemplateFormData} [printTemplateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    postPrintTemplate(printTemplateFormData?: PrintTemplateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    postPrintTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<PrintTemplateListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    postPrintTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<PrintTemplateFormData>} [customUpdateModelPrintTemplateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApiInterface
     */
    putPrintTemplate(customUpdateModelPrintTemplateFormData?: CustomUpdateModel<PrintTemplateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * PrintTemplateApi - object-oriented interface
 * 打印模板 接口
 * @export
 * @class PrintTemplateApi
 * @extends {BaseAPI}
 */
export class PrintTemplateApi extends BaseAPI implements PrintTemplateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public deletePrintTemplate(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).deletePrintTemplate(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public getPrintTemplate(id: number, options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).getPrintTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public getPrintTemplateDefault(options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).getPrintTemplateDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {PrintTemplateFormData} [printTemplateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public postPrintTemplate(printTemplateFormData?: PrintTemplateFormData, options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).postPrintTemplate(printTemplateFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public postPrintTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).postPrintTemplatePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public postPrintTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).postPrintTemplateSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<PrintTemplateFormData>} [customUpdateModelPrintTemplateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintTemplateApi
     */
    public putPrintTemplate(customUpdateModelPrintTemplateFormData?: CustomUpdateModel<PrintTemplateFormData>, options?: RawAxiosRequestConfig) {
        return PrintTemplateApiFp(this.configuration).putPrintTemplate(customUpdateModelPrintTemplateFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReceiptTrackApi - axios parameter creator
 * 回单跟踪 接口
 * @export
 */
export const ReceiptTrackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceiptTrack: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteReceiptTrack', 'ids', ids)
            const localVarPath = `/api/receiptTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptTrack: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReceiptTrack', 'id', id)
            const localVarPath = `/api/receiptTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptTrackDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receiptTrack/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ReceiptTrackFormData} [receiptTrackFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceiptTrack: async (receiptTrackFormData?: ReceiptTrackFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receiptTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptTrackFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceiptTrackPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receiptTrack/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceiptTrackSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receiptTrack/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ReceiptTrackFormData>} [customUpdateModelReceiptTrackFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReceiptTrack: async (customUpdateModelReceiptTrackFormData?: CustomUpdateModel<ReceiptTrackFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receiptTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelReceiptTrackFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptTrackApi - functional programming interface
 * 回单跟踪 接口
 * @export
 */
export const ReceiptTrackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceiptTrackApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReceiptTrack(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceiptTrack(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.deleteReceiptTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptTrack(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ReceiptTrackFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptTrack(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.getReceiptTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptTrackDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ReceiptTrackFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptTrackDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.getReceiptTrackDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ReceiptTrackFormData} [receiptTrackFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceiptTrack(receiptTrackFormData?: ReceiptTrackFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceiptTrack(receiptTrackFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.postReceiptTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceiptTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ReceiptTrackListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceiptTrackPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.postReceiptTrackPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceiptTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceiptTrackSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.postReceiptTrackSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ReceiptTrackFormData>} [customUpdateModelReceiptTrackFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReceiptTrack(customUpdateModelReceiptTrackFormData?: CustomUpdateModel<ReceiptTrackFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReceiptTrack(customUpdateModelReceiptTrackFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceiptTrackApi.putReceiptTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ReceiptTrackApi - factory interface
 * 回单跟踪 接口
 * @export
 */
export const ReceiptTrackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceiptTrackApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceiptTrack(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteReceiptTrack(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptTrack(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ReceiptTrackFormData>> {
            return localVarFp.getReceiptTrack(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptTrackDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ReceiptTrackFormData>> {
            return localVarFp.getReceiptTrackDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ReceiptTrackFormData} [receiptTrackFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceiptTrack(receiptTrackFormData?: ReceiptTrackFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postReceiptTrack(receiptTrackFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceiptTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ReceiptTrackListData>>> {
            return localVarFp.postReceiptTrackPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceiptTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postReceiptTrackSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ReceiptTrackFormData>} [customUpdateModelReceiptTrackFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReceiptTrack(customUpdateModelReceiptTrackFormData?: CustomUpdateModel<ReceiptTrackFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putReceiptTrack(customUpdateModelReceiptTrackFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceiptTrackApi - interface
 * 回单跟踪 接口
 * @export
 * @interface ReceiptTrackApi
 */
export interface ReceiptTrackApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    deleteReceiptTrack(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    getReceiptTrack(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ReceiptTrackFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    getReceiptTrackDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ReceiptTrackFormData>>;

    /**
     * 新增
     * @param {ReceiptTrackFormData} [receiptTrackFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    postReceiptTrack(receiptTrackFormData?: ReceiptTrackFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    postReceiptTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ReceiptTrackListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    postReceiptTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ReceiptTrackFormData>} [customUpdateModelReceiptTrackFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApiInterface
     */
    putReceiptTrack(customUpdateModelReceiptTrackFormData?: CustomUpdateModel<ReceiptTrackFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ReceiptTrackApi - object-oriented interface
 * 回单跟踪 接口
 * @export
 * @class ReceiptTrackApi
 * @extends {BaseAPI}
 */
export class ReceiptTrackApi extends BaseAPI implements ReceiptTrackApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public deleteReceiptTrack(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).deleteReceiptTrack(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public getReceiptTrack(id: number, options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).getReceiptTrack(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public getReceiptTrackDefault(options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).getReceiptTrackDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ReceiptTrackFormData} [receiptTrackFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public postReceiptTrack(receiptTrackFormData?: ReceiptTrackFormData, options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).postReceiptTrack(receiptTrackFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public postReceiptTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).postReceiptTrackPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public postReceiptTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).postReceiptTrackSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ReceiptTrackFormData>} [customUpdateModelReceiptTrackFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptTrackApi
     */
    public putReceiptTrack(customUpdateModelReceiptTrackFormData?: CustomUpdateModel<ReceiptTrackFormData>, options?: RawAxiosRequestConfig) {
        return ReceiptTrackApiFp(this.configuration).putReceiptTrack(customUpdateModelReceiptTrackFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReceivingCustomersApi - axios parameter creator
 * 收货客户 接口
 * @export
 */
export const ReceivingCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceivingCustomers: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteReceivingCustomers', 'ids', ids)
            const localVarPath = `/api/receivingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivingCustomers: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReceivingCustomers', 'id', id)
            const localVarPath = `/api/receivingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivingCustomersDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过发货客户 Id 获取收货客户列表
         * @param {number} [id] 发货客户Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivingCustomersListByShippingCustomerId: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers/listByShippingCustomerId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ReceivingCustomersFormData} [receivingCustomersFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomers: async (receivingCustomersFormData?: ReceivingCustomersFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receivingCustomersFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomersImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomersPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomersSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ReceivingCustomersFormData>} [customUpdateModelReceivingCustomersFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReceivingCustomers: async (customUpdateModelReceivingCustomersFormData?: CustomUpdateModel<ReceivingCustomersFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/receivingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelReceivingCustomersFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceivingCustomersApi - functional programming interface
 * 收货客户 接口
 * @export
 */
export const ReceivingCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceivingCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReceivingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceivingCustomers(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.deleteReceivingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceivingCustomers(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ReceivingCustomersFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceivingCustomers(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.getReceivingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceivingCustomersDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ReceivingCustomersFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceivingCustomersDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.getReceivingCustomersDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过发货客户 Id 获取收货客户列表
         * @param {number} [id] 发货客户Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceivingCustomersListByShippingCustomerId(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ReceivingCustomersListData[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceivingCustomersListByShippingCustomerId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.getReceivingCustomersListByShippingCustomerId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ReceivingCustomersFormData} [receivingCustomersFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivingCustomers(receivingCustomersFormData?: ReceivingCustomersFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivingCustomers(receivingCustomersFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.postReceivingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivingCustomersImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivingCustomersImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.postReceivingCustomersImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ReceivingCustomersListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivingCustomersPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.postReceivingCustomersPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivingCustomersSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.postReceivingCustomersSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ReceivingCustomersFormData>} [customUpdateModelReceivingCustomersFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReceivingCustomers(customUpdateModelReceivingCustomersFormData?: CustomUpdateModel<ReceivingCustomersFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReceivingCustomers(customUpdateModelReceivingCustomersFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReceivingCustomersApi.putReceivingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ReceivingCustomersApi - factory interface
 * 收货客户 接口
 * @export
 */
export const ReceivingCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceivingCustomersApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceivingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteReceivingCustomers(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivingCustomers(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ReceivingCustomersFormData>> {
            return localVarFp.getReceivingCustomers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivingCustomersDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ReceivingCustomersFormData>> {
            return localVarFp.getReceivingCustomersDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 通过发货客户 Id 获取收货客户列表
         * @param {number} [id] 发货客户Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivingCustomersListByShippingCustomerId(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ReceivingCustomersListData[]>> {
            return localVarFp.getReceivingCustomersListByShippingCustomerId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ReceivingCustomersFormData} [receivingCustomersFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomers(receivingCustomersFormData?: ReceivingCustomersFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postReceivingCustomers(receivingCustomersFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomersImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postReceivingCustomersImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ReceivingCustomersListData>>> {
            return localVarFp.postReceivingCustomersPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postReceivingCustomersSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ReceivingCustomersFormData>} [customUpdateModelReceivingCustomersFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReceivingCustomers(customUpdateModelReceivingCustomersFormData?: CustomUpdateModel<ReceivingCustomersFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putReceivingCustomers(customUpdateModelReceivingCustomersFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceivingCustomersApi - interface
 * 收货客户 接口
 * @export
 * @interface ReceivingCustomersApi
 */
export interface ReceivingCustomersApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    deleteReceivingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    getReceivingCustomers(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ReceivingCustomersFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    getReceivingCustomersDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ReceivingCustomersFormData>>;

    /**
     * 通过发货客户 Id 获取收货客户列表
     * @param {number} [id] 发货客户Id
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    getReceivingCustomersListByShippingCustomerId(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ReceivingCustomersListData[]>>;

    /**
     * 新增
     * @param {ReceivingCustomersFormData} [receivingCustomersFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    postReceivingCustomers(receivingCustomersFormData?: ReceivingCustomersFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    postReceivingCustomersImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    postReceivingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ReceivingCustomersListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    postReceivingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ReceivingCustomersFormData>} [customUpdateModelReceivingCustomersFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApiInterface
     */
    putReceivingCustomers(customUpdateModelReceivingCustomersFormData?: CustomUpdateModel<ReceivingCustomersFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ReceivingCustomersApi - object-oriented interface
 * 收货客户 接口
 * @export
 * @class ReceivingCustomersApi
 * @extends {BaseAPI}
 */
export class ReceivingCustomersApi extends BaseAPI implements ReceivingCustomersApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public deleteReceivingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).deleteReceivingCustomers(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public getReceivingCustomers(id: number, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).getReceivingCustomers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public getReceivingCustomersDefault(options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).getReceivingCustomersDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过发货客户 Id 获取收货客户列表
     * @param {number} [id] 发货客户Id
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public getReceivingCustomersListByShippingCustomerId(id?: number, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).getReceivingCustomersListByShippingCustomerId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ReceivingCustomersFormData} [receivingCustomersFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public postReceivingCustomers(receivingCustomersFormData?: ReceivingCustomersFormData, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).postReceivingCustomers(receivingCustomersFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public postReceivingCustomersImport(file?: File, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).postReceivingCustomersImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public postReceivingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).postReceivingCustomersPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public postReceivingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).postReceivingCustomersSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ReceivingCustomersFormData>} [customUpdateModelReceivingCustomersFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingCustomersApi
     */
    public putReceivingCustomers(customUpdateModelReceivingCustomersFormData?: CustomUpdateModel<ReceivingCustomersFormData>, options?: RawAxiosRequestConfig) {
        return ReceivingCustomersApiFp(this.configuration).putReceivingCustomers(customUpdateModelReceivingCustomersFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleApi - axios parameter creator
 * 角色接口
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteRole', 'ids', ids)
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRole', 'id', id)
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取所有角色键值列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAllKeyValueList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/allKeyValueList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取当前用户的角色和所有下级角色列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleChildRolesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/childRolesList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过角色ID获取菜单ID列表
         * @param {number} [roleId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleMenuIdsByRoleId: async (roleId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/menuIdsByRoleId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolePrint: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {RoleFormData} [roleFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRole: async (roleFormData?: RoleFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoleImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 配置角色菜单按钮权限
         * @param {number} roleId 
         * @param {Array<number>} [requestBody] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoleMenuIdList: async (roleId: number, requestBody?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('postRoleMenuIdList', 'roleId', roleId)
            const localVarPath = `/api/role/menuIdList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRolePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoleSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<RoleFormData>} [customUpdateModelRoleFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRole: async (customUpdateModelRoleFormData?: CustomUpdateModel<RoleFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelRoleFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * 角色接口
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.deleteRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<RoleFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取所有角色键值列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleAllKeyValueList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleAllKeyValueList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRoleAllKeyValueList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取当前用户的角色和所有下级角色列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleChildRolesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleChildRolesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRoleChildRolesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<RoleFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRoleDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRoleExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过角色ID获取菜单ID列表
         * @param {number} [roleId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleMenuIdsByRoleId(roleId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleMenuIdsByRoleId(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRoleMenuIdsByRoleId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolePrint(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<RolePrint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolePrint(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRolePrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {RoleFormData} [roleFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRole(roleFormData?: RoleFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRole(roleFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.postRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRoleImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRoleImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.postRoleImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 配置角色菜单按钮权限
         * @param {number} roleId 
         * @param {Array<number>} [requestBody] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRoleMenuIdList(roleId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRoleMenuIdList(roleId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.postRoleMenuIdList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRolePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<RoleListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRolePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.postRolePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRoleSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRoleSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.postRoleSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<RoleFormData>} [customUpdateModelRoleFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRole(customUpdateModelRoleFormData?: CustomUpdateModel<RoleFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRole(customUpdateModelRoleFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.putRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * RoleApi - factory interface
 * 角色接口
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteRole(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<RoleFormData>> {
            return localVarFp.getRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取所有角色键值列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAllKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getRoleAllKeyValueList(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取当前用户的角色和所有下级角色列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleChildRolesList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getRoleChildRolesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<RoleFormData>> {
            return localVarFp.getRoleDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getRoleExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过角色ID获取菜单ID列表
         * @param {number} [roleId] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleMenuIdsByRoleId(roleId?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<number[]>> {
            return localVarFp.getRoleMenuIdsByRoleId(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolePrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<RolePrint>> {
            return localVarFp.getRolePrint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {RoleFormData} [roleFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRole(roleFormData?: RoleFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postRole(roleFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoleImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postRoleImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 配置角色菜单按钮权限
         * @param {number} roleId 
         * @param {Array<number>} [requestBody] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoleMenuIdList(roleId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postRoleMenuIdList(roleId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRolePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<RoleListData>>> {
            return localVarFp.postRolePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoleSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postRoleSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<RoleFormData>} [customUpdateModelRoleFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRole(customUpdateModelRoleFormData?: CustomUpdateModel<RoleFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putRole(customUpdateModelRoleFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - interface
 * 角色接口
 * @export
 * @interface RoleApi
 */
export interface RoleApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    deleteRole(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRole(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<RoleFormData>>;

    /**
     * 获取所有角色键值列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRoleAllKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 获取当前用户的角色和所有下级角色列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRoleChildRolesList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRoleDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<RoleFormData>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRoleExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 通过角色ID获取菜单ID列表
     * @param {number} [roleId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRoleMenuIdsByRoleId(roleId?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<number[]>>;

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    getRolePrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<RolePrint>>;

    /**
     * 新增
     * @param {RoleFormData} [roleFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    postRole(roleFormData?: RoleFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    postRoleImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 配置角色菜单按钮权限
     * @param {number} roleId 
     * @param {Array<number>} [requestBody] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    postRoleMenuIdList(roleId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    postRolePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<RoleListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    postRoleSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<RoleFormData>} [customUpdateModelRoleFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    putRole(customUpdateModelRoleFormData?: CustomUpdateModel<RoleFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * RoleApi - object-oriented interface
 * 角色接口
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI implements RoleApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public deleteRole(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).deleteRole(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRole(id: number, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取所有角色键值列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRoleAllKeyValueList(options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRoleAllKeyValueList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取当前用户的角色和所有下级角色列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRoleChildRolesList(options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRoleChildRolesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRoleDefault(options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRoleDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRoleExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRoleExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过角色ID获取菜单ID列表
     * @param {number} [roleId] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRoleMenuIdsByRoleId(roleId?: number, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRoleMenuIdsByRoleId(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRolePrint(id?: number, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRolePrint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {RoleFormData} [roleFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public postRole(roleFormData?: RoleFormData, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).postRole(roleFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public postRoleImport(file?: File, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).postRoleImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 配置角色菜单按钮权限
     * @param {number} roleId 
     * @param {Array<number>} [requestBody] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public postRoleMenuIdList(roleId: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).postRoleMenuIdList(roleId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public postRolePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).postRolePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public postRoleSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).postRoleSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<RoleFormData>} [customUpdateModelRoleFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public putRole(customUpdateModelRoleFormData?: CustomUpdateModel<RoleFormData>, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).putRole(customUpdateModelRoleFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SalesQuoteApi - axios parameter creator
 * 对外报价 接口
 * @export
 */
export const SalesQuoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuote: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteSalesQuote', 'ids', ids)
            const localVarPath = `/api/salesQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuote: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalesQuote', 'id', id)
            const localVarPath = `/api/salesQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuote/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {SalesQuoteFormData} [salesQuoteFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuote: async (salesQuoteFormData?: SalesQuoteFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesQuoteFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuotePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuote/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuote/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteFormData>} [customUpdateModelSalesQuoteFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuote: async (customUpdateModelSalesQuoteFormData?: CustomUpdateModel<SalesQuoteFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelSalesQuoteFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesQuoteApi - functional programming interface
 * 对外报价 接口
 * @export
 */
export const SalesQuoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesQuoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSalesQuote(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSalesQuote(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.deleteSalesQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuote(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuote(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.getSalesQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.getSalesQuoteDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {SalesQuoteFormData} [salesQuoteFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuote(salesQuoteFormData?: SalesQuoteFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuote(salesQuoteFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.postSalesQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<SalesQuoteListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuotePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.postSalesQuotePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.postSalesQuoteSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteFormData>} [customUpdateModelSalesQuoteFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSalesQuote(customUpdateModelSalesQuoteFormData?: CustomUpdateModel<SalesQuoteFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSalesQuote(customUpdateModelSalesQuoteFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteApi.putSalesQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * SalesQuoteApi - factory interface
 * 对外报价 接口
 * @export
 */
export const SalesQuoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesQuoteApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuote(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteSalesQuote(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuote(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormData>> {
            return localVarFp.getSalesQuote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormData>> {
            return localVarFp.getSalesQuoteDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {SalesQuoteFormData} [salesQuoteFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuote(salesQuoteFormData?: SalesQuoteFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuote(salesQuoteFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteListData>>> {
            return localVarFp.postSalesQuotePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteFormData>} [customUpdateModelSalesQuoteFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuote(customUpdateModelSalesQuoteFormData?: CustomUpdateModel<SalesQuoteFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putSalesQuote(customUpdateModelSalesQuoteFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesQuoteApi - interface
 * 对外报价 接口
 * @export
 * @interface SalesQuoteApi
 */
export interface SalesQuoteApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    deleteSalesQuote(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    getSalesQuote(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    getSalesQuoteDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormData>>;

    /**
     * 新增
     * @param {SalesQuoteFormData} [salesQuoteFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    postSalesQuote(salesQuoteFormData?: SalesQuoteFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    postSalesQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    postSalesQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteFormData>} [customUpdateModelSalesQuoteFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApiInterface
     */
    putSalesQuote(customUpdateModelSalesQuoteFormData?: CustomUpdateModel<SalesQuoteFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * SalesQuoteApi - object-oriented interface
 * 对外报价 接口
 * @export
 * @class SalesQuoteApi
 * @extends {BaseAPI}
 */
export class SalesQuoteApi extends BaseAPI implements SalesQuoteApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public deleteSalesQuote(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).deleteSalesQuote(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public getSalesQuote(id: number, options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).getSalesQuote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public getSalesQuoteDefault(options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).getSalesQuoteDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {SalesQuoteFormData} [salesQuoteFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public postSalesQuote(salesQuoteFormData?: SalesQuoteFormData, options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).postSalesQuote(salesQuoteFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public postSalesQuotePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).postSalesQuotePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public postSalesQuoteSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).postSalesQuoteSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteFormData>} [customUpdateModelSalesQuoteFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteApi
     */
    public putSalesQuote(customUpdateModelSalesQuoteFormData?: CustomUpdateModel<SalesQuoteFormData>, options?: RawAxiosRequestConfig) {
        return SalesQuoteApiFp(this.configuration).putSalesQuote(customUpdateModelSalesQuoteFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SalesQuoteFormulaApi - axios parameter creator
 * 对外报价公式 接口
 * @export
 */
export const SalesQuoteFormulaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuoteFormula: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteSalesQuoteFormula', 'ids', ids)
            const localVarPath = `/api/salesQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteFormula: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalesQuoteFormula', 'id', id)
            const localVarPath = `/api/salesQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteFormulaDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteFormula/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {SalesQuoteFormulaFormData} [salesQuoteFormulaFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteFormula: async (salesQuoteFormulaFormData?: SalesQuoteFormulaFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesQuoteFormulaFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteFormulaPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteFormula/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteFormulaSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteFormula/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteFormulaFormData>} [customUpdateModelSalesQuoteFormulaFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuoteFormula: async (customUpdateModelSalesQuoteFormulaFormData?: CustomUpdateModel<SalesQuoteFormulaFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteFormula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelSalesQuoteFormulaFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesQuoteFormulaApi - functional programming interface
 * 对外报价公式 接口
 * @export
 */
export const SalesQuoteFormulaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesQuoteFormulaApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSalesQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSalesQuoteFormula(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.deleteSalesQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteFormula(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteFormulaFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteFormula(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.getSalesQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteFormulaDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteFormulaFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteFormulaDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.getSalesQuoteFormulaDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {SalesQuoteFormulaFormData} [salesQuoteFormulaFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteFormula(salesQuoteFormulaFormData?: SalesQuoteFormulaFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteFormula(salesQuoteFormulaFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.postSalesQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<SalesQuoteFormulaListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteFormulaPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.postSalesQuoteFormulaPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteFormulaSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.postSalesQuoteFormulaSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteFormulaFormData>} [customUpdateModelSalesQuoteFormulaFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData?: CustomUpdateModel<SalesQuoteFormulaFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteFormulaApi.putSalesQuoteFormula']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * SalesQuoteFormulaApi - factory interface
 * 对外报价公式 接口
 * @export
 */
export const SalesQuoteFormulaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesQuoteFormulaApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteSalesQuoteFormula(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteFormula(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormulaFormData>> {
            return localVarFp.getSalesQuoteFormula(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteFormulaDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormulaFormData>> {
            return localVarFp.getSalesQuoteFormulaDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {SalesQuoteFormulaFormData} [salesQuoteFormulaFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteFormula(salesQuoteFormulaFormData?: SalesQuoteFormulaFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteFormula(salesQuoteFormulaFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteFormulaListData>>> {
            return localVarFp.postSalesQuoteFormulaPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteFormulaSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteFormulaFormData>} [customUpdateModelSalesQuoteFormulaFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData?: CustomUpdateModel<SalesQuoteFormulaFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesQuoteFormulaApi - interface
 * 对外报价公式 接口
 * @export
 * @interface SalesQuoteFormulaApi
 */
export interface SalesQuoteFormulaApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    deleteSalesQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    getSalesQuoteFormula(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormulaFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    getSalesQuoteFormulaDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteFormulaFormData>>;

    /**
     * 新增
     * @param {SalesQuoteFormulaFormData} [salesQuoteFormulaFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    postSalesQuoteFormula(salesQuoteFormulaFormData?: SalesQuoteFormulaFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    postSalesQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteFormulaListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    postSalesQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteFormulaFormData>} [customUpdateModelSalesQuoteFormulaFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApiInterface
     */
    putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData?: CustomUpdateModel<SalesQuoteFormulaFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * SalesQuoteFormulaApi - object-oriented interface
 * 对外报价公式 接口
 * @export
 * @class SalesQuoteFormulaApi
 * @extends {BaseAPI}
 */
export class SalesQuoteFormulaApi extends BaseAPI implements SalesQuoteFormulaApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public deleteSalesQuoteFormula(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).deleteSalesQuoteFormula(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public getSalesQuoteFormula(id: number, options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).getSalesQuoteFormula(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public getSalesQuoteFormulaDefault(options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).getSalesQuoteFormulaDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {SalesQuoteFormulaFormData} [salesQuoteFormulaFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public postSalesQuoteFormula(salesQuoteFormulaFormData?: SalesQuoteFormulaFormData, options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).postSalesQuoteFormula(salesQuoteFormulaFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public postSalesQuoteFormulaPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).postSalesQuoteFormulaPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public postSalesQuoteFormulaSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).postSalesQuoteFormulaSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteFormulaFormData>} [customUpdateModelSalesQuoteFormulaFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteFormulaApi
     */
    public putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData?: CustomUpdateModel<SalesQuoteFormulaFormData>, options?: RawAxiosRequestConfig) {
        return SalesQuoteFormulaApiFp(this.configuration).putSalesQuoteFormula(customUpdateModelSalesQuoteFormulaFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SalesQuoteTemplateApi - axios parameter creator
 * 对外报价模板 接口
 * @export
 */
export const SalesQuoteTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuoteTemplate: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteSalesQuoteTemplate', 'ids', ids)
            const localVarPath = `/api/salesQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteTemplate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalesQuoteTemplate', 'id', id)
            const localVarPath = `/api/salesQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteTemplateDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteTemplate/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {SalesQuoteTemplateFormData} [salesQuoteTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteTemplate: async (salesQuoteTemplateFormData?: SalesQuoteTemplateFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesQuoteTemplateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteTemplatePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteTemplate/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteTemplateSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteTemplate/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteTemplateFormData>} [customUpdateModelSalesQuoteTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuoteTemplate: async (customUpdateModelSalesQuoteTemplateFormData?: CustomUpdateModel<SalesQuoteTemplateFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelSalesQuoteTemplateFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesQuoteTemplateApi - functional programming interface
 * 对外报价模板 接口
 * @export
 */
export const SalesQuoteTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesQuoteTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSalesQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSalesQuoteTemplate(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.deleteSalesQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteTemplate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteTemplateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteTemplate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.getSalesQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteTemplateDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteTemplateFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteTemplateDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.getSalesQuoteTemplateDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {SalesQuoteTemplateFormData} [salesQuoteTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteTemplate(salesQuoteTemplateFormData?: SalesQuoteTemplateFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteTemplate(salesQuoteTemplateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.postSalesQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<SalesQuoteTemplateListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteTemplatePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.postSalesQuoteTemplatePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteTemplateSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.postSalesQuoteTemplateSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteTemplateFormData>} [customUpdateModelSalesQuoteTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData?: CustomUpdateModel<SalesQuoteTemplateFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteTemplateApi.putSalesQuoteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * SalesQuoteTemplateApi - factory interface
 * 对外报价模板 接口
 * @export
 */
export const SalesQuoteTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesQuoteTemplateApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteSalesQuoteTemplate(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteTemplate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteTemplateFormData>> {
            return localVarFp.getSalesQuoteTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteTemplateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteTemplateFormData>> {
            return localVarFp.getSalesQuoteTemplateDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {SalesQuoteTemplateFormData} [salesQuoteTemplateFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteTemplate(salesQuoteTemplateFormData?: SalesQuoteTemplateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteTemplate(salesQuoteTemplateFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteTemplateListData>>> {
            return localVarFp.postSalesQuoteTemplatePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteTemplateSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteTemplateFormData>} [customUpdateModelSalesQuoteTemplateFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData?: CustomUpdateModel<SalesQuoteTemplateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesQuoteTemplateApi - interface
 * 对外报价模板 接口
 * @export
 * @interface SalesQuoteTemplateApi
 */
export interface SalesQuoteTemplateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    deleteSalesQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    getSalesQuoteTemplate(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteTemplateFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    getSalesQuoteTemplateDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteTemplateFormData>>;

    /**
     * 新增
     * @param {SalesQuoteTemplateFormData} [salesQuoteTemplateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    postSalesQuoteTemplate(salesQuoteTemplateFormData?: SalesQuoteTemplateFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    postSalesQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteTemplateListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    postSalesQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteTemplateFormData>} [customUpdateModelSalesQuoteTemplateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApiInterface
     */
    putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData?: CustomUpdateModel<SalesQuoteTemplateFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * SalesQuoteTemplateApi - object-oriented interface
 * 对外报价模板 接口
 * @export
 * @class SalesQuoteTemplateApi
 * @extends {BaseAPI}
 */
export class SalesQuoteTemplateApi extends BaseAPI implements SalesQuoteTemplateApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public deleteSalesQuoteTemplate(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).deleteSalesQuoteTemplate(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public getSalesQuoteTemplate(id: number, options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).getSalesQuoteTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public getSalesQuoteTemplateDefault(options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).getSalesQuoteTemplateDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {SalesQuoteTemplateFormData} [salesQuoteTemplateFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public postSalesQuoteTemplate(salesQuoteTemplateFormData?: SalesQuoteTemplateFormData, options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).postSalesQuoteTemplate(salesQuoteTemplateFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public postSalesQuoteTemplatePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).postSalesQuoteTemplatePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public postSalesQuoteTemplateSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).postSalesQuoteTemplateSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteTemplateFormData>} [customUpdateModelSalesQuoteTemplateFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteTemplateApi
     */
    public putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData?: CustomUpdateModel<SalesQuoteTemplateFormData>, options?: RawAxiosRequestConfig) {
        return SalesQuoteTemplateApiFp(this.configuration).putSalesQuoteTemplate(customUpdateModelSalesQuoteTemplateFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SalesQuoteWithApi - axios parameter creator
 * 对外报价关联 接口
 * @export
 */
export const SalesQuoteWithApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuoteWith: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteSalesQuoteWith', 'ids', ids)
            const localVarPath = `/api/salesQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteWith: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalesQuoteWith', 'id', id)
            const localVarPath = `/api/salesQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteWithDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteWith/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {SalesQuoteWithFormData} [salesQuoteWithFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteWith: async (salesQuoteWithFormData?: SalesQuoteWithFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesQuoteWithFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteWithPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteWith/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteWithSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteWith/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteWithFormData>} [customUpdateModelSalesQuoteWithFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuoteWith: async (customUpdateModelSalesQuoteWithFormData?: CustomUpdateModel<SalesQuoteWithFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesQuoteWith`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelSalesQuoteWithFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesQuoteWithApi - functional programming interface
 * 对外报价关联 接口
 * @export
 */
export const SalesQuoteWithApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesQuoteWithApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSalesQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSalesQuoteWith(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.deleteSalesQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteWith(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteWithFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteWith(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.getSalesQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesQuoteWithDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<SalesQuoteWithFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesQuoteWithDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.getSalesQuoteWithDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {SalesQuoteWithFormData} [salesQuoteWithFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteWith(salesQuoteWithFormData?: SalesQuoteWithFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteWith(salesQuoteWithFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.postSalesQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<SalesQuoteWithListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteWithPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.postSalesQuoteWithPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSalesQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSalesQuoteWithSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.postSalesQuoteWithSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteWithFormData>} [customUpdateModelSalesQuoteWithFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData?: CustomUpdateModel<SalesQuoteWithFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesQuoteWithApi.putSalesQuoteWith']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * SalesQuoteWithApi - factory interface
 * 对外报价关联 接口
 * @export
 */
export const SalesQuoteWithApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesQuoteWithApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteSalesQuoteWith(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteWith(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteWithFormData>> {
            return localVarFp.getSalesQuoteWith(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesQuoteWithDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteWithFormData>> {
            return localVarFp.getSalesQuoteWithDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {SalesQuoteWithFormData} [salesQuoteWithFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteWith(salesQuoteWithFormData?: SalesQuoteWithFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteWith(salesQuoteWithFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteWithListData>>> {
            return localVarFp.postSalesQuoteWithPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSalesQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postSalesQuoteWithSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<SalesQuoteWithFormData>} [customUpdateModelSalesQuoteWithFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData?: CustomUpdateModel<SalesQuoteWithFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesQuoteWithApi - interface
 * 对外报价关联 接口
 * @export
 * @interface SalesQuoteWithApi
 */
export interface SalesQuoteWithApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    deleteSalesQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    getSalesQuoteWith(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteWithFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    getSalesQuoteWithDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<SalesQuoteWithFormData>>;

    /**
     * 新增
     * @param {SalesQuoteWithFormData} [salesQuoteWithFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    postSalesQuoteWith(salesQuoteWithFormData?: SalesQuoteWithFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    postSalesQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<SalesQuoteWithListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    postSalesQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteWithFormData>} [customUpdateModelSalesQuoteWithFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApiInterface
     */
    putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData?: CustomUpdateModel<SalesQuoteWithFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * SalesQuoteWithApi - object-oriented interface
 * 对外报价关联 接口
 * @export
 * @class SalesQuoteWithApi
 * @extends {BaseAPI}
 */
export class SalesQuoteWithApi extends BaseAPI implements SalesQuoteWithApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public deleteSalesQuoteWith(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).deleteSalesQuoteWith(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public getSalesQuoteWith(id: number, options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).getSalesQuoteWith(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public getSalesQuoteWithDefault(options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).getSalesQuoteWithDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {SalesQuoteWithFormData} [salesQuoteWithFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public postSalesQuoteWith(salesQuoteWithFormData?: SalesQuoteWithFormData, options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).postSalesQuoteWith(salesQuoteWithFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public postSalesQuoteWithPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).postSalesQuoteWithPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public postSalesQuoteWithSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).postSalesQuoteWithSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<SalesQuoteWithFormData>} [customUpdateModelSalesQuoteWithFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesQuoteWithApi
     */
    public putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData?: CustomUpdateModel<SalesQuoteWithFormData>, options?: RawAxiosRequestConfig) {
        return SalesQuoteWithApiFp(this.configuration).putSalesQuoteWith(customUpdateModelSalesQuoteWithFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShippingCustomersApi - axios parameter creator
 * 发货客户 接口
 * @export
 */
export const ShippingCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShippingCustomers: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteShippingCustomers', 'ids', ids)
            const localVarPath = `/api/shippingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingCustomers: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShippingCustomers', 'id', id)
            const localVarPath = `/api/shippingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingCustomersDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shippingCustomers/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ShippingCustomersFormData} [shippingCustomersFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomers: async (shippingCustomersFormData?: ShippingCustomersFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shippingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shippingCustomersFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomersImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shippingCustomers/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomersPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shippingCustomers/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomersSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shippingCustomers/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShippingCustomersFormData>} [customUpdateModelShippingCustomersFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShippingCustomers: async (customUpdateModelShippingCustomersFormData?: CustomUpdateModel<ShippingCustomersFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shippingCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelShippingCustomersFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingCustomersApi - functional programming interface
 * 发货客户 接口
 * @export
 */
export const ShippingCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShippingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShippingCustomers(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.deleteShippingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingCustomers(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShippingCustomersFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShippingCustomers(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.getShippingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingCustomersDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShippingCustomersFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShippingCustomersDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.getShippingCustomersDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ShippingCustomersFormData} [shippingCustomersFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShippingCustomers(shippingCustomersFormData?: ShippingCustomersFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShippingCustomers(shippingCustomersFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.postShippingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShippingCustomersImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShippingCustomersImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.postShippingCustomersImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShippingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ShippingCustomersListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShippingCustomersPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.postShippingCustomersPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShippingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShippingCustomersSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.postShippingCustomersSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShippingCustomersFormData>} [customUpdateModelShippingCustomersFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShippingCustomers(customUpdateModelShippingCustomersFormData?: CustomUpdateModel<ShippingCustomersFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putShippingCustomers(customUpdateModelShippingCustomersFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingCustomersApi.putShippingCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ShippingCustomersApi - factory interface
 * 发货客户 接口
 * @export
 */
export const ShippingCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingCustomersApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShippingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteShippingCustomers(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingCustomers(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShippingCustomersFormData>> {
            return localVarFp.getShippingCustomers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingCustomersDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShippingCustomersFormData>> {
            return localVarFp.getShippingCustomersDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ShippingCustomersFormData} [shippingCustomersFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomers(shippingCustomersFormData?: ShippingCustomersFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postShippingCustomers(shippingCustomersFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomersImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postShippingCustomersImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShippingCustomersListData>>> {
            return localVarFp.postShippingCustomersPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShippingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postShippingCustomersSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShippingCustomersFormData>} [customUpdateModelShippingCustomersFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShippingCustomers(customUpdateModelShippingCustomersFormData?: CustomUpdateModel<ShippingCustomersFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putShippingCustomers(customUpdateModelShippingCustomersFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingCustomersApi - interface
 * 发货客户 接口
 * @export
 * @interface ShippingCustomersApi
 */
export interface ShippingCustomersApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    deleteShippingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    getShippingCustomers(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShippingCustomersFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    getShippingCustomersDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShippingCustomersFormData>>;

    /**
     * 新增
     * @param {ShippingCustomersFormData} [shippingCustomersFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    postShippingCustomers(shippingCustomersFormData?: ShippingCustomersFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    postShippingCustomersImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    postShippingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShippingCustomersListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    postShippingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ShippingCustomersFormData>} [customUpdateModelShippingCustomersFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApiInterface
     */
    putShippingCustomers(customUpdateModelShippingCustomersFormData?: CustomUpdateModel<ShippingCustomersFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ShippingCustomersApi - object-oriented interface
 * 发货客户 接口
 * @export
 * @class ShippingCustomersApi
 * @extends {BaseAPI}
 */
export class ShippingCustomersApi extends BaseAPI implements ShippingCustomersApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public deleteShippingCustomers(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).deleteShippingCustomers(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public getShippingCustomers(id: number, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).getShippingCustomers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public getShippingCustomersDefault(options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).getShippingCustomersDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ShippingCustomersFormData} [shippingCustomersFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public postShippingCustomers(shippingCustomersFormData?: ShippingCustomersFormData, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).postShippingCustomers(shippingCustomersFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public postShippingCustomersImport(file?: File, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).postShippingCustomersImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public postShippingCustomersPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).postShippingCustomersPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public postShippingCustomersSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).postShippingCustomersSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ShippingCustomersFormData>} [customUpdateModelShippingCustomersFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCustomersApi
     */
    public putShippingCustomers(customUpdateModelShippingCustomersFormData?: CustomUpdateModel<ShippingCustomersFormData>, options?: RawAxiosRequestConfig) {
        return ShippingCustomersApiFp(this.configuration).putShippingCustomers(customUpdateModelShippingCustomersFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShortTransportApi - axios parameter creator
 * 短驳 接口
 * @export
 */
export const ShortTransportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortTransport: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteShortTransport', 'ids', ids)
            const localVarPath = `/api/shortTransport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransport: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShortTransport', 'id', id)
            const localVarPath = `/api/shortTransport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransport/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ShortTransportFormData} [shortTransportFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransport: async (shortTransportFormData?: ShortTransportFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shortTransportFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransport/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransport/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportFormData>} [customUpdateModelShortTransportFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortTransport: async (customUpdateModelShortTransportFormData?: CustomUpdateModel<ShortTransportFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelShortTransportFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTransportApi - functional programming interface
 * 短驳 接口
 * @export
 */
export const ShortTransportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTransportApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShortTransport(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShortTransport(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.deleteShortTransport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortTransport(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShortTransportFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortTransport(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.getShortTransport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortTransportDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShortTransportFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortTransportDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.getShortTransportDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ShortTransportFormData} [shortTransportFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransport(shortTransportFormData?: ShortTransportFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransport(shortTransportFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.postShortTransport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransportPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ShortTransportListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransportPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.postShortTransportPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransportSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransportSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.postShortTransportSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportFormData>} [customUpdateModelShortTransportFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShortTransport(customUpdateModelShortTransportFormData?: CustomUpdateModel<ShortTransportFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putShortTransport(customUpdateModelShortTransportFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportApi.putShortTransport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ShortTransportApi - factory interface
 * 短驳 接口
 * @export
 */
export const ShortTransportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTransportApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortTransport(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteShortTransport(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransport(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportFormData>> {
            return localVarFp.getShortTransport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportFormData>> {
            return localVarFp.getShortTransportDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ShortTransportFormData} [shortTransportFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransport(shortTransportFormData?: ShortTransportFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postShortTransport(shortTransportFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShortTransportListData>>> {
            return localVarFp.postShortTransportPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postShortTransportSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportFormData>} [customUpdateModelShortTransportFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortTransport(customUpdateModelShortTransportFormData?: CustomUpdateModel<ShortTransportFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putShortTransport(customUpdateModelShortTransportFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTransportApi - interface
 * 短驳 接口
 * @export
 * @interface ShortTransportApi
 */
export interface ShortTransportApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    deleteShortTransport(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    getShortTransport(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    getShortTransportDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportFormData>>;

    /**
     * 新增
     * @param {ShortTransportFormData} [shortTransportFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    postShortTransport(shortTransportFormData?: ShortTransportFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    postShortTransportPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShortTransportListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    postShortTransportSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ShortTransportFormData>} [customUpdateModelShortTransportFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApiInterface
     */
    putShortTransport(customUpdateModelShortTransportFormData?: CustomUpdateModel<ShortTransportFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ShortTransportApi - object-oriented interface
 * 短驳 接口
 * @export
 * @class ShortTransportApi
 * @extends {BaseAPI}
 */
export class ShortTransportApi extends BaseAPI implements ShortTransportApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public deleteShortTransport(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).deleteShortTransport(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public getShortTransport(id: number, options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).getShortTransport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public getShortTransportDefault(options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).getShortTransportDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ShortTransportFormData} [shortTransportFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public postShortTransport(shortTransportFormData?: ShortTransportFormData, options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).postShortTransport(shortTransportFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public postShortTransportPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).postShortTransportPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public postShortTransportSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).postShortTransportSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ShortTransportFormData>} [customUpdateModelShortTransportFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportApi
     */
    public putShortTransport(customUpdateModelShortTransportFormData?: CustomUpdateModel<ShortTransportFormData>, options?: RawAxiosRequestConfig) {
        return ShortTransportApiFp(this.configuration).putShortTransport(customUpdateModelShortTransportFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShortTransportCostApi - axios parameter creator
 * 短驳费用 接口
 * @export
 */
export const ShortTransportCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortTransportCost: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteShortTransportCost', 'ids', ids)
            const localVarPath = `/api/shortTransportCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportCost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShortTransportCost', 'id', id)
            const localVarPath = `/api/shortTransportCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportCostDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportCost/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ShortTransportCostFormData} [shortTransportCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportCost: async (shortTransportCostFormData?: ShortTransportCostFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shortTransportCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportCostPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportCost/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportCostFormData>} [customUpdateModelShortTransportCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortTransportCost: async (customUpdateModelShortTransportCostFormData?: CustomUpdateModel<ShortTransportCostFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelShortTransportCostFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTransportCostApi - functional programming interface
 * 短驳费用 接口
 * @export
 */
export const ShortTransportCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTransportCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShortTransportCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShortTransportCost(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportCostApi.deleteShortTransportCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortTransportCost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShortTransportCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortTransportCost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportCostApi.getShortTransportCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortTransportCostDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShortTransportCostFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortTransportCostDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportCostApi.getShortTransportCostDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ShortTransportCostFormData} [shortTransportCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransportCost(shortTransportCostFormData?: ShortTransportCostFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransportCost(shortTransportCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportCostApi.postShortTransportCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransportCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ShortTransportCostListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransportCostPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportCostApi.postShortTransportCostPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportCostFormData>} [customUpdateModelShortTransportCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShortTransportCost(customUpdateModelShortTransportCostFormData?: CustomUpdateModel<ShortTransportCostFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putShortTransportCost(customUpdateModelShortTransportCostFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportCostApi.putShortTransportCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ShortTransportCostApi - factory interface
 * 短驳费用 接口
 * @export
 */
export const ShortTransportCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTransportCostApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortTransportCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteShortTransportCost(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportCostFormData>> {
            return localVarFp.getShortTransportCost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportCostFormData>> {
            return localVarFp.getShortTransportCostDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ShortTransportCostFormData} [shortTransportCostFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportCost(shortTransportCostFormData?: ShortTransportCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postShortTransportCost(shortTransportCostFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShortTransportCostListData>>> {
            return localVarFp.postShortTransportCostPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportCostFormData>} [customUpdateModelShortTransportCostFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortTransportCost(customUpdateModelShortTransportCostFormData?: CustomUpdateModel<ShortTransportCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putShortTransportCost(customUpdateModelShortTransportCostFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTransportCostApi - interface
 * 短驳费用 接口
 * @export
 * @interface ShortTransportCostApi
 */
export interface ShortTransportCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApiInterface
     */
    deleteShortTransportCost(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApiInterface
     */
    getShortTransportCost(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportCostFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApiInterface
     */
    getShortTransportCostDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportCostFormData>>;

    /**
     * 新增
     * @param {ShortTransportCostFormData} [shortTransportCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApiInterface
     */
    postShortTransportCost(shortTransportCostFormData?: ShortTransportCostFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApiInterface
     */
    postShortTransportCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShortTransportCostListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ShortTransportCostFormData>} [customUpdateModelShortTransportCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApiInterface
     */
    putShortTransportCost(customUpdateModelShortTransportCostFormData?: CustomUpdateModel<ShortTransportCostFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ShortTransportCostApi - object-oriented interface
 * 短驳费用 接口
 * @export
 * @class ShortTransportCostApi
 * @extends {BaseAPI}
 */
export class ShortTransportCostApi extends BaseAPI implements ShortTransportCostApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApi
     */
    public deleteShortTransportCost(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ShortTransportCostApiFp(this.configuration).deleteShortTransportCost(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApi
     */
    public getShortTransportCost(id: number, options?: RawAxiosRequestConfig) {
        return ShortTransportCostApiFp(this.configuration).getShortTransportCost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApi
     */
    public getShortTransportCostDefault(options?: RawAxiosRequestConfig) {
        return ShortTransportCostApiFp(this.configuration).getShortTransportCostDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ShortTransportCostFormData} [shortTransportCostFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApi
     */
    public postShortTransportCost(shortTransportCostFormData?: ShortTransportCostFormData, options?: RawAxiosRequestConfig) {
        return ShortTransportCostApiFp(this.configuration).postShortTransportCost(shortTransportCostFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApi
     */
    public postShortTransportCostPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ShortTransportCostApiFp(this.configuration).postShortTransportCostPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ShortTransportCostFormData>} [customUpdateModelShortTransportCostFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportCostApi
     */
    public putShortTransportCost(customUpdateModelShortTransportCostFormData?: CustomUpdateModel<ShortTransportCostFormData>, options?: RawAxiosRequestConfig) {
        return ShortTransportCostApiFp(this.configuration).putShortTransportCost(customUpdateModelShortTransportCostFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShortTransportDetailsApi - axios parameter creator
 * 短驳明细 接口
 * @export
 */
export const ShortTransportDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortTransportDetails: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteShortTransportDetails', 'ids', ids)
            const localVarPath = `/api/shortTransportDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportDetails: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShortTransportDetails', 'id', id)
            const localVarPath = `/api/shortTransportDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportDetailsDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportDetails/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ShortTransportDetailsFormData} [shortTransportDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportDetails: async (shortTransportDetailsFormData?: ShortTransportDetailsFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shortTransportDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportDetailsPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportDetails/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportDetailsFormData>} [customUpdateModelShortTransportDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortTransportDetails: async (customUpdateModelShortTransportDetailsFormData?: CustomUpdateModel<ShortTransportDetailsFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shortTransportDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelShortTransportDetailsFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTransportDetailsApi - functional programming interface
 * 短驳明细 接口
 * @export
 */
export const ShortTransportDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTransportDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShortTransportDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShortTransportDetails(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportDetailsApi.deleteShortTransportDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortTransportDetails(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShortTransportDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortTransportDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportDetailsApi.getShortTransportDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortTransportDetailsDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ShortTransportDetailsFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortTransportDetailsDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportDetailsApi.getShortTransportDetailsDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ShortTransportDetailsFormData} [shortTransportDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransportDetails(shortTransportDetailsFormData?: ShortTransportDetailsFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransportDetails(shortTransportDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportDetailsApi.postShortTransportDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShortTransportDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ShortTransportDetailsListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShortTransportDetailsPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportDetailsApi.postShortTransportDetailsPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportDetailsFormData>} [customUpdateModelShortTransportDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShortTransportDetails(customUpdateModelShortTransportDetailsFormData?: CustomUpdateModel<ShortTransportDetailsFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putShortTransportDetails(customUpdateModelShortTransportDetailsFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortTransportDetailsApi.putShortTransportDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ShortTransportDetailsApi - factory interface
 * 短驳明细 接口
 * @export
 */
export const ShortTransportDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTransportDetailsApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortTransportDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteShortTransportDetails(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportDetailsFormData>> {
            return localVarFp.getShortTransportDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortTransportDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportDetailsFormData>> {
            return localVarFp.getShortTransportDetailsDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ShortTransportDetailsFormData} [shortTransportDetailsFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportDetails(shortTransportDetailsFormData?: ShortTransportDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postShortTransportDetails(shortTransportDetailsFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShortTransportDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShortTransportDetailsListData>>> {
            return localVarFp.postShortTransportDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ShortTransportDetailsFormData>} [customUpdateModelShortTransportDetailsFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShortTransportDetails(customUpdateModelShortTransportDetailsFormData?: CustomUpdateModel<ShortTransportDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putShortTransportDetails(customUpdateModelShortTransportDetailsFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTransportDetailsApi - interface
 * 短驳明细 接口
 * @export
 * @interface ShortTransportDetailsApi
 */
export interface ShortTransportDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApiInterface
     */
    deleteShortTransportDetails(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApiInterface
     */
    getShortTransportDetails(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportDetailsFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApiInterface
     */
    getShortTransportDetailsDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ShortTransportDetailsFormData>>;

    /**
     * 新增
     * @param {ShortTransportDetailsFormData} [shortTransportDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApiInterface
     */
    postShortTransportDetails(shortTransportDetailsFormData?: ShortTransportDetailsFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApiInterface
     */
    postShortTransportDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ShortTransportDetailsListData>>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ShortTransportDetailsFormData>} [customUpdateModelShortTransportDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApiInterface
     */
    putShortTransportDetails(customUpdateModelShortTransportDetailsFormData?: CustomUpdateModel<ShortTransportDetailsFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ShortTransportDetailsApi - object-oriented interface
 * 短驳明细 接口
 * @export
 * @class ShortTransportDetailsApi
 * @extends {BaseAPI}
 */
export class ShortTransportDetailsApi extends BaseAPI implements ShortTransportDetailsApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApi
     */
    public deleteShortTransportDetails(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ShortTransportDetailsApiFp(this.configuration).deleteShortTransportDetails(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApi
     */
    public getShortTransportDetails(id: number, options?: RawAxiosRequestConfig) {
        return ShortTransportDetailsApiFp(this.configuration).getShortTransportDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApi
     */
    public getShortTransportDetailsDefault(options?: RawAxiosRequestConfig) {
        return ShortTransportDetailsApiFp(this.configuration).getShortTransportDetailsDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ShortTransportDetailsFormData} [shortTransportDetailsFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApi
     */
    public postShortTransportDetails(shortTransportDetailsFormData?: ShortTransportDetailsFormData, options?: RawAxiosRequestConfig) {
        return ShortTransportDetailsApiFp(this.configuration).postShortTransportDetails(shortTransportDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApi
     */
    public postShortTransportDetailsPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ShortTransportDetailsApiFp(this.configuration).postShortTransportDetailsPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ShortTransportDetailsFormData>} [customUpdateModelShortTransportDetailsFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTransportDetailsApi
     */
    public putShortTransportDetails(customUpdateModelShortTransportDetailsFormData?: CustomUpdateModel<ShortTransportDetailsFormData>, options?: RawAxiosRequestConfig) {
        return ShortTransportDetailsApiFp(this.configuration).putShortTransportDetails(customUpdateModelShortTransportDetailsFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StockApi - axios parameter creator
 * 库存 接口
 * @export
 */
export const StockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStock: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteStock', 'ids', ids)
            const localVarPath = `/api/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStock: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStock', 'id', id)
            const localVarPath = `/api/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {StockFormData} [stockFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStock: async (stockFormData?: StockFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<StockFormData>} [customUpdateModelStockFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStock: async (customUpdateModelStockFormData?: CustomUpdateModel<StockFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelStockFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockApi - functional programming interface
 * 库存 接口
 * @export
 */
export const StockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStock(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStock(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.deleteStock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStock(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<StockFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStock(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.getStock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<StockFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.getStockDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {StockFormData} [stockFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStock(stockFormData?: StockFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStock(stockFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.postStock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStockPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<StockListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStockPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.postStockPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStockSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStockSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.postStockSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<StockFormData>} [customUpdateModelStockFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStock(customUpdateModelStockFormData?: CustomUpdateModel<StockFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStock(customUpdateModelStockFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockApi.putStock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * StockApi - factory interface
 * 库存 接口
 * @export
 */
export const StockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStock(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteStock(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStock(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<StockFormData>> {
            return localVarFp.getStock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<StockFormData>> {
            return localVarFp.getStockDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {StockFormData} [stockFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStock(stockFormData?: StockFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postStock(stockFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<StockListData>>> {
            return localVarFp.postStockPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postStockSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<StockFormData>} [customUpdateModelStockFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStock(customUpdateModelStockFormData?: CustomUpdateModel<StockFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putStock(customUpdateModelStockFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockApi - interface
 * 库存 接口
 * @export
 * @interface StockApi
 */
export interface StockApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    deleteStock(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    getStock(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<StockFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    getStockDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<StockFormData>>;

    /**
     * 新增
     * @param {StockFormData} [stockFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    postStock(stockFormData?: StockFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    postStockPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<StockListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    postStockSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<StockFormData>} [customUpdateModelStockFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApiInterface
     */
    putStock(customUpdateModelStockFormData?: CustomUpdateModel<StockFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * StockApi - object-oriented interface
 * 库存 接口
 * @export
 * @class StockApi
 * @extends {BaseAPI}
 */
export class StockApi extends BaseAPI implements StockApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public deleteStock(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).deleteStock(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getStock(id: number, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).getStock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getStockDefault(options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).getStockDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {StockFormData} [stockFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public postStock(stockFormData?: StockFormData, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).postStock(stockFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public postStockPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).postStockPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public postStockSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).postStockSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<StockFormData>} [customUpdateModelStockFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public putStock(customUpdateModelStockFormData?: CustomUpdateModel<StockFormData>, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).putStock(customUpdateModelStockFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StockLocationApi - axios parameter creator
 * 库位 接口
 * @export
 */
export const StockLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockLocation: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteStockLocation', 'ids', ids)
            const localVarPath = `/api/stockLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockLocation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStockLocation', 'id', id)
            const localVarPath = `/api/stockLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockLocationDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stockLocation/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {StockLocationFormData} [stockLocationFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockLocation: async (stockLocationFormData?: StockLocationFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stockLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockLocationFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockLocationPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stockLocation/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockLocationSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stockLocation/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<StockLocationFormData>} [customUpdateModelStockLocationFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStockLocation: async (customUpdateModelStockLocationFormData?: CustomUpdateModel<StockLocationFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stockLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelStockLocationFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockLocationApi - functional programming interface
 * 库位 接口
 * @export
 */
export const StockLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStockLocation(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStockLocation(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.deleteStockLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockLocation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<StockLocationFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockLocation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.getStockLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockLocationDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<StockLocationFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockLocationDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.getStockLocationDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {StockLocationFormData} [stockLocationFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStockLocation(stockLocationFormData?: StockLocationFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStockLocation(stockLocationFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.postStockLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStockLocationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<StockLocationListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStockLocationPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.postStockLocationPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStockLocationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStockLocationSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.postStockLocationSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<StockLocationFormData>} [customUpdateModelStockLocationFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStockLocation(customUpdateModelStockLocationFormData?: CustomUpdateModel<StockLocationFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStockLocation(customUpdateModelStockLocationFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockLocationApi.putStockLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * StockLocationApi - factory interface
 * 库位 接口
 * @export
 */
export const StockLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockLocationApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockLocation(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteStockLocation(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockLocation(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<StockLocationFormData>> {
            return localVarFp.getStockLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockLocationDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<StockLocationFormData>> {
            return localVarFp.getStockLocationDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {StockLocationFormData} [stockLocationFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockLocation(stockLocationFormData?: StockLocationFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postStockLocation(stockLocationFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockLocationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<StockLocationListData>>> {
            return localVarFp.postStockLocationPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStockLocationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postStockLocationSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<StockLocationFormData>} [customUpdateModelStockLocationFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStockLocation(customUpdateModelStockLocationFormData?: CustomUpdateModel<StockLocationFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putStockLocation(customUpdateModelStockLocationFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockLocationApi - interface
 * 库位 接口
 * @export
 * @interface StockLocationApi
 */
export interface StockLocationApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    deleteStockLocation(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    getStockLocation(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<StockLocationFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    getStockLocationDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<StockLocationFormData>>;

    /**
     * 新增
     * @param {StockLocationFormData} [stockLocationFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    postStockLocation(stockLocationFormData?: StockLocationFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    postStockLocationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<StockLocationListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    postStockLocationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<StockLocationFormData>} [customUpdateModelStockLocationFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApiInterface
     */
    putStockLocation(customUpdateModelStockLocationFormData?: CustomUpdateModel<StockLocationFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * StockLocationApi - object-oriented interface
 * 库位 接口
 * @export
 * @class StockLocationApi
 * @extends {BaseAPI}
 */
export class StockLocationApi extends BaseAPI implements StockLocationApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public deleteStockLocation(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).deleteStockLocation(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public getStockLocation(id: number, options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).getStockLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public getStockLocationDefault(options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).getStockLocationDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {StockLocationFormData} [stockLocationFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public postStockLocation(stockLocationFormData?: StockLocationFormData, options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).postStockLocation(stockLocationFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public postStockLocationPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).postStockLocationPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public postStockLocationSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).postStockLocationSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<StockLocationFormData>} [customUpdateModelStockLocationFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockLocationApi
     */
    public putStockLocation(customUpdateModelStockLocationFormData?: CustomUpdateModel<StockLocationFormData>, options?: RawAxiosRequestConfig) {
        return StockLocationApiFp(this.configuration).putStockLocation(customUpdateModelStockLocationFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ThirdPartyUserApi - axios parameter creator
 * 三方用户 接口
 * @export
 */
export const ThirdPartyUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThirdPartyUser: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteThirdPartyUser', 'ids', ids)
            const localVarPath = `/api/thirdPartyUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThirdPartyUser: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getThirdPartyUser', 'id', id)
            const localVarPath = `/api/thirdPartyUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThirdPartyUserDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/thirdPartyUser/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {ThirdPartyUserFormData} [thirdPartyUserFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThirdPartyUser: async (thirdPartyUserFormData?: ThirdPartyUserFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/thirdPartyUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(thirdPartyUserFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThirdPartyUserPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/thirdPartyUser/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThirdPartyUserSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/thirdPartyUser/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ThirdPartyUserFormData>} [customUpdateModelThirdPartyUserFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putThirdPartyUser: async (customUpdateModelThirdPartyUserFormData?: CustomUpdateModel<ThirdPartyUserFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/thirdPartyUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelThirdPartyUserFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThirdPartyUserApi - functional programming interface
 * 三方用户 接口
 * @export
 */
export const ThirdPartyUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThirdPartyUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThirdPartyUser(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThirdPartyUser(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.deleteThirdPartyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThirdPartyUser(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ThirdPartyUserFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThirdPartyUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.getThirdPartyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThirdPartyUserDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<ThirdPartyUserFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThirdPartyUserDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.getThirdPartyUserDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {ThirdPartyUserFormData} [thirdPartyUserFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postThirdPartyUser(thirdPartyUserFormData?: ThirdPartyUserFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postThirdPartyUser(thirdPartyUserFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.postThirdPartyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postThirdPartyUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<ThirdPartyUserListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postThirdPartyUserPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.postThirdPartyUserPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postThirdPartyUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postThirdPartyUserSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.postThirdPartyUserSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ThirdPartyUserFormData>} [customUpdateModelThirdPartyUserFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putThirdPartyUser(customUpdateModelThirdPartyUserFormData?: CustomUpdateModel<ThirdPartyUserFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putThirdPartyUser(customUpdateModelThirdPartyUserFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThirdPartyUserApi.putThirdPartyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * ThirdPartyUserApi - factory interface
 * 三方用户 接口
 * @export
 */
export const ThirdPartyUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThirdPartyUserApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThirdPartyUser(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteThirdPartyUser(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThirdPartyUser(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ThirdPartyUserFormData>> {
            return localVarFp.getThirdPartyUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThirdPartyUserDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ThirdPartyUserFormData>> {
            return localVarFp.getThirdPartyUserDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {ThirdPartyUserFormData} [thirdPartyUserFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThirdPartyUser(thirdPartyUserFormData?: ThirdPartyUserFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postThirdPartyUser(thirdPartyUserFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThirdPartyUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ThirdPartyUserListData>>> {
            return localVarFp.postThirdPartyUserPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThirdPartyUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postThirdPartyUserSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<ThirdPartyUserFormData>} [customUpdateModelThirdPartyUserFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putThirdPartyUser(customUpdateModelThirdPartyUserFormData?: CustomUpdateModel<ThirdPartyUserFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putThirdPartyUser(customUpdateModelThirdPartyUserFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThirdPartyUserApi - interface
 * 三方用户 接口
 * @export
 * @interface ThirdPartyUserApi
 */
export interface ThirdPartyUserApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    deleteThirdPartyUser(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    getThirdPartyUser(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<ThirdPartyUserFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    getThirdPartyUserDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<ThirdPartyUserFormData>>;

    /**
     * 新增
     * @param {ThirdPartyUserFormData} [thirdPartyUserFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    postThirdPartyUser(thirdPartyUserFormData?: ThirdPartyUserFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    postThirdPartyUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<ThirdPartyUserListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    postThirdPartyUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<ThirdPartyUserFormData>} [customUpdateModelThirdPartyUserFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApiInterface
     */
    putThirdPartyUser(customUpdateModelThirdPartyUserFormData?: CustomUpdateModel<ThirdPartyUserFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * ThirdPartyUserApi - object-oriented interface
 * 三方用户 接口
 * @export
 * @class ThirdPartyUserApi
 * @extends {BaseAPI}
 */
export class ThirdPartyUserApi extends BaseAPI implements ThirdPartyUserApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public deleteThirdPartyUser(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).deleteThirdPartyUser(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public getThirdPartyUser(id: number, options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).getThirdPartyUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public getThirdPartyUserDefault(options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).getThirdPartyUserDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {ThirdPartyUserFormData} [thirdPartyUserFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public postThirdPartyUser(thirdPartyUserFormData?: ThirdPartyUserFormData, options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).postThirdPartyUser(thirdPartyUserFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public postThirdPartyUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).postThirdPartyUserPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public postThirdPartyUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).postThirdPartyUserSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<ThirdPartyUserFormData>} [customUpdateModelThirdPartyUserFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyUserApi
     */
    public putThirdPartyUser(customUpdateModelThirdPartyUserFormData?: CustomUpdateModel<ThirdPartyUserFormData>, options?: RawAxiosRequestConfig) {
        return ThirdPartyUserApiFp(this.configuration).putThirdPartyUser(customUpdateModelThirdPartyUserFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TruckDataApi - axios parameter creator
 * 车辆资料 接口
 * @export
 */
export const TruckDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTruckData: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteTruckData', 'ids', ids)
            const localVarPath = `/api/truckData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckData: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTruckData', 'id', id)
            const localVarPath = `/api/truckData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckDataDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/truckData/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckDataKeyValueList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/truckData/keyValueList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {TruckDataFormData} [truckDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTruckData: async (truckDataFormData?: TruckDataFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/truckData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(truckDataFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTruckDataPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/truckData/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTruckDataSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/truckData/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<TruckDataFormData>} [customUpdateModelTruckDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTruckData: async (customUpdateModelTruckDataFormData?: CustomUpdateModel<TruckDataFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/truckData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelTruckDataFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TruckDataApi - functional programming interface
 * 车辆资料 接口
 * @export
 */
export const TruckDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TruckDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTruckData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTruckData(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.deleteTruckData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTruckData(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<TruckDataFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.getTruckData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTruckDataDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<TruckDataFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckDataDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.getTruckDataDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTruckDataKeyValueList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckDataKeyValueList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.getTruckDataKeyValueList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {TruckDataFormData} [truckDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTruckData(truckDataFormData?: TruckDataFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTruckData(truckDataFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.postTruckData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTruckDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<TruckDataListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTruckDataPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.postTruckDataPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTruckDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTruckDataSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.postTruckDataSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<TruckDataFormData>} [customUpdateModelTruckDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTruckData(customUpdateModelTruckDataFormData?: CustomUpdateModel<TruckDataFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTruckData(customUpdateModelTruckDataFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TruckDataApi.putTruckData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * TruckDataApi - factory interface
 * 车辆资料 接口
 * @export
 */
export const TruckDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TruckDataApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTruckData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteTruckData(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckData(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<TruckDataFormData>> {
            return localVarFp.getTruckData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckDataDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<TruckDataFormData>> {
            return localVarFp.getTruckDataDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckDataKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getTruckDataKeyValueList(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {TruckDataFormData} [truckDataFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTruckData(truckDataFormData?: TruckDataFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postTruckData(truckDataFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTruckDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<TruckDataListData>>> {
            return localVarFp.postTruckDataPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTruckDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postTruckDataSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<TruckDataFormData>} [customUpdateModelTruckDataFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTruckData(customUpdateModelTruckDataFormData?: CustomUpdateModel<TruckDataFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putTruckData(customUpdateModelTruckDataFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TruckDataApi - interface
 * 车辆资料 接口
 * @export
 * @interface TruckDataApi
 */
export interface TruckDataApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    deleteTruckData(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    getTruckData(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<TruckDataFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    getTruckDataDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<TruckDataFormData>>;

    /**
     * 获取键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    getTruckDataKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 新增
     * @param {TruckDataFormData} [truckDataFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    postTruckData(truckDataFormData?: TruckDataFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    postTruckDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<TruckDataListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    postTruckDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<TruckDataFormData>} [customUpdateModelTruckDataFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApiInterface
     */
    putTruckData(customUpdateModelTruckDataFormData?: CustomUpdateModel<TruckDataFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * TruckDataApi - object-oriented interface
 * 车辆资料 接口
 * @export
 * @class TruckDataApi
 * @extends {BaseAPI}
 */
export class TruckDataApi extends BaseAPI implements TruckDataApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public deleteTruckData(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).deleteTruckData(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public getTruckData(id: number, options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).getTruckData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public getTruckDataDefault(options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).getTruckDataDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public getTruckDataKeyValueList(options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).getTruckDataKeyValueList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {TruckDataFormData} [truckDataFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public postTruckData(truckDataFormData?: TruckDataFormData, options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).postTruckData(truckDataFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public postTruckDataPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).postTruckDataPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public postTruckDataSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).postTruckDataSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<TruckDataFormData>} [customUpdateModelTruckDataFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TruckDataApi
     */
    public putTruckData(customUpdateModelTruckDataFormData?: CustomUpdateModel<TruckDataFormData>, options?: RawAxiosRequestConfig) {
        return TruckDataApiFp(this.configuration).putTruckData(customUpdateModelTruckDataFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * 用户接口
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteUser', 'ids', ids)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserKeyValueList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/keyValueList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {UserFormData} [userFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser: async (userFormData?: UserFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改锁屏密码
         * @param {UserChangePinInput} [userChangePinInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserChangePin: async (userChangePinInput?: UserChangePinInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/changePin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userChangePinInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改用户密码
         * @param {UserChangePwdInput} [userChangePwdInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserChangePwd: async (userChangePwdInput?: UserChangePwdInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/changePwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userChangePwdInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取用户资料分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 清空用户锁屏密码
         * @param {number} [id] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserResetPin: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/resetPin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 重置用户密码
         * @param {number} id 用户Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserResetPwd: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postUserResetPwd', 'id', id)
            const localVarPath = `/api/user/resetPwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 设置锁屏密码
         * @param {string} [pin] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetPin: async (pin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/setPin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pin !== undefined) {
                localVarQueryParameter['pin'] = pin;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<UserFormData>} [customUpdateModelUserFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser: async (customUpdateModelUserFormData?: CustomUpdateModel<UserFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelUserFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * 用户接口
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UserFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<UserFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserKeyValueList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueItem[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserKeyValueList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserKeyValueList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {UserFormData} [userFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUser(userFormData?: UserFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUser(userFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改锁屏密码
         * @param {UserChangePinInput} [userChangePinInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserChangePin(userChangePinInput?: UserChangePinInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserChangePin(userChangePinInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserChangePin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改用户密码
         * @param {UserChangePwdInput} [userChangePwdInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserChangePwd(userChangePwdInput?: UserChangePwdInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserChangePwd(userChangePwdInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserChangePwd']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取用户资料分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<UserListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 清空用户锁屏密码
         * @param {number} [id] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserResetPin(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserResetPin(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserResetPin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 重置用户密码
         * @param {number} id 用户Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserResetPwd(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserResetPwd(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserResetPwd']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 设置锁屏密码
         * @param {string} [pin] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserSetPin(pin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserSetPin(pin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserSetPin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postUserSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<UserFormData>} [customUpdateModelUserFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUser(customUpdateModelUserFormData?: CustomUpdateModel<UserFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUser(customUpdateModelUserFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.putUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * 用户接口
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteUser(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<UserFormData>> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<UserFormData>> {
            return localVarFp.getUserDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取键值对列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>> {
            return localVarFp.getUserKeyValueList(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {UserFormData} [userFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(userFormData?: UserFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postUser(userFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改锁屏密码
         * @param {UserChangePinInput} [userChangePinInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserChangePin(userChangePinInput?: UserChangePinInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postUserChangePin(userChangePinInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改用户密码
         * @param {UserChangePwdInput} [userChangePwdInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserChangePwd(userChangePwdInput?: UserChangePwdInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postUserChangePwd(userChangePwdInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取用户资料分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<UserListData>>> {
            return localVarFp.postUserPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 清空用户锁屏密码
         * @param {number} [id] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserResetPin(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postUserResetPin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 重置用户密码
         * @param {number} id 用户Id
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserResetPwd(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>> {
            return localVarFp.postUserResetPwd(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 设置锁屏密码
         * @param {string} [pin] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetPin(pin?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postUserSetPin(pin, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postUserSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<UserFormData>} [customUpdateModelUserFormData] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(customUpdateModelUserFormData?: CustomUpdateModel<UserFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putUser(customUpdateModelUserFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * 用户接口
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteUser(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUser(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<UserFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<UserFormData>>;

    /**
     * 获取键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserKeyValueList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueItem[]>>;

    /**
     * 新增
     * @param {UserFormData} [userFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUser(userFormData?: UserFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改锁屏密码
     * @param {UserChangePinInput} [userChangePinInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserChangePin(userChangePinInput?: UserChangePinInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改用户密码
     * @param {UserChangePwdInput} [userChangePwdInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserChangePwd(userChangePwdInput?: UserChangePwdInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取用户资料分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<UserListData>>>;

    /**
     * 清空用户锁屏密码
     * @param {number} [id] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserResetPin(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 重置用户密码
     * @param {number} id 用户Id
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserResetPwd(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<string>>;

    /**
     * 设置锁屏密码
     * @param {string} [pin] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserSetPin(pin?: string, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<UserFormData>} [customUpdateModelUserFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putUser(customUpdateModelUserFormData?: CustomUpdateModel<UserFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * UserApi - object-oriented interface
 * 用户接口
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserDefault(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取键值对列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserKeyValueList(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserKeyValueList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {UserFormData} [userFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUser(userFormData?: UserFormData, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUser(userFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改锁屏密码
     * @param {UserChangePinInput} [userChangePinInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserChangePin(userChangePinInput?: UserChangePinInput, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserChangePin(userChangePinInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改用户密码
     * @param {UserChangePwdInput} [userChangePwdInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserChangePwd(userChangePwdInput?: UserChangePwdInput, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserChangePwd(userChangePwdInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取用户资料分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 清空用户锁屏密码
     * @param {number} [id] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserResetPin(id?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserResetPin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 重置用户密码
     * @param {number} id 用户Id
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserResetPwd(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserResetPwd(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 设置锁屏密码
     * @param {string} [pin] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserSetPin(pin?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserSetPin(pin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<UserFormData>} [customUpdateModelUserFormData] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUser(customUpdateModelUserFormData?: CustomUpdateModel<UserFormData>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).putUser(customUpdateModelUserFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserOrgRoleApi - axios parameter creator
 * 用户机构和角色接口
 * @export
 */
export const UserOrgRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取当前用户所有机构键值对树列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrgRoleUserAllOrgList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userOrgRole/userAllOrgList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserOrgRoleApi - functional programming interface
 * 用户机构和角色接口
 * @export
 */
export const UserOrgRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserOrgRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取当前用户所有机构键值对树列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOrgRoleUserAllOrgList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<KeyValueTree[]>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrgRoleUserAllOrgList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserOrgRoleApi.getUserOrgRoleUserAllOrgList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * UserOrgRoleApi - factory interface
 * 用户机构和角色接口
 * @export
 */
export const UserOrgRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserOrgRoleApiFp(configuration)
    return {
        /**
         * 获取当前用户所有机构键值对树列表
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrgRoleUserAllOrgList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>> {
            return localVarFp.getUserOrgRoleUserAllOrgList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserOrgRoleApi - interface
 * 用户机构和角色接口
 * @export
 * @interface UserOrgRoleApi
 */
export interface UserOrgRoleApiInterface {
    /**
     * 获取当前用户所有机构键值对树列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrgRoleApiInterface
     */
    getUserOrgRoleUserAllOrgList(options?: RawAxiosRequestConfig): Promise<UniformResult<KeyValueTree[]>>;

}

/**
 * UserOrgRoleApi - object-oriented interface
 * 用户机构和角色接口
 * @export
 * @class UserOrgRoleApi
 * @extends {BaseAPI}
 */
export class UserOrgRoleApi extends BaseAPI implements UserOrgRoleApiInterface {
    /**
     * 获取当前用户所有机构键值对树列表
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrgRoleApi
     */
    public getUserOrgRoleUserAllOrgList(options?: RawAxiosRequestConfig) {
        return UserOrgRoleApiFp(this.configuration).getUserOrgRoleUserAllOrgList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WaybillApi - axios parameter creator
 * 运单 接口
 * @export
 */
export const WaybillApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybill: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteWaybill', 'ids', ids)
            const localVarPath = `/api/waybill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybill: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWaybill', 'id', id)
            const localVarPath = `/api/waybill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillExport: async (queryInput?: Array<DynamicFilterModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryInput) {
                localVarQueryParameter['queryInput'] = queryInput;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillPrint: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {WaybillFormData} [waybillFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybill: async (waybillFormData?: WaybillFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waybillFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillImport: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {DynamicUpdateModel} [dynamicUpdateModel] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybill: async (dynamicUpdateModel?: DynamicUpdateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WaybillApi - functional programming interface
 * 运单 接口
 * @export
 */
export const WaybillApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WaybillApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWaybill(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWaybill(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.deleteWaybill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybill(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybill(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.getWaybill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.getWaybillDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillExport(queryInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.getWaybillExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillPrint(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillPrint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillPrint(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.getWaybillPrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {WaybillFormData} [waybillFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybill(waybillFormData?: WaybillFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybill(waybillFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.postWaybill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillImport(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<Object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillImport(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.postWaybillImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<WaybillListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.postWaybillPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.postWaybillSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {DynamicUpdateModel} [dynamicUpdateModel] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWaybill(dynamicUpdateModel?: DynamicUpdateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWaybill(dynamicUpdateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillApi.putWaybill']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * WaybillApi - factory interface
 * 运单 接口
 * @export
 */
export const WaybillApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WaybillApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybill(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteWaybill(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybill(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillFormData>> {
            return localVarFp.getWaybill(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillFormData>> {
            return localVarFp.getWaybillDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 导出
         * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File> {
            return localVarFp.getWaybillExport(queryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取打印数据
         * @param {number} [id] 主键
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillPrint>> {
            return localVarFp.getWaybillPrint(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {WaybillFormData} [waybillFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybill(waybillFormData?: WaybillFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybill(waybillFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 导入
         * @param {File} [file] 文件流
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>> {
            return localVarFp.postWaybillImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillListData>>> {
            return localVarFp.postWaybillPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {DynamicUpdateModel} [dynamicUpdateModel] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybill(dynamicUpdateModel?: DynamicUpdateModel, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putWaybill(dynamicUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WaybillApi - interface
 * 运单 接口
 * @export
 * @interface WaybillApi
 */
export interface WaybillApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    deleteWaybill(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    getWaybill(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    getWaybillDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillFormData>>;

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    getWaybillExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig): Promise<File>;

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    getWaybillPrint(id?: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillPrint>>;

    /**
     * 新增
     * @param {WaybillFormData} [waybillFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    postWaybill(waybillFormData?: WaybillFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    postWaybillImport(file?: File, options?: RawAxiosRequestConfig): Promise<UniformResult<Object>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    postWaybillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    postWaybillSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {DynamicUpdateModel} [dynamicUpdateModel] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApiInterface
     */
    putWaybill(dynamicUpdateModel?: DynamicUpdateModel, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * WaybillApi - object-oriented interface
 * 运单 接口
 * @export
 * @class WaybillApi
 * @extends {BaseAPI}
 */
export class WaybillApi extends BaseAPI implements WaybillApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public deleteWaybill(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).deleteWaybill(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public getWaybill(id: number, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).getWaybill(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public getWaybillDefault(options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).getWaybillDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导出
     * @param {Array<DynamicFilterModel>} [queryInput] 动态查询条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public getWaybillExport(queryInput?: Array<DynamicFilterModel>, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).getWaybillExport(queryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取打印数据
     * @param {number} [id] 主键
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public getWaybillPrint(id?: number, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).getWaybillPrint(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {WaybillFormData} [waybillFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public postWaybill(waybillFormData?: WaybillFormData, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).postWaybill(waybillFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 导入
     * @param {File} [file] 文件流
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public postWaybillImport(file?: File, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).postWaybillImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public postWaybillPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).postWaybillPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public postWaybillSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).postWaybillSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {DynamicUpdateModel} [dynamicUpdateModel] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillApi
     */
    public putWaybill(dynamicUpdateModel?: DynamicUpdateModel, options?: RawAxiosRequestConfig) {
        return WaybillApiFp(this.configuration).putWaybill(dynamicUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WaybillSignApi - axios parameter creator
 * 运单签收 接口
 * @export
 */
export const WaybillSignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybillSign: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteWaybillSign', 'ids', ids)
            const localVarPath = `/api/waybillSign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillSign: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWaybillSign', 'id', id)
            const localVarPath = `/api/waybillSign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillSignDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillSign/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {WaybillSignFormData} [waybillSignFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSign: async (waybillSignFormData?: WaybillSignFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillSign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waybillSignFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSignPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillSign/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSignSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillSign/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillSignFormData>} [customUpdateModelWaybillSignFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybillSign: async (customUpdateModelWaybillSignFormData?: CustomUpdateModel<WaybillSignFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillSign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelWaybillSignFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WaybillSignApi - functional programming interface
 * 运单签收 接口
 * @export
 */
export const WaybillSignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WaybillSignApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWaybillSign(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWaybillSign(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.deleteWaybillSign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillSign(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillSignFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillSign(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.getWaybillSign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillSignDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillSignFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillSignDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.getWaybillSignDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {WaybillSignFormData} [waybillSignFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillSign(waybillSignFormData?: WaybillSignFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillSign(waybillSignFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.postWaybillSign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillSignPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<WaybillSignListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillSignPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.postWaybillSignPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillSignSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillSignSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.postWaybillSignSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillSignFormData>} [customUpdateModelWaybillSignFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWaybillSign(customUpdateModelWaybillSignFormData?: CustomUpdateModel<WaybillSignFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWaybillSign(customUpdateModelWaybillSignFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillSignApi.putWaybillSign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * WaybillSignApi - factory interface
 * 运单签收 接口
 * @export
 */
export const WaybillSignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WaybillSignApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybillSign(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteWaybillSign(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillSign(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillSignFormData>> {
            return localVarFp.getWaybillSign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillSignDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillSignFormData>> {
            return localVarFp.getWaybillSignDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {WaybillSignFormData} [waybillSignFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSign(waybillSignFormData?: WaybillSignFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillSign(waybillSignFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSignPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillSignListData>>> {
            return localVarFp.postWaybillSignPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillSignSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillSignSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillSignFormData>} [customUpdateModelWaybillSignFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybillSign(customUpdateModelWaybillSignFormData?: CustomUpdateModel<WaybillSignFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putWaybillSign(customUpdateModelWaybillSignFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WaybillSignApi - interface
 * 运单签收 接口
 * @export
 * @interface WaybillSignApi
 */
export interface WaybillSignApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    deleteWaybillSign(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    getWaybillSign(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillSignFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    getWaybillSignDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillSignFormData>>;

    /**
     * 新增
     * @param {WaybillSignFormData} [waybillSignFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    postWaybillSign(waybillSignFormData?: WaybillSignFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    postWaybillSignPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillSignListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    postWaybillSignSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<WaybillSignFormData>} [customUpdateModelWaybillSignFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApiInterface
     */
    putWaybillSign(customUpdateModelWaybillSignFormData?: CustomUpdateModel<WaybillSignFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * WaybillSignApi - object-oriented interface
 * 运单签收 接口
 * @export
 * @class WaybillSignApi
 * @extends {BaseAPI}
 */
export class WaybillSignApi extends BaseAPI implements WaybillSignApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public deleteWaybillSign(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).deleteWaybillSign(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public getWaybillSign(id: number, options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).getWaybillSign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public getWaybillSignDefault(options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).getWaybillSignDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {WaybillSignFormData} [waybillSignFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public postWaybillSign(waybillSignFormData?: WaybillSignFormData, options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).postWaybillSign(waybillSignFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public postWaybillSignPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).postWaybillSignPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public postWaybillSignSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).postWaybillSignSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<WaybillSignFormData>} [customUpdateModelWaybillSignFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillSignApi
     */
    public putWaybillSign(customUpdateModelWaybillSignFormData?: CustomUpdateModel<WaybillSignFormData>, options?: RawAxiosRequestConfig) {
        return WaybillSignApiFp(this.configuration).putWaybillSign(customUpdateModelWaybillSignFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WaybillTrackApi - axios parameter creator
 * 运单跟踪 接口
 * @export
 */
export const WaybillTrackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybillTrack: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteWaybillTrack', 'ids', ids)
            const localVarPath = `/api/waybillTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrack: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWaybillTrack', 'id', id)
            const localVarPath = `/api/waybillTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrackDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrack/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {WaybillTrackFormData} [waybillTrackFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrack: async (waybillTrackFormData?: WaybillTrackFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waybillTrackFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackPage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrack/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrack/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillTrackFormData>} [customUpdateModelWaybillTrackFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybillTrack: async (customUpdateModelWaybillTrackFormData?: CustomUpdateModel<WaybillTrackFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelWaybillTrackFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WaybillTrackApi - functional programming interface
 * 运单跟踪 接口
 * @export
 */
export const WaybillTrackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WaybillTrackApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWaybillTrack(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWaybillTrack(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.deleteWaybillTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillTrack(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillTrackFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillTrack(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.getWaybillTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillTrackDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillTrackFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillTrackDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.getWaybillTrackDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {WaybillTrackFormData} [waybillTrackFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillTrack(waybillTrackFormData?: WaybillTrackFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillTrack(waybillTrackFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.postWaybillTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<WaybillTrackListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillTrackPage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.postWaybillTrackPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillTrackSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.postWaybillTrackSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillTrackFormData>} [customUpdateModelWaybillTrackFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWaybillTrack(customUpdateModelWaybillTrackFormData?: CustomUpdateModel<WaybillTrackFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWaybillTrack(customUpdateModelWaybillTrackFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackApi.putWaybillTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * WaybillTrackApi - factory interface
 * 运单跟踪 接口
 * @export
 */
export const WaybillTrackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WaybillTrackApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybillTrack(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteWaybillTrack(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrack(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackFormData>> {
            return localVarFp.getWaybillTrack(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrackDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackFormData>> {
            return localVarFp.getWaybillTrackDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {WaybillTrackFormData} [waybillTrackFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrack(waybillTrackFormData?: WaybillTrackFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillTrack(waybillTrackFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillTrackListData>>> {
            return localVarFp.postWaybillTrackPage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillTrackSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillTrackFormData>} [customUpdateModelWaybillTrackFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybillTrack(customUpdateModelWaybillTrackFormData?: CustomUpdateModel<WaybillTrackFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putWaybillTrack(customUpdateModelWaybillTrackFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WaybillTrackApi - interface
 * 运单跟踪 接口
 * @export
 * @interface WaybillTrackApi
 */
export interface WaybillTrackApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    deleteWaybillTrack(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    getWaybillTrack(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    getWaybillTrackDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackFormData>>;

    /**
     * 新增
     * @param {WaybillTrackFormData} [waybillTrackFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    postWaybillTrack(waybillTrackFormData?: WaybillTrackFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    postWaybillTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillTrackListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    postWaybillTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<WaybillTrackFormData>} [customUpdateModelWaybillTrackFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApiInterface
     */
    putWaybillTrack(customUpdateModelWaybillTrackFormData?: CustomUpdateModel<WaybillTrackFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * WaybillTrackApi - object-oriented interface
 * 运单跟踪 接口
 * @export
 * @class WaybillTrackApi
 * @extends {BaseAPI}
 */
export class WaybillTrackApi extends BaseAPI implements WaybillTrackApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public deleteWaybillTrack(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).deleteWaybillTrack(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public getWaybillTrack(id: number, options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).getWaybillTrack(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public getWaybillTrackDefault(options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).getWaybillTrackDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {WaybillTrackFormData} [waybillTrackFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public postWaybillTrack(waybillTrackFormData?: WaybillTrackFormData, options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).postWaybillTrack(waybillTrackFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public postWaybillTrackPage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).postWaybillTrackPage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public postWaybillTrackSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).postWaybillTrackSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<WaybillTrackFormData>} [customUpdateModelWaybillTrackFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackApi
     */
    public putWaybillTrack(customUpdateModelWaybillTrackFormData?: CustomUpdateModel<WaybillTrackFormData>, options?: RawAxiosRequestConfig) {
        return WaybillTrackApiFp(this.configuration).putWaybillTrack(customUpdateModelWaybillTrackFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WaybillTrackCacheApi - axios parameter creator
 * 运单跟踪缓存 接口
 * @export
 */
export const WaybillTrackCacheApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybillTrackCache: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteWaybillTrackCache', 'ids', ids)
            const localVarPath = `/api/waybillTrackCache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrackCache: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWaybillTrackCache', 'id', id)
            const localVarPath = `/api/waybillTrackCache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrackCacheDefault: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrackCache/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新增
         * @param {WaybillTrackCacheFormData} [waybillTrackCacheFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackCache: async (waybillTrackCacheFormData?: WaybillTrackCacheFormData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrackCache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waybillTrackCacheFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackCachePage: async (paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrackCache/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paginatedDynamicSearchInfos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackCacheSetValid: async (isValidInput?: IsValidInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrackCache/setValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillTrackCacheFormData>} [customUpdateModelWaybillTrackCacheFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybillTrackCache: async (customUpdateModelWaybillTrackCacheFormData?: CustomUpdateModel<WaybillTrackCacheFormData>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/waybillTrackCache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customUpdateModelWaybillTrackCacheFormData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WaybillTrackCacheApi - functional programming interface
 * 运单跟踪缓存 接口
 * @export
 */
export const WaybillTrackCacheApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WaybillTrackCacheApiAxiosParamCreator(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWaybillTrackCache(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWaybillTrackCache(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.deleteWaybillTrackCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillTrackCache(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillTrackCacheFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillTrackCache(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.getWaybillTrackCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWaybillTrackCacheDefault(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<WaybillTrackCacheFormData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWaybillTrackCacheDefault(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.getWaybillTrackCacheDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 新增
         * @param {WaybillTrackCacheFormData} [waybillTrackCacheFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillTrackCache(waybillTrackCacheFormData?: WaybillTrackCacheFormData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillTrackCache(waybillTrackCacheFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.postWaybillTrackCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillTrackCachePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<PagedResult<WaybillTrackCacheListData>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillTrackCachePage(paginatedDynamicSearchInfos, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.postWaybillTrackCachePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWaybillTrackCacheSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWaybillTrackCacheSetValid(isValidInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.postWaybillTrackCacheSetValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillTrackCacheFormData>} [customUpdateModelWaybillTrackCacheFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData?: CustomUpdateModel<WaybillTrackCacheFormData>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<UniformResult<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WaybillTrackCacheApi.putWaybillTrackCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, request, BASE_PATH, configuration)(axios, localVarOperationServerIndex || basePath);
        },
    }
};

/**
 * WaybillTrackCacheApi - factory interface
 * 运单跟踪缓存 接口
 * @export
 */
export const WaybillTrackCacheApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WaybillTrackCacheApiFp(configuration)
    return {
        /**
         * 删除
         * @param {Array<number>} ids 主键数组
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaybillTrackCache(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.deleteWaybillTrackCache(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 通过主键获取单个表单
         * @param {number} id 主键ID
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrackCache(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackCacheFormData>> {
            return localVarFp.getWaybillTrackCache(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取默认表单数据
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWaybillTrackCacheDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackCacheFormData>> {
            return localVarFp.getWaybillTrackCacheDefault(options).then((request) => request(axios, basePath));
        },
        /**
         * 新增
         * @param {WaybillTrackCacheFormData} [waybillTrackCacheFormData] 实体表单
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackCache(waybillTrackCacheFormData?: WaybillTrackCacheFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillTrackCache(waybillTrackCacheFormData, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取分页列表
         * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackCachePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillTrackCacheListData>>> {
            return localVarFp.postWaybillTrackCachePage(paginatedDynamicSearchInfos, options).then((request) => request(axios, basePath));
        },
        /**
         * 启用或禁用单据
         * @param {IsValidInput} [isValidInput] 
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWaybillTrackCacheSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.postWaybillTrackCacheSetValid(isValidInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改
         * @param {CustomUpdateModel<WaybillTrackCacheFormData>} [customUpdateModelWaybillTrackCacheFormData] 实体自定义更新参数
         * @param {RawAxiosRequestConfig} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData?: CustomUpdateModel<WaybillTrackCacheFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>> {
            return localVarFp.putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WaybillTrackCacheApi - interface
 * 运单跟踪缓存 接口
 * @export
 * @interface WaybillTrackCacheApi
 */
export interface WaybillTrackCacheApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    deleteWaybillTrackCache(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    getWaybillTrackCache(id: number, options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackCacheFormData>>;

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    getWaybillTrackCacheDefault(options?: RawAxiosRequestConfig): Promise<UniformResult<WaybillTrackCacheFormData>>;

    /**
     * 新增
     * @param {WaybillTrackCacheFormData} [waybillTrackCacheFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    postWaybillTrackCache(waybillTrackCacheFormData?: WaybillTrackCacheFormData, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    postWaybillTrackCachePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig): Promise<UniformResult<PagedResult<WaybillTrackCacheListData>>>;

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    postWaybillTrackCacheSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

    /**
     * 修改
     * @param {CustomUpdateModel<WaybillTrackCacheFormData>} [customUpdateModelWaybillTrackCacheFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApiInterface
     */
    putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData?: CustomUpdateModel<WaybillTrackCacheFormData>, options?: RawAxiosRequestConfig): Promise<UniformResult<number>>;

}

/**
 * WaybillTrackCacheApi - object-oriented interface
 * 运单跟踪缓存 接口
 * @export
 * @class WaybillTrackCacheApi
 * @extends {BaseAPI}
 */
export class WaybillTrackCacheApi extends BaseAPI implements WaybillTrackCacheApiInterface {
    /**
     * 删除
     * @param {Array<number>} ids 主键数组
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public deleteWaybillTrackCache(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).deleteWaybillTrackCache(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通过主键获取单个表单
     * @param {number} id 主键ID
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public getWaybillTrackCache(id: number, options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).getWaybillTrackCache(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取默认表单数据
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public getWaybillTrackCacheDefault(options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).getWaybillTrackCacheDefault(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新增
     * @param {WaybillTrackCacheFormData} [waybillTrackCacheFormData] 实体表单
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public postWaybillTrackCache(waybillTrackCacheFormData?: WaybillTrackCacheFormData, options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).postWaybillTrackCache(waybillTrackCacheFormData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取分页列表
     * @param {PaginatedDynamicSearchInfos} [paginatedDynamicSearchInfos] 动态分页搜索条件
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public postWaybillTrackCachePage(paginatedDynamicSearchInfos?: PaginatedDynamicSearchInfos, options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).postWaybillTrackCachePage(paginatedDynamicSearchInfos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 启用或禁用单据
     * @param {IsValidInput} [isValidInput] 
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public postWaybillTrackCacheSetValid(isValidInput?: IsValidInput, options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).postWaybillTrackCacheSetValid(isValidInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改
     * @param {CustomUpdateModel<WaybillTrackCacheFormData>} [customUpdateModelWaybillTrackCacheFormData] 实体自定义更新参数
     * @param {RawAxiosRequestConfig} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaybillTrackCacheApi
     */
    public putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData?: CustomUpdateModel<WaybillTrackCacheFormData>, options?: RawAxiosRequestConfig) {
        return WaybillTrackCacheApiFp(this.configuration).putWaybillTrackCache(customUpdateModelWaybillTrackCacheFormData, options).then((request) => request(this.axios, this.basePath));
    }
}



